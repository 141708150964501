import { faker } from '@faker-js/faker';
import 'chartjs-adapter-moment';

import { observable } from 'mobx';
import moment from 'moment';


import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import * as React from 'react';
// { field: 'name', headerName: 'Name', width: 100, editable: true, sortable: false },
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhoneOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import PetsOutlinedIcon from '@mui/icons-material/PetsOutlined';
import QueueMusicOutlinedIcon from '@mui/icons-material/QueueMusicOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import TimeToLeaveOutlinedIcon from '@mui/icons-material/TimeToLeaveOutlined';

import { CategoryScale, ChartData, ChartOptions, ChartSettings, LiniarScale, TimeScale } from '../Stores/Models';
// import {DEFAULT_TITLE_DISPLAY,  DEFAULT_TITLE_FONT_SIZE, DEFAULT_TITLE_FONT_STYLE, DEFAULT_TITLE_FONT_FAMILY} from '../ThemeManager.js';

export const LABELS_TYPE_DAY = 'day';
export const LABELS_TYPE_MONTH = 'month';
export const LABELS_TYPE_WEEK = 'week';
export const LABELS_TYPE_QUARTER = 'quarter';
export const LABELS_TYPE_YEAR = 'year';
export const LABELS_TYPE_CATEGORY = 'category';
export const LABELS_TYPE_NUMERIC = 'numeric';

export const TIME_LABELS = [LABELS_TYPE_DAY, LABELS_TYPE_MONTH, LABELS_TYPE_WEEK, LABELS_TYPE_QUARTER, LABELS_TYPE_YEAR,]

const DATA_TYPE_PRIMITIVE = "primitive"
const DATA_TYPE_XY = "xy"
const DATA_TYPE_XYV = "xyv"
// const DATA_TYPE_CY = "cy"   // 
// const DATA_TYPE_CYR = "cyr" // category, numberic, radius

export const DEFAULT_MIN = 0;
export const DEFAULT_MAX = 1000;
export const DEFAULT_MIN_VALUE = 5;
export const DEFAULT_MAX_VALUE = 50;


export const isTimeType = (labelType) => {
    return TIME_LABELS.includes(labelType)
}

export const defulatTimeFormats = () => {
    // https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
    return {
        'day': "MMM-DD",
        'week': "MMM-DD",
        'month': "MMM 'YY",
        // 'quarter': "MMM-YY",
        'quarter': "[Q]Q 'YY",
        'year': "YYYY",
    }
}

export const generateLiniarLabels = (len, min, max) => [...Array(len - 1).keys()].map((k) => ((k +1) * max / len) + min)

export const generateStepedSeries = (len, start, step) => [...Array(len).keys()].map((k) => k * step + start)
    
export const generateOrderedLabels = ({len, labelType, min=0, max=1000, step=100, maxDate, valueOptions}) => {
	if(isTimeType(labelType)){
        return _timeLabels(len, labelType, maxDate);	
    } 

    if(valueOptions?.length > 0){
        return Array(len).fill().map((_, i) => valueOptions[i % valueOptions.length])
    }

	let dto = getDataTypeObj(labelType);
	if(dto.dgType === 'date') return _timeLabels(len, 'month'); //???
	if(dto.dgType === 'number') return generateStepedSeries(len, min || DEFAULT_MIN, step)		
	return generateSeries({len:len, dataType:labelType, min:min || DEFAULT_MIN, max: max || DEFAULT_MAX})
}

const _timeLabels = (len, labelType, maxDate) =>{
	let start = maxDate ? maxDate : moment()
	start = start.startOf(labelType)	
	let res = [...Array(len).keys()].map((i) => moment(start).subtract(i, labelType))
	return res

} 
const fakeNumber = (min, max) => faker.datatype.number({ 'min': min, 'max': max });


// replce with genseries
export const generateData = (len, min, max) => Array.from({ length: len }, () => fakeNumber(min, max))


// export const generateNextLabel = (len, labelType, labelSubType) => {
//     if(labelType == "time"){

//     } else if(labelType == "liniar"){

//     } else if(labelType == "category"){

//     }
//     switch (labelType) {
//         case LABELS_TYPE_CATEGORY:
//             // return countryList.slice(0, len)
//             // return Array.from({ length: len }, () => faker.commerce.department())
//             return Array.from({ length: len }, () => faker.address.country())
//         case LABELS_TYPE_DAY:
//             return _timeLabels(len, labelType);
//         case LABELS_TYPE_WEEK:
//             return _timeLabels(len, labelType);
//         case LABELS_TYPE_MONTH:
//             return _timeLabels(len, labelType);
//         case LABELS_TYPE_QUARTER:
//             return _timeLabels(len, labelType);
//         case LABELS_TYPE_YEAR:
//             return _timeLabels(len, labelType);
//         case LABELS_TYPE_NUMERIC:
//             return generateData(len, DEFAULT_MIN, DEFAULT_MAX)
//         default:
//             return generateData(len, DEFAULT_MIN, DEFAULT_MAX)
//     }
//     // return labelType === LABELS_TYPE_CATEGORY ? faker.commerce.department() : moment().startOf(labelType).subtract(len, labelType)
    
// }


// todo : replace with object
// export const generataeDataset = function (labels, datasetName, color, dataType) {

//     let data = generateData(labels.length, DEFAULT_MIN, DEFAULT_MAX)
//     let dataObjs = data
//     if (dataType == DATA_TYPE_XYV) {
//         let values = generateData(labels.length, -DEFAULT_MAX_VALUE, DEFAULT_MAX_VALUE);
//         dataObjs = labels.map((l, i) => { return { 'x': l, 'y': data[i], 'r': values[i] } })
//     }
//     return {
//         label: datasetName, //'Dataset 1',
//         data: dataObjs,
//         min: 0,
//         max: 1000,
//         backgroundColor: color,
//         borderColor: color,
//         // datalabels: {
//         //     align: 'end',
//         //     anchor: 'end'
//         // }
//     }
// }

// export const defaultChartSettings = () => new ChartSettings();
// add grace config to y axix?  (e.g. top and bottom margins from last data point)
// const axesType = ['liniar', 'category', 'time']

// export const defaultChartData = (numOfDs, lengthOfDs, labelType) => new ChartData(numOfDs, lengthOfDs, labelType)

// export const defaultCahrtOptions = () => observable(new ChartOptions()); // todo theme


// DATA UTILS



export const DASHING_DATA_TYPES = [

	// dataType is unique, dgType is MUI Data Grid type
    { category: 'Custom', dataType: 'custom', dgType: 'singleSelect', displayName: 'Custom List', example: 'Option 1, Option 2...', icon: <FormatListBulletedOutlinedIcon /> },

    { category: 'Numeric', dataType: 'index', dgType: 'number', displayName: 'Row Number', example: '1,2,3...', icon: <FormatListNumberedOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Numeric', dataType: 'integer', dgType: 'number', displayName: 'Integers', example: '32, 49...', icon: <PinOutlinedIcon sx={{ transform: 'scale(1.1)' }} /> },
    { category: 'Numeric', dataType: 'decimal', dgType: 'number', displayName: 'Decimals', example: '1.32', icon: <PinOutlinedIcon sx={{ transform: 'scale(1.1)' }} /> },
    { category: 'Numeric', dataType: 'percentage', dgType: 'number', displayName: 'Percentage', example: '67%', icon: <PercentOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Numeric', dataType: 'currency', dgType: 'number', displayName: 'Currency', example: '$25.0', icon: <PaidOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },



    { category: 'Date & Time', dataType: 'day', dgType: 'date', displayName: 'Days', example: 'Mar. 18, 2022', icon: <CalendarMonthOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Date & Time', dataType: 'month', dgType: 'date', displayName: 'Months', example: "Mar. '22", icon: <CalendarMonthOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Date & Time', dataType: 'year', dgType: 'date', displayName: 'Years', example: '2022', icon: <CalendarMonthOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Date & Time', dataType: 'past', dgType: 'date', displayName: 'Past Date', example: 'Sep. 15, 2018', icon: <CalendarMonthOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Date & Time', dataType: 'future', dgType: 'date', displayName: 'Future Date', example: 'Jan. 8, 2032', icon: <CalendarMonthOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    // { category: 'Date & Time', dataType: 'weekday', dgType: 'date', displayName: 'Weekday', example: 'Wednesday', icon: <CalendarMonthOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    // { dataType: 'time', dgType: 'dateTime', displayName: 'Time', example: '22:15', icon: <AccessTimeOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },

    { category: 'People', dataType: 'fullname', dgType: 'string', displayName: 'Name', example: 'Johnny Depp', icon: <BadgeOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'People', dataType: 'birthdate', dgType: 'date', displayName: 'Birth Date', example: 'Mar. 22, 1988', icon: <CalendarMonthOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'People', dataType: 'email', dgType: 'string', displayName: 'Email', example: 'johnny.depp@domain.com', icon: <AlternateEmailOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'People', dataType: 'jobtype', dgType: 'string', displayName: 'Job Type', example: 'Designer, Assistant', icon: <BadgeOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'People', dataType: 'jobtitle', dgType: 'string', displayName: 'Job Title', example: 'Global Accounts Engineer', icon: <BadgeOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'People', dataType: 'phone', dgType: 'string', displayName: 'Phone', example: '+48 91 463 61 70', icon: <LocalPhoneIcon sx={{ transform: 'scale(0.8)' }} /> },

    { category: 'People', dataType: 'username', dgType: 'string', displayName: 'Username', example: 'johnny_dp', icon: <BadgeOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },

    { category: 'Geo', dataType: 'countries', dgType: 'string', displayName: 'Countries', example: '🇺🇳 Atlantis', icon: <LanguageOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Geo', dataType: 'countrycode', dgType: 'string', displayName: 'Country Code', example: 'UK', icon: <LanguageOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Geo', dataType: 'cities', dgType: 'string', displayName: 'Cities', example: 'New York', icon: <LanguageOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Geo', dataType: 'states', dgType: 'string', displayName: 'States', example: 'Navada', icon: <LanguageOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Geo', dataType: 'address', dgType: 'string', displayName: 'Address', example: '34830 Abbey Road', icon: <LanguageOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    // { dataType: 'timezone', dgType: 'string', displayName: 'Countries', example: '🇺🇳 Atlantis', icon: <LanguageOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Geo', dataType: 'zipcode', dgType: 'string', displayName: 'Zip code', example: '90210', icon: <LanguageOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },

    { category: 'Business', dataType: 'copmany', dgType: 'string', displayName: 'Copmany Name', example: 'Acme LLC.', icon: <BusinessCenterOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Business', dataType: 'copmanydescription', dgType: 'string', displayName: 'One-Liner', example: 'Upgradable flexibility', icon: <BusinessCenterOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Business', dataType: 'website', dgType: 'string', displayName: 'Website', example: '"https://rabbit.org"', icon: <BusinessCenterOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Business', dataType: 'currencycode', dgType: 'string', displayName: 'Currency Code', example: 'USD', icon: <BusinessCenterOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },

    { category: 'Products', dataType: 'product', dgType: 'string', displayName: 'Prodcuts', example: 'Towels, Gloves', icon: <BusinessCenterOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Products', dataType: 'productname', dgType: 'string', displayName: 'Product Name', example: 'Incredible Soft Gloves', icon: <BusinessCenterOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Products', dataType: 'department', dgType: 'string', displayName: 'Department', example: 'Toys, Garden', icon: <BusinessCenterOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },
    { category: 'Products', dataType: 'productdescription', dgType: 'string', displayName: 'Our Gloves are designed for..', example: 'USD', icon: <BusinessCenterOutlinedIcon sx={{ transform: 'scale(0.8)' }} /> },


    { category: 'General', dataType: 'text', dgType: 'string', displayName: 'Text', example: 'Lorem Ipsum', icon: <AbcOutlinedIcon sx={{ transform: 'scale(1.1)' }} /> },
    { category: 'General', dataType: 'uuid', dgType: 'string', displayName: 'Unique ID', example: 'e89b-12d3-...', icon: <AbcOutlinedIcon sx={{ transform: 'scale(1.1)' }} /> },
    { category: 'General', dataType: 'version', dgType: 'string', displayName: 'Version', example: '1.09.2', icon: <AbcOutlinedIcon sx={{ transform: 'scale(1.1)' }} /> },

    { category: 'General', dataType: 'yesno', dgType: 'string', displayName: 'Yes / No', example: 'Yes, No', icon: <RuleOutlinedIcon sx={{ transform: 'scale(1.1)' }} /> },
    { category: 'General', dataType: 'animals', dgType: 'string', displayName: 'Animals', example: 'Cat, Dog', icon: <PetsOutlinedIcon sx={{ transform: 'scale(1.1)' }} /> },
    { category: 'General', dataType: 'music', dgType: 'string', displayName: 'Music Genre', example: 'Jazz', icon: <QueueMusicOutlinedIcon sx={{ transform: 'scale(1.1)' }} /> },
    { category: 'General', dataType: 'colors', dgType: 'string', displayName: 'Colors', example: 'Red, Blue', icon: <PaletteOutlinedIcon sx={{ transform: 'scale(1.1)' }} /> },
    { category: 'General', dataType: 'cars', dgType: 'string', displayName: 'Cars', example: 'Ford, Tesla', icon: <TimeToLeaveOutlinedIcon sx={{ transform: 'scale(1.1)' }} /> },

    

]


export const getDataTypeObj = dataType => Object.assign({}, DASHING_DATA_TYPES.find(x => x.dataType === dataType))

export const NumericDataTypes = DASHING_DATA_TYPES.filter((t) => t.category === 'Numeric')


export const generateSeries = ({len, dataType='integer', min=0, max=1000, valueOptions=[]}) => { 

	switch(dataType){
		// Numeric
		case 'integer': return Array.from({ length: len }, () => faker.datatype.number({ 'min': min, 'max': max }));
		case 'decimal': return Array.from({ length: len }, () => faker.datatype.number({ min: 10, max: 100, precision: 0.1 }));
		case 'index': return Array.from({length: len}, (x, i) => i+1);
		case 'currency': return Array.from({ length: len }, () => faker.datatype.number({ 'min': min, 'max': max,  }));
		case 'percentage': return Array.from({ length: len }, () => faker.datatype.number({ 'min': 0, 'max': 1, precision: 0.001 }));
				
		// People
		case 'fullname': return Array.from({ length: len }, () => faker.name.fullName());
		case 'firstname': return Array.from({ length: len }, () => faker.name.firstName());
		case 'lastname': return Array.from({ length: len }, () => faker.name.lastName());
		case 'jobtitle': return Array.from({ length: len }, () => faker.name.jobTitle());	
		case 'jobtype': return Array.from({ length: len }, () => faker.name.jobType());
		case 'email': return Array.from({ length: len }, () => faker.internet.email());
		case 'phone': return Array.from({ length: len }, () => faker.phone.number());
		case 'username': return Array.from({ length: len }, () => faker.internet.userName());

		// Geo
		case 'countries': return Array.from({ length: len }, () => faker.address.country());
		case 'cities': return Array.from({ length: len }, () => faker.address.cityName());
		case 'countrycode': return Array.from({ length: len }, () => faker.address.countryCode());
		case 'states': return Array.from({ length: len }, () => faker.address.state()) // [ '37.9163', '-179.2408' ]);
		case 'timezone': return Array.from({ length: len }, () => faker.address.timeZone()) // [ '37.9163', '-179.2408' ]);
		case 'zipcode': return Array.from({ length: len }, () => faker.address.zipCode()) // [ '37.9163', '-179.2408' ]);
		case 'address': return Array.from({ length: len }, () => faker.address.streetAddress()) // [ '37.9163', '-179.2408' ]);
		// case 'longitude': return Array.from({ length: len }, () => faker.address.longitude());
		// case 'latitude': return Array.from({ length: len }, () => faker.address.latitude());
		// case 'latitnearby': return Array.from({ length: len }, () => faker.address.nearbyGPSCoordinate([33, -170], 1000, true) // [ '37.9163', '-179.2408' ]);		
		// DATE
		
		case 'date': return Array.from({ length: len }, () => faker.date.between("'2020-01-01T00:00:00.000Z'", new Date()))
		case 'birthdate': return Array.from({ length: len }, () => faker.date.birthdate({ min: 18, max: 65, mode: 'age' }))
		case 'year': return Array.from({ length: len }, () => faker.date.between("'2020-01-01T00:00:00.000Z'", new Date()))
		case 'month': return Array.from({ length: len }, () => faker.date.between("'2020-01-01T00:00:00.000Z'", new Date()))
		case 'day': return Array.from({ length: len }, () => faker.date.between("'2020-01-01T00:00:00.000Z'", new Date()))
		// case 'year': return Array.from({ length: len }, () => faker.date.between())
		// case 'month': return Array.from({ length: len }, () => faker.date.between())
		// case 'day': return Array.from({ length: len }, () => faker.date.between())
		case 'past': return Array.from({ length: len }, () => faker.date.past(5))// years
		case 'future': return Array.from({ length: len }, () => faker.date.future(5)) // years
		case 'soon': return Array.from({ length: len }, () => faker.date.soon(30)) // days
		case 'recent': return Array.from({ length: len }, () => faker.date.recent(30)) // days
		case 'weekday': return Array.from({ length: len }, () => faker.date.weekday())
		
		// COMPANIES
		case 'copmany': return Array.from({ length: len }, () => faker.company.name() + faker.company.suffixes())
		case 'copmanydescription': return Array.from({ length: len }, () => faker.company.catchPhrase())
		case 'website': return Array.from({ length: len }, () => faker.internet.url());
		case 'currencycode': return Array.from({ length: len }, () => faker.finance.currencyCode() );						
		case 'product': return Array.from({ length: len }, () => faker.commerce.product())
		case 'productname': return Array.from({ length: len }, () => faker.commerce.productName())
		case 'department': return Array.from({ length: len }, () => faker.commerce.department())
		case 'productdescription': return Array.from({ length: len }, () => faker.commerce.productDescription())
		// case 'domain': return Array.from({ length: len }, () => faker.internet.domainName());
		
		// GENERAL
		case 'text': return Array.from({ length: len }, () => faker.lorem.sentence());
		case 'uuid': return Array.from({ length: len }, () => faker.datatype.uuid());
		case 'version': return Array.from({ length: len }, () => faker.system.semver());
		case 'yesno': return Array.from({ length: len }, () => faker.datatype.boolean() ? 'Yes' : 'No');
		case 'animals': return Array.from({ length: len }, () => faker.animal.type())
		case 'musicgenre': return Array.from({ length: len }, () => faker.music.genre())
		case 'colors': return Array.from({ length: len }, () => faker.color.human())
		case 'cars': return Array.from({ length: len }, () => faker.vehicle.manufacturer())
		case 'custom': return Array.from({ length: len }, () => faker.helpers.arrayElement(valueOptions)) 

		default: return Array.from({ length: len }, () => faker.datatype.number({ 'min': min, 'max': max }));
	}
}

export const generateDataPoint = ({dataType, min, max, valueOptions}) => { 
	return generateSeries(({len:1, dataType, min, max, valueOptions}))[0]
}

export const dataValueFormatter = (dataType, value) => {

    // date, day, year, month, percentage, currency
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    switch(dataType){
      case 'percentage':  return `${(value * 100).toFixed(1)}%`;

      case 'birthdate': break;
      case 'day': return moment(value).format('DD/MM/YY');;
      case 'month': return moment(value).format('MMM. DD');;
      case 'year': return moment(value).format('YYYY');;
      case 'future': return moment(value).format('DD/MM/YY');;
      case 'past': return moment(value).format('DD/MM/YY');;
      // case 'weekday': return moment(value).format('MMM - DDD');;

      case 'currency': return currencyFormatter.format(value);

      default: return value ;
    }
  }

  export const getIndexScale = (dataTypeObj, labelType, axis, display) => {
    let indexScale = null;
	if(isTimeType(dataTypeObj.dataType)){
		indexScale = new TimeScale(dataTypeObj.displayName, labelType, axis);
	}else if(dataTypeObj.dgType === 'number') {
		indexScale = new LiniarScale(dataTypeObj.displayName, labelType, axis);
	}else{
	    indexScale = new CategoryScale(dataTypeObj.displayName, labelType, axis);    
    }
    if(display !== undefined){
        indexScale.display = indexScale.display && display; 
    }
    return indexScale;
}
export const generateDataGridRows = (len, startIdx, columns) => {
    let series = Object.assign({}, ...columns.map((col) => ({ [col.field]: generateSeries({len, ...col}) })));
    let rows = Array.from({length: len}, (_, idx) => { return {
        id: startIdx + idx,
        ...Object.assign({}, ...Object.entries(series).map(([key, val]) => ({[key] : val[idx]})))
    }})
    
    return rows;
    
}


export function mergeDeep(target, ...sources) {
    const isObject = (item) => (item  && typeof item === 'object' && !Array.isArray(item));
    if (!sources.length) return target;
	
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
		
		for (let key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);                 
            } else {
                if(moment.isMoment(target)){
                    console.log("target is moment, source is", moment.isMoment(source), source)
                }                				
				// console.log("ASSIGN KEY", key, source[key])
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return mergeDeep(target, ...sources);
}
