import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';


// NOT USED YET
const HideablePropertyWidget = observer(({ hideableObj, onToggle, label }) => {
    return <FormControlLabel
            control={<IconButton aria-label="toggle property"
                onClick={(e) => { console.log("IconButton clicked"); onToggle(e); e.preventDefault()}}
                sx={{pointerEvents: "auto"}}
                edge="end" 
                size="small">
                
                {hideableObj.display ? <VisibilityOutlinedIcon sx={{ fontSize: 14,  }} /> : 
                                        <VisibilityOffOutlinedIcon sx={{ fontSize: 14 }} />
                                        }

            </IconButton>}
            // onClick={(e) => { console.log("FormControlLabel clicked"); e.stopPropagation(); }}
            sx={{ justifyContent: 'space-between', marginLeft: 0, width: '100%', pointerEvents: "none" }}
            labelPlacement="start"
            label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>{label}</Typography>} />

})
export default HideablePropertyWidget;