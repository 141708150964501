import { action, computed, configure, makeAutoObservable, makeObservable, observable, toJS } from 'mobx';
import { v4 as uuid } from 'uuid';
import { generateData, generateOrderedLabels, getDataTypeObj, getIndexScale, LABELS_TYPE_MONTH } from '../Utils/ElementsUtils';
import { Board, ChartTitle, Collaborator, COLLABORATOR_ROLE_VIEWER, DashElement, Dataset, ElementsFactory, Grid, Tab } from "./Models";

import { toJpeg } from 'html-to-image';
import { Analytics } from '../Services/Analytics';
import { getBoardCollaborators, getUserBoards, getUserSharedBoards, inviteToDashboard, saveBoard, saveBoardThumbnail, saveUserForm, updateDBUserName } from '../Services/DBService';
import { generateDataGridRows, generateSeries, mergeDeep } from '../Utils/ElementsUtils';
import { DataGridColumn } from './Models';
import { ThemeStore } from './ThemeManager';

export const GRID_SIZE = 10;

configure({
    enforceActions: "never",
    // enforceActions: "observe",

    // computedRequiresReaction: false,
    // reactionRequiresObservable: false,
    // observableRequiresReaction: false,
    // disableErrorBoundaries: true
})

const snapshots = [];

class UserSourceFormImpl {

    constructor() {
        this.role = '';
        this.tool = '';
        this.source = '';

        makeObservable(this, {
            role: observable,
            tool: observable,
            source: observable,
        })
    }

    reset() {
        this.role = '';
        this.tool = '';
        this.source = '';
    }

    update(prop, val) {
        // TODO analytics
        this[prop] = val
        saveUserForm(toJS(this))
    }

    _emptyOption() {
        return { value: '', label: '' }
    }

}

class UserStoreImpl {

    NO_USER = 'no_user'
    USER_ANON = 'anon'
    AUTHED_USER = 'authed_user'

    constructor() {
        this.currentUser = null;
        this.uid = null;
        this.email = null;
        this.emailVerified = null;
        this.displayName = null;
        this.isAnonymous = null;
        this.photoURL = null;
        this.createdAt = null;
        this.lastLoginAt = null;
        this.sharedBoards = observable.array();
        this.sourceForm = new UserSourceFormImpl()

        // add in the future - licence type, account id, user theme, signup answers, additional user settings, Local,  etc.

        makeObservable(this, {
            currentUser: observable,
            uid: observable,
            email: observable,
            emailVerified: observable,
            displayName: observable,
            isAnonymous: observable,
            photoURL: observable,
            createdAt: observable,
            lastLoginAt: observable,
            sharedBoards: observable,

            authStatus: computed,
            isAuthed: computed,
        })
    }

    signIn(fbUser) {
        this.currentUser = fbUser;
        this.uid = fbUser?.uid;
        this.email = fbUser?.email;
        this.emailVerified = fbUser?.emailVerified
        this.displayName = fbUser?.displayName || fbUser?.providerData ?  fbUser?.providerData[0]?.displayName : null
        this.isAnonymous = fbUser?.isAnonymous
        this.photoURL = fbUser?.photoURL || fbUser?.providerData ? fbUser?.providerData[0]?.photoURL : null
        this.createdAt = fbUser?.createdAt
        this.lastLoginAt = fbUser?.lastLoginAt
    }

    signOut() {
        this.signIn({})
    }

    get userId() {
        return this.uid
    }

    get authStatus() {
        if (this.currentUser === null) return this.NO_USER
        if (this.currentUser.isAnonymous) return this.USER_ANON
        return this.AUTHED_USER
    }
    
    get isAuthed() {
        return this.authStatus === this.AUTHED_USER
    }

    updateUserName(userName){
        console.log("updating user name", userName)
        updateDBUserName(userName).then(() => {
            this.displayName = userName
        })
    }

}
export const UserStore = new UserStoreImpl();


class BoardsListImpl {

    constructor() {
        this.boards = observable.array();
        this.sharedBoards = observable.array();
        this.lastRefresh = null;
        this.loading = false;

        makeObservable(this, {
            boards: observable,
            lastRefresh: observable,
            refresh: action,
        })
    }

    refresh() {
        this.loading = true;
        getUserBoards().then((boards) => {
            if(boards){
                boards.sort((a, b) => b.updatedAt - a.updatedAt);
                this.boards = observable.array(boards);
            }
            this.loading = false;
        })
        if(UserStore.isAuthed){
            getUserSharedBoards().then((sharedBoards) => {
                console.log("SHARED BOARDS", sharedBoards)
                if(sharedBoards){
                    sharedBoards.sort((a, b) => b.updatedAt - a.updatedAt);
                    this.sharedBoards = observable.array(sharedBoards);
                    this.loading = false;
                }
            })
        }
    }

}
export const BoardsList = new BoardsListImpl();

class BoardDoc {

    constructor() {

        this.objective = '';
        this.dashboardType = '';
        this.audiance = '';
        this.decisions = '';
        this.questions = observable.array();
        this.metrics = observable.array();
        this.filters = '';
        this.requirements = null;
        this.enableAI = false;
        makeObservable(this, {
            objective: observable,
            dashboardType: observable,
            audiance: observable,
            decisions: observable,
            questions: observable,
            metrics: observable,
            filters: observable,
            requirements: observable,
            enableAI: observable,
        })
    }
}



class BoardStoreImpl {

    constructor() {

        this.board = null;
        this.boardId = null;
        this.currentUid = null;
        this.currentTabUid = null;
        this.currentTab = null;
        this.currentElem = null;
        this.clipboard = null;
        this.newChartLabelType = LABELS_TYPE_MONTH
        this.isRelaoding = true;
        // this.canvasMode = 'design'; // design | boards | doc
        this.canvasMode = 'design'; // design | home | doc
        this.editorTab = 'board'; // theme | board | element | data
        this.editorSection = null;

        this.doc = new BoardDoc()
        this.themeApplyToAll = true;
        this.showAuthDialog = false;
        this.showInviteDialog = false;
        this.showChartDescriptionDialog = false;
        this.showDataEditDialog = false;
        this.thumbnailUrl = null;
        this.collaborators = observable.array();
        this.isEditMode = true;
        this.rightDrawerOpen = false;

        makeAutoObservable(this, {
            currentUid: observable,
            // currenBoardUid: observable,
            currentTabUid: observable,
            // expandadPanel: observable,
            editorSection: observable,
            newChartLabelType: observable,
            clipboard: observable,
            isRelaoding: observable,
            canvasMode: observable,
            editorTab: observable,
            doc: observable,
            themeApplyToAll: observable,
            showAuthDialog: observable,
            showInviteDialog: observable,
            showDataEditDialog: observable,
            showChartDescriptionDialog: observable,
            collaborators: observable,
            thumbnailUrl: observable,

            setFontSize: observable,

            isEditMode: observable,
            rightDrawerOpen: observable,

            updateDocObjective: action,
            updateTitle: action,
            updateColor: action,
            updateBoardBackgroundColor: action,
            toggleBoardPrivacy: action,
            addDatapoint: action,
            removeDatapoint: action,
            updateLabel: action,
            updateDataheader: action,
            updateCell: action,
            addChart: action,
            addDataset: action,


            updateDocRequirements: action,

            updateBoardTitle: action,
            updateTabTitle: action,
            init: action,
            loadStore: action,
            getTabById: action,
            getChartById: action,
            updateRows: action,
            updateRowsPerPage: action,
            addColumn: action,
            addRows: action,
            updateColumnAlignment: action,
            updateColumnDataType: action,

            currentBoard: computed,
            // currentTab: computed,
            currentTab: observable,
            // current: computed,
            current: observable,

            editMode: computed,

            legenedDirection: computed,
            legenedBottom: computed,
            elementsCount: computed,

        });
    }

    get elementsCount() {
        return this.board.tabs.reduce( (accumulator, t) => accumulator + t.charts.length, 0)
    }

    /* BOARD CRUD */

    updateCurrentBoard() {
        this.board.updatedAt = new Date().getTime();
        // this.board = observable({...this.board})
        this.saveStore()
    }

    get currentBoard() {
        return this.board
    }

    updateBoardTitle(title) {
        this.board.boardName = title
        this.board.title.text = title
        this.updateCurrentBoard()
        Analytics.trackBoardTitleEdit()
    }


    _loadDataFromBoard(otherBoard) {

        otherBoard.board.tabs = observable.array(otherBoard.board.tabs.map(t => mergeDeep(new Tab(), t)))
        otherBoard.board.tabs.forEach(t => {
            t.charts = observable.array(t.charts.map(c => {
                let dummyElement = ElementsFactory(c.chartType, c.chartVariant, t.id)
                let merged = mergeDeep(dummyElement, c)
                return merged;
            }))
        })
        this.board = observable(mergeDeep(new Board(), otherBoard.board))
        this.currentTabUid = otherBoard.currentTabUid
        this.setActiveTab(otherBoard.currentTabUid)


        this.doc = observable(mergeDeep(new BoardDoc(), otherBoard.doc))

        this.collaborators = otherBoard.collaborators ? observable.array(
            Object.values(otherBoard.collaborators).map((c) => { return mergeDeep(new Collaborator(), c) })
        ) : [];
        // console.log("Other board collaborators", otherBoard.collaborators)
        this.thumbnailUrl = otherBoard.thumbnailUrl

        if (otherBoard.currentUid) {
            this.currentUid = otherBoard.currentUid
            this.setActive(this.getChartById(this.currentTabUid, this.currentUid))
        }

        this.isRelaoding = false;

        if (otherBoard.theme) {
            ThemeStore.loadFromDB(otherBoard.theme)
        }
    }

    initFromTemplate(templateBoard) {
        // let boardData = BoardStore.boardToJs()
        // const newBoardId = nanoid();
        BoardStore.createNewBoard();
        templateBoard.id = BoardStore.board.id
        templateBoard.board.id = BoardStore.board.id
        templateBoard.board.ownerId = UserStore.userId
        const objsWithCreateTime = [templateBoard.board, ...templateBoard.board.tabs,
        [].concat.apply([], templateBoard.board.tabs.map((t) => t.charts))]

        objsWithCreateTime.forEach((e) => {
            e.createdAt = new Date();
            e.updatedAt = new Date();
        })
        this._loadDataFromBoard(templateBoard);
        this.saveStore();
    }

    init(otherBoard) {
        if (otherBoard && otherBoard.board) {
            this._loadDataFromBoard(otherBoard)
            Analytics.trackBoardLoad();
            this.saveStore()
        } else {
            console.log("init new board")
            this.createNewBoard()
        }
        this.boardId = this.board.id // ????
    }

    updateBoardOwnerEmail(email) {
        if(this.board){
            this.board.ownerEmail = email;
            this.saveStore()
        }
    }

    updateBoardOwnerId(userId) {
        this.board.ownerId = userId;
        this.saveStore()
    }

    clearStore() {
        this.createNewBoard()
        Analytics.trackClearBoard()
    }

    createNewBoard() {
        this.board = new Board()
        this.addTab();
        this.resetBoardScale();
        this.isRelaoding = false;
        this.currentUid = null;
        this.currentElem = null;
        snapshots.splice(0, snapshots.length)
        Analytics.trackNewBoard()
        this.saveStore()
    }

    /* TAB CRUD */

    updateCurrentTab() {
        this.saveStore()
    }

    getTabById(tabId) {
        return this.board.tabs.find(tab => tab.id === tabId);
    }

    _createTab() {
        var name = `Untitled Sheet ${this._next_untiteled() + 1}`;
        // return {id: uuid(), name: name, charts: observable.map()}
        return new Tab(name)
    }

    _next_untiteled() {
        var max_ut = 0;
        // [...this.currentBoard.tabs].forEach(([idx, tab]) => {
        this.board.tabs.forEach((tab, idx) => {
            if (tab.tabName.startsWith('Untitled Sheet')) {
                var i = parseInt(tab.tabName.split(" ").pop()) || 0
                max_ut = max_ut > i ? max_ut : i
            }
        })
        return max_ut
    }

    setActiveTab(tabId) {
        if (this.currentTab) {
            this.currentTab.isActive = false;
        }
        this.currentTabUid = tabId;
        this.currentTab = this.getTabById(tabId);
        this.currentTab.isActive = true;
        // this.saveStore();
        // this.board.tabs.get(tabId).isActive = true;
        // this.getTabById(tabId).isActive = true;
    }

    addTab() {
        var newTab = observable(this._createTab())
        this.board.tabs.push(newTab);
        this.setActiveTab(newTab.id);
        this.saveStore();
        Analytics.trackNewTab()
        return newTab;
    }

    deleteTab(tabId) {
        console.log("deleting tab", tabId)
        // var otherTabs = [...this.board.tabs.keys()].filter((k) => k !== tabId)
        var otherTabs = this.board.tabs.filter((t) => t.id !== tabId)

        this.currentTabUid = otherTabs[0].id;
        this.currentTab = otherTabs[0]
        otherTabs[0].isActive = true;

        this.board.tabs = otherTabs
        Analytics.trackDeleteTab(tabId)
    }

    updateTabTitle(tabId, title) {
        this.currentTab.tabName = title
        this.updateCurrentTab()
    }

    /* ELEMENT CRUD */

    _applyEdit({ editMode = 'element', editFn, editParams, noSave }) {
        this.elementsToUpdate(editMode).forEach((element) => {
            try {
                editFn({ element, ...editParams })
                this._updateElement(element, noSave)
            } catch (e) {
                console.log(e)
                console.log(('failed to apply edit'))
            }
        })
        this.saveStore()
    }

    elementsToUpdate(editMode) {
        if (editMode === null || editMode === 'element')
            return [this.currentElem]

        if (editMode === 'theme') {
            let elements = [ThemeStore.theme.charts.default]
            if (this.themeApplyToAll) {
                elements.push(...this.board.tabs.map((t) => [...t.charts.map((c) => c)]).flat())
            }
            return elements
        }
    }

    _rerenderElement(element) {
        let toUpdate = element || this.currentElem
        if (toUpdate) {
            toUpdate.chartSettings.renderedAt = new Date().getTime();
        }
    }

    updateCurrent() {
        if (this.currentElem) {
            this.currentElem.chartSettings.updatedAt = new Date().getTime();
        }
        this.saveStore()
    }

    _updateElement(element) {
        if (element) {
            element.chartSettings.updatedAt = new Date().getTime();
        }
    }

    getChartById(tabId, chartId) {
        return this.board.tabs.find(tab => tab.id === tabId)?.charts.find(chart => chart.key === chartId);
    }

    setActive(chart) {
        if (!chart) return
        if (chart.chartSettings.isDataDraggable) {
            chart.chartOptions.plugins.dragData = false
        } // sometimes last save is in data drag mode and locks chart (example: CMD + R)
        if (this.currentUid === chart.key) {
            this.currentElem = this.getChartById(chart.tabId, chart.key)
            return;
        }

        this.setActiveTab(chart.tabId);
        if (this.currentElem) {
            this.getTabById(this.currentElem.tabId).charts.forEach(c => c.isActive = false)
            this.currentElem.isActive = false;
            // this.updateCurrent(true)
            chart.isActive = false;
        }
        if (this.currentElem) { this.currentElem.isActive = false };

        this.currentUid = chart.key;
        this.editorTab = 'element'
        this.currentElem = chart;
        this.currentElem.isActive = true;
        // this.updateCurrent()
    }

    unsetActiveChart() {
        if (this.currentElem) {
            this.currentElem.isActive = false;
            this.updateCurrent(true)
            this.currentUid = null;
            this.currentElem = null;
        }
    }

    addChart(chartType, chartVariant) {
        if (!this.editMode) return;
        let newChart = observable(ElementsFactory(chartType, chartVariant, this.currentTabUid));
        newChart.isActive = true;
        newChart.tabId = this.currentTabUid;

        // this.currentTab.charts.push(newChart);
        this.getTabById(this.currentTabUid).charts.push(newChart);

        // newChart.chartSettings.zIndex = this.currentTab.charts.length + 1
        this.setActive(newChart)
        this.updateCurrent()
        setTimeout(() => this.saveBoardThumbnail(), 5000);
        Analytics.trackNewChart(chartType, chartVariant)
    }

    deleteChart(chart) {
        if (chart) {
            Analytics.trackDeleteChart(chart.key)
            this.unsetActiveChart();
            // this.board.tabs.get(chart.tabId).charts.delete(chart.key);
            let tab = this.getTabById(chart.tabId)

            tab.charts = tab.charts.filter(c => c.key !== chart.key)
            this.currentTabUid = tab.id
            this.currentTab = tab
            this.saveStore()
            setTimeout(() => this.saveBoardThumbnail(), 5000);
        } else {

        }
    }

    /* BOARD PERSIST */

    boardToJs() {
        let boardJs = JSON.parse(JSON.stringify(toJS(this.board)));
        let theme = JSON.parse(JSON.stringify(ThemeStore.theme));

        let boardData = {
            id: boardJs.id,
            board: boardJs,
            doc: JSON.parse(JSON.stringify(toJS(this.doc))),
            theme: theme,
            currentUid: this.currentUid,
            currentTabUid: this.currentTabUid,
            thumbnailUrl: this.thumbnailUrl || '',
            // Collaborators are managed separatly 
        }
        return boardData
    }

    printStore() {
        let toSave = this.boardToJs()
        console.log("-------- store as JS ----------")
        console.log(toSave)
        console.log("-------- </> ----------")
    }

    saveStore() {
        if (!this.editMode) return;
        try {
            let toSave = this.boardToJs()
            saveBoard(toSave)
            snapshots.push(toSave)
            if (snapshots.length > 100) {
                snapshots.shift()
            }

        } catch (e) {
            console.log("failed to seralize ", e)
        }
    }

    loadStore() {
        if (!this.editMode) return;
        // getBoard()
        if (snapshots.length === 0) return
        let snapshot = snapshots.pop();
        let activeTabId = snapshot.currentTabUid;
        let activeChartId = snapshot.currentUid;

        this.doc = snapshot.doc;

        mergeDeep(this.board, snapshot.board);
        this.currentTabUid = activeTabId;
        this.currentUid = activeChartId;
        this.currentTab = this.getTabById(activeTabId);
        this.currentElem = this.getChartById(activeTabId, activeChartId);
        this.setActiveTab(activeTabId);
        this.setActive(this.currentElem);
        this._updateElement(this.currentElem);
        this._rerenderElement(this.currentElem);
    }

    /* COPY / PASTE */

    onCopy() {
        if (this.currentElem) {
            this.clipboard = toJS(this.currentElem);
            Analytics.trackCopyChart();
        }
    }

    onPaste() {
        try {
            if (this.clipboard) {
                var newChart = observable(toJS(this.clipboard));
                if (newChart instanceof DashElement || 1) {
                    newChart.key = uuid();
                    newChart.isActive = true;
                    newChart.tabId = this.currentTabUid;
                    newChart.chartSettings.positionX += 20
                    newChart.chartSettings.positionY += 20
                    this.currentTab.charts.push(newChart);
                    this.setActive(newChart)
                    this.updateCurrent()
                    Analytics.trackPasteChart()
                }
            }
        } catch {
            console.log("paste error")
        }
    }

    // PERMISSIONS

    get editMode() {
        // return this.isEditMode 
        if (!this.board) return false;
        return (this.board.ownerId != null && this.board.ownerId === UserStore.userId) ||
            (UserStore.email && this.board.authedUserEmail === UserStore.email)
        //    TODO: change ownership between boards after signup
    }

    /* EDIT BOARD */

    showGridDots(value) {
        this.board.dottedBackground = value;
    }

    updateBoardScaleUp(value) {
        this.board.scale = Math.min(this.board.scale + 0.05, 1.25)
        this.updateCurrentBoard()
        Analytics.trackBoardEdit('board_scale_up')
    }

    updateBoardScaleDown(value) {
        this.board.scale = Math.max(this.board.scale - 0.05, 0.5)
        this.updateCurrentBoard()
        Analytics.trackBoardEdit('board_scale_down')
    }

    resetBoardScale(value) {
        this.board.scale = 1
        this.board.positionX = Math.max(0, window.innerWidth / 2 - this.board.width / 2 - 120);;
        // this.board.positionX = 20;
        this.board.positionY = 0;
        Analytics.trackBoardEdit('board_scale_reset')
    }

    updateBoardBackgroundColor(color) {
        this.board.backgroundColor = [`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`]
        this.updateCurrentTab()
        this.updateCurrentBoard()
        Analytics.trackBoardEdit('board_background')
    }

    toggleBoardPrivacy() {
        this.board.isPrivate = !this.board.isPrivate;
        this.updateCurrentBoard()
        Analytics.trackToggleBoardPrivacy(this.board.isPrivate)
    }

    updateBoardLocation(d) {
        var noChane = this.board.positionX === d.x && this.board.positionY === d.y;
        if (noChane) return;
        // this.currentElem.chartSettings.positionX = Math.round(d.x / GRID_SIZE) * GRID_SIZE;
        // this.currentElem.chartSettings.positionY = Math.round(d.y / GRID_SIZE) * GRID_SIZE;
        this.board.positionX = Math.round(d.x / GRID_SIZE) * GRID_SIZE;
        this.board.positionY = Math.round(d.y / GRID_SIZE) * GRID_SIZE;

        // this.updateCurrentBoard()
    }

    updateBoardDimentions(value, type) {
        this.board[type] = Number(value) || 0;
        // this.updateCurrentBoard()
        // TODO: Analytics.trackChartEdit('update_board_dimensions')
    }

    /* ELEMENT TITLES */
    _getTitleObj(element, titleType) {

        // var ref = this.currentElem;
        // if (titleType.startsWith("theme_")) {
        //     titleType = titleType.replace("theme_", '')
        //     ref = ThemeStore.defaults
        // }

        if (titleType === "title" ||
            titleType === "subtitle" ||
            titleType === "metric" ||
            titleType === "startAdornment" ||
            titleType === "endAdornment" ||
            titleType === "placeholder" ||
            // titleType === "metricAdornment" || 
            titleType === "submetric") {
            return element.chartOptions.plugins[titleType]
        } else if (titleType === "x" || titleType === "y") {
            return element.chartOptions.scales[titleType].title
        } else if (titleType === "x-ticks") {
            return element.chartOptions.scales.x.ticks
        } else if (titleType === "y-ticks") {
            return element.chartOptions.scales.y.ticks
        } else if (titleType === "legend") {
            return element.chartOptions.plugins.legend.title
        } else if (titleType === "legendlabels") {
            return element.chartOptions.plugins.legend.labels
        } else if (titleType === "datalabels") {
            return element.chartOptions.plugins.datalabels
        } else if (titleType === "boardTitle") {
            return this.board.title
        } else if (titleType === "layout") {
            return element.chartOptions.layout
        } else {
            return element.chartOptions.plugins[titleType]
        }
    }

    updateTitle({ text, titleType, editMode }) {
        this._applyEdit({
            editMode: editMode,
            noSave: true,
            editParams: { text, titleType },
            editFn: ({ element, ...editParams }) => this._getTitleObj(element, titleType).text = text,
        })
        Analytics.trackChartEdit({editType: 'update_title', text, titleType, editMode})
    }

    setFontFamily({ fontFamily, titleType, editMode }) {
        this._applyEdit({
            editMode: editMode,
            noSave: true,
            editParams: { fontFamily, titleType },
            editFn: ({ element, ...editParams }) => this._getTitleObj(element, titleType).font.family = `${fontFamily}`
        })
        Analytics.trackChartEdit({editType: 'set_font', fontFamily, titleType, editMode})
    }

    toggleShowTitle({ titleType, editMode, shouldDisplay }) {
        this._applyEdit({
            editMode: editMode,
            noSave: true,
            editParams: { titleType },
            editFn: ({ element, ...editParams }) => {
                let title = this._getTitleObj(element, titleType)
                if (title) {
                    title.display = shouldDisplay
                }
            }
        })
        Analytics.trackChartEdit({editType: 'toggle_title', titleType, editMode, shouldDisplay})
    }

    // showTitle(titleType) {
    //     this._getTitleObj(titleType).display = true;
    //     this.updateCurrent();
    //     Analytics.trackChartEdit('show_title')
    // }

    setFontSize({ fontSize, titleType, editMode }) {
        // let tickTitle = this._getTitleObj(this.currentElem, titleType)
        // this.currentElem.chartOptions.
        this._applyEdit({
            editMode: editMode,
            editParams: { titleType, fontSize },
            editFn: ({ element, ...editParams }) => {
                this._getTitleObj(element, titleType).font.size = parseInt(fontSize) || 0
            }
        })

        Analytics.trackChartEdit({editType: 'set_font_size', fontSize, titleType, editMode})
    }

    toggleBold({ titleType, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { titleType },
            editFn: ({ element, ...editParams }) => this._getTitleObj(element, titleType).font.weight = this._getTitleObj(element, titleType).font.weight === 'bold' ? 'normal' : 'bold'
        })
        Analytics.trackChartEdit({editType: 'toggle_bold', titleType, editMode})
    }

    updatePadding({ titleType, direction, value, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { titleType, direction, value },
            editFn: ({ element, ...editParams }) => this._getTitleObj(element, titleType).padding[direction] = parseInt(value) || 0
        })
        Analytics.trackChartEdit({editType: 'update_padding', direction, value, editMode})
    }

    // updateSubtitle({text, editMode}) {        
    // this._applyEdit({ 
    //         editMode: editMode, 
    //         editParams: {text},
    //         editFn: ({element, ...editParams}) => element.chartOptions.plugins.subtitle.text = text
    //     })
    //     Analytics.trackChartEdit("subtitle")
    // }

    toggleItalic({ titleType, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { titleType },
            editFn: ({ element, ...editParams }) => this._getTitleObj(element, titleType).font.style = this._getTitleObj(element, titleType).font.style === 'italic' ? 'normal' : 'italic'
        })
        Analytics.trackChartEdit({editType: 'toggle_italic', titleType, editMode})
    }

    updateTitleColor({ color, titleType, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { titleType },
            editFn: ({ element, ...editParams }) => this._getTitleObj(element, titleType).color = [`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`]
        })
        Analytics.trackChartEdit({editType: 'update_title_color', titleType, editMode})
    }

    setTitleAlignment({ align, titleType, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { align, titleType },
            editFn: ({ element, ...editParams }) => this._getTitleObj(element, titleType).align = align
        })
        Analytics.trackChartEdit({editType: 'set_title_alignment', titleType, editMode})
    }

    /* ELEMENT DESCRIPTION */

    updateChartDescription({ newDescription, editMode }) {
        this.currentElem.chartSettings.description = newDescription
    }

    // ////////////
    getChartDescription() {
        // return toJS(this.currentElem.chartSettings.draftjsDescription);
        return this.currentElem.chartSettings.description
    }

    /* ELEMENT LEGEND */

    toggleShowLegend({ editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: {},
            editFn: ({ element, ...editParams }) => element.chartOptions.plugins.legend.display = !element.chartOptions.plugins.legend.display
        })
        Analytics.trackChartEdit({editType: "toggle_show_legend", editMode})
    }

    updateLegend({ direction, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: {},
            editFn: ({ element, ...editParams }) => {
                let isOn = element.chartOptions.plugins.legend.display
                let curDir = element.chartOptions.plugins.legend.position
                element.chartOptions.plugins.legend.display = !(isOn && curDir === direction)
                element.chartOptions.plugins.legend.position = direction
            }
        })
        Analytics.trackChartEdit({editType: "update_legend_direction", direction, editMode})
    }

    alignLegend({ align, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: {},
            editFn: ({ element, ...editParams }) => element.chartOptions.plugins.legend.align = align

        })
        Analytics.trackChartEdit({editType: "update_legend_align", align, editMode})
    }

    /* ELEMENT BORDER & BACKGROUND */


    updateBackgroundColor({ color, editMode }) {
        // console.log("updateBackgroundColor", editMode, color)
        this._applyEdit({
            editMode: editMode,
            editParams: { color: color },
            editFn: ({ element, color }) => element.chartSettings.backgroundColor = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`,
        })
        this._rerenderElement(this.currentElem);
        Analytics.trackChartEdit({editType: "background_color", color: color.hex, editMode})
    }

    updateBorderColor({ color, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { color },
            editFn: ({ element, ...editParams }) => element.chartSettings.borderColor = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
        })
        Analytics.trackChartEdit({editType: "border_color", color: color.hex, editMode})
    }

    updateBorderRadius({ radius, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { radius },
            editFn: ({ element, ...editParams }) => element.chartSettings.borderRadius = parseInt(radius) || 0
        })
        Analytics.trackChartEdit({editType: 'update_radius', radius, editMode})
    }

    updateBorderStroke({ width, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { width },
            editFn: ({ element, ...editParams }) => element.chartSettings.borderWidth = parseInt(width) || 0
        })
        Analytics.trackChartEdit({editType: 'update_border', width, editMode})
    }

    /* ELEMENT LABELS */
    updateLabelColor({ idx, color, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { idx, color },
            editFn: ({ element, ...editParams }) => {
                // console.log("updating colorrrr", idx, element.chartData.datasets[0])
                if (element.chartData.datasets[0].backgroundColor) element.chartData.datasets[0].backgroundColor[idx] = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                if (element.chartData.datasets[0].hoverBackgroundColor) element.chartData.datasets[0].hoverBackgroundColor[idx] = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                if (element.chartData.datasets[0].pointBackgroundColor) element.chartData.datasets[0].pointBackgroundColor[idx] = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                if (element.chartData.datasets[0].pointBorderColor) element.chartData.datasets[0].pointBorderColor[idx] = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                if (element.chartData.datasets[0].pointBorderColor) element.chartData.datasets[0].borderColor[idx] = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
            }
        })
        Analytics.trackChartEdit({editType: "dataset_label_color", color: color.hex, editMode})
    }

    toggleShowDatalabels({ editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: {},
            editFn: ({ element, ...editParams }) => {
                element.chartOptions.plugins.datalabels.display = !element.chartOptions.plugins.datalabels.display
            }
        })
        Analytics.trackChartEdit({editType: "toggle_show_datalabels", editMode})
    }

    alignDatalabels({ align, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { align },
            editFn: ({ element, ...editParams }) => element.chartOptions.plugins.datalabels.align = align
        })
        Analytics.trackChartEdit({editType: "update_datalabels_align", align, editMode})
    }

    anchorDatalabels({ anchor, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { anchor },
            editFn: ({ element, ...editParams }) => element.chartOptions.plugins.datalabels.anchor = anchor
        })
        Analytics.trackChartEdit({editType: "update_datalabels_anchor", anchor, editMode})
    }

    toggleLables({ editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: {},
            editFn: ({ element, ...editParams }) => element.chartOptions.plugins.datalabels.display = !element.chartOptions.plugins.datalabels.display
        })
        Analytics.trackChartEdit({editType: 'toggle_labels_display', editMode})
    }

    updateLabel({ idx, text, editMode }) {
        console.log()
        this._applyEdit({
            editMode: editMode,
            editParams: { idx, text, },
            editFn: ({ element, ...editParams }) => element.chartData.labels[idx] = text
        })
        Analytics.trackChartEdit({editType: 'update_dataset_label', idx, text, editMode})
    }


    // _updateLabelType(element, dataLabel) {
    //     console.log(" _updateLabelType(element, dataLabel)", dataLabel)
    //     if (dataLabel.type === 'category') {
    //         element.chartData.labelType = dataLabel.type;
    //         element.chartOptions.labelType = dataLabel.type;
    //         element.chartOptions.labelSubType = dataLabel.subType;
    //         // len, labelType, min, max, step
    //         element.chartData.labels = generateOrderedLabels({element.chartData.labels.length, dataLabel.type});
    //         let newScale = new CategoryScale("", "category", dataLabel.subType, 'x');

    //         newScale.title = Object.assign(new ChartTitle(), element.chartOptions.scales[element.chartOptions.indexAxis].title)
    //         newScale.grid = Object.assign(new Grid(), element.chartOptions.scales[element.chartOptions.indexAxis].grid)
    //         // newScale.ticks = Object.assign(new Ticks(),this.current.chartOptions.scales[this.current.chartOptions.indexAxis].ticks)
    //         element.chartOptions.scales[this.currentElem.chartOptions.indexAxis] = newScale
    //     }

    //     if (dataLabel.type === 'time') {
    //         element.chartData.labelType = dataLabel.type;
    //         element.chartOptions.labelType = dataLabel.type;
    //         element.chartOptions.labelSubType = dataLabel.subType;
    //         element.chartData.labels = generateOrderedLabels(element.chartData.labels.length, dataLabel.subType);

    //         let newScale = new TimeScale("", "time", dataLabel.subType, 'x');
    //         newScale.title = Object.assign(new ChartTitle(), element.chartOptions.scales[element.chartOptions.indexAxis].title)
    //         newScale.grid = Object.assign(new Grid(), element.chartOptions.scales[element.chartOptions.indexAxis].grid)
    //         element.chartOptions.scales[element.chartOptions.indexAxis] = newScale

    //         element.chartOptions.scales[element.chartOptions.indexAxis].time.unit = dataLabel.subType
    //         element.chartOptions.scales[element.chartOptions.indexAxis].time.displayFormats = defulatTimeFormats()
    //     }

    //     if (dataLabel.type === 'liniar') {
    //         element.chartData.labelType = dataLabel.type;
    //         element.chartOptions.labelType = dataLabel.type;
    //         element.chartOptions.labelSubType = dataLabel.subType;
    //         element.chartData.labels = generateLiniarLabels(element.chartData.labels.length, dataLabel.subType);

    //         let newScale = new LiniarScale("", "liniar", dataLabel.subType, 'x');
    //         newScale.title = Object.assign(new ChartTitle(), element.chartOptions.scales[element.chartOptions.indexAxis].title)
    //         newScale.grid = Object.assign(new Grid(), element.chartOptions.scales[element.chartOptions.indexAxis].grid)
    //         element.chartOptions.scales[element.chartOptions.indexAxis] = newScale
    //     }
    // }

    // _refreshLabels(){}

    updateLabelType(newLabelType, valueOptions) {

        let currentIndexScale = this.currentElem.chartOptions.scales[this.currentElem.chartOptions.indexAxis]

        this.currentElem.chartData.labelType = newLabelType;
        this.currentElem.chartOptions.labelType = newLabelType;
        let dto = getDataTypeObj(newLabelType)
        let newScale = getIndexScale(dto, newLabelType, this.currentElem.chartOptions.indexAxis, currentIndexScale.display)

        //if its the same type we dont need to create a new scale... 
        let title = currentIndexScale.title
        title.text = dto.displayName;
        newScale.title = Object.assign(new ChartTitle(), title)
        newScale.grid = Object.assign(new Grid(), currentIndexScale.grid)
        newScale.stacked = currentIndexScale.stacked

        this.currentElem.chartOptions.scales[this.currentElem.chartOptions.indexAxis] = newScale
        this.currentElem.chartData.valueOptions = valueOptions;

        this.currentElem.chartData.labels = generateOrderedLabels({
            len: this.currentElem.chartData.labels.length,
            labelType: newLabelType,
            step: this.currentElem.chartData.labelsStep,
            max: this.currentElem.chartData.labelsMax,
            min: this.currentElem.chartData.labelsMin,
            valueOptions: valueOptions,
        });
        this.updateCurrent();
        Analytics.trackUpdateLabelType(newLabelType)
    }

    updateLabelStep(labelsStep) {
        this.currentElem.chartData.labelsStep = parseInt(labelsStep) || 0;;
        this.currentElem.chartData.labels = generateOrderedLabels({
            len: this.currentElem.chartData.labels.length,
            labelType: this.currentElem.chartData.labelType,
            step: this.currentElem.chartData.labelsStep,
            max: this.currentElem.chartData.labelsMax,
            min: this.currentElem.chartData.labelsMin,
        });

        let maxLabel = this.currentElem.chartData.labels[this.currentElem.chartData.labels.length - 1]
        this.currentElem.chartOptions.scales[this.currentElem.chartOptions.indexAxis]['max'] = maxLabel;
        this.updateCurrent()
        Analytics.trackUpdateLabelStep(labelsStep)
    }

    updateLabelMin(labelsMin) {
        this.currentElem.chartData.labelsMin = parseInt(labelsMin) || 0;
        this.currentElem.chartData.labels = generateOrderedLabels({
            len: this.currentElem.chartData.labels.length,
            labelType: this.currentElem.chartData.labelType,
            step: this.currentElem.chartData.labelsStep,
            max: this.currentElem.chartData.labelsMax,
            min: this.currentElem.chartData.labelsMin,
        });

        Analytics.trackUpdateLabelMin(labelsMin)
        // let minLabel = this.currentElem.chartData.labels[0]

        this.currentElem.chartOptions.scales[this.currentElem.chartOptions.indexAxis]['min'] = parseInt(labelsMin) || 0;;
        this.updateCurrent()
    }

    updateLabelLastDate(labelsToDate) {
        this.currentElem.chartData.labelsToDate = labelsToDate
        this.currentElem.chartData.labels = generateOrderedLabels({
            len: this.currentElem.chartData.labels.length,
            labelType: this.currentElem.chartData.labelType,
            // step: this.currentElem.chartData.labelsStep,
            // max: this.currentElem.chartData.labelsMax,
            // min: this.currentElem.chartData.labelsMin,
            maxDate: labelsToDate,
        });
        // console.log("updateLabelLastDate", labelsToDate)
        Analytics.trackUpdateLabelMin(labelsToDate)
        this.updateCurrent()
    }


    // setDataLabelType(dataLabel) {
    //     this.currentElem.chartData.changeDataType(dataLabel.subType)
    // }

    /* ELEMENT DATASETS */

    // updateColor(idx, color){
    //     this.defaults.colors.datasets[idx] = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    // }

    // updateSuccessColor(color){
    //     this.defaults.colors.danger = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    // }

    // updateDangerColor(color){
    //     this.defaults.colors.success = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    // }

    // updateColor(dsIdx, color) {
    //     this.currentElem.chartData.datasets[dsIdx].backgroundColor = [`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`]
    //     this.currentElem.chartData.datasets[dsIdx].borderColor = [`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`]
    //     this.updateCurrent()
    //     Analytics.trackChartEdit("dataset_color")
    // }

    updateDSColor({ dsIdx, color, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { dsIdx, color },
            editFn: ({ element, ...editParams }) => {
                if (editMode === 'theme') {
                    ThemeStore.updateColor(dsIdx, color)
                }
                if (element.chartData.datasets.length <= dsIdx) return;
                element.chartData.datasets[dsIdx].backgroundColor = [`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`]
                element.chartData.datasets[dsIdx].borderColor = [`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`]
            }
        })
        Analytics.trackChartEdit({editType: "dataset_color", dsIdx, color: color.hex, editMode})
    }

    updateDSDataType(dsIdx, dataType) {
        this.currentElem.chartData.datasets[dsIdx].dataType = dataType;
        this.updateCurrent()
    }

    setDatasetsSize(numOfRows) {
        let currentSize = this.currentElem.chartData.labels.length;
        let diff = numOfRows - currentSize;
        let op = () => currentSize < 1000 && diff > 0 ? this.addDatapoint() : this.deleteDatapoint()
        Array.from(Array(Math.abs(diff)).keys()).forEach(op)
        Analytics.trackChartEdit({editType: "dataset_size", numOfRows})
    }

    addDatapoint() {
        // let len = this.currentElem.chartData.labels.length;
        let labelType = this.currentElem.chartData.labelType;

        this.currentElem.chartData.labels = generateOrderedLabels({
            len: this.currentElem.chartData.labels.length + 1,
            labelType: labelType,
            step: this.currentElem.chartData.labelsStep,
            max: this.currentElem.chartData.labelsMax,
            min: this.currentElem.chartData.labelsMin,
            valueOptions: this.currentElem.chartData.valueOptions,
        });

        if (this.currentElem?.chartType === 'pie') {
            let colorIdx = this.currentElem.chartData.labels.length % ThemeStore.colors.datasets.length
            let nextColor = ThemeStore.colors.datasets[colorIdx]
            this.currentElem.chartData.datasets[0].backgroundColor.push(nextColor)
            this.currentElem.chartData.datasets[0].hoverBackgroundColor.push(nextColor)
            this.currentElem.chartData.datasets[0].pointBackgroundColor.push(nextColor)
            this.currentElem.chartData.datasets[0].borderColor.push(nextColor)
            // this.currentElem.chartData.datasets[0].pointBorderColor.push('rgba(255,255,255,1)') 
            this.currentElem.chartData.datasets[0].pointBorderColor.push(nextColor)
        }


        this.currentElem.chartData.datasets = this.currentElem.chartData.datasets.map((ds) => {
            return { ...ds, data: [...ds.data, Math.floor(Math.random() * (ds.max - ds.min) + ds.min)] }
        })
        this.updateCurrent();
        Analytics.trackChartEdit({editType: "add_data_point"})

    }

    deleteDatapoint() {
        this.currentElem.chartData.labels.pop();
        this.currentElem.chartData.datasets = this.currentElem.chartData.datasets.map((ds) => { return { ...ds, data: ds.data.slice(0, -1) } })
        this.updateCurrent();
        Analytics.trackChartEdit({editType: "remove_data_point"})
    }

    deleteDatapointAtIndex(dpIdx) {
        this.currentElem.chartData.labels.splice(dpIdx, 1);
        this.currentElem.chartData.datasets = this.currentElem.chartData.datasets.map((ds) => { return { ...ds, data: ds.data.slice(0, -1) } })
        if (this.currentElem?.chartType === 'pie') {
            this.currentElem.chartData.datasets[0].backgroundColor.slice(0, -1)
            this.currentElem.chartData.datasets[0].hoverBackgroundColor.slice(0, -1)
            this.currentElem.chartData.datasets[0].pointBackgroundColor.slice(0, -1)
            this.currentElem.chartData.datasets[0].borderColor.slice(0, -1)
            this.currentElem.chartData.datasets[0].pointBorderColor.slice(0, -1)
        }

        this.updateCurrent();
        Analytics.trackChartEdit({editType: "remove_data_point"})
    }

    addDataset() {
        // var labels = this.currentElem.chartData.labels
        var idx = this.currentElem.chartData.datasets.length
        // todo get next color from pallete 
        // console.log("new data")
        // theme.charts.default.colors[idx]
        // var newData = generataeDataset(labels, `Dataset ${idx + 1}`, 'rgba(0, 200, 0, 0.2)')
        var colorIdx = idx % ThemeStore.colors.datasets.length
        var nextColor = ThemeStore.colors.datasets[colorIdx]
        // console.log("nextColor", nextColor)
        // var newData = generataeDataset(labels, `Dataset ${idx + 1}`, nextColor)
        // console.log("NEW DS LEN ", this.currentElem.chartData.datasets[0].length)

        let newData = new Dataset(this.currentElem.chartData.datasets[0].data.length,
            `Dataset ${idx + 1}`,
            nextColor, 
            null, 
            this.currentElem.chartVariant.includes("fill"), 
            this.currentElem.chartVariant.includes("stacked"), 
            this.currentElem.chartData.datasets.length)
        this.currentElem.chartData.datasets.push(newData)
        // console.log("NEW DS", newData)
        // this.rerenderElement()
        this.updateCurrent();
        // this._rerenderElement();
        Analytics.trackChartEdit({editType: "add_dataset"})
    }

    removeDataset(dsIdx) {

        if (this.currentElem.chartData.datasets.length > 1) {
            if (dsIdx) {
                this.currentElem.chartData.datasets.splice(dsIdx, 1);
                this.currentElem.chartData.labels.splice(dsIdx, 1);
            }
            else {
                // Why is this needed? 
                this.currentElem.chartData.datasets.pop();
                this.currentElem.chartData.labels.pop();
            }
            this.updateCurrent();
            Analytics.trackChartEdit({editType: "remove_dataset"})
        }
    }

    updateCell(dsIndex, dIndex, newData) {
        this.currentElem.chartData.datasets[dsIndex].data[dIndex] = parseInt(newData)
        this.updateCurrent();
        Analytics.trackChartEdit({editType: 'update_datapoint'})
    }

    updateSeriesName(datasetIdx, newLabel) {
        this.currentElem.chartData.datasets[datasetIdx].label = newLabel
        this.updateCurrent();
        Analytics.trackChartEdit({editType: 'update_series_name'})
    }

    updateSeriesIndex(currentIdx, newIdx) {
        const seriesToMove = this.currentElem.chartData.datasets.splice(currentIdx, 1)[0];
        this.currentElem.chartData.datasets.splice(newIdx, 0, seriesToMove);
        // this.currentElem.chartData.datasets[datasetIdx].label = newLabel
        this.updateCurrent();
        Analytics.trackChartEdit({editType: 'update_series_index'})
    }

    updateDatapoint(dsIdx, rowIdx, value) {
        this.currentElem.chartData.datasets[dsIdx].data[rowIdx] = value
        this._updateValueAxisRange(value)
        this.updateCurrent()
        Analytics.trackChartEdit({editType: 'update_datapoint'})
    }


    _updateValueAxisRange(value) {
        let valuesAxis = this.currentElem.chartOptions.indexAxis === 'x' ? 'y' : 'x'
        this.currentElem.chartOptions.scales[valuesAxis]['max'] = Math.max(value, this.currentElem.chartOptions.scales[valuesAxis]['max']);
        this.currentElem.chartOptions.scales[valuesAxis]['min'] = Math.min(value, this.currentElem.chartOptions.scales[valuesAxis]['min']);
    }

    setAxisRangeLimit(axis, limitType, value) {
        this.currentElem.chartOptions.scales[axis][limitType] = parseInt(value) || 0
        this.updateCurrent()
        Analytics.trackChartEdit({editType: 'axis_range', axis, limitType, value})
    }

    setDSRangeMinimum(dsIdx, val, shouldRefreshData) {
        // this.current.chartData.datasets[dsIdx].min = Math.min(val, this.current.chartData.datasets[dsIdx].max)
        this.currentElem.chartData.datasets[dsIdx].min = val
        this._updateValueAxisRange(val)

        if (getDataTypeObj(this.currentElem.chartData.labelType)?.type === 'number') {
            this.currentElem.chartData.labels = generateOrderedLabels({
                len: this.currentElem.chartData.labels.length,
                labelType: this.currentElem.chartData.labelType,
                step: this.currentElem.chartData.labelsStep,
                max: this.currentElem.chartData.labelsMax,
                min: this.currentElem.chartData.labelsMin,
            });
        }
        // let valuesAxis = this.currentElem.chartOptions.indexAxis === 'x' ? 'y' : 'x'
        // this.currentElem.chartOptions.scales[valuesAxis]['min'] = Math.min(val,  this.currentElem.chartOptions.scales[valuesAxis]['min']);

        if (shouldRefreshData) {
            this._refresheDSData(dsIdx)
        }

        this.updateCurrent()
        Analytics.trackChartEdit({editType: 'update_dataset_min', value: val})
    }

    setDSRangeMaximum(dsIdx, val, shouldRefreshData) {

        this.currentElem.chartData.datasets[dsIdx].max = val

        if (shouldRefreshData) {
            this._refresheDSData(dsIdx)
        }
        this._updateValueAxisRange(val)

        // let valuesAxis = this.currentElem.chartOptions.indexAxis === 'x' ? 'y' : 'x'
        // this.currentElem.chartOptions.scales[valuesAxis]['max'] = Math.max(val,  this.currentElem.chartOptions.scales[valuesAxis]['max']);

        this.updateCurrent()
        Analytics.trackChartEdit({editType: 'update_dataset_max', value: val})
    }

    _refresheDSData(dsIdx) {
        // TODO replace with generate series
        this.currentElem.chartData.datasets[dsIdx].data = generateData(this.currentElem.chartData.datasets[dsIdx].data.length,
            Math.min(this.currentElem.chartData.datasets[dsIdx].min, this.currentElem.chartData.datasets[dsIdx].max),
            Math.max(this.currentElem.chartData.datasets[dsIdx].min, this.currentElem.chartData.datasets[dsIdx].max)
        )
        this.updateCurrent()
    }

    /* ELEMENT KPI & SPARK */

    updateSparkColor(color) {
        this.currentElem.chartOptions.plugins.spark.color = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
        this.currentElem.chartOptions.plugins.spark.backgroundColor = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},0.1)`
        this.updateCurrent()
        Analytics.trackChartEdit({editType: 'spark_color', color: color.hex})
    }

    updateSparkWidth(width) {
        this.currentElem.chartOptions.plugins.spark.thikness = parseInt(width) || 0;
        this.updateCurrent()
        Analytics.trackChartEdit("spark_width")
        Analytics.trackChartEdit({editType: 'spark_width', width})
    }

    /* TICKS & GRID*/

    // toggleTickBold({axis, editMode}) {
    //     this._applyEdit({ 
    //         editMode: editMode, 
    //         editParams: {axis},
    //         editFn: ({element, ...editParams}) => element.chartOptions.scales[axis].ticks.font.weight = element.chartOptions.scales[axis].ticks.font.weight === 'bold' ? 'normal' : 'bold'
    //     })  
    //     Analytics.trackChartEdit('toggle_tick_bold')
    //     // this._getTitleObj(titleType).font.weight = this._getTitleObj(titleType).font.weight === 'bold' ? 'normal' : 'bold'               
    // }

    // toggleTickItalic({axis}) {
    //     this.currentElem.chartOptions.scales[axis].ticks.font.style = this.currentElem.chartOptions.scales[axis].ticks.font.style === 'italic' ? 'normal' : 'italic'
    //     this.updateCurrent()
    //     Analytics.trackChartEdit('toggle_tick_italic')
    //     // this._getTitleObj(titleType).font.weight = this._getTitleObj(titleType).font.weight === 'bold' ? 'normal' : 'bold'               
    // }

    updateTickColor(axis, color) {
        this.currentElem.chartOptions.scales[axis].ticks.color = [`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`]
        this.updateCurrent()
        Analytics.trackChartEdit({editType: 'update_tick_color', color: color.hex})
    }

    _setTickAlignment(element, axis, align) {
        let min = 0, max = 45;
        if (align !== 'auto') min = max = parseInt(align);
        element.chartOptions.scales[axis].ticks.maxRotation = max
        element.chartOptions.scales[axis].ticks.minRotation = min
    }

    setTickAlignment({ axis, align, editMode }) {
        console.log("axis, align,", axis, align,)
        this._applyEdit({
            editMode: editMode,
            editParams: { axis, align },
            editFn: ({ element, ...editParams }) => this._setTickAlignment(element, axis, align)
        })
        Analytics.trackChartEdit({editType: 'set_tick_alignment', axis, align, editMode})
    }


    toggleTicksDisplay({ axis, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { axis },
            editFn: ({ element, ...editParams }) => element.chartOptions.scales[axis].ticks.display = !element.chartOptions.scales[axis].ticks.display
        })
        Analytics.trackChartEdit({editType: 'toggle_tick_display', axis, editMode})
    }

    toggleGrid({ axis, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { axis },
            editFn: ({ element, ...editParams }) => element.chartOptions.scales[axis].grid.display = !element.chartOptions.scales[axis].grid.display
        })
        Analytics.trackChartEdit({editType: 'toggle_grid', axis, editMode})
    }

    updateGridColor({ color, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { color },
            editFn: ({ element, ...editParams }) => {
                element.chartOptions.scales.y.grid.color = [`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`]
                element.chartOptions.scales.x.grid.color = [`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`]
            }
        })
        Analytics.trackChartEdit({editType: 'update_grid_color', color: color.hex, editMode})
    }

    updateGridBorderWidth({ width, editMode }) {
        this._applyEdit({
            editMode: editMode,
            editParams: { width },
            editFn: ({ element, ...editParams }) => {
                element.chartOptions.scales.x.grid.borderWidth = width;
                element.chartOptions.scales.y.grid.borderWidth = width;
                element.chartOptions.scales.y.grid.lineWidth = width;
                element.chartOptions.scales.x.grid.lineWidth = width;
            }
        })
        Analytics.trackChartEdit({editType: 'update_grid_border', width, editMode})
    }

    /* POINTS & LINES */
    updateLineTension({ tension, editMode }) {
        this._applyEdit({
            editMode: editMode,
            noSave: false,
            editParams: { tension },
            editFn: ({ element, ...editParams }) => element.chartOptions.elements.line.tension = tension ? 0.5 : 0
        })
        Analytics.trackChartEdit({editType: 'line_tension', tension, editMode})
    }

    setDatapointStyle({ value, editMode }) {
        this._applyEdit({
            editMode: editMode,
            noSave: false,
            editParams: { value },
            editFn: ({ element, ...editParams }) => element.chartOptions.elements.point.pointStyle = value
        })
        // TODO: analytics
        Analytics.trackChartEdit({editType: 'datapoint_style', value, editMode})
    }

    setDatapointRadius({ value, editMode }) {
        this._applyEdit({
            editMode: editMode,
            noSave: false,
            editParams: { value },
            editFn: ({ element, ...editParams }) => element.chartOptions.elements.point.radius = parseInt(value) || 0
        })
        Analytics.trackChartEdit({editType: 'datapoint_radius', value, editMode})
        // TODO: analytics
    }

    /* FILTERS */
    updateSliderRange({ newValues, editMode }) {
        this._applyEdit({
            editMode: editMode,
            noSave: false,
            editParams: { newValues },
            editFn: ({ element, ...editParams }) => element.chartOptions.plugins.range.value = newValues
        })
        Analytics.trackChartEdit({editType: 'slider_range', value: newValues, editMode})
    }

    updateSliderColor(color) {
        this.currentElem.chartOptions.plugins.range.color = [`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`]
        this.updateCurrent()
        Analytics.trackChartEdit({editType: 'slider_color', color: color.hex})
    }

    updateSliderBounds(newValue, valueType) {
        this.currentElem.chartOptions.plugins.range[valueType] = newValue
        Analytics.trackChartEdit({editType: 'slider_range', value: newValue})
        this.updateCurrent()
    }

    toggleIsMultiple() {
        this.currentElem.chartOptions.plugins.listSettings.isMultiple = !this.currentElem.chartOptions.plugins.listSettings.isMultiple
        this.updateCurrent()
    }

    updateDate(pluginName, newDate) {
        if(this.currentElem) {
            this.currentElem.chartOptions.plugins[pluginName] = newDate
            this.updateCurrent()
        }
    }

    updateListOptions(textBlock) {
        this.currentElem.chartOptions.plugins.listOptions = textBlock.split("\n")
        this.updateCurrent()
        Analytics.trackChartEdit({editType: 'update_list_options', options: this.currentElem.chartOptions.plugins.listOptions})
    }

    /* ELEMENT LOCATION */

    // toggleDrag(toggleValue) {
    //     this.currentElem.chartOptions.plugins.dragData = toggleValue ? { round: 0 } : false
    //     this.updateCurrent();
    // }


    updateElementLocation(d) {
        var noChane = this.currentElem.chartSettings.positionX === d.x && this.currentElem.chartSettings.positionY === d.y;
        if (noChane) { return; }
        this.currentElem.chartSettings.positionX = Math.round(d.x / GRID_SIZE) * GRID_SIZE;
        this.currentElem.chartSettings.positionY = Math.round(d.y / GRID_SIZE) * GRID_SIZE;
        this.editorTab = 'element'
        this.updateCurrent();        
    }

    moveElementLocation(direction) {
        if (!this.currentElem) return;
        var val = GRID_SIZE;

        switch (direction) {
            case 'ArrowUp':
                this.currentElem.chartSettings.positionY = Math.max(0, this.currentElem.chartSettings.positionY - val)
                break;
            case 'ArrowDown':
                this.currentElem.chartSettings.positionY = Math.min(this.board.height - this.currentElem.chartSettings.height, this.currentElem.chartSettings.positionY + val)
                break;
            case 'ArrowLeft':
                this.currentElem.chartSettings.positionX = Math.max(0, this.currentElem.chartSettings.positionX - val)
                break;
            case 'ArrowRight':
                this.currentElem.chartSettings.positionX = Math.min(this.board.width - this.currentElem.chartSettings.width, this.currentElem.chartSettings.positionX + val)
                break;
            default:
                break;
        }
        
        this.updateCurrent();
    }

    updateChartdDimentions(value, field) {
        this.currentElem.chartSettings[field] = Number(value)
        this.updateCurrent()
        Analytics.trackChartResized()
    }

    zPlus() {
        const currentElemIdx = this.currentTab.charts.indexOf(this.currentElem)
        if(currentElemIdx < this.currentTab.charts.length -1){
            let temp = this.currentTab.charts[currentElemIdx + 1]
            this.currentTab.charts[currentElemIdx + 1] = this.currentElem
            this.currentTab.charts[currentElemIdx] = temp
        }
        Analytics.trackClickSendToFront()
        this.updateCurrent()
    }
    
    zMinus() {
        const currentElemIdx = this.currentTab.charts.indexOf(this.currentElem)
        if(currentElemIdx > 0){
            let temp = this.currentTab.charts[currentElemIdx - 1]
            this.currentTab.charts[currentElemIdx - 1] = this.currentElem
            this.currentTab.charts[currentElemIdx] = temp
        }
        Analytics.trackClickSendToBack()
        this.updateCurrent()
    }

    /* DATA GRID */

    updateTableColumnHeader(columnIdx, value) {
        this.currentElem.columns[columnIdx].headerName = value;
        this.updateCurrent();
        Analytics.trackChartEdit({editType: 'table_column_header', value})
    }

    updateTableColumnOptions(columnIdx, textBlock) {
        this.currentElem.columns[columnIdx].valueOptions = textBlock;
        this.updateCurrent();
        Analytics.trackChartEdit({editType: 'table_column_options', options: this.currentElem.columns[columnIdx].valueOptions})
    }

    updateTableColumnWidth(columnIdx, value) {
        this.currentElem.columns[columnIdx].width = Number(value) || 80;
        this.currentElem.columns[columnIdx].width = Math.max(Math.min(Number(value), 300), 10)
        this.updateCurrent();
        Analytics.trackChartEdit({editType: 'table_column_width', width: value})
    }

    updateColumnDataType(columnIdx, type, dataType, valueOptions) {
        this.currentElem.columns[columnIdx].type = type;
        this.currentElem.columns[columnIdx].dataType = dataType;
        if (valueOptions) this.currentElem.columns[columnIdx].valueOptions = valueOptions;
        this.populateColumn(columnIdx)
        // TODO generate data
        this.updateCurrent();
        Analytics.trackChartEdit({editType: 'table_column_datatype', dataType: dataType})
    }

    populateColumn(columnIdx) {
        let series = generateSeries({ len: this.currentElem.rows.length, ...this.currentElem.columns[columnIdx] })
        let fieldName = this.currentElem.columns[columnIdx].field
        this.currentElem.rows.forEach((r, idx) => r[fieldName] = series[idx])
    }

    reorderColumns(fromIndex, toIndex) {
        this.currentElem.columns.splice(toIndex, 0, ...this.currentElem.columns.splice(fromIndex, 1))
        Analytics.trackChartEdit({editType: 'table_column_reorder'})
        this.updateCurrent();
    }

    addColumn(type, dataType, valueOptions, columnName) {
        // console.log("adding column", type, dataType, valueOptions)
        let defaultColName = `${dataType} ${this.currentElem.columns.length + 1}`
        let col = new DataGridColumn(this.currentElem.columns.length + 1, type || 'number', dataType || 'number', columnName || defaultColName, valueOptions)
        this.currentElem.columns.push(col)

        this.populateColumn(col.index - 1)
        this._updateTableWidth();
        this._updateColumnIndexes();
        this.updateCurrent();
        Analytics.trackChartEdit({editType: 'table_column_add', dataType})
    }

    _updateTableWidth() {
        let fitColsWidth = this.currentElem.columns.reduce((accumulator, column) => accumulator + column.width, 0) + 50;
        this.currentElem.chartSettings.width = Math.max(this.currentElem.chartSettings.width, fitColsWidth)
    }

    _updateColumnIndexes() {
        this.currentElem.columns.forEach((col, idx) => col.index = idx)
    }

    updateRowsPerPage(rows) {
        let rowsPerPage = Number(rows) || 10;
        rowsPerPage = Math.max(Math.min(rowsPerPage, 100), 1)
        this.currentElem.chartSettings.rowsPerPage = rowsPerPage
        this.updateCurrent();
    }

    updateRows(numOfrows) {

        let diff = numOfrows - this.currentElem.rows.length
        if (diff < 0) {
            this.currentElem.rows = this.currentElem.rows.splice(0, Math.abs(numOfrows))
        } else {
            this.addRows(diff)
        }
        this.updateCurrent();
        Analytics.trackChartEdit({editType: 'table_update_rows', numOfrows})
    }

    addRows(numofRowsToAdd) {
        let newRows = generateDataGridRows(numofRowsToAdd, this.currentElem.rows.length, this.currentElem.columns);
        this.currentElem.rows = this.currentElem.rows.concat(newRows)
    }

    distributeColumnsEvenly() {
        let colWidth = (this.currentElem.columns.reduce((accumulator, column) => accumulator + column.width, 0)) / this.currentElem.columns.length;
        colWidth = Math.floor(colWidth);
        this.currentElem.columns.forEach((col, idx) => col.width = colWidth)
        this.updateCurrent();
    }

    deleteColumn(columnIdx) {
        console.log("deleteColumn", columnIdx)
        this.currentElem.columns.splice(columnIdx, 1);
        this._updateTableWidth();
        this._updateColumnIndexes();
        this.updateCurrent();
        Analytics.trackChartEdit({editType: 'table_column_delete'})
    }

    updateColumnAlignment(columnIdx, newAlignment) {
        this.currentElem.columns[columnIdx].align = newAlignment
        this.currentElem.columns[columnIdx].headerAlign = newAlignment
        this.updateCurrent();
        Analytics.trackChartEdit({editType: 'table_column_alignment', alignment: newAlignment})
    }

    updateTableColumnDescription(columnIdx, description) {
        this.currentElem.columns[columnIdx].description = description
        // this.updateCurrent(); 
    }

    /*  PLANNING DOC  */

    updateDocObjective(value) {
        this.doc.objective = value;
        this.updateCurrent();
        // Analytics.trackUpdatePlanningDoc({editType: 'objective', value})
    }

    updateDocType(value) {
        this.doc.dashboardType = value;
        this.updateCurrent();
        Analytics.trackUpdatePlanningDoc({editType: 'doc_type', value})
    }

    updateDocAudiance(value) {
        this.doc.audiance = value;
        this.updateCurrent();
        // Analytics.trackUpdatePlanningDoc({editType: 'audiance', value})
    }

    updateDocDecisions(value) {
        this.doc.decisions = value;
        this.updateCurrent();
        // Analytics.trackUpdatePlanningDoc({editType: 'decisions', value})
    }

    updateDocAddQuestion(question) {
        this.doc.questions.push({ question: question })
        this.doc.questions = [...this.doc.questions]
        this.updateCurrent();
        Analytics.trackUpdatePlanningDoc({editType: 'add_question', question})
    }

    updateDocRemoveQuestion(idx) {
        this.doc.questions.splice(idx, 1)
        this.doc.questions = [...this.doc.questions]
        this.updateCurrent();
        Analytics.trackUpdatePlanningDoc({editType: 'remove_question'})
    }

    updateDocAddMetric(metricName, description) {
        this.doc.metrics.push({ name: metricName, description: description })
        this.doc.metrics = [...this.doc.metrics]
        this.updateCurrent();
        Analytics.trackUpdatePlanningDoc({editType: 'add_metric', metricName, description})
    }

    updateDocRemoveMetric(idx) {
        this.doc.metrics.splice(idx, 1)
        this.doc.metrics = [...this.doc.metrics]
        this.updateCurrent();
    }

    updateDocRequirements(newState) {
        this.doc.requirements = newState
    }

    updateDocFilters(value) {
        this.doc.filters = value
        this.updateCurrent();
    }

    applyTheme() {
        this.board.tabs.forEach((t) => {
            t.charts.forEach((c) => console.log("applyTheme", c))
        })
    }

    /* COLABORATORS */

    addCollaborator(toEmail) {

        // call server function
        // if user does not exists: 
        //      add collaborator to board with status invited
        // if user exists: 
        //      get user info and add collaborator to board

        // let sender = UserStore.displayName || UserStore.email
        // let collaborator = new Collaborator(null, toEmail, COLLABORATOR_ROLE_VIEWER, COLLABORATOR_STATUS_SENT)
        let collaborator = new Collaborator(toEmail, COLLABORATOR_ROLE_VIEWER)
        this.collaborators.push(collaborator)
        // this.saveStore() ???? 
        inviteToDashboard({ boardName: BoardStore.board.title.text, boardId: BoardStore.board.id, collaborator: toJS(collaborator) }).then(_ => {
            this.refreshCollaborators()
        })

        Analytics.trackInviteCollaborator({toEmail})
    }

    removeCollaborator(collaboratorId) {
        this.collaborators = this.collaborators.filter(collaborator => collaborator.id !== collaboratorId);
        Analytics.trackRemoveCollaborator(collaboratorId)
        // saveCollaborators(this.board.id, this.collaborators).then(() => {
        //     this.refreshCollaborators();
        // })
        // this.saveStore(); TODO: Update Collaborators???
        
        // TODO move to SERVER function!! 
    }

    refreshCollaborators() {
        if (this.board)
            getBoardCollaborators(this.board.id).then(dbCollaborators => {
                console.log("getBoardCollaborators", dbCollaborators)
                if(dbCollaborators !== undefined && dbCollaborators.length > 0)
                    // this.collaborators = observable.array([...dbCollaborators.map((k, v) => mergeDeep(new Collaborator(), c))])
                    this.collaborators = Object.entries(dbCollaborators).map(([k, v]) => mergeDeep(new Collaborator(), v))
            })
    }


    /* THUMBNAILS */

    async saveBoardThumbnail() {
        // toBlob(document.getElementById('main-board'), { backgroundColor: BoardStore.board.backgroundColor[0], })
        //     .then(async (imageBlob) => {
        //         let thumbnailUrl = await saveBoardThumbnail(this.board.id, imageBlob)
        //         this.thumbnailUrl = thumbnailUrl
        //     })



        // TODO: Fix 

        if (this.board.tabs[0].id !== this.currentTabUid) return;
        const boardId = this.board.id
        try {
            let boardDomElem = document.getElementById('main-board')
            // const fontEmbedCss = await getFontEmbedCSS(boardDomElem);
            toJpeg(boardDomElem,
                {
                    backgroundColor: BoardStore.board.backgroundColor[0],
                    useCorsEverywhereProxy: true,
                    fontEmbedCSS: {},
                    preferredFontFormat: {},
                    quality: 0.95
                }).then(async function (dataUrl) {
                    let thumbnailUrl = await saveBoardThumbnail(boardId, dataUrl)
                    BoardStore.thumbnailUrl = thumbnailUrl
                });
        } catch { }
    }
}


export const BoardStore = new BoardStoreImpl();
