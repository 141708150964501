import { Dialog, DialogTitle, FormControlLabel, FormGroup, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
// import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import EditElementDataTable from '../EditableTable';

// import IconButton from '@mui/material/IconButton';

import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { observer } from 'mobx-react-lite';
import { DASHING_DATA_TYPES, getDataTypeObj } from '../../../Utils/ElementsUtils';
import { ColumnDataTypeWidget } from '../EditorWidgets/ColumnInputWidget';
import NumberInputWidget from '../EditorWidgets/NumberInputWidget';
import PlusMinusWidget from '../EditorWidgets/PlusMinusWidget';
import { PropertyDateWidget } from '../EditorWidgets/PropertyDateWidget';

// import DescriptionTextbox from '../Elements/TitleBox';
// import PaddingWidget from './EditorWidgets/PaddingWidget';
// import persistSchema from '../Stores/PersistManager';
// import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import EditIcon from '@mui/icons-material/Edit';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';



const EditNumericDataProps = observer(() => {
  return <Box sx={{marginY:1}}>
    <NumberInputWidget
      fontSize={12}
      key="update-label-min-input"
      numericValue={BoardStore.currentElem.chartData.labelsMin}
      onValueChange={(e) => BoardStore.updateLabelMin(e.target.value)}
      label={"Minimum value"}
    />
    <NumberInputWidget
      fontSize={12}
      key="update-step-input"
      numericValue={BoardStore.currentElem.chartData.labelsStep}
      onValueChange={(e) => BoardStore.updateLabelStep(e.target.value)}
      label={"Step Size"}
    />
  </Box>
})

const EditDataDateProps = observer(() => {
  return <Box sx={{ marginY: 1 }}>
    <PropertyDateWidget label={"End Date"}
      value={BoardStore.currentElem.chartData.labelsToDate}
      onSelect={(selectedDate) => BoardStore.updateLabelLastDate(selectedDate)} />
  </Box>
})

const EditDataLabelType = observer(() => {
  return <FormControlLabel
    control={<ColumnDataTypeWidget
      dataTypes={DASHING_DATA_TYPES}
      valueOptions={BoardStore.currentElem.chartData.valueOptions}
      currentDataType={BoardStore.currentElem.chartOptions.labelType}
      aggregateCategories={true}
      onSelect={(columnIdx, type, dataType, valueOptions) =>
        BoardStore.updateLabelType(dataType, valueOptions)
        // TODO custom options
      }
    ></ColumnDataTypeWidget>}
    sx={{ justifyContent: 'space-between', width: '100%', marginLeft: 0, paddingX: 0, alignItems: 'center', }}
    labelPlacement="start"
    label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>Labels type</Typography>} />

})

export const EditDataProps = observer(() => {
  return <FormGroup sx={{ marginX: 1, marginBottom: 0, gap:0 }}>

    <Box sx={{ marginRight: 1 }}>
      <PlusMinusWidget onPlus={() => BoardStore.addDataset()} label={"Add Data Series"} fontSize={12} />
    </Box>

    
    <Box sx={{ marginBottom: 1 }}>
      <NumberInputWidget
        fontSize={12}
        showUnderline={true}
        numericValue={BoardStore.currentElem.chartData.labels.length}
        onValueChange={(e) => BoardStore.setDatasetsSize(e.target.value)}
        label={"# of Rows: "}
      />
    </Box>

    <Box sx={{ marginY: 1, marginX:0 }}>
    <EditDataLabelType />
    </Box>

    {getDataTypeObj(BoardStore.currentElem.chartData.labelType).dgType === 'number' &&
      <EditNumericDataProps />
    }

    {getDataTypeObj(BoardStore.currentElem.chartData.labelType).dgType === 'date' &&
      <EditDataDateProps />
    }

  </FormGroup>
})


const EditDataSection = observer(() => {
  // const [selectedDsIdx, setSelectedDsIdx] = React.useState(0);

  // const handleChange = (event) => {
  // setFont(event.target.value);
  // // ChartStore.setFontFamily(event.target.value, titleType)
  // };



  return <>
    {BoardStore.currentElem ? <>

      <Box sx={{ width: '90%', marginTop: '20px', opacity: BoardStore.editMode ? 1 : 0.4,  }}>

        <EditDataProps />

      </Box>


      <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
        <EditDataModal />
      </Box>
      <Box sx={{ maxHeight: 400, overflow: 'scroll' }}>
        <EditElementDataTable />
      </Box>


      {BoardStore.editMode && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 1 }}>
        <TipsAndUpdatesOutlinedIcon sx={{ fontSize: 14, marginY: 1.5 }} />
        <Typography color="textSecondary" sx={{ fontSize: 12, fontStyle: 'italic', textTransform: 'none', marginTop: 0 }}>
          Double click cells to edit them
        </Typography>
      </Box>}



      <Box sx={{ padding: 2, width: '70%', display: 'flex', alignItems: 'left', flexDirection: 'column', justifyContent: 'center' }}>

      </Box>



    </>
      : ''}
  </>;
})

export default EditDataSection;


const EditDataModal = observer(() => {

  const handleClickOpen = () => {
    BoardStore.showDataEditDialog = true;
  };

  const handleClose = () => {
    BoardStore.showDataEditDialog = false;
  };

  return <Box>
    <IconButton aria-label="edit" sx={{ width: 24, height: 24 }}
      onClick={handleClickOpen}>
      <OpenInFullOutlinedIcon sx={{ fontSize: 18 }} />
    </IconButton>

    <Dialog open={BoardStore.showDataEditDialog} onClose={handleClose} fullWidth maxWidth={'md'} >
      <DialogTitle>Edit Chart Data
        <IconButton aria-label="edit" sx={{ position: 'absolute', top: '10px', zIndex: 10, right: '10px', width: 24, height: 24 }} onClick={handleClose}>

          <ClearOutlinedIcon />

        </IconButton>
      </DialogTitle>
      {/* <DialogContent sx={{minHeight: 900, display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
      <DialogContent >
        <Box sx={{ display: 'flex', justifyContent: 'start', width: '100%', gap: 2 }}>
          <Box sx={{ minWidth: '220px', paddingRight: 2, borderRight: '1px solid rgba(200,200,200,0.50)' }}>
            <EditDataProps />
          </Box>

          {/* <Box sx={{ }}>

    <Divider orientation='vertical' sx={{marginX: 4, color: 'blue', fontSize: 14, fontStyle: 'italic', fontWeight: '300',
    '                                   ::after , ::before': { borderColor: 'blue' }}} />
</Box> */}
          <Box sx={{ maxHeight: '80vh', overflow: 'scroll', }}>

            <EditElementDataTable />
          </Box>
        </Box>

      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  </Box>
})