import Box from '@mui/material/Box';
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Fade from '@mui/material/Fade';

export function CopySnackbar(open, setOpen) {
  return <Snackbar
    open={open}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    autoHideDuration={1000}
    onClose={() => setOpen(false)}
    TransitionComponent={Fade}
    sx={{
      borderRadius: '50px',      
      minWidth: 0,
      minHeight: 0,
      marginTop: '40px',
      width: 120,
      height: 30,
      '& .MuiSnackbarContent-root': {
        minWidth: 0,
      },
    }}
  >
    <SnackbarContent sx={{
      backgroundColor: 'rgba(153, 209, 245, 1)',
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.8)',
      minWidth: 0,
      height: 30,
      borderRadius: '10px',
      '& .MuiSnackbarContent-message': {
        height: 30,
        padding: '0',
        textAlign: 'center',
        margin: '0 auto',
      },
    }}
      message={<Box component={"span"}>Copied Chart!</Box>} />

  </Snackbar>;
}
