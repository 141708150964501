import { ClickAwayListener, Divider, FormControlLabel, FormGroup, Grid, IconButton, ToggleButton, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { BoardStore } from '../../Stores/Stores';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useState } from 'react';
import { getDataTypeObj } from '../../Utils/ElementsUtils';
import ColorPickerWidget from './EditorWidgets/DashingColors';
import DSMinMaxFields from './EditorWidgets/MinMaxFields';
const EditElementDataTable = observer(() => {
    return (<Grid container>
        <ElementDataTable chart={BoardStore.currentElem} />
    </Grid>
    );
})


export default EditElementDataTable;


const CustomColumnMenuComponent = observer((props) => {
    // const { hideMenu, currentColumn, color, chart, ...other } = props;
    const { hideMenu, currentColumn } = props;
    let columnIdx = currentColumn.colIdx - 1;

    return BoardStore.currentElem.chartData.datasets[columnIdx] && <ClickAwayListener onClickAway={hideMenu} >
        <Box sx={{ marginTop: 1, border: '1px solid rgba(64,64,64,0.5)', borderRadius: 1, paddingX: 2, position: 'absolute', left: -20, backgroundColor: 'white', width: 220 }}
        // onClick={(e) => { e.stopPropagation(); e.preventDefault() }}
        >

            <Box sx={{ widht: '100%', margin: 1, marginTop: 2 }}>
                <FormGroup>
                    <FormControlLabel
                        control={<ToggleButton key="updateGridColor" value="color" aria-label="color"
                            disableRipple disableFocusRipple
                            sx={{ border: 'none', padding: 1 }}>
                            <ColorPickerWidget color={BoardStore.currentElem.chartData.datasets[columnIdx]?.backgroundColor}
                                updateColor={(color, e) => BoardStore.updateDSColor({ dsIdx: columnIdx, color })} />
                        </ToggleButton>}
                        sx={{ justifyContent: 'space-between', marginLeft: 0 }}
                        labelPlacement="start"
                        label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>Series Color</Typography>} />
                </FormGroup>

                <FormGroup sx={{ width: '100%' }}>

                    <FormControlLabel control={<Box sx={{ '& .MuiSvgIcon-root': { fontSize: 12 } }}>
                        <IconButton aria-label="move-left" disabled={columnIdx === 0} onClick={() => BoardStore.updateSeriesIndex(columnIdx, columnIdx - 1)}> <ArrowBackIosIcon /> </IconButton>
                        <IconButton aria-label="move-right" disabled={columnIdx === BoardStore.currentElem.chartData.datasets.length - 1} onClick={() => BoardStore.updateSeriesIndex(columnIdx, columnIdx + 1)}> <ArrowForwardIosIcon /> </IconButton>
                    </Box>
                    }
                        sx={{ justifyContent: 'space-between', marginLeft: 0, }}
                        labelPlacement="start"
                        label={<Typography component={'span'} color="textSecondary" sx={{ fontSize: 12 }}>Move Column</Typography>} />

                </FormGroup>

                <FormGroup >
                    <FormControlLabel
                        control={<ButtonGroup disableElevation variant="contained">
                            <DSMinMaxFields dsIdx={columnIdx} shouldRefreshData={true}/>
                        </ButtonGroup>}
                        sx={{ justifyContent: 'space-between', marginLeft: 0, paddingX: 0, alignItems: 'baseline' }}
                        labelPlacement="start"
                        label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>Values Range</Typography>} />
                </FormGroup>

                <Divider sx={{ marginTop: 1 }}></Divider>
                <FormGroup sx={{ marginTop: 0 }}>
                    <FormControlLabel
                        control={<ButtonGroup disableElevation variant="contained" onClick={() => { }}>
                            <IconButton aria-label="add column" disableFocusRipple disableRipple sx={{ color: 'rgba(247, 76, 76, 1)', transform: 'scale(0.8)' }}
                                onClick={(e) => { BoardStore.removeDataset(columnIdx - 1) }}> <DeleteOutlineOutlinedIcon /> </IconButton>
                        </ButtonGroup>}
                        sx={{ justifyContent: 'space-between', marginLeft: 0, paddingX: 0 }}
                        labelPlacement="start"
                        label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>Delete Series</Typography>} />
                </FormGroup>
            </Box>
        </Box>
    </ClickAwayListener>
})

function CustomPagination() { return (<></>);}

// const CustomEditComponent = observer((params) => {
//     const { id, value, field, colDef } = params;
//     console.log("CustomEditComponent", id, value, field, colDef)
//     if(field === INDEX_COL_FIELD){

//     }
//     // const dsIdx = 0;
//     // console.log("colDef.colidx", colDef.colIdx)
//     // const getDsIdx = () => {
//     //     BoardStore.currentElem.chartData.datasets.forEach()
//     // };

//     const onEditCell = val => {

//         if(field === INDEX_COL_FIELD){
//             BoardStore.updateTitle({ text: val, titleType: 'x' }) 
//         }else{
//             BoardStore.updateDatapoint(colDef.colIdx - 1, id, val)
//         }        
//     }


//     return <TextField
//         type="number"
//         autoFocus
//         value={field === INDEX_COL_FIELD ?
//             BoardStore.currentElem?.chartOptions.scales['x'].label :
//             BoardStore.currentElem?.chartData.datasets[colDef.colIdx - 1].data[id]}
//         onChange={(e) => onEditCell(e.target.value)}
//         InputProps={{ disableUnderline: true }}
//         inputProps={{
//             style: { fontSize: 12, textAlign: 'left' },
//             inputMode: 'numeric',
//             pattern: '[0-9]*',
//             padding: 0
//         }}
//         variant="standard"
//     />
// })

const EditableColumnHeader = observer(({ chart, column, colIdx }) => {
    const [showUnderline, setShowUnderline] = useState(false)
    return (
        <div>
            <TextField size="small"
                key={`column-header-input-${column}`}
                onMouseEnter={() => setShowUnderline(true)}
                onMouseLeave={() => setShowUnderline(false)}
                disabled={!BoardStore.editMode}
                sx={{
                    width: `${column.width - 30}`, alignSelf: 'flex-start', marginTop: '4px', 
                    // "& .MuiInputBase-input": { WebkitTextFillColor: "#0000000", }
                }}
                variant="standard"
                margin="dense"
                value={colIdx === 0 ? chart.chartOptions.scales['x'].title.text : chart.chartData.datasets[colIdx - 1]?.label}
                onKeyDown={e => { e.stopPropagation(); }}
                onKeyUp={e => { e.stopPropagation(); }}
                onChange={(event) => {
                    if (colIdx === 0) {
                        BoardStore.updateTitle({ text: event.target.value, titleType: 'x' });
                    } else {
                        BoardStore.updateSeriesName(colIdx - 1, event.target.value);
                    }
                }}
                InputProps={{
                    disableUnderline: !BoardStore.editMode || !showUnderline,
                }}
                inputProps={{
                    style: { fontSize: 12, paddingBottom: 3, textAlign: 'left', fontWeight: 500, '& .Mui-disabled': { color: 'red' }, },
                }}
            />
            {/* <ColumnDrag onDisableDrag={onDisableDrag} style={{alignSelf: 'flex-end'}} column={column} flexItem onHover={(e)=> console.log("hover column drag")}/> */}

            {/* </Box> */}

            {/* </Popper> */}
        </div>
    );
})


const CustomColumnMenuIcon = (props) => <ArrowDropDownOutlinedIcon sx={{ backgroundColor: 'rgba(242,242,242,0.7)', zIndex: 10, }} />


const INDEX_COL_FIELD = 'indexCol';


const updateCell = (cellData, event) => {
    // if (!BoardStore.editMode) return;
    const { id, field, colDef, row, } = { ...cellData }
    const newVal = colDef.type === 'date' ? moment(event.target.value, 'YYYY-MM-DD') : event.target.value
    if (field === INDEX_COL_FIELD) {
        BoardStore.updateLabel({ idx: row.id, text: newVal })
    } else {
        BoardStore.updateDatapoint(colDef.colIdx - 1, id, newVal)
    }

}

const ElementDataTable = observer(({ chart }) => {

    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [dgType, setDgType] = React.useState();

    const colWidth = Math.max(80 / (chart.chartData.datasets.length), 80);
    // const tableWidth = (1 + chart.chartData.datasets.length) * colWidth;
    React.useEffect(() => {
        setColumns(getChartColumns())
        setRows(getChartRows())
        setDgType(getDataTypeObj(chart.chartData.labelType)?.dgType)
        // console.log("element data table use effect DATA TYPE", getDataTypeObj(chart.chartData.labelType))
    }, [chart?.chartSettings.updatedAt, chart?.chartData.datasets.length, BoardStore.editMode, chart.chartData.labelType])


    const getChartColumns = () => {

        const lableColHeader = { label: chart.chartOptions.scales.x?.title.text, field: INDEX_COL_FIELD }
        const sereisLabels = chart.chartData.datasets.map((ds) => { return { label: ds.label, field: ds.id } })
        const cols = [lableColHeader, ...sereisLabels];

        const colsObjs = cols.map((col, idx) => {
            return {
                field: col.field,
                headerName: col.label,
                colIdx: idx,
                type: idx === 0 ? dgType : 'number',
                width: col.field !== INDEX_COL_FIELD ? colWidth : colWidth + 30,
                sortable: false,
                // editable: col.field !== INDEX_COL_FIELD || isEditableLables,
                // editable: isEditableLables,
                editable: BoardStore.editMode,
                headerAlign: 'center',
                align: 'center',
                headerClassName: col.field === INDEX_COL_FIELD ? 'chart-data-table-labels-cell' : '',
                // headerClassName: 'chart-data-table-labels-cell',
                // renderEditCell: (params) => <CustomEditComponent {...params} />,
                renderHeader: () => <EditableColumnHeader colIdx={idx} column={col} chart={chart} />
            }
        });
        return colsObjs
    }

    const processRowUpdate = (newRow) => {
        // console.log("newRow", newRow)
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const getChartRows = () => {
        const rows = []
        for (let i = 0; i < chart.chartData.datasets[0].data.length; i++) {
            const row = { id: i }
            let label = chart.chartData.labels[i]
            row[INDEX_COL_FIELD] = dgType !== 'date' ? label : moment.isMoment(label) ? label.format(`MMM DD, 'YY`) : moment(label).format(`MMM DD, 'YY`);
            // TODO: get time format
            chart.chartData.datasets.forEach((ds) => row[ds.id] = ds.data[i])
            rows.push(row)
        }
        return rows
    }

    return (
        <Box sx={{ overflow: 'scroll', width: `${colWidth * columns.length + 30}px`, marginX: 1, marginY: 0 }}>
            <DataGrid

                disableColumnMenu={!BoardStore.editMode}
                isCellEditable={(params) => BoardStore.editMode}
                // disabled={!BoardStore.editMode}
                onCellEditStop={(cellData, event) => updateCell(cellData, event)}
                key={`chart-data-table`}
                autoHeight
                margin="dense"
                headerHeight={30}
                sx={{
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeaders': { backgroundColor: 'rgba(0,0,0,0.05)', color: 'white', fontSize: 14, borderRadius: 0, },
                    '& .MuiDataGrid-columnHeader': { fontSize: 14, borderRight: '1px solid rgba(0,0,0,0.12)', fontWeight: 500 },
                    fontSize: 12,

                    '& .MuiDataGrid-menuIconButton': {
                        // color: 'red',
                        padding: 0,
                        position: 'absolute',
                        right: 1,
                        backgroundColor: 'rgba(0,0,0,0)',
                        display: 'block !important'
                    },
                    '& .MuiDataGrid-menuIconButton:hover': {
                        // color: 'red',
                        visibility: "visible",

                    },

                    [`& .${gridClasses.columnHeaderTitleContainer} .${gridClasses.iconButtonContainer}`]: {
                        visibility: "visible",
                        width: "auto"
                    },


                    '& .chart-data-table-labels-cell': {
                        backgroundColor: 'rgba(64, 64, 64, 0.7)',
                        '& .MuiDataGrid-menuIconButton': {
                            display: 'none'
                        }
                    },

                    '& .MuiDataGrid-footerContainer': { display: 'none' },
                    '& .MuiDataGrid-columnSeparator': {
                        visibility: 'hidden',
                    },
                    '&>.MuiDataGrid-main': {
                        '&>.MuiDataGrid-columnHeaders': {
                            borderBottom: 'none',
                        },


                        '& div div div div >.MuiDataGrid-cell': {
                            borderRight: '1px solid rgba(0,0,0,0.12)',
                        },
                    },
                    '& .MuiDataGrid-cell': {
                        fontSize: 12,
                    },
                    '& .MuiDataGrid-row > .MuiDataGrid-cell:first-of-type ': {
                        backgroundColor: 'rgba(64,64,64,0.05)',
                    }
                }}

                components={{
                    ColumnMenu: CustomColumnMenuComponent,
                    Pagination: CustomPagination,
                    ColumnMenuIcon: CustomColumnMenuIcon,
                    // ColumnMenuIcon: ArrowDropDownOutlinedIcon,
                }}
                componentsProps={{
                    columnMenu: { 'chart': chart },
                }}
                processRowUpdate={processRowUpdate}
                rows={getChartRows()}
                rowHeight={25}
                columns={columns}
                rowsPerPageOptions={[]}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
            />
        </Box>
    );
})