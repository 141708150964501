import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import React from 'react';

// import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { BoardStore } from '../../../Stores/Stores';

export function PropertyDateWidget({ value, onSelect, label, size='small' }) {

  // const [value, setValue] = React.useState(moment('2022-04-07'));
  const getFontSize = (size) => {
    if(size === 'small') return 12;
    if(size === 'medium') return 14;
    if(size === 'large') return 18;
  }
  return <FormGroup sx={{width: '100%'}}>
    <FormControlLabel
      control={        
      <DatePicker
        value={value}
        disabled={!BoardStore.editMode}
        variant="outlined"
        size="small"
        // inputFormat="MMM DD, 'YY"
        onChange={(newValue) => {
          onSelect(newValue)
        }}

        // sx={{ border: '1px solid red', maxWidth: '50%'}} 
        renderInput={(params) => <TextField {...params} size="small" helperText={null} 
                                  sx={{                                    
                                    '& .MuiInputBase-input': {padding: 0, border: 'none', justifyContent: 'start', fontSize: 12, textAlign: 'right', width: '10ch'},
                                    '& .MuiIconButton-root': {paddingLeft: 0, border: 'none', justifyContent: 'start', fontSize: 12, textAlign: 'right'},
                                    '& .MuiSvgIcon-root': {margin: 0,fontSize: 18, textAlign: 'right'},
                                    '& fieldset': {border: 'none'},
                                }} 
                                />}
      />}
      sx={{ justifyContent: 'space-between', marginLeft: 0, border: 'none', }}
      labelPlacement="start"
      label={<Typography component={'span'} color="textSecondary" sx={{ fontSize: getFontSize(size)}}>{label}</Typography>} />
  </FormGroup>;
}
