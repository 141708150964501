import React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import NumberInputWidget from '../EditorWidgets/NumberInputWidget';
import { PropertyColorWidget } from '../EditorWidgets/PropertyColorWidget';
import PaddingWidget from '../EditorWidgets/PaddingWidget';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';

const EditChartSection = observer(() => {

  return <>
    {BoardStore.currentElem ?
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap:0.5 }}>
        <PropertyColorWidget colorValue={BoardStore.currentElem.chartSettings.backgroundColor}
          onValueChange={(color) => BoardStore.updateBackgroundColor({color: color})}
          allowEditColor={true}
          label={"Background Color"} />

        <PropertyColorWidget colorValue={BoardStore.currentElem.chartSettings.borderColor}
          onValueChange={(color) => BoardStore.updateBorderColor({color: color})}
          label={"Border Color"} />

        <NumberInputWidget numericValue={BoardStore.currentElem.chartSettings.borderWidth}
          onValueChange={(e) => BoardStore.updateBorderStroke({width: e.target.value})}
          label={"Border Stroke"}
          inputAdornment={"px"} />

        <NumberInputWidget numericValue={BoardStore.currentElem.chartSettings.borderRadius}
          onValueChange={(e) => BoardStore.updateBorderRadius({radius: e.target.value})}
          label={"Border Radius"}
          inputAdornment={"px"} />

        <NumberInputWidget numericValue={BoardStore.currentElem.chartSettings.width}
          onValueChange={(e) => BoardStore.updateChartdDimentions(e.target.value, "width")}
          label={"Width"}
          inputAdornment={"px"}
        />
        <NumberInputWidget numericValue={BoardStore.currentElem.chartSettings.height}
          onValueChange={(e) => BoardStore.updateChartdDimentions(e.target.value, "height")}
          label={"Height"}
          inputAdornment={"px"}
        />
        <PaddingWidget allowXaxis={true} titleObj={BoardStore.currentElem.chartOptions.layout} titleType={"layout"} />
      </Box>
      : ''}
  </>
})

export default EditChartSection;


