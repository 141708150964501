import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { observer } from "mobx-react-lite";
import * as React from 'react';
import { useEffect } from 'react';
import { BoardStore, UserStore } from '../Stores/Stores';
// import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Stack } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DashingLogo } from '../Assets/svgs/dashing-white.svg';
import { DASHING_GREEN } from '../Components/Editors/EditorWidgets/DashingColors';
import { logOut } from '../Services/DBService';
import { BoardSearch } from './BoardsManager';
import { Analytics } from '../Services/Analytics';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({

  width: 62,
  height: 34,
  // padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    // transform: 'translateX(6px)',
    '&.Mui-checked': {
      // color: '#fff',
      transform: 'translateX(30px)',
      '& .MuiSwitch-thumb:before': {
        // marginRight: 1, 
        // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        // '#fff',
        // )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        // )}" d="M13.0681494,22.3691217 L13.0681494,26.4760308 C13.0681494,26.8820146 12.741303,27.2085668 12.3353191,27.2085668 L11.6619155,27.2085668 C11.2585794,27.2085668 10.9323213,26.8820146 10.9323213,26.4760308 L10.9323213,22.3691217 C10.2995161,21.9513702 9.92601091,21.1717635 10.1082918,20.3244929 C10.2645073,19.5978406 10.8528897,19.0124002 11.5792478,18.8594207 C12.8325022,18.5975906 13.9386611,19.5448862 13.9386611,20.754012 C13.9386611,21.4277099 13.5915155,22.0219761 13.0681494,22.3691217 Z M11.9999412,2.89207596 C15.0242266,2.89207596 17.4848418,5.35266169 17.4848418,8.37700602 L17.4848418,12.0606328 L6.51492285,12.0606328 L6.51492285,8.37700602 C6.51492285,5.35266169 8.97547916,2.89207596 11.9999412,2.89207596 Z M20.2343527,12.061839 L20.3770354,12.061839 L20.3770354,8.37700602 C20.3770354,3.75782181 16.6190371,0 11.9999412,0 C7.38069812,0 3.62272921,3.75782181 3.62272921,8.37700602 L3.62272921,12.061839 L3.76276422,12.061839 C1.68203816,12.0746069 0,13.7660592 0,15.8498448 L0,30.1740141 C0,32.2657135 1.69454128,33.9602548 3.7862701,33.9602548 L20.2108174,33.9602548 C22.3054587,33.9602548 24,32.2657135 24,30.1740141 L24,15.8498448 C24,13.7660592 22.3181089,12.0746069 20.2343527,12.061839 L20.2343527,12.061839 Z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    // backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    // width: 32,
    // height: 32,
    '&:before': {
      // content: "''",
      // position: 'absolute',
      // width: '100%',
      // height: '100%',
      // left: 0,
      // top: 0,
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center',
      // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
      //   '#fff',
      // )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      // )}" d="M8.29590296,16.6418006 L8.29590296,19.248919 C8.29590296,19.5066427 8.08841667,19.7139422 7.83274726,19.7139422 L7.4048885,19.7139422 C7.14903233,19.7139422 6.93986524,19.5066427 6.93986524,19.248919 L6.93986524,16.6418006 C6.53833913,16.3766066 6.30302615,15.8817023 6.41694751,15.3438441 C6.51592837,14.8825559 6.88944103,14.5109108 7.35259673,14.4137976 C8.1481787,14.2475844 8.84851494,14.8489398 8.84851494,15.6165083 C8.84851494,16.0441803 8.62832922,16.4214281 8.29590296,16.6418006 Z M12.8320275,10.0978401 L4.13665272,10.0978401 L4.13665272,5.31783048 C4.13665272,3.39793805 5.69868267,1.83592678 7.61853774,1.83592678 C9.53857958,1.83592678 11.1004228,3.39793805 11.1004228,5.31783048 L11.1004228,7.65700955 L12.936611,7.65700955 L12.936611,5.31783048 C12.936611,2.38551331 10.5509856,0 7.61853774,0 C4.6862766,0 2.30065123,2.38551331 2.30065123,5.31783048 L2.30065123,10.0986058 L2.39066779,10.0986058 C1.06974026,10.106711 0,11.1804665 0,12.5032803 L0,21.596446 C0,22.9242835 1.07758403,24 2.40542154,24 L12.8320275,24 C14.159865,24 15.237449,22.9242835 15.237449,21.596446 L15.237449,12.5032803 C15.237449,11.1754428 14.159865,10.0978401 12.8320275,10.0978401 L12.8320275,10.0978401 Z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 12,
  },
}));

const ShareDashboard = observer(() => {

  return (<>

    <FormGroup>
      <Button variant='contained' size="small"
        sx={{ marginX: 2, textTransform: 'none', height: '30px', bgcolor: DASHING_GREEN, ':hover': { bgcolor: DASHING_GREEN, } }}
        onClick={() => {
          UserStore.isAnonymous ? BoardStore.showAuthDialog = true : BoardStore.showInviteDialog = true;
          Analytics.trackClickedShare({isAnon: UserStore.isAnonymous});
        }}>Share</Button>
      {/* 
    <Button variant='contained' size="small" 
            sx={{marginX: 2, textTransform: 'none', height: '30px', bgcolor: DASHING_GREEN, ':hover': {bgcolor: DASHING_GREEN,}}} 
            // onClick={() => saveProfilePic() }>PHOTO</Button>
            onClick={() => console.log("boardToJs", BoardStore.boardToJs()) }>PHOTO</Button> */}
      {/* <FormControlLabel control={<MaterialUISwitch size="small" color="default"
        disabled={!BoardStore.editMode}
        checked={!BoardStore.board?.isPrivate}
        onChange={(e) => BoardStore.toggleBoardPrivacy()}
        icon={<Box sx={{ display: 'flex', backgroundColor: '#001e3c', borderRadius: '50%', width: '30px', height: '30px', marginTop: '0px' }}>
          <LockOutlinedIcon sx={{ fontSize: 18, margin: '0 auto', alignSelf: 'baseline', marginTop: '4px' }} /></Box>}
        checkedIcon={<Box sx={{ display: 'flex', backgroundColor: '#003892', borderRadius: '50%', width: '30px', height: '30px', marginTop: '0px' }}>
          <LockOpenOutlinedIcon sx={{ fontSize: 18, margin: '0 auto', alignSelf: 'baseline', marginTop: '4px' }} /></Box>}
      />} label={<Box sx={{ fontSize: 12, margin: 0, color: !BoardStore.board?.isPrivate ? 'white' : '#888', fontWeight: !BoardStore.board?.isPrivate ? 'bold' : 'normal' }}>{BoardStore.board?.isPrivate ? ' Private' : ' Public'}</Box>} /> */}
    </FormGroup>
  </>
  );
});

export const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(4)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(4)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  // backgroundColor: 'red',
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: 'unset !important',
  height: '48px !important',
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: "rgb(44, 44, 44)",
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

// TABS
const MenuTabs = styled(Tabs)({


  // borderBottom: '1px solid #e8e8e8',
  // borderBottom: 0,
  color: 'white',
  // width: '220px',
  // minHeight: 20,
  minHeight: 'unset',
  // marginBottom: 2,
  // height: '20px',
  '& .MuiTabs-root': {
    minHeigh: 'unset',
    // height: '20px',
  },
  '& .MuiTabs-indicator': {
    // height: 1,
    backgroundColor: '#6DD400',
    // width: '100%'
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-evenly',
    // height: '20px',
  },
  '& .MuiTabs-scroller': {
    // height: '20px',
  },
});

const MenuTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  // width: '48px',
  // height: 'em',
  // height: '20px',
  color: 'rgba(200,200,200,0.8)',
  minHeight: 0,
  margin: 0,
  padding: 0,
  fontSize: 12,
  // [theme.breakpoints.up('sm')]: {
  //   minWidth: 0,
  // },
  // fontWeight: theme.typography.fontWeightRegular,
  fontWeight: 'lighter',
  // marginRight: theme.spacing(1),
  // color: 'rgba(0, 0, 0, 0.85)',
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  '&:hover': {
    // color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    // color: '#6DD400',
    color: 'white',
    fontWeight: '500',
    margin: 0,
    padding: 0,
    fontSize: 12,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },

}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}

    >
      {value === index && (
        <Box sx={{ pt: 0, height: '20px', }}>
          <Typography>{children}</Typography>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// const Stam = observer(({ board }) => {
//   return <Box sx={{ fontSize: 18, marginLeft: 5, color: 'white', textAlign: 'center', width: '50ch', overflow: 'visible' }}>
//     {BoardStore.board.title.text}
//   </Box>
// })

const HomeAppBar = () => {

  return <Stack spacing={2} direction="row" sx={{ width: '100%', }} justifyContent="start" alignItems="center">

    {/* LEFT */}
    <Box sx={{}}>

      <Box sx={{ padding: 0, display: 'flex', flexDirection: 'row', height: '48px', }} >

        <Button sx={{
          width: `${drawerWidth * 1.5 - 20}px `, padding: 0, borderRadius: 0, marginRight: '0px', marginLeft: 1,
          alignItems: 'center', display: 'flex', justifyContent: 'flex-start', color: 'white',
          borderRight: '1px solid rgba(100,100,100,1)',
          textTransform: 'none'
        }}

          onClick={() => {BoardStore.canvasMode = 'home'; Analytics.trackCanvasMode({mode: 'home'})}}>
          <DashingLogo style={{ width: 32, height: 32, marginLeft: '3px', marginRight: '12px' }} />
          
          <Box sx={{
            height: '24px',
            width: '120px',
            // backgroundImage: `url('/images/dashing-name-logo.png')`,
            backgroundImage: `url('/images/dashing-name-logo.svg')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            margin: '8px',
          }}></Box>
        </Button>
      </Box>

    </Box>
    {UserStore.authStatus === UserStore.NO_USER ? '' :
      <Box sx={{ width: 300 }}>
        <BoardSearch isLight={true} />
      </Box>
    }
    {UserStore.authStatus === UserStore.NO_USER ? '' :
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'end' }}>
        {/* <Button variant="outlined" size="small" sx={{ color: 'white', borderColor: 'white', fontSize: 12 }}
        
          onClick={() => logOut()}>
          Logout </Button> */}
          {/* <Avatar sx={{ width: 36, height: 36, fontSize: "14px",
                                                    backgroundColor: DASHING_GREEN}}
                                    alt={UserStore.displayName || UserStore.email}
                                    src={UserStore.photoURL}
                                /> */}
                                 <UserProfileMenu/>
      </Box>
    }


  </Stack>
}


const UserProfileMenu = observer(({ }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return <>
  {UserStore.currentUser ?
        UserStore.isAnonymous ?
          <><Button variant="outlined" size="small" sx={{ color: 'white', borderColor: 'white', fontSize: 12 }}
            // onClick={() => googleSignIn(() => { navigate(`/board/${BoardStore.board.id}`); console.log("after sign up success") })}>
            onClick={() => BoardStore.showAuthDialog = true}>
            Sign In
          </Button>
          </>
          :
          <IconButton size="small" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            {/* <Avatar alt="You" size="small" src={UserStore.photoURL} referrerPolicy="no-referrer" sx={{ width: 35, height: 35 }} /> */}
            <Avatar sx={{ width: 36, height: 36, fontSize: "14px",
                                                    backgroundColor: DASHING_GREEN}}
                                    alt={UserStore.displayName || UserStore.email}
                                    src={UserStore.photoURL}
                                />
          </IconButton>

        : ''}

      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key={'display-name'} 
        // onClick={() => { logOut(); handleCloseUserMenu() }}
        >
          <Box sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>

            <Typography sx={{ fontSize: 16, fontWeight: '500', marginX: 0, marginY: 0, justifyContent: 'center' }}>
              {UserStore.displayName}</Typography>
            <Typography color="textSecondary"
              sx={{ fontSize: 11, textAlign: 'center', fontWeight: '300', fontStyle: 'italic', marginX: 0, marginY: 'auto', justifyContent: 'center' }}>
              {UserStore.email}</Typography>
          </Box>
        </MenuItem>
        <MenuItem key={'logout'} sx={{ justifyContent: 'center', borderTop: '1px solid #ccc', paddingTop: 1, color: 'rgba(64,64,64,1)', fontWeight: 500 }}
          onClick={() => { logOut(); handleCloseUserMenu() }}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu></>
})

const DeisgnAppBar = observer(({ board }) => {

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  // const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  const settings = ['Logout'];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log("new value", newValue)
    BoardStore.canvasMode = newValue
    setValue(newValue);
  };
  useEffect(() => {

    // if (!didMount.current) {
    // setBoardZoomFuncs()
    // } else didMount.current = true;

  }, [board?.updatedAt,
  board?.id,
  board?.height,
  board?.width,
  board?.scale,
  board?.positionX,
  board?.positionY,
    // board.updatedAt
    // location,
  ]);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);

    // console.log("UserStore.currentUser", JSON.stringifyUserStore.currentUser)
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return <Stack spacing={2} direction="row" sx={{ width: '100%', }} justifyContent="space-between" alignItems="center">

    {/* LEFT */}
    <Box sx={{ width: '33%' }}>

      <Box sx={{ padding: 0, display: 'flex', flexDirection: 'row', height: '48px', }} >
        <Button sx={{
          width: `${drawerWidth - 10}px`, padding: 0, borderRadius: 0, marginRight: '20px',
          alignItems: 'center', display: 'flex', justifyContent: 'flex-start', color: 'white',
          borderRight: '1px solid rgba(100,100,100,1)',
          textTransform: 'none'
        }}

          onClick={() => {BoardStore.canvasMode = 'home'; Analytics.trackCanvasMode({mode: 'home'})}}>
          <ChevronLeftIcon sx={{ margin: 0, padding: 0 }} />
          <DashingLogo style={{ width: 24, height: 24, marginRight: '12px' }} />
          <Typography>Home</Typography>

        </Button>
        {/* <ExpandMoreIcon sx={{fontSize: 16}}/></Box> */}
        {BoardStore.board && BoardStore.editMode ?

          <TextField id={`board-title-input-${BoardStore.board?.id}`} variant="standard"
            placeholder="Untitled Dashboard"
            sx={{
              alignSelf: 'center',
              minWidth: '5ch',
              margin: 0, padding: 0, '&.Mui-disabled': {},
              '& .MuiInput-underline:before': { borderBottomColor: 'rgba(0,0,0,0)' },
              '& .MuiInput-underline:hover:before': { borderBottomColor: 'white' },
              '& .MuiInput-underline:after': { borderBottomColor: 'white' },
            }}
            disabled={!BoardStore.editMode}
            margin="normal"

            value={BoardStore.board?.title.text}
            onChange={(event) => BoardStore.updateBoardTitle(event.target.value)}
            inputProps={{
              sx: {
                color: 'white',
                fontSize: 16,
                // paddingLeft: 1,
                minWidth: '4ch',
                width: `${BoardStore.board?.title.text ? Math.min(24, BoardStore.board.title.text?.length + 2) : 16}ch`,
                // overflow: 'visible'
              }
            }}

            InputProps={{
              // disableUnderline: true,
              endAdornment: <InputAdornment position="end">
                <IconButton aria-label="toggle title visibility" edge="start" size="small">
                  <EditOutlinedIcon sx={{ fontSize: 14, color: '#ccc' }} />
                </IconButton>
              </InputAdornment>
            }}
          />
          :
          <Box sx={{ fontSize: 18, display: 'flex', alignItems: 'center', color: 'white', textAlign: 'left', overflow: 'visible' }}>
            {BoardStore.board?.title.text}
          </Box>}
      </Box>

    </Box>

    {/* CENTER */}
    <Box sx={{ width: '33%', color: 'white', alignItems: 'end', display: 'flex', height: '48px' }}>
      <MenuTabs value={BoardStore.canvasMode} onChange={handleChange} aria-label="edit tabs" sx={{ justifyContent: 'space-between', display: 'flex', margin: '0 auto', width: '250px', marginBottom: '8px' }}>
        <MenuTab value={'home'} {...a11yProps('home')} />
        <MenuTab label={<Box sx={{ display: 'flex', alignItems: 'center', paddingRight: '8px' }} onClick={() => Analytics.trackCanvasMode({mode: 'doc'})}>
          <AssignmentTurnedInOutlinedIcon sx={{ transform: 'scale(0.8)', marginRight: 0.5 }} />
          <Box></Box>Plan</Box>} value={'doc'} {...a11yProps('doc')} />
        <MenuTab label={<Box sx={{ display: 'flex', alignItems: 'center', paddingRight: '8px' }} onClick={() => Analytics.trackCanvasMode({mode: 'design'})}>
          <BrushOutlinedIcon sx={{ transform: 'scale(0.8)', marginRight: 0.5 }} /> <Box></Box>Design</Box>} value={'design'}  {...a11yProps('design')} />
      </MenuTabs>
    </Box>

    {/* Right */}
    <Box sx={{ width: '33%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: BoardStore.editMode ? 'flex-end' : 'space-between' }}>
      <Box sx={{ display: 'flex', justifySelf: 'center', alignSelf: 'center', backgroundColor: 'white' }}>

        {/* <PropertyToggleWidget isChecked={BoardStore.isEditMode} 
                      onCheck={(e) => BoardStore.isEditMode = e.target.checked}
                      label={"Edit Mode"} /> */}

      </Box>
      {BoardStore.editMode ? <ShareDashboard sx={{ flexGrow: 0, textAlign: 'right' }} /> :
        <Box sx={{ fontSize: 14, 
          // border: '1px solid white', 
        borderRadius: '4px', paddingY: 0.3, paddingX: 1.5 }}>View Only Mode</Box>}

<UserProfileMenu/>
      
    </Box>
  </Stack>
})

export const AppTopBar = observer(({ board }) => {

  return <AppBar position="fixed" >
    <Box sx={{ marginX: '10px' }}>

      <Toolbar disableGutters sx={{ minHeight: 'unset !important', height: '48px', display: 'flex' }}>

        {BoardStore.canvasMode === 'home' ? <HomeAppBar /> : <DeisgnAppBar board={board}></DeisgnAppBar>}

      </Toolbar>
    </Box>
  </AppBar>;
});
