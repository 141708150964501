import StartRoundedIcon from '@mui/icons-material/StartRounded';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import VerticalAlignCenterRoundedIcon from '@mui/icons-material/VerticalAlignCenterRounded';
import VerticalAlignTopRoundedIcon from '@mui/icons-material/VerticalAlignTopRounded';
import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import FontSelectWidget from '../EditorWidgets/FontSelectWidget';
import FontStyleWidget from '../EditorWidgets/FontStyleWidget';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0),
    border: 0,
    // '&.Mui-disabled': {
    //   border: 0,
    // },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  // },
  '& .MuiToggleButton-root': {
    '& .MuiSvgIcon-root': {
      fontSize: 14
    },
  },
}));

const EditLabelsSection = observer(({storeObj, editType}) => {

  const [alignment, setAlignment] = React.useState('start');
  const [anchor, setAnchor] = React.useState('start');

  // const titleType = (editType == 'theme' ? 'theme_' : '') + 'datalabels'
  const titleType = 'datalabels'


  const handleAlignment = (event, newAlignment,) => {
    setAlignment(newAlignment);
  };

  // const handleAnchor = (event, newAnchor,) => {
  //   setAnchor(newAnchor);
  // };

  const getAnchorIcon = (direction) => {
    if (direction === "start") return <VerticalAlignBottomOutlinedIcon sx={{ transform: `rotate(90deg)` }} />
    if (direction === "center") return <VerticalAlignCenterRoundedIcon sx={{ transform: `rotate(-90deg)` }} />
    if (direction === "end") return <VerticalAlignTopRoundedIcon sx={{ transform: `rotate(90deg)` }} />
  }

  const getAlignIcon = (direction) => {
    if (direction === "start") return <StartRoundedIcon sx={{ transform: `rotate(180deg)` }} />
    if (direction === "center") return <VerticalAlignCenterRoundedIcon sx={{ transform: `rotate(90deg)` }} />
    if (direction === "end") return <StartRoundedIcon />
  }

  return <>
  {/* {ChartStore.current ?  */}
  <Paper elevation={0}
    style={{ width: '100%' }}
    onClick={e => {e.stopPropagation(); e.preventDefault()}}
    sx={{
      display: 'flex', 
      alignItems: 'center',
      justifyContent: 'space-between',
      //  border: (theme) => `1px solid ${theme.palette.divider}`, 
      // , my:1
      flexWrap: 'wrap'
    }} >
    <FontStyleWidget
      titleType={titleType}
      shouldDisplay={storeObj.chartOptions.plugins.datalabels.display !== false}
      toggleDisplay={() => BoardStore.toggleShowDatalabels({editMode: editType})}
      font={storeObj.chartOptions.plugins.datalabels.font}
      color={storeObj.chartOptions.plugins.datalabels.color}
      label={"Labels Style"}
      editMode={editType}
    />

    {editType === 'theme' || storeObj.chartOptions.plugins.datalabels.display ? <>
      <FontSelectWidget titleType={titleType} titleObj={storeObj.chartOptions.plugins.datalabels} editMode={editType}/>

      <FormGroup sx={{ marginTop: 1, width: '100%' }}>
        <FormControlLabel
          control={<StyledToggleButtonGroup
                      size="small"
                      value={anchor}
                      exclusive
                      onChange={setAnchor}
                      aria-label="Labels alignment" >

                      {['start', 'center', 'end'].map((direction) => {
                        return <ToggleButton key={`anchor-label-button-${direction}`}
                          value={direction} aria-label="anchor-label-button"
                          selected={storeObj.chartOptions.plugins.datalabels.anchor === direction}
                          onClick={(event, newAnchor) => BoardStore.anchorDatalabels({anchor: newAnchor, editMode: editType})} >
                          {getAnchorIcon(direction)}
                        </ToggleButton>
                      })}
                    </StyledToggleButtonGroup>

                    }
                    sx={{ justifyContent: 'space-between', marginLeft: 0 }}
                    labelPlacement="start"
                    label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>Labels Anchor</Typography>} />
      </FormGroup>

      <FormGroup sx={{ marginTop: 1, width: '100%' }}>
        <FormControlLabel
          control={<StyledToggleButtonGroup
            size="small"
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="Legend alignment" >

            {['start', 'center', 'end'].map((direction) =>
              <ToggleButton key={`align-label-button-${direction}`}
                value={direction} aria-label="align-label-button"
                selected={storeObj.chartOptions.plugins.datalabels.align === direction}
                onClick={(event, newAlignment) => BoardStore.alignDatalabels({align: newAlignment, editMode: editType})} >
                {getAlignIcon(direction)}
              </ToggleButton>
            )}
          </StyledToggleButtonGroup>}

          sx={{ justifyContent: 'space-between', marginLeft: 0 }}
          labelPlacement="start"
          label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>Labels Alignment</Typography>} />
      </FormGroup>
    </> : ''}

  </Paper>
  {/* : ''} */}
  </>

})
export default EditLabelsSection;