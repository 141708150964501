import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import React from 'react';
// import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';

export function LabelActionWidget({ onAction, label, icon, size='small', disabled, variant }) {

  const getFontSize = (size) => {
    if(size === 'small') return 12;
    if(size === 'medium') return 14;
    if(size === 'large') return 18;
  }
  return <FormGroup sx={{marginTop: 1}}>
    <FormControlLabel
      control={<IconButton 
        disabled={disabled}
        sx={{color: variant === "danger" ? 'rgba(247, 76, 76, 1)' : 'rgba(64,64,64, 1)'}}        
            size='small' edge="end" aria-label="delete" onClick={(e) => onAction(e.target.value) }>
        {icon}
    </IconButton>}
      sx={{ justifyContent: 'space-between', marginLeft: 0 }}
      labelPlacement="start"
      label={<Typography color="textSecondary" sx={{ fontSize: getFontSize(size)}}>{label}</Typography>} />
  </FormGroup>;
}
