import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ExampleTheme from "./themes/ExampleTheme";
// import AutoFocusPlugin from "./plugins/AutoFocusPlugin"
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ClickAwayListener } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from 'react';
import { useEffect, useState } from "react";
import { BoardStore } from "../../../Stores/Stores";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import "./styles.css";

function Placeholder({placeholderMsg}) {
  return <div className="editor-placeholder">{placeholderMsg}</div>;
}


// const loadContent = async () => {
//   // 'empty' editor
//   const value = BoardStore.doc.requirements;

//   return value;
// }

// const initState = await loadContent();


// const FocusPlugin = (editMode) => {
  
//   const [editor] = useLexicalComposerContext();
//   const [hasFocus, setHasFocus] = useState(() => editor.getRootElement() === document.activeElement );

//   // });
  
//   React.useLayoutEffect(() => {
//     setHasFocus(editor.getRootElement() === document.activeElement);
//     return mergeRegister(
//       editor.registerCommand(FOCUS_COMMAND, () => { editor.setEditable(editMode); console.log("FOCUS_COMMAND", editMode)}, COMMAND_PRIORITY_LOW),
//       editor.registerCommand(BLUR_COMMAND, () => {  editor.setEditable(editMode); console.log("BLUR_COMMAND", editMode)}, COMMAND_PRIORITY_LOW),
//     );
//   }, [editor, editMode]);
// }


const Editor = observer( ({anchorKey, placeholderMsg, initState, onChange, editMode, showToolbar=true, selfAnchor=false}) => {
  
  const [hasFocus, setHasFocus] = useState(true);
  // const [editor] = useLexicalComposerContext();
  // const editorState = editor.getEditorState();
  // const json = editorState.toJSON();

  const onStateChange = (editorState, editor) => {
    // = editorState.toJSON();
    // BoardStore.updateDocRequirements(editorState.toJSON().editorState)
    // editor.setEditable(editMode);
    onChange(editor.toJSON().editorState);
  }

  useEffect(() => {
  }, [editMode, showToolbar])


  const editorConfig = {
    // The editor theme
    theme: ExampleTheme,
    editable: editMode,
    // editable: true,
    // editable: false,
    editorState: initState? JSON.stringify(initState || {}) : null,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode
    ]
  };

  return (
    

    <LexicalComposer initialConfig={editorConfig} style={{margin: 0, width: '100%', height: '100%'}}  >
      {/* <ClickAwayListener onClickAway={() => setHasFocus(false)} > */}
      {/* <div className="editor-container" style={{margin: 0}} onBlur={() => {}} onFocus={() => {setHasFocus(true)}}> */}
        
      <ClickAwayListener onClickAway={() => setHasFocus(false)} >
      <div className="editor-container" style={{margin: 0, width: '100%', height: '100%'}} onBlur={() => {}} onFocus={() => {setHasFocus(true)}}>
        
        {editMode ? <ToolbarPlugin showToolbar={showToolbar} hasFocus={true} anchorKey={`${anchorKey}-editor-container`} /> : ''}
        
        <div className="dropdown-container" >      
          <div id={`${anchorKey}-editor-container`} style={{position: 'absolute !important', top:0, left:0, width: '100%', height: '100%'}}> </div>
        </div>

        <div className="editor-inner"  style={{border: editMode ? '1px solid rgba(200,200,200,1)' : 0, width: '100%', height: '100%'}}>
        {/* <div id={'some-container'}  > */}{/* </div> */}
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input"/>}
            // placeholder={<Placeholder placeholderMsg={placeholderMsg}/>}
            placeholder={<Placeholder placeholderMsg={placeholderMsg}/>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <OnChangePlugin onChange={onStateChange} ignoreSelectionChange/>
          <ListMaxIndentLevelPlugin maxDepth={7} />
          {/* <MarkdownShortcutPlugin transformers={TRANSFORMERS} /> */}
        </div>
        
      </div>
    </ClickAwayListener>
    </LexicalComposer>
);
})
export default Editor;