import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Divider, FormGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ButtonGroup from '@mui/material/ButtonGroup';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect } from 'react';
import { BoardStore } from '../../../Stores/Stores';
// { field: 'name', headerName: 'Name', width: 100, editable: true, sortable: false },
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ToggleButton from '@mui/material/ToggleButton';
import { DASHING_DATA_TYPES } from '../../../Utils/ElementsUtils';
import PlusMinusWidget from './PlusMinusWidget';
import StyledToggleButtonGroup from './StyledToggleButtonGroup';



const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} sx={{
        margin: 0,
        padding: 0,
    }} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    // marginLeft: 'auto',
    margin: 0,
    padding: 0,
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));



export const EditColumnMenue = ({ chart, columnIdx, disableWidth, disableDatatype }) => {

    const getIcon = (direction) => {
        if (direction === "left") return <FormatAlignLeftIcon />
        if (direction === "center") return <FormatAlignCenterIcon />
        if (direction === "right") return <FormatAlignRightIcon />
    }

    return <Box sx={{ widht: '100%', margin: 1, marginTop: 2 }}>

        {/* {disableWidth ? '' : */}
        {/* <NumberInputWidget numericValue={chart.columns[columnIdx]?.width}
                onValueChange={(e) => BoardStore.updateTableColumnWidth(columnIdx, e.target.value)}
                label={"Column Width"}
                inputAdornment={"px"}
                charLength={3}
            /> */}
        {/* } */}

        <PlusMinusWidget onPlus={() => BoardStore.updateTableColumnWidth(columnIdx, chart.columns[columnIdx].width + 10)}
            onMinus={() => BoardStore.updateTableColumnWidth(columnIdx, chart.columns[columnIdx].width - 10)}
            label={"Column Width"} />

        {disableDatatype ? '' :
            <FormGroup sx={{ marginTop: 1 }}>
                <FormControlLabel
                    control={<ButtonGroup disableElevation variant="contained" onClick={() => { }}>
                        <ColumnDataTypeWidget
                            // chart={chart} 
                            // columnIdx={columnIdx} 
                            dataTypes={DASHING_DATA_TYPES}
                            valueOptions={chart?.columns[columnIdx]?.valueOptions}
                            currentDataType={chart.columns[columnIdx]?.dataType}
                            onSelect={(columnIdx, type, dataType, valueOptions) => BoardStore.updateColumnDataType(columnIdx, type, dataType, valueOptions)}
                            columnIdx={columnIdx} style={{ justifyContent: 'flex-start' }}
                        />

                    </ButtonGroup>}
                    sx={{ justifyContent: 'space-between', marginLeft: 0, paddingX: 0 }}
                    labelPlacement="start"
                    label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>Data type</Typography>} />
            </FormGroup>}

        <StyledToggleButtonGroup valueObj={chart.columns[columnIdx]?.align} txt={'Alignment'}>

            {['left', 'center', 'right'].map((direction) =>
                <ToggleButton key={`legend-aligned-${direction}`}
                    value={direction} aria-label={`legend-aligned-${direction}`}
                    selected={chart.columns[columnIdx].align === direction}
                    onClick={(event, newAlignment) => BoardStore.updateColumnAlignment(columnIdx, newAlignment)}>
                    {getIcon(direction)}
                </ToggleButton>
            )}

        </StyledToggleButtonGroup>

        <Typography color="textSecondary" sx={{ fontSize: 12, marginTop: 2, marginLeft: -1, }}>Column Description</Typography>
        <TextField
            id="outlined-multiline-static"
            //   label="Desc"
            multiline
            rows={4}

            //   defaultValue="Default Value"
            value={chart.columns[columnIdx].description}
            onChange={(e) => BoardStore.updateTableColumnDescription(columnIdx, e.target.value)}
            placeholder='e.g. Sum of revenue from crm...'
            sx={{ marginX: -1, width: '200px' }}
            inputProps={{
                style: { fontSize: 12, color: 'rgba(64,64,64,1)' }
            }}
        />

        <Divider sx={{ marginTop: 1 }}></Divider>
        <FormGroup sx={{ marginTop: 0 }}>
            <FormControlLabel
                control={<ButtonGroup disableElevation variant="contained" onClick={() => { }}>
                    <IconButton aria-label="add column" disableFocusRipple disableRipple sx={{ color: 'rgba(247, 76, 76, 1)', transform: 'scale(0.8)' }}
                        onClick={() => BoardStore.deleteColumn(columnIdx)}> <DeleteOutlineOutlinedIcon /> </IconButton>
                </ButtonGroup>}
                sx={{ justifyContent: 'space-between', marginLeft: 0, paddingX: 0 }}
                labelPlacement="start"
                label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>Delete Column</Typography>} />
        </FormGroup>
    </Box>
}


const ColumnInputWidget = observer(({ chart, columnIdx }) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        setExpanded(false);
    }, [columnIdx, chart.chartSettings.updatedAt]
    )

    return <Box key={`ColumnInputWidget-${columnIdx}`} sx={{
        border: '1px solid rgba(64,64,64,0.5)',
        borderRadius: 1,
        widht: '100%',
    }}>
        <FormGroup row sx={{ justifyContent: 'space-between', }}>
            <Box sx={{ display: 'flex', alignItems: 'start' }}>

                <DragIndicatorIcon sx={{ fontSize: 18, color: '#888', alignSelf: 'center' }} />

                <ColumnDataTypeWidget
                    dataTypes={DASHING_DATA_TYPES}
                    valueOptions={chart?.columns[columnIdx]?.valueOptions}
                    currentDataType={chart.columns[columnIdx]?.dataType}
                    onSelect={(columnIdx, type, dataType, valueOptions) => BoardStore.updateColumnDataType(columnIdx, type, dataType, valueOptions)}
                    columnIdx={columnIdx} style={{ justifyContent: 'flex-start' }}
                />

                <TextField size="small"
                    sx={{ width: '8ch', marginLeft: '12px', alignSelf: 'flex-start', marginTop: '4px' }}
                    variant="standard"
                    key={`ColumnInputWidget-text-${columnIdx}`}
                    margin="dense"
                    value={chart.columns[columnIdx].headerName}
                    onChange={(event) => BoardStore.updateTableColumnHeader(columnIdx, event.target.value)}
                    InputProps={{
                        style: { fontSize: 12 },
                    }}
                    inputProps={{
                        style: { fontSize: 12, paddingBottom: 3 },
                    }}
                />
            </Box>

            <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                sx={{ margin: 0, paddingX: 0, marginLeft: 0.5, display: 'flex', justifySelf: 'flex-end' }}
                aria-label="show more" >

                <ExpandMoreIcon sx={{ transform: 'scale(0.8)', padding: 0, margin: 0 }} />
            </ExpandMore>
            {/* </Box> */}
        </FormGroup>

        <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ marginX: 1 }}>

            <EditColumnMenue chart={chart} columnIdx={columnIdx} disableDatatype={true} />

        </Collapse>
    </Box>


})
export default ColumnInputWidget;



export const ColumnDataTypeStyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({

    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 240,
        // maxHeight: 500,
        // padding: 4,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },

}));

// type GridNativeColTypes = 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect';



// const getDataTypeIcon = (dt) => DASHING_DATA_TYPES.find((x) => x.dataType === dt)

// BoardStore.currentElem.columns[columnIdx].datatype


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderRadius: 0,
    //   border: `1px solid ${theme.palette.divider}`,
    //   '&:not(:last-child)': {
    //     borderBottom: 0,
    //   },
    //   '&:before': {
    //     display: 'none',
    //   },
    // marginTop: '20px',
    '& .MuiAccordion-root': {
        borderRadius: '0 !improtant',
        padding: '0 !improtant',

        // margin: '0 !improtant',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    //   backgroundColor:
    //     theme.palette.mode === 'dark'
    //       ? 'rgba(255, 255, 255, .05)'
    //       : 'rgba(0, 0, 0, .03)',
    // borderRadius: 0,
    // padding:0,margin:0,
    fontSize: '12px !improtant',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    //   borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// const groupBy = (array, key) => {
//     // Return the end result
//     return array.reduce((result, currentValue) => {
//         // If an array already present for key, push it to the array. Else create an array and push the object
//         (result[currentValue[key]] = result[currentValue[key]] || []).push(
//             currentValue
//         );
//         // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
//         return result;
//     }, {}); // empty object is the initial value for result object
// };

const groupBy = (arr, key) => {
    return arr.reduce(
        (accumulator, target) => {
            accumulator[target[key]] = [...(accumulator[target[key]] || []), target]
            return accumulator
        },
        {});
};

// valueOptions, dataTypes, handleClose? 
// export const ColumnDataTypeMenuWidget = observer(({ chart, columnIdx, handleClose, onSelect }) => {
export const ColumnDataTypeMenuWidget = observer(({ dataTypes, currentDataType, columnIdx = 0, valueOptions, handleClose, onSelect, aggregateCategories = true }) => {
    const [expanded, setExpanded] = React.useState();

    // const [customOptions, setCustomOptions] = React.useState(chart?.columns[columnIdx]?.valueOptions);
    const dataTypeCategories = Object.entries(groupBy(dataTypes, 'category'))

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    // const currentDataType = chart.columns[columnIdx]?.dataType;

    return <Box sx={{
        height: '400px', display: "flex",
        flexDirection: "column",
        // height: 700,
        overflow: "hidden",
        mb: 3,
        overflowY: "scroll",
    }}>


        {aggregateCategories ?
            dataTypeCategories.map(([category, cdtArr]) => {
                return <Accordion key={`datatype-acc-${category}`}
                    style={{ borderRadius: 0, marginTop: 0, fontSize: 10, }}
                    expanded={expanded === category} onChange={handleChange(category)}>

                    <AccordionSummary aria-controls={category} id={`datatype-menu-category-${category}`}
                        style={{ fontSize: 10, height: '50px', minHeight: 'unset', backgroundColor: cdtArr.map(x => x.dataType).includes(currentDataType) ? 'rgba(200,200,200,0.4)' : 'white', }}
                        sx={{ '& .MuiAccordionSummary-content': { flexDirection: 'column' } }}>
                        <Box><Typography sx={{ fontSize: 14 }}>{category}</Typography></Box>
                        <Box><Typography variant='secondary' sx={{ fontSize: 11, color: 'rgba(100,100,100)', fontStyle: 'italic' }}>{cdtArr.map(cdt => cdt.displayName).join(', ')}...</Typography></Box>
                    </AccordionSummary>


                    {category !== 'Custom' ?

                        <AccordionDetails>
                            <DataTypeMenuOptions currentDataType={currentDataType}
                                onSelect={(selectedCdt) => { onSelect(columnIdx, selectedCdt.dgType, selectedCdt.dataType); handleClose(); }}
                                dataTypes={cdtArr} />
                        </AccordionDetails>
                        :
                        <AccordionDetails sx={{ flexDirection: 'row' }}>
                            {/* handleClose(e) */}
                            <DataTypeCustomSection valueOptions={valueOptions} columnIdx={columnIdx} onSelect={(...params) => { onSelect(...params); handleClose() }} />
                        </AccordionDetails>}
                </Accordion>
            }
            ) :
            <>
                <DataTypeMenuOptions currentDataType={currentDataType}
                    onSelect={(selectedCdt) => { onSelect(columnIdx, selectedCdt.dgType, selectedCdt.dataType); handleClose(); }}
                    dataTypes={[].concat(...dataTypes.values())} />

            </>
        }

        <Box
            sx={{ position: 'absolute', bottom: 0, marginY: 1, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <ArrowForwardIosIcon sx={{ fontSize: 12, color: 'rgba(64,64,64,0.5)', transform: "rotate(90deg)", }}></ArrowForwardIosIcon>
        </Box>
    </Box>
})


const DataTypeCustomSection = ({ valueOptions, columnIdx, onSelect }) => {
    const [customOptions, setCustomOptions] = React.useState(valueOptions);
    return <>
        <Box container sx={{ display: 'flex', marginBottom: 1, marginLeft: 1, justifyContent: 'space-between' }}>
            <Typography color="textSecondary" sx={{ fontSize: 14 }}>List of values</Typography>
            <Button size="small" variant="outlined"
                onClick={(e) => { onSelect(columnIdx, 'string', 'custom', [...customOptions]); }}
                sx={{ display: 'flex', justifyContent: "center", fontSize: 9, fontWeight: 'regular' }}> {columnIdx ? 'Update ' : ' Add '} Column </Button>
        </Box>
        <TextField id="outlined-basic"
            sx={{ width: '100%', maxHeight: 200, overflow: 'scroll' }}
            multiline
            rows={4}
            disabled={!BoardStore.editMode}
            inputProps={{ sx: { fontSize: 12 } }}
            value={customOptions?.join("\n")}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(event) => setCustomOptions(event.target.value?.split("\n"))}
            helperText="One option per row"
            placeholder={"Option 1\nOption 2\n ..."}
            variant="outlined"
        /></>
}


export const DataTypeMenuOptions = ({ dataTypes, currentDataType, onSelect }) => {
    return dataTypes.map((cdt, idx) => {

        return <MenuItem key={`cdt-menu-${cdt.dataType}`}
            sx={{ backgroundColor: cdt.dataType === currentDataType ? 'rgba(200,200,200,0.4)' : 'white', }}
            onClick={(e) => { onSelect(cdt); }}
            disableRipple >
            <Box direction="row" sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
                <Box sx={{
                    display: 'flex', alignItems: 'center',
                    fontSize: '14px', color: 'rgba(64,64,64,1)'
                }}>{cdt.icon} {cdt.displayName}</Box>
                <Box sx={{ fontSize: '12px', color: '#aaa' }}>{cdt.example}</Box>
            </Box>
        </MenuItem>
    })
}


const SelectedDataTypeIconLabel = ({dataTypes, currentDataType}) => {
    let dataTypeObj = dataTypes.find(cdt => cdt?.dataType === currentDataType)        
    return <Box direction="row" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                    fontSize: '12px', color: 'rgba(64,64,64,0.87)',
                    textTransform: 'none', fontWeight: 'normal',
                    gap: 1
                }}> 
                {dataTypeObj.icon} 
                <span style={{maxWidth: '6ch', minWidth: '6ch', overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis', textAlign: 'left'}}> {dataTypeObj.displayName}</span>   
                </Box>
}


export const ColumnDataTypeWidget = observer(({
    dataTypes,
    currentDataType,
    columnIdx,
    valueOptions,
    onSelect,
    aggregateCategories = true
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [columnDataType, setColumnDataType] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, selectedColumnDataType) => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button
                disableFocusRipple
                disableRipple
                disabled={!BoardStore.editMode}
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                size='small'
                sx={{
                    justifyContent: 'flex-start',
                    minWidth: 'unset',
                    marginLeft: '4px',
                    // marginRight: '12px',
                    border: 'none', color: 'rgba(64,64,64,1)', paddingX: 0, 
                    '&:hover': { border: 'none', },
                    '&:disabled': { border: 'none', },
                    height: '30px',
                    maxWidth: '100px',
                    '& .MuiButton-endIcon': { padding: 0, margin: 0 },
                    '& .MuiButton-iconSizeSmall': { padding: 0, margin: 0 }
                }}
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon sx={{ transform: 'scale(1)', width: '12px', padding: 0, margin: 0 }} />}
            // startIcon={<EditOutlinedIcon sx={{ transform: 'scale(0.8)', color: '#888', padding: 0, margin: 0 }} />}
            >

                {/* <Box sx={{ display: 'flex' }}>{dataTypes.find((x) => x?.dataType === currentDataType)?.icon}</Box> */}
                <Box sx={{ display: 'flex',}}>
                    
                    <SelectedDataTypeIconLabel dataTypes={dataTypes} currentDataType={currentDataType}/>                    

                </Box>

            </Button>
            
            <ColumnDataTypeStyledMenu
                id="column-data-type"
                MenuListProps={{ 'aria-labelledby': 'column-data-type', }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <ColumnDataTypeMenuWidget
                    // chart={chart} 
                    dataTypes={dataTypes}
                    valueOptions={valueOptions}
                    currentDataType={currentDataType}
                    aggregateCategories={aggregateCategories}
                    columnIdx={columnIdx}
                    handleClose={handleClose}
                    onSelect={(columnIdx, type, dataType, valueOptions) => onSelect(columnIdx, type, dataType, valueOptions)} />

            </ColumnDataTypeStyledMenu>
        </Box>
    );
}
)