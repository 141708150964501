const isHit = (box, e) => (e.x >= box.left && e.x <= box.left + box.width && e.y >= box.top && e.y <= box.top + box.height)

const getHitType = (box) => {
      switch(box.weight){
        case 1000:
          return "legend";
        case 1500:
          return "subtitle";
        case 2000:
          return "title";        
        default: 
          if(box.axis === 'x') return 'x-axis'
          if(box.axis === 'y') return 'y-axis'
          return "chartArea"
      }
}


export const ChartBoxPlugin = ({onBoxClick, onBoxHover}) => {


  
  return {
  id: 'titleClickPlugin',
  afterEvent: function (chart, args, options) {

    function callBox(e, boxes, call){
      for(let box of boxes){
        if(isHit(box, e)){
          let boxType = getHitType(box)
          call(boxType, box, args.event.x, args.event.y)
          return;
        }
      }
    }

    if(args.event.type === 'mousemove'){
      callBox(args.event, [...chart.boxes, chart.chartArea], onBoxHover)
      // let isBoxHit = false;
      // for(let box of chart.boxes){
      //   if(isHit(box, args.event)){
      //     let boxType = getHitType(box)
      //     onBoxHover(boxType, box, args.event.x, args.event.y)
      //     return;
      //   }
      // }

    }

    if(args.event.type === 'click'){
      callBox(args.event, [...chart.boxes, chart.chartArea], onBoxClick)
      // for(let box in [...chart.boxes, chart.chartArea]){
      //   let isBoxHit = isHit(box, args.event)
      //   if(isBoxHit){
      //     let clickedBox = getHitType(box)
      //     onBoxClick(clickedBox)
      //     return;
      //   }
      // }
    }
  }
}};
