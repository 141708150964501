import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import NumberInputWidget from './NumberInputWidget';

const fonts = [
  { value: 'Helvetica', label: 'Helvetica' },
  { value: 'Arial', label: 'Arial' },
  { value: 'Verdana', label: 'Verdana' },  
  { value: 'Roboto', label: 'Roboto' },
  { value: 'Calibri', label: 'Calibri' },
  { value: 'Courier New', label: 'Courier New' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Meiryo UI', label: 'Meiryo UI' },
  { value: 'Poppins', label: 'Poppins' },
  { value: 'Times New Roman', label: 'Times New Roman' },
  { value: 'Trebuchet MS ', label: 'Trebuchet MS' },
]

const FontSelectWidget = observer(({ titleType, titleObj, noLabel, editMode }) => {

  const [font, setFont] = React.useState(fonts.find((f) => f.value === titleObj.font.family)?.value);

  const handleChange = (event) => {
    setFont(event.target.value);
    BoardStore.setFontFamily({fontFamily: event.target.value, titleType, editMode})
  };

  return <>
    <Box my={0} sx={{
      width: '100%',
      display: 'flex',
      flexGrow:1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: "center",
      flex: 1,
    }}>
      <TextField
        id="outlined-select-currency"
        select
        style ={{maxWidth: '10ch', paddingBottom: 0}}
        label={<div style={{fontSize:11}}>{noLabel? ''  : 'Font'}</div>}
        variant='standard'
        size="small"
        sx={{
          flexGrow: 1,
          // marginTop: '6px',
          // fontSize: '14px',
        }}
        value={font}
        onChange={handleChange}
        InputProps={{ style: { fontSize: 12, fontFamily: font }, 
                      // disableUnderline: true, 
                    }}
        InputLabelProps={{shrink: noLabel}}
      >
        {fonts.map((option) => (
          <MenuItem key={option.value}
            value={option.value} dense
            style={{ fontFamily: option.value }}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <NumberInputWidget style={{marginTop: 1.5 }}
        numericValue={titleObj.font.size}
        onValueChange={(e) => BoardStore.setFontSize({fontSize: e.target.value, titleType: titleType, editMode})}
        label={""}
        inputAdornment={"px"} />

    </Box></>
});

export default FontSelectWidget;