import Paper from '@mui/material/Paper';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import FontSelectWidget from '../EditorWidgets/FontSelectWidget';
import FontStyleWidget from '../EditorWidgets/FontStyleWidget';
import LegendAlignmentWidget from '../EditorWidgets/LegendAlignmentWidget';
import LegendPositionWidget from '../EditorWidgets/LegendPositionWidget';

const EditLegendSection = observer(({storeObj, editType}) => {


  // const [alignment, setAlignment] = React.useState('left');
  
  // const titleType = (editType == 'theme' ? 'theme_' : '') + 'legend'
  // const labelsTitleType = (editType == 'theme' ? 'theme_' : '') + 'legendlabels'
  
  // const titleType = 'legend'
  const labelsTitleType = 'legendlabels'
  

  return <>
  {editType === 'theme' || storeObj ? 
    <Paper elevation={0}
      style={{ width: '100%' }}
      onClick={e => {e.stopPropagation(); e.preventDefault()}}
      sx={{
        display: 'flex', 
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }} >
        
      
      {/* <> */}
      <FontStyleWidget
        titleType={labelsTitleType}
        shouldDisplay={storeObj.chartOptions.plugins.legend.display}
        toggleDisplay={() => BoardStore.toggleShowLegend({editMode: editType})}
        font={storeObj.chartOptions.plugins.legend.labels.font}
        color={storeObj.chartOptions.plugins.legend.labels.color}
        label={"Legend Style"}
        editMode={editType}
      />
      {/* </> */}

      {/* {storeObj.chartOptions.plugins.legend.display ? <> */}
        <LegendPositionWidget storeObj={storeObj} editType={editType}/>
        <LegendAlignmentWidget storeObj={storeObj} editType={editType}/>
        <FontSelectWidget titleType={labelsTitleType} titleObj={storeObj.chartOptions.plugins.legend.labels} />

        {/* <EditTitleWidget key="legendtitle"
          titleObj={storeObj.chartOptions.plugins.legend.title}
          titleType={titleType} titleLabel={"Legend Title"} />

          <PaddingWidget titleObj={BoardStore.currentElem.chartOptions.plugins.legend} titleType={"legend"} />
          <NumberInputWidget            
            numericValue={storeObj.chartOptions.plugins.legend.labels.padding}
            onValueChange={(e) => {storeObj.chartOptions.plugins.legend.labels.padding = parseInt(e.target.value) || 0; BoardStore.updateCurrent()}}
            label={"Lables Padding"}
            inputAdornment={"px"}
          /> */}
      {/* </> : ''} */}

    </Paper>
  : ''}
  </>

});

export default EditLegendSection;


