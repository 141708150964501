
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import React from 'react';
import ElementContainer from './ElementContainer';


const BoxContainer = observer(({ chart }) => {

  return <ElementContainer chart={chart} >
    <Box 
      sx={
        {
          backgroundColor: chart.chartSettings.backgroundColor,
          position: "relative",
          height: '100%',
          display: "flex",
          flexDirection: "column",
          alignItems: chart.chartOptions.plugins.title.align,
          justifyContent: "center",
          // layout: {padding: { top: 0, right: 0, left: 0, bottom: 5 }},
          
          // TODO: add padding config
        }
      }> 
      
    </Box>
  </ElementContainer>

});

export default BoxContainer;

