import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, ClickAwayListener, Container, Divider, FormControlLabel, FormGroup, Menu, Switch, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { observer } from "mobx-react-lite";
import * as React from 'react';
import { BoardStore } from '../Stores/Stores';
import Editor from '../Components/Editors/TextEditor/Editor';
// import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import Tooltip from '@mui/material/Tooltip';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Popover from '@mui/material/Popover';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import SpellcheckOutlinedIcon from '@mui/icons-material/SpellcheckOutlined';
import { PropertyToggleWidget } from '../Components/Editors/EditorWidgets/PropertyToggleWidget';

const NA = () => {
  return <Box sx={{ fontStyle: 'italic', fontSize: 14, color: 'rgba(200,200,200,1)' }}>N/A</Box>
}

const AIButton = ({ txt }) => {
  return <Button
    disableElevation
    type="submit"
    variant="contained"
    sx={{
      textTransform: 'none',
      backgroundColor: 'white',
      color: '#0ABF53',
      borderColor: '#0ABF53',
      fontWeight: '500',
      fontSize: 12,
      width: '10ch',
      my: 0,
      height: '20px',
      ':hover': {
        backgroundColor: 'white',
      }
    }}
  // onClick={onEmailSubmit}
  >
    <AutoFixHighOutlinedIcon sx={{ fontSize: 16, marginRight: 0.5 }} />
    {txt}
    <ArrowForwardIosIcon sx={{ fontSize: 12, marginRight: 1 }} />

  </Button>
}
const isLocalhost = window.location.hostname === "localhost"
function AIPopover({ itemLabel, suggestion, updateSuggestion }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const clickedSuggestion = () => {

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    if (suggestion) {
      let accumulator = '';
      [...suggestion].forEach(async (c, i) => {
        await sleep(40 * i);
        updateSuggestion(accumulator)
        accumulator += c
      });
    }
  }

  const open = Boolean(anchorEl);

  
  
  return (
    <div>
      <>
        <Box
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          // onMouseEnter={handlePopoverOpen}               
          onClick={handlePopoverOpen}
        >
          {isLocalhost && BoardStore.doc.enableAI && <AIButton txt={"A.I."} />}
        </Box>
        <ClickAwayListener onClickAway={() => handlePopoverClose()}>
          <Menu
            id="mouse-over-popover"
            sx={{ width: '200px', }}
            open={open}
            onMouseLeave={handlePopoverClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >

            {[
              { label: `Suggest a ${itemLabel}`, icon: <AutoAwesomeOutlinedIcon sx={{ fontSize: 18 }} /> },
              { label: "Explain this", icon: <QuestionMarkOutlinedIcon sx={{ fontSize: 18 }} /> },
              { label: "Improve Writing", icon: <SpellcheckOutlinedIcon sx={{ fontSize: 18 }} /> },
            ].map(t => <MenuItem sx={{ display: 'flex', alignItems: 'start', padding: 1 }} onClick={clickedSuggestion}>
              <Box sx={{ color: '#0ABF53', marginRight: 1 }}>{t.icon}</Box>
              <Box sx={{ fontSize: 14, color: 'rgba(64,64,64,0.87)' }}>{t.label}</Box>
            </MenuItem>
            )}

          </Menu>
        </ClickAwayListener>
      </>
    </div>
  );
}


const SelectDashboardType = observer(() => {
  // const [age, setAge] = React.useState('');

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };




  const UsageOptionItem = (value, itemName, helperText) => {
    return <MenuItem value={value} sx={{}} key={value}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography sx={{ fontSize: 14, fontWeight: '500', marginBottom: 0, color: 'rgba(64,64,64,1)', marginRight: 1, }}>{itemName}</Typography>
        <Typography sx={{ fontSize: 12, fontStyle: 'italic', fontWeight: '300', marginBottom: 0, color: 'rgba(100,100,100,1)', }}>{helperText}</Typography>
      </Box>
    </MenuItem>
  }

  const options = {
    'strategic': { type: 'strategic', displayName: 'Strategic', desc: 'Focused on long-term strategies and high-level metrics' },
    'tactical': { type: 'tactical', displayName: 'Tactical', desc: 'Used by mid-management to track performance' },
    'operational': { type: 'operational', displayName: 'Operational', desc: 'Shows shorter time frames and operational processes' },
    'analytical': { type: 'analytical', displayName: 'Analytical', desc: 'Dynamically explore and analyse datasets' },
    'other': { type: 'other', displayName: 'Other', desc: '' },
  }
  return (
    <Box sx={{ marginY: 1, width: '100%' }}>
      {BoardStore.editMode ?
        <FormControl fullWidth variant='standard'>
          <Select
            labelId="SelectDashboardType"
            value={BoardStore.doc.dashboardType}
            size="small"
            label="Dashboard Type"
            placeholder="Dashboard Type..."
            onChange={(e) => BoardStore.updateDocType(e.target.value)}
          >
            {Object.entries(options).map(([_, o]) => UsageOptionItem(o.type, o.displayName, o.desc))}
          </Select>
        </FormControl>
        :
        <Box sx={{ marginLeft: -2 }}>

          {options[BoardStore.doc.dashboardType] ?
            UsageOptionItem(options[BoardStore.doc.dashboardType].type,
              options[BoardStore.doc.dashboardType].displayName,
              options[BoardStore.doc.dashboardType].desc)
            : <NA />}
        </Box>
      }
    </Box>
  );
})


const TextInput = ({ title, subTitle, txt, helperText, onUpdate, direction }) => {
  return <Box sx={{ width: '100%', display: 'flex', flexDirection: direction || 'column' }} >
    <SectionTitle title={title} subTitle={subTitle} helperText={helperText} />

    {BoardStore.editMode ? <TextField variant="standard" placeholder={helperText}
      sx={{
        width: '100%', alignSelf: 'center', margin: 0, padding: 0,
        '& .MuiInput-underline:before': { borderBottomColor: '#ccc' },
        '& .MuiInput-underline:after': { borderBottomColor: '#ccc' },
      }}
      value={txt}
      // helperText={<i>{helperText}</i>}
      // autocomplete="do-not-autofill"
      // id={`${title}-plan-field`}
      onChange={(event) => onUpdate(event.target.value)}
      inputProps={{
        autocomplete: "off",
        sx: {
          color: 'rgba(64,64,64,1)', fontSize: 16,
          '&::placeholder': { fontStyle: 'italic', fontSize: 14, fontWeight: 'normal', }
        }
      }}
    />
      :
      <Box sx={{ color: 'rgba(64,64,64,1)', fontSize: 16, }}>
        {txt || <NA />}
      </Box>}

  </Box>
}


const SectionTitle = ({ title, subTitle }) => {
  return <Box sx={{ color: 'rgba(64,64,64,0.87)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Typography sx={{ fontWeight: '500', fontSize: 14, }}>{title}</Typography>
    {subTitle ? <Tooltip title={subTitle} placement="right">
      <IconButton sx={{ padding: 1 }}>
        <InfoOutlinedIcon sx={{ fontSize: 16 }} />
      </IconButton>
    </Tooltip> : ''}
  </Box>
}






const PlanningDoc = observer(({ }) => {



  React.useEffect(() => {

  }, [BoardStore.board.title.tex, BoardStore.editMode]);


  return (
    <Box sx={{ backgroundColor: 'rgba(235,236,240, 1)', color: 'rgba(64,64,64, 1)', display: 'flex', justifyContent: 'center', paddingBottom: '100px' }}>

      <Box sx={{
        display: 'flex', backgroundColor: 'white',
        width: '1080px',
        // width: '60%', 
        // minWidth: '860px', 
        // maxWidth: '1080px', 
        paddingBottom: '100px'
      }}>

        <Container sx={{ justifyContent: 'center', alignItems: 'cneter', display: 'flex', flexDirection: 'column', marginTop: 1, }}>

          {/* {UserStore.currentUser.isAnonymous ? '' :
            <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'end' }} >
              <Typography sx={{ fontSize: 14, fontStyle: 'italic', fontWeight: '300', marginX: 0, marginY: 0, justifyContent: 'center' }}>
                Created by: </Typography>
              <Box sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography sx={{ fontSize: 14, fontStyle: 'italic', fontWeight: '300', marginX: 1, marginY: 0, justifyContent: 'center' }}>
                  {UserStore.currentUser?.providerData[0]?.displayName}</Typography>
              </Box>
              <Avatar alt="You" src={UserStore.currentUser?.providerData[0]?.photoURL} referrerPolicy="no-referrer" sx={{ width: 24, height: 24, border: '1px solid rgba(64,64,64,0.5)' }} />
            </Box>
          } */}

          {isLocalhost && <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <FormGroup sx={{ justifyContent: 'space-between', alignItems: 'center', marginTop:2, marginRight:2 }}>
              <FormControlLabel
                control={<Switch
                  size={'small'}
                  checked={BoardStore.doc.enableAI}
                  onChange={(e) => BoardStore.doc.enableAI = e.target.checked}
                  sx={{    '&.Mui-checked': {
                    backgroundColor:"#0ABF53"}}} 
                  color="success"/>}                
                labelPlacement="start"
                label={<Typography component={'span'} sx={{ fontSize: 16, marginRight:1, color:'#0ABF53', display: 'flex', alignItems: 'center'}}>
                  <AutoFixHighOutlinedIcon sx={{ fontSize: 24, marginRight: 1 }} /> {'Enable A.I. assistance'}</Typography>
                } 
                />
            </FormGroup>
          </Box>}

          <Box sx={{ width: '100%', marginY: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* <FactCheckOutlinedIcon sx={{ fontSize: 36, marginRight: 2 }} /> */}
            <AssignmentTurnedInOutlinedIcon sx={{ fontSize: 36, marginRight: 2 }} />
            <Typography component={'h1'} sx={{ fontSize: 32, fontWeight: 500 }}>
              Dashboard Requirements
            </Typography>



          </Box>

          {/* <Box sx={{ marginLeft: 'calc(10% + 40px)', width: '720px' }}> */}
          <SectionContainer color={'#4C94F7'}>
            <Box sx={{ width: '720px' }}>
              <TextInput title={'Dashbord Title'} txt={BoardStore.board.title.text}
                onUpdate={(value) => { BoardStore.updateBoardTitle(value) }} />
            </Box>
          </SectionContainer>
          {/* </Box> */}

          <SectionContainer sectionName={'Who?'} color={'#4C94F7'}>

            <Box sx={{ marginY: 0, width: '720px' }}>
              <SectionTitle title={'Usage Type'}></SectionTitle>
              <SelectDashboardType />
            </Box>

            <Box sx={{ width: '720px', marginTop: 2 }}>
              <TextInput
                title={'Audience'}
                subTitle={'Who will use this dashboard? Use personas, not names'}
                size="small"
                txt={BoardStore.doc.audiance}
                onUpdate={(value) => { BoardStore.updateDocAudiance(value) }}
                helperText={"CFO, marketing stakeholders, HR Ops..."}
              />
            </Box>
          </SectionContainer>

          {/* <Box sx={{ width: '100%', margin: '50px auto' }}> */}
          <SectionContainer sectionName={'Why?'} color={'#C19FF8'}>

            <Box sx={{ width: '100%', marginBottom: 1 }}>
              <Box sx={{ width: '720px', marginBottom: 1 }}>
                <TextInput title={'Business Objective(s)'}
                  size="small"
                  txt={BoardStore.doc.objective}
                  onUpdate={(value) => { BoardStore.updateDocObjective(value) }}
                  helperText={"Example: Monitor the sales' funnel performance and KPIs"}
                />
              </Box>

              <Box sx={{ width: '100%', display: 'flex', marginY: 1, alignItems: 'end', justifyContent: 'space-between' }}>
                <Box sx={{ width: '720px' }}>
                  <TextInput
                    title={'Decisions & Actions'}
                    subTitle={'What decision(s) will the users take based on this dashboard?'}
                    size="small"
                    txt={BoardStore.doc.decisions}
                    onUpdate={(value) => { BoardStore.updateDocDecisions(value) }}
                    helperText={"Example: allocate marketing budget per channel, hire more sales reps, ..."}
                  />
                </Box>
                <AIPopover itemLabel={"Decision"} />
              </Box>

              <Box sx={{ marginY: 2, display: 'flex', alignItems: 'center' }}>
                <Box>
                  <SectionTitle title={'Questions & Hypothesis'} subTitle={'What business questions and hypothesis will be answered in this dashboard?'} />
                  <QuestionsList />
                </Box>

              </Box>

            </Box>

            <Box sx={{ marginTop: 2 }}>
              <SectionTitle title={'Metrics & KPIs'} subTitle={'What Metrics will this dashboard include?'} />
              <KPIsList />
            </Box>
          </SectionContainer>

          <SectionContainer sectionName={'How?'} color={'#56BF9C'}>


            <Box sx={{ marginBottom: 1, display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
              <Box sx={{ width: '720px', }}>
                <TextInput
                  title={'Dynamic Controls'}
                  subTitle={'What slicers, filters, action buttons, etc. will this dashboard have?'}
                  size="small"
                  txt={BoardStore.doc.filters}
                  onUpdate={(value) => { BoardStore.updateDocFilters(value) }}
                  helperText={"Example: Invoice date range, filter by country..."}
                /></Box>
              <AIPopover itemLabel={"Filter"} />
            </Box>

            <Box sx={{ marginY: 2, }}>
              <SectionTitle title={'Technical Requirements'} subTitle={'Add links to docs, screenshots, list data sources, etc.'} />
              {/* {BoardStore.editMode ? 
                  <TechReqEditor editMode={true}/> : 
                  <TechReqEditor editMode={false}/> } */}
              <Editor initState={BoardStore.doc.requirements}
                // key={`TechReqEditor-${editMode}`}
                ancoreKey={`doc-${BoardStore.editMode}`}
                placeholderMsg={""}
                editMode={BoardStore.editMode}
                onChange={(state) => BoardStore.updateDocRequirements(state)} />

            </Box>

          </SectionContainer>

          {/* </Box> */}

        </Container>
      </Box>
    </Box>
  );
})

const TechReqEditor = (editMode) => {
  console.log("TechReqEditor", editMode)
  return <Editor initState={BoardStore.doc.requirements}
    key={`TechReqEditor-${editMode}`}
    ancoreKey={'doc'}
    placeholderMsg={""}
    editMode={editMode}
    onChange={(state) => BoardStore.updateDocRequirements(state)} />
}


const SectionContainer = ({ children, sectionName, color }) => {
  // let color = 'rgb(109, 212, 0)';
  // let color = 'rgb(109, 212, 0, 0.7)';
  // return <Box sx={{ width: '100%', margin: '20px auto'}}>
  return <Box sx={{ width: '100%', margin: '20px auto' }}>

    <Box sx={{ display: 'flex' }}>
      <Box sx={{ width: '10%', marginRight: '0px' }}>
        {sectionName && <Divider orientation='vertical' sx={{
          color: color, fontSize: 14, fontStyle: 'italic', fontWeight: '300',
          '::after , ::before': { borderColor: color }
        }} >
          {sectionName}</Divider>}
      </Box>
      <Box sx={{ width: '80%' }}>
        {children}
      </Box>
      {/* <Box sx={{ width: '10%' }}></Box> */}
    </Box>
  </Box>
}


const KPIsList = observer(() => {

  // const [kpis, setKPIs] = React.useState([]);
  const [kpiNameInput, setKpiNameInput] = React.useState('');
  const [kpiDescInput, setKpiDescInput] = React.useState('');

  const addKPI = () => {
    BoardStore.updateDocAddMetric(kpiNameInput, kpiDescInput)
    setKpiNameInput('')
    setKpiDescInput('')
  }

  const removeKPI = (idx) => {
    BoardStore.updateDocRemoveMetric(idx)
  }
  return <>
    {BoardStore.editMode &&
      <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: 1, marginTop: 0, }}>
        <Box sx={{ width: '720px', display: 'flex' }}>
          <TextField variant="standard"
            placeholder='e.g. Landing page conversion'
            label="Metric Name"
            sx={{ width: '220px', alignSelf: 'center', marginRight: 4, marginY: 0 }}
            margin="normal"
            value={kpiNameInput}
            required
            onChange={(event) => setKpiNameInput(event.target.value)}
            InputLabelProps={{
              sx: {
                fontStyle: 'italic',
                fontSize: 14,
                fontWeight: 'normal',
              }
            }}
            inputProps={{
              autocomplete: "off",
              sx: {
                color: 'rgba(64,64,64,1)', fontSize: 16, fontWeight: 500,
                '&::placeholder': {
                  fontStyle: 'italic',
                  fontSize: 14,
                  fontWeight: 'normal',
                }
              }
            }} />

          <TextField variant="standard"
            placeholder="e.g. Weekly average of  unique visitors / signups"
            sx={{ width: '500px', alignSelf: 'center', marginRight: 0, marginY: 0 }}
            label="Metric Description"
            shrink='false'
            margin="normal"
            value={kpiDescInput}
            multiline
            onChange={(event) => setKpiDescInput(event.target.value)}
            InputLabelProps={{
              sx: {
                fontStyle: 'italic',
                fontSize: 14,
                fontWeight: 'normal',
              }
            }}
            inputProps={{
              autocomplete: "off",
              sx: {
                color: 'rgba(64,64,64,0.7)', fontSize: 14,
                '&::placeholder': {
                  textOverflow: 'ellipsis !important',
                  fontStyle: 'italic',
                }
              }
            }} />
        </Box>
        <IconButton aria-label="add" disabled={kpiNameInput?.length === 0} onClick={addKPI}
          sx={{ paddingBottom: 0, marginLeft: 0 }} disableRipple>
          <AddCircleOutlineOutlinedIcon sx={{ fontSize: 24, marginLeft: 1 }} />
        </IconButton>
        {/* <AIButton txt={'A.I.'}/> */}
        <AIPopover itemLabel={"KPI"} />
      </Box>
    }
    {!BoardStore.editMode && BoardStore.doc.metrics.length === 0 && <NA />}
    {BoardStore.doc.metrics.map((k, idx) => {
      return <Box key={`kpi-box-${idx}`}>
        <List sx={{ padding: 0.1 }} key={`kpi-list-${idx}`}>
          <ListItem key={`kpi-${idx}`} sx={{ padding: 0, margin: 0 }}
            secondaryAction={
              BoardStore.editMode && <IconButton size='small' edge="end" aria-label="delete" onClick={() => { removeKPI(idx) }}>
                {/* <DeleteIcon /> */}
                <ClearIcon size='small' />

              </IconButton>
            }
          >
            <ListItemAvatar sx={{ minWidth: 'unset', width: '40px', display: 'flex', alignSelf: 'center' }}>
              <AutoGraphIcon sx={{ fontSize: 24, color: 'rgba(64,64,64,0.7)' }} />
            </ListItemAvatar>
            <ListItemText
              primary={k.name}
              secondary={<Box component={'span'} sx={{ fontSize: 12, fontStyle: 'italic' }}>{k.description}</Box>}
            />
          </ListItem>
        </List>
      </Box>
    })}</>
})



const QuestionsList = observer(() => {

  // const [questions, setQuestions] = React.useState([]);
  const [questionInput, setQuestionInput] = React.useState('');

  const addQuestion = () => {
    if (questionInput.length > 0) {
      BoardStore.updateDocAddQuestion(questionInput)
      setQuestionInput('')
    }
  }

  const removeQuestion = (idx) => {
    BoardStore.updateDocRemoveQuestion(idx)
  }
  return <>
    <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>

      {BoardStore.editMode && <>
        <TextField variant="standard"
          placeholder='Which customer segment is converting better?'
          sx={{ alignSelf: 'center', marginRight: 1, marginY: 0 }}
          margin="normal"
          required
          value={questionInput}
          onChange={(event) => setQuestionInput(event.target.value)}
          onKeyDown={(e) => { if (e.key === 'Enter') addQuestion() }}
          inputProps={{
            autocomplete: "off",
            sx: {
              width: '720px',
              color: 'rgba(64,64,64,1)', fontSize: 16,
              '&::placeholder': {
                fontStyle: 'italic',
                fontSize: 14,
                fontWeight: 'normal',
              }
            }
          }} />

        <IconButton aria-label="add" disabled={questionInput?.length === 0} onClick={addQuestion}
          sx={{ paddingBottom: 0, }} disableRipple>
          <AddCircleOutlineOutlinedIcon sx={{ fontSize: 24, marginRight: 0 }} />
        </IconButton>
        <AIPopover itemLabel={"Question"} suggestion={"Which marketing channel is generating our most retained users?"} updateSuggestion={setQuestionInput} />
      </>}
    </Box>
    {!BoardStore.editMode && BoardStore.doc.questions.length === 0 && <NA />}
    <Box sx={{ marginTop: 1, marginBottom: 2 }}>
      {BoardStore.doc.questions.map((q, idx) => {
        return <Box key={`questions-box-${idx}`}>
          <List sx={{ padding: 0, }} key={`questions-list-${idx}`}>

            <ListItem key={`question-${idx}`} sx={{ padding: 0, margin: 0, display: 'flex', alingItems: 'center', justifyContent: 'center' }}

              secondaryAction={
                BoardStore.editMode && <IconButton size='small' edge="end" aria-label="delete" onClick={() => { removeQuestion(idx) }}>
                  <ClearIcon size='small' />
                </IconButton>
              }
            >
              <ListItemAvatar sx={{ minWidth: 'unset', width: '40px', display: 'flex', alignSelf: 'center' }}>
                {/* <ListItemAvatar sx={{}}> */}
                {/* <ElectricBoltOutlinedIcon /> */}
                {/* <QueryStatsOutlinedIcon sx={{fontSize: 18}}/> */}
                <QueryStatsOutlinedIcon sx={{ fontSize: 24, color: 'rgba(64,64,64,0.7)' }} />
              </ListItemAvatar>
              <ListItemText key={`question-txt-${idx}`}
                primary={q.question}
              />
            </ListItem>
          </List>
        </Box>
      })}
    </Box></>
})

export default PlanningDoc;
