import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ColorPickerWidget from './DashingColors';


const InputColorWidget = observer(({txt, updateTxt, color, updateColor}) => {
 
    return  <TextField size="small"
            style={{ width: '100%', marginBottom: 0 }}
            variant="standard"
            margin="dense"
            value={txt}
            onChange={(e) => updateTxt(e)}
            InputProps={{
                style: { fontSize: 11 }, 
                // disableUnderline: true, 
                endAdornment: <InputAdornment position="end">
                    <ColorPickerWidget color={color}
                               updateColor={(color, e) => updateColor(color,e)}
                               hideArrow={true} />
                </InputAdornment>
            }} />

})
export default InputColorWidget;