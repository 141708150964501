const exampleBoard = {
    "id": "",
    "board": {
        "id": "",
        "ownerId": "",
        "isPrivate": true,
        "updatedAt": 1676992157143,
        "createdAt": 1676971572990,
        "scale": 1,
        "title": {
            "text": "Board Example",
            "align": "start",
            "padding": {
                "top": 0,
                "right": 0,
                "left": 0,
                "bottom": 0
            },
            "display": true,
            "color": "rgba(58, 81, 102, 1)",
            "position": "top",
            "font": {
                "weight": "bold",
                "style": "normal",
                "size": 16,
                "family": "'Arial', sans-serif"
            }
        },
        "backgroundColor": [
            "rgba(207,226,250,0.9882352941176471)"
        ],
        "dottedBackground": true,
        "width": 1082,
        "height": 773,
        "positionX": 179,
        "positionY": 0,
        "tabs": [
            {
                "id": "3Y30nQ_5X8bJAsbSjQokP",
                "tabName": "Main KPIs",
                "charts": [
                    {
                        "key": "Z42xrnjKyH85FxWcZZ-lN",
                        "createdAt": 1676971898498,
                        "chartVariant": [],
                        "chartType": "line",
                        "tabId": "3Y30nQ_5X8bJAsbSjQokP",
                        "isActive": false,
                        "chartSettings": {
                            "renderedAt": 1676972199545,
                            "updatedAt": 1676992364741,
                            "positionX": 20,
                            "positionY": 370,
                            "width": 520,
                            "height": 360,
                            "backgroundColor": "rgba(255,255,255,1)",
                            "borderColor": "rgba(204, 204, 204, 1)",
                            "borderRadius": 0,
                            "borderWidth": 1,
                            "activeBoxType": null,
                            "description": null,
                            "isDataDraggable": true,
                            "zIndex": 2
                        },
                        "chartOptions": {
                            "maintainAspectRatio": false,
                            "responsive": true,
                            "animation": true,
                            "interaction": {
                                "mode": "index",
                                "intersect": false
                            },
                            "indexAxis": "x",
                            "layout": {
                                "padding": {
                                    "top": 10,
                                    "right": 20,
                                    "left": 10,
                                    "bottom": 10
                                }
                            },
                            "labelType": "month",
                            "seriesDataType": "department",
                            "valuesDataType": "number",
                            "elements": {
                                "point": {
                                    "radius": 2,
                                    "pointStyle": "circle",
                                    "hitRadius": 2,
                                    "hoverBorderWidth": 2
                                },
                                "line": {
                                    "tension": 0.5
                                }
                            },
                            "plugins": {
                                "legend": {
                                    "padding": 0.4,
                                    "display": true,
                                    "position": "top",
                                    "align": "end",
                                    "title": {
                                        "text": "Legend Title",
                                        "align": "center",
                                        "padding": {
                                            "top": 0,
                                            "right": 0,
                                            "left": 0,
                                            "bottom": 5
                                        },
                                        "display": false,
                                        "color": "rgba(58, 81, 102, 1)",
                                        "position": "bottom",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 12,
                                            "family": "'Arial', sans-serif"
                                        }
                                    },
                                    "labels": {
                                        "usePointStyle": true,
                                        "pointStyle": "circle",
                                        "textAlign": "right",
                                        "radius": 6,
                                        "boxWidth": 6,
                                        "boxHeight": 6,
                                        "color": "rgba(125, 125, 125, 1)",
                                        "padding": 10,
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 14,
                                            "family": "'Arial', sans-serif"
                                        }
                                    }
                                },
                                "title": {
                                    "text": "Revenue by Category ",
                                    "align": "start",
                                    "padding": {
                                        "top": 0,
                                        "right": 0,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": [
                                        "rgba(69,90,100,1)"
                                    ],
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "subtitle": {
                                    "text": "Sum of $ revenue in top product categories",
                                    "align": "start",
                                    "padding": {
                                        "top": 0,
                                        "right": 0,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "italic",
                                        "size": 11,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "datalabels": {
                                    "display": "auto",
                                    "source": "data",
                                    "padding": {
                                        "top": 0,
                                        "right": 0,
                                        "left": 0,
                                        "bottom": 5
                                    },
                                    "align": "end",
                                    "anchor": "end",
                                    "color": "rgba(58, 81, 102, 1)",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "normal",
                                        "size": 12,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "dragData": false,
                                "stacked100": {
                                    "enable": false,
                                    "replaceTooltipLabel": false
                                },
                                "tooltip": {
                                    "enabled": false,
                                    "animation": false
                                }
                            },
                            "scales": {
                                "y": {
                                    "axis": "y",
                                    "display": true,
                                    "labelType": "month",
                                    "stacked": false,
                                    "min": 0,
                                    "max": 1000,
                                    "title": {
                                        "text": "Revenue",
                                        "align": "center",
                                        "padding": {
                                            "top": 10,
                                            "right": 0,
                                            "left": 0,
                                            "bottom": 5
                                        },
                                        "display": false,
                                        "color": "rgba(125, 125, 125, 1)",
                                        "position": "bottom",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 8,
                                            "family": "'Arial', sans-serif"
                                        }
                                    },
                                    "grid": {
                                        "display": false,
                                        "drawBorder": true,
                                        "drawOnChartArea": true,
                                        "drawTicks": false,
                                        "borderWidth": 1,
                                        "lineWidth": 1,
                                        "color": [
                                            "rgba(200, 200,200, 0.2)"
                                        ],
                                        "borderDashOffset": 0,
                                        "offset": false,
                                        "borderDash": [],
                                        "borderColor": "rgba(0,0,0,0.1)",
                                        "tickLength": 8
                                    },
                                    "ticks": {
                                        "autoSkip": true,
                                        "source": "labels",
                                        "display": true,
                                        "labelType": "month",
                                        "color": "rgba(58, 81, 102, 1)",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 8,
                                            "family": "'Arial', sans-serif"
                                        },
                                        "minor": {},
                                        "major": {},
                                        "backdropPadding": 2,
                                        "padding": 3,
                                        "showLabelBackdrop": false,
                                        "minRotation": 0,
                                        "mirror": false,
                                        "textStrokeColor": "",
                                        "maxRotation": 50,
                                        "crossAlign": "near",
                                        "textStrokeWidth": 0,
                                        "backdropColor": "rgba(255, 255, 255, 0.75)",
                                        "labelOffset": 0,
                                        "autoSkipPadding": 3,
                                        "align": "center"
                                    },
                                    "type": "linear",
                                    "beginAtZero": false,
                                    "offset": false,
                                    "position": "left",
                                    "id": "y",
                                    "grace": 0,
                                    "bounds": "ticks",
                                    "reverse": false
                                },
                                "x": {
                                    "axis": "x",
                                    "display": true,
                                    "labelType": "month",
                                    "stacked": false,
                                    "min": null,
                                    "max": null,
                                    "title": {
                                        "text": "Months",
                                        "align": "center",
                                        "padding": {
                                            "top": 10,
                                            "right": 0,
                                            "left": 0,
                                            "bottom": 5
                                        },
                                        "display": true,
                                        "color": "rgba(125, 125, 125, 1)",
                                        "position": "bottom",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 12,
                                            "family": "'Arial', sans-serif"
                                        }
                                    },
                                    "grid": {
                                        "display": false,
                                        "drawBorder": true,
                                        "drawOnChartArea": true,
                                        "drawTicks": false,
                                        "borderWidth": 1,
                                        "lineWidth": 1,
                                        "color": [
                                            "rgba(200, 200,200, 0.2)"
                                        ],
                                        "borderDashOffset": 0,
                                        "borderDash": [],
                                        "borderColor": "rgba(0,0,0,0.1)",
                                        "tickLength": 8,
                                        "offset": false
                                    },
                                    "ticks": {
                                        "autoSkip": true,
                                        "source": "labels",
                                        "display": true,
                                        "labelType": "month",
                                        "color": "rgba(58, 81, 102, 1)",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 8,
                                            "family": "'Arial', sans-serif"
                                        },
                                        "textStrokeWidth": 0,
                                        "backdropPadding": 2,
                                        "crossAlign": "near",
                                        "minRotation": 0,
                                        "minor": {},
                                        "maxRotation": 50,
                                        "align": "center",
                                        "labelOffset": 0,
                                        "mirror": false,
                                        "showLabelBackdrop": false,
                                        "backdropColor": "rgba(255, 255, 255, 0.75)",
                                        "major": {
                                            "enabled": false
                                        },
                                        "autoSkipPadding": 3,
                                        "textStrokeColor": "",
                                        "padding": 3
                                    },
                                    "bounds": "data",
                                    "type": "time",
                                    "time": {
                                        "unit": "month",
                                        "displayFormats": {
                                            "day": "MMM-DD",
                                            "week": "MMM-DD",
                                            "month": "MMM 'YY",
                                            "quarter": "[Q]Q 'YY",
                                            "year": "YYYY",
                                            "datetime": "MMM D, YYYY, h:mm:ss a",
                                            "hour": "hA",
                                            "millisecond": "h:mm:ss.SSS a",
                                            "minute": "h:mm a",
                                            "second": "h:mm:ss a"
                                        },
                                        "parser": false,
                                        "isoWeekday": false,
                                        "minUnit": "millisecond",
                                        "round": false
                                    },
                                    "id": "x",
                                    "reverse": false,
                                    "position": "bottom",
                                    "adapters": {},
                                    "grace": 0,
                                    "offset": false,
                                    "beginAtZero": false
                                }
                            }
                        },
                        "chartData": {
                            "labelType": "month",
                            "valuesDataType": "number",
                            "seriesDataType": "department",
                            "labelsStep": 100,
                            "labelsMax": 1000,
                            "labelsMin": 0,
                            "labelsToDate": "2023-02-21T09:31:38.503Z",
                            "valueOptions": [],
                            "labels": [
                                "2023-01-31T22:00:00.000Z",
                                "2022-12-31T22:00:00.000Z",
                                "2022-11-30T22:00:00.000Z",
                                "2022-10-31T22:00:00.000Z",
                                "2022-09-30T21:00:00.000Z",
                                "2022-08-31T21:00:00.000Z",
                                "2022-07-31T21:00:00.000Z",
                                "2022-06-30T21:00:00.000Z"
                            ],
                            "datasets": [
                                {
                                    "backgroundColor": "rgba(76, 148, 247, 1)",
                                    "dataType": "number",
                                    "min": "500",
                                    "label": "Toys",
                                    "data": [
                                        730,
                                        786,
                                        714,
                                        768,
                                        621,
                                        574,
                                        633,
                                        806
                                    ],
                                    "max": "900",
                                    "borderColor": "rgba(76, 148, 247, 1)",
                                    "id": "EddOnvUk01igxwi4w7XlY"
                                },
                                {
                                    "min": "200",
                                    "data": [
                                        222,
                                        349,
                                        342,
                                        304,
                                        247,
                                        204,
                                        223,
                                        323
                                    ],
                                    "id": "I4p7-_0oci-t89ms1C2L7",
                                    "dataType": "number",
                                    "backgroundColor": "rgba(193, 159, 248, 1)",
                                    "max": "350",
                                    "borderColor": "rgba(193, 159, 248, 1)",
                                    "label": "Gadgets"
                                },
                                {
                                    "data": [
                                        888,
                                        576,
                                        432,
                                        411,
                                        392,
                                        315,
                                        354,
                                        455
                                    ],
                                    "label": "Fashion",
                                    "id": "i-Eb2ukoiiM_IsH9M6D8S",
                                    "backgroundColor": [
                                        "rgba(86,191,156,1)"
                                    ],
                                    "max": "800",
                                    "min": "100",
                                    "borderColor": [
                                        "rgba(86,191,156,1)"
                                    ],
                                    "dataType": "integer"
                                }
                            ]
                        }
                    },
                    {
                        "key": "oGn7_F3V7WTkqW5tiF5ly",
                        "createdAt": 1676972829271,
                        "chartVariant": [],
                        "chartType": "kpi",
                        "tabId": "3Y30nQ_5X8bJAsbSjQokP",
                        "isActive": false,
                        "chartSettings": {
                            "renderedAt": 1676972829271,
                            "updatedAt": 1676992250109,
                            "positionX": 20,
                            "positionY": 120,
                            "width": 320,
                            "height": 230,
                            "backgroundColor": "rgba(255,255,255,1)",
                            "borderColor": "rgba(204, 204, 204, 1)",
                            "borderRadius": 0,
                            "borderWidth": 1,
                            "activeBoxType": null,
                            "description": null,
                            "isDataDraggable": false,
                            "zIndex": 4
                        },
                        "isUp": true,
                        "chartOptions": {
                            "layout": {
                                "padding": {
                                    "top": 0,
                                    "right": 0,
                                    "left": 0,
                                    "bottom": 5
                                }
                            },
                            "plugins": {
                                "title": {
                                    "text": "Weekly Revenue",
                                    "align": "start",
                                    "padding": {
                                        "top": 0,
                                        "right": 0,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": [
                                        "rgba(69,90,100,1)"
                                    ],
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "subtitle": {
                                    "text": "Chart Sub title",
                                    "align": "start",
                                    "padding": {
                                        "top": 0,
                                        "right": 0,
                                        "left": 0,
                                        "bottom": 25
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "italic",
                                        "size": 11,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "metric": {
                                    "text": "42",
                                    "align": "start",
                                    "padding": {
                                        "top": 0,
                                        "right": 0,
                                        "left": 0,
                                        "bottom": 5
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 48,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "startAdornment": {
                                    "text": "$",
                                    "align": "start",
                                    "padding": {
                                        "top": 0,
                                        "right": 0,
                                        "left": 0,
                                        "bottom": 5
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 36,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "endAdornment": {
                                    "text": "K",
                                    "align": "start",
                                    "padding": {
                                        "top": 0,
                                        "right": 0,
                                        "left": 0,
                                        "bottom": 5
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 36,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "submetric": {
                                    "text": "⬆ 7% from last week",
                                    "align": "start",
                                    "padding": {
                                        "top": 0,
                                        "right": 0,
                                        "left": 0,
                                        "bottom": 5
                                    },
                                    "display": true,
                                    "color": "rgba(80, 227, 194, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "normal",
                                        "style": "normal",
                                        "size": 16,
                                        "family": "'Arial', sans-serif"
                                    }
                                }
                            }
                        }
                    },
                    {
                        "key": "QvDSm9g8aVOYuGNPmX3Bz",
                        "createdAt": 1676972934734,
                        "chartVariant": [
                            "line",
                            "area"
                        ],
                        "chartType": "kpi",
                        "tabId": "3Y30nQ_5X8bJAsbSjQokP",
                        "isActive": false,
                        "chartSettings": {
                            "renderedAt": 1676990944250,
                            "updatedAt": 1676992250109,
                            "positionX": 360,
                            "positionY": 120,
                            "width": 310,
                            "height": 230,
                            "backgroundColor": "rgba(255,255,255,1)",
                            "borderColor": "rgba(204, 204, 204, 1)",
                            "borderRadius": 0,
                            "borderWidth": 1,
                            "activeBoxType": null,
                            "description": null,
                            "isDataDraggable": false,
                            "zIndex": 6
                        },
                        "isUp": true,
                        "chartOptions": {
                            "layout": {
                                "padding": {
                                    "top": 0,
                                    "right": 0,
                                    "left": 0,
                                    "bottom": 5
                                }
                            },
                            "plugins": {
                                "title": {
                                    "text": "Conversion",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 130,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": [
                                        "rgba(69,90,100,1)"
                                    ],
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "subtitle": {
                                    "text": "From visit to purchase",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 25
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "italic",
                                        "size": 11,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "metric": {
                                    "text": "42",
                                    "align": "start",
                                    "padding": {
                                        "bottom": 5,
                                        "left": 0,
                                        "top": 0,
                                        "right": 0
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 48,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "startAdornment": {
                                    "text": "",
                                    "align": "start",
                                    "padding": {
                                        "left": 0,
                                        "top": 0,
                                        "right": 0,
                                        "bottom": 5
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 36,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "endAdornment": {
                                    "text": "%",
                                    "align": "start",
                                    "padding": {
                                        "left": 0,
                                        "top": 0,
                                        "bottom": 5,
                                        "right": 0
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 36,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "submetric": {
                                    "text": "",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "bottom": 5,
                                        "left": 0
                                    },
                                    "display": false,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "normal",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "spark": {
                                    "color": "rgba(58,81,102,1)",
                                    "thikness": 2,
                                    "borderColor": "rgba(0,0,0,1)",
                                    "backgroundColor": "rgba(58,81,102,0.1)",
                                    "sparkType": "line"
                                },
                                "dragData": false
                            }
                        }
                    },
                    {
                        "key": "pSneiKpA8GXtVN_08DWvK",
                        "createdAt": 1676972955106,
                        "chartVariant": [],
                        "chartType": "pie",
                        "tabId": "3Y30nQ_5X8bJAsbSjQokP",
                        "isActive": false,
                        "chartSettings": {
                            "renderedAt": 1676991011015,
                            "updatedAt": 1676992364741,
                            "positionX": 690,
                            "positionY": 120,
                            "width": 370,
                            "height": 230,
                            "backgroundColor": "rgba(255,255,255,1)",
                            "borderColor": "rgba(204, 204, 204, 1)",
                            "borderRadius": 0,
                            "borderWidth": 1,
                            "activeBoxType": null,
                            "description": null,
                            "isDataDraggable": true,
                            "zIndex": 8
                        },
                        "chartOptions": {
                            "maintainAspectRatio": false,
                            "responsive": true,
                            "animation": true,
                            "interaction": {
                                "mode": "index",
                                "intersect": false
                            },
                            "indexAxis": "x",
                            "layout": {
                                "padding": {
                                    "top": 10,
                                    "right": 20,
                                    "left": 10,
                                    "bottom": 10
                                }
                            },
                            "labelType": "department",
                            "seriesDataType": "department",
                            "valuesDataType": "number",
                            "elements": {
                                "point": {
                                    "radius": 2,
                                    "pointStyle": "circle",
                                    "hitRadius": 2,
                                    "hoverBorderWidth": 2
                                },
                                "line": {
                                    "tension": 0.5
                                }
                            },
                            "plugins": {
                                "legend": {
                                    "padding": 0.4,
                                    "display": true,
                                    "position": "right",
                                    "align": "center",
                                    "title": {
                                        "text": "Legend Title",
                                        "align": "center",
                                        "padding": {
                                            "bottom": 5,
                                            "left": 0,
                                            "right": 0,
                                            "top": 0
                                        },
                                        "display": false,
                                        "color": "rgba(58, 81, 102, 1)",
                                        "position": "bottom",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 12,
                                            "family": "'Arial', sans-serif"
                                        }
                                    },
                                    "labels": {
                                        "usePointStyle": true,
                                        "pointStyle": "circle",
                                        "textAlign": "right",
                                        "radius": 6,
                                        "boxWidth": 6,
                                        "boxHeight": 6,
                                        "color": "rgba(125, 125, 125, 1)",
                                        "padding": 10,
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 14,
                                            "family": "'Arial', sans-serif"
                                        }
                                    }
                                },
                                "title": {
                                    "text": "Weekly revenue type",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 20
                                    },
                                    "display": true,
                                    "color": [
                                        "rgba(69,90,100,1)"
                                    ],
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "subtitle": {
                                    "text": "Weekly revenue type",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 25
                                    },
                                    "display": false,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "italic",
                                        "size": 11,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "datalabels": {
                                    "display": "auto",
                                    "source": "data",
                                    "padding": {
                                        "bottom": 5,
                                        "right": 0,
                                        "left": 0,
                                        "top": 0
                                    },
                                    "align": "center",
                                    "anchor": "center",
                                    "color": "rgba(58, 81, 102, 1)",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "dragData": false,
                                "stacked100": {
                                    "enable": false,
                                    "replaceTooltipLabel": false
                                },
                                "tooltip": {
                                    "enabled": false
                                }
                            },
                            "scales": {
                                "y": {
                                    "axis": "y",
                                    "display": false,
                                    "labelType": "department",
                                    "stacked": false,
                                    "min": 0,
                                    "max": 1000,
                                    "title": {
                                        "text": "Values",
                                        "align": "center",
                                        "padding": {
                                            "left": 0,
                                            "right": 0,
                                            "bottom": 5,
                                            "top": 10
                                        },
                                        "display": true,
                                        "color": "rgba(125, 125, 125, 1)",
                                        "position": "bottom",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 8,
                                            "family": "'Arial', sans-serif"
                                        }
                                    },
                                    "grid": {
                                        "display": true,
                                        "drawBorder": true,
                                        "drawOnChartArea": true,
                                        "drawTicks": false,
                                        "borderWidth": 1,
                                        "lineWidth": 1,
                                        "color": [
                                            "rgba(200, 200,200, 0.2)"
                                        ],
                                        "tickLength": 8,
                                        "offset": false,
                                        "borderDash": [],
                                        "borderDashOffset": 0,
                                        "borderColor": "rgba(0,0,0,0.1)"
                                    },
                                    "ticks": {
                                        "autoSkip": true,
                                        "source": "labels",
                                        "display": true,
                                        "labelType": "department",
                                        "color": "rgba(58, 81, 102, 1)",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 8,
                                            "family": "'Arial', sans-serif"
                                        },
                                        "minRotation": 0,
                                        "maxRotation": 50,
                                        "mirror": false,
                                        "textStrokeWidth": 0,
                                        "textStrokeColor": "",
                                        "padding": 3,
                                        "autoSkipPadding": 3,
                                        "labelOffset": 0,
                                        "minor": {},
                                        "major": {},
                                        "align": "center",
                                        "crossAlign": "near",
                                        "showLabelBackdrop": false,
                                        "backdropColor": "rgba(255, 255, 255, 0.75)",
                                        "backdropPadding": 2
                                    },
                                    "type": "linear",
                                    "offset": false,
                                    "reverse": false,
                                    "beginAtZero": false,
                                    "bounds": "ticks",
                                    "grace": 0,
                                    "id": "y",
                                    "position": "left"
                                },
                                "x": {
                                    "axis": "x",
                                    "display": false,
                                    "labelType": "department",
                                    "stacked": false,
                                    "min": null,
                                    "max": null,
                                    "title": {
                                        "text": "Department",
                                        "align": "center",
                                        "padding": {
                                            "left": 0,
                                            "right": 0,
                                            "top": 10,
                                            "bottom": 5
                                        },
                                        "display": true,
                                        "color": "rgba(125, 125, 125, 1)",
                                        "position": "bottom",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 12,
                                            "family": "'Arial', sans-serif"
                                        }
                                    },
                                    "grid": {
                                        "display": true,
                                        "drawBorder": true,
                                        "drawOnChartArea": true,
                                        "drawTicks": false,
                                        "borderWidth": 1,
                                        "lineWidth": 1,
                                        "color": [
                                            "rgba(200, 200,200, 0.2)"
                                        ],
                                        "tickLength": 8,
                                        "offset": false,
                                        "borderDash": [],
                                        "borderDashOffset": 0,
                                        "borderColor": "rgba(0,0,0,0.1)"
                                    },
                                    "ticks": {
                                        "autoSkip": true,
                                        "source": "labels",
                                        "display": true,
                                        "labelType": "department",
                                        "color": "rgba(58, 81, 102, 1)",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 8,
                                            "family": "'Arial', sans-serif"
                                        },
                                        "minRotation": 0,
                                        "maxRotation": 50,
                                        "mirror": false,
                                        "textStrokeWidth": 0,
                                        "textStrokeColor": "",
                                        "padding": 3,
                                        "autoSkipPadding": 3,
                                        "labelOffset": 0,
                                        "minor": {},
                                        "major": {},
                                        "align": "center",
                                        "crossAlign": "near",
                                        "showLabelBackdrop": false,
                                        "backdropColor": "rgba(255, 255, 255, 0.75)",
                                        "backdropPadding": 2
                                    },
                                    "type": "category",
                                    "offset": false,
                                    "reverse": false,
                                    "beginAtZero": false,
                                    "bounds": "ticks",
                                    "grace": 0,
                                    "id": "x",
                                    "position": "bottom"
                                }
                            }
                        },
                        "chartData": {
                            "labelType": "department",
                            "valuesDataType": "number",
                            "seriesDataType": "department",
                            "labelsStep": 100,
                            "labelsMax": 1000,
                            "labelsMin": 0,
                            "labelsToDate": "2023-02-21T09:49:15.109Z",
                            "valueOptions": [],
                            "labels": [
                                "New",
                                "Reccuring"
                            ],
                            "datasets": [
                                {
                                    "id": "4JL5oDzPI9Z5aKEIKJSEf",
                                    "min": 0,
                                    "max": 1000,
                                    "label": "Books",
                                    "backgroundColor": [
                                        "rgba(76, 148, 247, 1)",
                                        "rgba(86,191,156,1)"
                                    ],
                                    "borderColor": [
                                        "rgba(76, 148, 247, 1)",
                                        "rgba(86,191,156,1)"
                                    ],
                                    "dataType": "number",
                                    "data": [
                                        527,
                                        129
                                    ],
                                    "hoverBackgroundColor": [
                                        "rgba(76, 148, 247, 1)",
                                        "rgba(86,191,156,1)"
                                    ],
                                    "pointBackgroundColor": [
                                        "rgba(76, 148, 247, 1)",
                                        "rgba(86,191,156,1)"
                                    ],
                                    "pointBorderColor": [
                                        "rgba(76, 148, 247, 1)",
                                        "rgba(86,191,156,1)"
                                    ]
                                }
                            ]
                        }
                    },
                    {
                        "key": "S9CVvXWHcEcgoN8OZGg-F",
                        "createdAt": 1676972989437,
                        "chartVariant": [
                            "date"
                        ],
                        "chartType": "filter",
                        "tabId": "3Y30nQ_5X8bJAsbSjQokP",
                        "isActive": false,
                        "chartSettings": {
                            "renderedAt": 1676972989437,
                            "updatedAt": 1676992250109,
                            "positionX": 20,
                            "positionY": 10,
                            "width": 350,
                            "height": 60,
                            "backgroundColor": "rgba(255,255,255,1)",
                            "borderColor": "rgba(204, 204, 204, 1)",
                            "borderRadius": 0,
                            "borderWidth": 0,
                            "activeBoxType": null,
                            "description": null,
                            "isDataDraggable": false,
                            "zIndex": 9
                        },
                        "chartOptions": {
                            "layout": {
                                "padding": {
                                    "top": 0,
                                    "right": 0,
                                    "left": 0,
                                    "bottom": 5
                                }
                            },
                            "plugins": {
                                "title": {
                                    "text": "Users created at",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": [
                                        "rgba(69,90,100,1)"
                                    ],
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "startDatePicker": {
                                    "text": "From:",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 25
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "italic",
                                        "size": 11,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "endDatePicker": {
                                    "text": "To:",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 25
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "italic",
                                        "size": 11,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "startDate": "2023-02-21T09:49:49.439Z",
                                "endDate": "2023-02-21T09:49:49.439Z",
                                "dragData": false
                            }
                        }
                    },
                    {
                        "key": "8nJ9uDKuU7IhlWzS3haXV",
                        "createdAt": 1676972994003,
                        "chartVariant": [
                            "range"
                        ],
                        "chartType": "filter",
                        "tabId": "3Y30nQ_5X8bJAsbSjQokP",
                        "isActive": false,
                        "chartSettings": {
                            "renderedAt": 1676972994003,
                            "updatedAt": 1676992269571,
                            "positionX": 370,
                            "positionY": 20,
                            "width": 350,
                            "height": 70,
                            "backgroundColor": "rgba(255,255,255,1)",
                            "borderColor": "rgba(204, 204, 204, 1)",
                            "borderRadius": 0,
                            "borderWidth": 0,
                            "activeBoxType": null,
                            "description": null,
                            "isDataDraggable": false,
                            "zIndex": 10
                        },
                        "chartOptions": {
                            "layout": {
                                "padding": {
                                    "top": 0,
                                    "right": 0,
                                    "left": 0,
                                    "bottom": 5
                                }
                            },
                            "plugins": {
                                "title": {
                                    "text": "Shopping Cart size",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": [
                                        "rgba(69,90,100,1)"
                                    ],
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "range": {
                                    "value": [
                                        109,
                                        690
                                    ],
                                    "color": [
                                        "rgba(10, 10,10, 0.8)"
                                    ],
                                    "min": 0,
                                    "max": 1000
                                },
                                "dragData": false
                            }
                        }
                    },
                    {
                        "key": "xxP7avoBDFuUvA15Nqa0y",
                        "createdAt": 1676986610337,
                        "chartVariant": [
                            "list"
                        ],
                        "chartType": "filter",
                        "tabId": "3Y30nQ_5X8bJAsbSjQokP",
                        "isActive": false,
                        "chartSettings": {
                            "renderedAt": 1676986610337,
                            "updatedAt": 1676992265456,
                            "positionX": 730,
                            "positionY": 20,
                            "width": 320,
                            "height": 70,
                            "backgroundColor": "rgba(255,255,255,1)",
                            "borderColor": "rgba(204, 204, 204, 1)",
                            "borderRadius": 0,
                            "borderWidth": 0,
                            "activeBoxType": null,
                            "description": null,
                            "isDataDraggable": false,
                            "zIndex": 10
                        },
                        "chartOptions": {
                            "layout": {
                                "padding": {
                                    "top": 0,
                                    "right": 0,
                                    "left": 0,
                                    "bottom": 5
                                }
                            },
                            "plugins": {
                                "title": {
                                    "text": "Subscription Type",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": [
                                        "rgba(69,90,100,1)"
                                    ],
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "placeholder": {
                                    "text": "User type",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 16,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "listOptions": [
                                    "Pro",
                                    "Premiom",
                                    "Free",
                                    ""
                                ],
                                "listSettings": {
                                    "isMultiple": true,
                                    "listName": "Movies"
                                }
                            }
                        }
                    },
                    {
                        "key": "Nzh1GCDY0w0Te2o6vJyVw",
                        "createdAt": 1676990552445,
                        "chartVariant": [
                            "stacked"
                        ],
                        "chartType": "bar",
                        "tabId": "3Y30nQ_5X8bJAsbSjQokP",
                        "isActive": false,
                        "chartSettings": {
                            "renderedAt": 1676990778962,
                            "updatedAt": 1676992386852,
                            "positionX": 550,
                            "positionY": 370,
                            "width": 510,
                            "height": 360,
                            "backgroundColor": "rgba(255,255,255,1)",
                            "borderColor": "rgba(204, 204, 204, 1)",
                            "borderRadius": 0,
                            "borderWidth": 1,
                            "activeBoxType": null,
                            "description": null,
                            "isDataDraggable": true,
                            "zIndex": 9
                        },
                        "chartOptions": {
                            "maintainAspectRatio": false,
                            "responsive": true,
                            "animation": true,
                            "interaction": {
                                "mode": "index",
                                "intersect": false
                            },
                            "indexAxis": "x",
                            "layout": {
                                "padding": {
                                    "top": 10,
                                    "right": 20,
                                    "left": 10,
                                    "bottom": 10
                                }
                            },
                            "labelType": "month",
                            "seriesDataType": "department",
                            "valuesDataType": "number",
                            "elements": {
                                "point": {
                                    "radius": 2,
                                    "pointStyle": "circle",
                                    "hitRadius": 2,
                                    "hoverBorderWidth": 2
                                },
                                "line": {
                                    "tension": 0.5
                                }
                            },
                            "plugins": {
                                "legend": {
                                    "padding": 0.4,
                                    "display": true,
                                    "position": "top",
                                    "align": "end",
                                    "title": {
                                        "text": "Legend Title",
                                        "align": "center",
                                        "padding": {
                                            "bottom": 5,
                                            "left": 0,
                                            "right": 0,
                                            "top": 0
                                        },
                                        "display": false,
                                        "color": "rgba(58, 81, 102, 1)",
                                        "position": "bottom",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 12,
                                            "family": "'Arial', sans-serif"
                                        }
                                    },
                                    "labels": {
                                        "usePointStyle": true,
                                        "pointStyle": "circle",
                                        "textAlign": "right",
                                        "radius": 6,
                                        "boxWidth": 6,
                                        "boxHeight": 6,
                                        "color": "rgba(125, 125, 125, 1)",
                                        "padding": 10,
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 14,
                                            "family": "'Arial', sans-serif"
                                        }
                                    }
                                },
                                "title": {
                                    "text": "Revenue Accounting",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": [
                                        "rgba(69,90,100,1)"
                                    ],
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "subtitle": {
                                    "text": "Chart Sub title",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": false,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "italic",
                                        "size": 11,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "datalabels": {
                                    "display": "auto",
                                    "source": "data",
                                    "padding": {
                                        "bottom": 5,
                                        "right": 0,
                                        "left": 0,
                                        "top": 0
                                    },
                                    "align": "center",
                                    "anchor": "center",
                                    "color": "rgba(58, 81, 102, 1)",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "normal",
                                        "size": 12,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "dragData": false,
                                "stacked100": {
                                    "enable": false,
                                    "replaceTooltipLabel": false
                                },
                                "tooltip": {
                                    "enabled": false
                                }
                            },
                            "scales": {
                                "y": {
                                    "axis": "y",
                                    "display": true,
                                    "labelType": "month",
                                    "stacked": true,
                                    "min": -700,
                                    "max": 700,
                                    "title": {
                                        "text": "$K Revenue",
                                        "align": "center",
                                        "padding": {
                                            "left": 0,
                                            "right": 0,
                                            "bottom": 5,
                                            "top": 10
                                        },
                                        "display": true,
                                        "color": "rgba(125, 125, 125, 1)",
                                        "position": "bottom",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 12,
                                            "family": "'Arial', sans-serif"
                                        }
                                    },
                                    "grid": {
                                        "display": true,
                                        "drawBorder": true,
                                        "drawOnChartArea": true,
                                        "drawTicks": false,
                                        "borderWidth": 1,
                                        "lineWidth": 1,
                                        "color": [
                                            "rgba(200, 200,200, 0.2)"
                                        ],
                                        "tickLength": 8,
                                        "offset": false,
                                        "borderDash": [],
                                        "borderDashOffset": 0,
                                        "borderColor": "rgba(0,0,0,0.1)"
                                    },
                                    "ticks": {
                                        "autoSkip": true,
                                        "source": "labels",
                                        "display": true,
                                        "labelType": "month",
                                        "color": "rgba(58, 81, 102, 1)",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 8,
                                            "family": "'Arial', sans-serif"
                                        },
                                        "minRotation": 0,
                                        "maxRotation": 50,
                                        "mirror": false,
                                        "textStrokeWidth": 0,
                                        "textStrokeColor": "",
                                        "padding": 3,
                                        "autoSkipPadding": 3,
                                        "labelOffset": 0,
                                        "minor": {},
                                        "major": {},
                                        "align": "center",
                                        "crossAlign": "near",
                                        "showLabelBackdrop": false,
                                        "backdropColor": "rgba(255, 255, 255, 0.75)",
                                        "backdropPadding": 2
                                    },
                                    "type": "linear",
                                    "beginAtZero": true,
                                    "offset": false,
                                    "reverse": false,
                                    "bounds": "ticks",
                                    "grace": 0,
                                    "id": "y",
                                    "position": "left"
                                },
                                "x": {
                                    "axis": "x",
                                    "display": true,
                                    "labelType": "month",
                                    "stacked": true,
                                    "min": null,
                                    "max": null,
                                    "title": {
                                        "text": "Months",
                                        "align": "center",
                                        "padding": {
                                            "left": 0,
                                            "right": 0,
                                            "top": 10,
                                            "bottom": 5
                                        },
                                        "display": false,
                                        "color": "rgba(125, 125, 125, 1)",
                                        "position": "bottom",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 12,
                                            "family": "'Arial', sans-serif"
                                        }
                                    },
                                    "grid": {
                                        "display": true,
                                        "drawBorder": true,
                                        "drawOnChartArea": true,
                                        "drawTicks": false,
                                        "borderWidth": 1,
                                        "lineWidth": 1,
                                        "color": [
                                            "rgba(200, 200,200, 0.2)"
                                        ],
                                        "offset": true,
                                        "tickLength": 8,
                                        "borderDash": [],
                                        "borderDashOffset": 0,
                                        "borderColor": "rgba(0,0,0,0.1)"
                                    },
                                    "ticks": {
                                        "autoSkip": true,
                                        "source": "labels",
                                        "display": true,
                                        "labelType": "month",
                                        "color": "rgba(58, 81, 102, 1)",
                                        "font": {
                                            "weight": "lighter",
                                            "style": "normal",
                                            "size": 8,
                                            "family": "'Arial', sans-serif"
                                        },
                                        "major": {
                                            "enabled": false
                                        },
                                        "minRotation": 0,
                                        "maxRotation": 50,
                                        "mirror": false,
                                        "textStrokeWidth": 0,
                                        "textStrokeColor": "",
                                        "padding": 3,
                                        "autoSkipPadding": 3,
                                        "labelOffset": 0,
                                        "minor": {},
                                        "align": "center",
                                        "crossAlign": "near",
                                        "showLabelBackdrop": false,
                                        "backdropColor": "rgba(255, 255, 255, 0.75)",
                                        "backdropPadding": 2
                                    },
                                    "bounds": "data",
                                    "type": "time",
                                    "time": {
                                        "unit": "month",
                                        "displayFormats": {
                                            "day": "MMM-DD",
                                            "week": "MMM-DD",
                                            "month": "MMM 'YY",
                                            "quarter": "[Q]Q 'YY",
                                            "year": "YYYY",
                                            "datetime": "MMM D, YYYY, h:mm:ss a",
                                            "millisecond": "h:mm:ss.SSS a",
                                            "second": "h:mm:ss a",
                                            "minute": "h:mm a",
                                            "hour": "hA"
                                        },
                                        "parser": false,
                                        "round": false,
                                        "isoWeekday": false,
                                        "minUnit": "millisecond"
                                    },
                                    "offset": true,
                                    "adapters": {},
                                    "reverse": false,
                                    "beginAtZero": false,
                                    "grace": 0,
                                    "id": "x",
                                    "position": "bottom"
                                }
                            }
                        },
                        "chartData": {
                            "labelType": "month",
                            "valuesDataType": "number",
                            "seriesDataType": "department",
                            "labelsStep": 100,
                            "labelsMax": 1000,
                            "labelsMin": 0,
                            "labelsToDate": "2023-02-21T14:42:32.448Z",
                            "valueOptions": [],
                            "labels": [
                                "2023-01-31T22:00:00.000Z",
                                "2022-12-31T22:00:00.000Z",
                                "2022-11-30T22:00:00.000Z",
                                "2022-10-31T22:00:00.000Z",
                                "2022-09-30T21:00:00.000Z",
                                "2022-08-31T21:00:00.000Z",
                                "2022-07-31T21:00:00.000Z",
                                "2022-06-30T21:00:00.000Z"
                            ],
                            "datasets": [
                                {
                                    "id": "sTSQss8RgsCAU3Oc4bARR",
                                    "min": "100",
                                    "max": "300",
                                    "label": "Retained",
                                    "backgroundColor": "rgba(76, 148, 247, 1)",
                                    "borderColor": "rgba(76, 148, 247, 1)",
                                    "dataType": "number",
                                    "data": [
                                        127,
                                        210,
                                        110,
                                        115,
                                        292,
                                        137,
                                        133,
                                        263
                                    ]
                                },
                                {
                                    "id": "_7TtuCmYmWWSp1f26qCYz",
                                    "min": "100",
                                    "max": "400",
                                    "label": "New",
                                    "backgroundColor": [
                                        "rgba(86,191,156,1)"
                                    ],
                                    "borderColor": [
                                        "rgba(86,191,156,1)"
                                    ],
                                    "dataType": "number",
                                    "data": [
                                        237,
                                        260,
                                        265,
                                        178,
                                        317,
                                        267,
                                        278,
                                        193
                                    ]
                                },
                                {
                                    "id": "emzChWGfLqiAk2k8Q0ZqX",
                                    "min": "0",
                                    "max": "-500",
                                    "label": "Churned",
                                    "backgroundColor": [
                                        "rgba(247,76,76,1)"
                                    ],
                                    "borderColor": [
                                        "rgba(247,76,76,1)"
                                    ],
                                    "dataType": "integer",
                                    "data": [
                                        -398,
                                        -253,
                                        -402,
                                        -481,
                                        -327,
                                        -202,
                                        -5,
                                        -113
                                    ]
                                }
                            ]
                        }
                    },
                    {
                        "key": "kC3CX5AlLFfEO71oj-B-w",
                        "createdAt": 1676991034147,
                        "chartVariant": [],
                        "chartType": "box",
                        "tabId": "3Y30nQ_5X8bJAsbSjQokP",
                        "isActive": false,
                        "chartSettings": {
                            "renderedAt": 1676991034147,
                            "updatedAt": 1676992272701,
                            "positionX": 20,
                            "positionY": 10,
                            "width": 1040,
                            "height": 90,
                            "backgroundColor": "rgba(255,255,255,1)",
                            "borderColor": "rgba(204, 204, 204, 1)",
                            "borderRadius": 0,
                            "borderWidth": 1,
                            "activeBoxType": null,
                            "description": null,
                            "isDataDraggable": false,
                            "zIndex": 6
                        },
                        "chartOptions": {
                            "layout": {
                                "padding": {
                                    "top": 0,
                                    "right": 0,
                                    "left": 0,
                                    "bottom": 5
                                }
                            },
                            "plugins": {
                                "title": {
                                    "text": "Chart title",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": [
                                        "rgba(69,90,100,1)"
                                    ],
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                }
                            }
                        }
                    }
                ],
                "isActive": true
            },
            {
                "id": "4-D-gS9EqnjCC6QzCmbPC",
                "tabName": "Explore",
                "charts": [
                    {
                        "key": "fY1iaBjcgfzZuY4sgXQjK",
                        "createdAt": 1676990797730,
                        "chartVariant": [
                            ""
                        ],
                        "chartType": "datagrid",
                        "tabId": "4-D-gS9EqnjCC6QzCmbPC",
                        "isActive": false,
                        "chartSettings": {
                            "renderedAt": 1676990797730,
                            "updatedAt": 1676992250109,
                            "positionX": 60,
                            "positionY": 150,
                            "width": 950,
                            "height": 570,
                            "backgroundColor": "rgba(255,255,255,1)",
                            "borderColor": "rgba(204, 204, 204, 1)",
                            "borderRadius": 0,
                            "borderWidth": 1,
                            "activeBoxType": null,
                            "description": null,
                            "isDataDraggable": false,
                            "rowsPerPage": 100,
                            "dragData": false,
                            "zIndex": 2
                        },
                        "rows": [
                            {
                                "id": 0,
                                "H5RQl_G1UwnulqFWogf3K": 1,
                                "DlONm1HLhUO1IqsMWLpzA": "Gwen_Welch75",
                                "uQTy2uzCLOkrlH48CHKQb": "Felipa95@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Awesome Granite Soap",
                                "BwjQzDEEZXjbWGDE3t30f": "Cook Islands",
                                "d4sw1hBeUI4LFnSSTDhIe": "81d843dd-4ef2-48c0-901b-0dee76875f75",
                                "EKhG-DhiMLHhN-OnOkMKb": "814 Zetta Route",
                                "uEZJrsWD42Qg3cpQVVjGK": 66
                            },
                            {
                                "id": 1,
                                "H5RQl_G1UwnulqFWogf3K": 2,
                                "DlONm1HLhUO1IqsMWLpzA": "Grant76",
                                "uQTy2uzCLOkrlH48CHKQb": "Haskell70@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Ergonomic Steel Ball",
                                "BwjQzDEEZXjbWGDE3t30f": "Bermuda",
                                "d4sw1hBeUI4LFnSSTDhIe": "bbc213f1-ab42-4449-9443-f796272d6010",
                                "EKhG-DhiMLHhN-OnOkMKb": "4668 Samantha Common",
                                "uEZJrsWD42Qg3cpQVVjGK": 15
                            },
                            {
                                "id": 2,
                                "H5RQl_G1UwnulqFWogf3K": 3,
                                "DlONm1HLhUO1IqsMWLpzA": "Davion_OKon",
                                "uQTy2uzCLOkrlH48CHKQb": "Demetrius_Orn75@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Refined Frozen Shoes",
                                "BwjQzDEEZXjbWGDE3t30f": "Saudi Arabia",
                                "d4sw1hBeUI4LFnSSTDhIe": "d2858e91-c4d0-4cab-abcd-9efcd34cd034",
                                "EKhG-DhiMLHhN-OnOkMKb": "574 Leonie Spring",
                                "uEZJrsWD42Qg3cpQVVjGK": 262
                            },
                            {
                                "id": 3,
                                "H5RQl_G1UwnulqFWogf3K": 4,
                                "DlONm1HLhUO1IqsMWLpzA": "Krystal_Gulgowski",
                                "uQTy2uzCLOkrlH48CHKQb": "Rosalee.Daniel@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Electronic Wooden Ball",
                                "BwjQzDEEZXjbWGDE3t30f": "Central African Republic",
                                "d4sw1hBeUI4LFnSSTDhIe": "684f09ec-5873-408a-a056-5dd5c0a8a0a2",
                                "EKhG-DhiMLHhN-OnOkMKb": "117 Dante Ridge",
                                "uEZJrsWD42Qg3cpQVVjGK": 100
                            },
                            {
                                "id": 4,
                                "H5RQl_G1UwnulqFWogf3K": 5,
                                "DlONm1HLhUO1IqsMWLpzA": "Russell_Kovacek",
                                "uQTy2uzCLOkrlH48CHKQb": "Shana_Swaniawski@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handmade Plastic Fish",
                                "BwjQzDEEZXjbWGDE3t30f": "Kiribati",
                                "d4sw1hBeUI4LFnSSTDhIe": "94164a54-ac1d-4ceb-a3c6-a5c2ad268a12",
                                "EKhG-DhiMLHhN-OnOkMKb": "09778 Hessel Lakes",
                                "uEZJrsWD42Qg3cpQVVjGK": 2
                            },
                            {
                                "id": 5,
                                "H5RQl_G1UwnulqFWogf3K": 6,
                                "DlONm1HLhUO1IqsMWLpzA": "Madilyn.Padberg47",
                                "uQTy2uzCLOkrlH48CHKQb": "Trudie.Ondricka24@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Elegant Cotton Pants",
                                "BwjQzDEEZXjbWGDE3t30f": "Saint Pierre and Miquelon",
                                "d4sw1hBeUI4LFnSSTDhIe": "dad95f2c-0f9f-46df-8c25-f3000716e7ae",
                                "EKhG-DhiMLHhN-OnOkMKb": "29492 Kirlin Neck",
                                "uEZJrsWD42Qg3cpQVVjGK": 994
                            },
                            {
                                "id": 6,
                                "H5RQl_G1UwnulqFWogf3K": 7,
                                "DlONm1HLhUO1IqsMWLpzA": "Aniya_Donnelly",
                                "uQTy2uzCLOkrlH48CHKQb": "Michaela71@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Recycled Fresh Soap",
                                "BwjQzDEEZXjbWGDE3t30f": "Swaziland",
                                "d4sw1hBeUI4LFnSSTDhIe": "1f4f35cb-aa43-4bc6-9a7e-ec1a14b42485",
                                "EKhG-DhiMLHhN-OnOkMKb": "618 Maria Meadow",
                                "uEZJrsWD42Qg3cpQVVjGK": 650
                            },
                            {
                                "id": 7,
                                "H5RQl_G1UwnulqFWogf3K": 8,
                                "DlONm1HLhUO1IqsMWLpzA": "Brook.Johnson21",
                                "uQTy2uzCLOkrlH48CHKQb": "Roslyn_Bergstrom63@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Modern Fresh Pants",
                                "BwjQzDEEZXjbWGDE3t30f": "Lebanon",
                                "d4sw1hBeUI4LFnSSTDhIe": "c1181d68-5ca8-488e-b07d-9c3a179c527b",
                                "EKhG-DhiMLHhN-OnOkMKb": "176 Jacobi Forest",
                                "uEZJrsWD42Qg3cpQVVjGK": 809
                            },
                            {
                                "id": 8,
                                "H5RQl_G1UwnulqFWogf3K": 9,
                                "DlONm1HLhUO1IqsMWLpzA": "Charlene_Maggio72",
                                "uQTy2uzCLOkrlH48CHKQb": "Emerson.Prosacco@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Electronic Rubber Towels",
                                "BwjQzDEEZXjbWGDE3t30f": "French Guiana",
                                "d4sw1hBeUI4LFnSSTDhIe": "5e4072b8-15dc-4171-87df-fa98c64e3163",
                                "EKhG-DhiMLHhN-OnOkMKb": "802 Tremblay Wells",
                                "uEZJrsWD42Qg3cpQVVjGK": 177
                            },
                            {
                                "id": 9,
                                "H5RQl_G1UwnulqFWogf3K": 10,
                                "DlONm1HLhUO1IqsMWLpzA": "Dave.Spencer",
                                "uQTy2uzCLOkrlH48CHKQb": "Laurence_Altenwerth87@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Modern Wooden Bacon",
                                "BwjQzDEEZXjbWGDE3t30f": "Cocos (Keeling) Islands",
                                "d4sw1hBeUI4LFnSSTDhIe": "c4cafe6f-873e-4d2d-916c-e382e57184c6",
                                "EKhG-DhiMLHhN-OnOkMKb": "503 Labadie Points",
                                "uEZJrsWD42Qg3cpQVVjGK": 278
                            },
                            {
                                "id": 10,
                                "H5RQl_G1UwnulqFWogf3K": 11,
                                "DlONm1HLhUO1IqsMWLpzA": "Ava.Prosacco",
                                "uQTy2uzCLOkrlH48CHKQb": "Koby78@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Incredible Bronze Bacon",
                                "BwjQzDEEZXjbWGDE3t30f": "United States of America",
                                "d4sw1hBeUI4LFnSSTDhIe": "e65f3bae-554b-42ec-a81f-e55142e20559",
                                "EKhG-DhiMLHhN-OnOkMKb": "23044 Evelyn Meadow",
                                "uEZJrsWD42Qg3cpQVVjGK": 990
                            },
                            {
                                "id": 11,
                                "H5RQl_G1UwnulqFWogf3K": 12,
                                "DlONm1HLhUO1IqsMWLpzA": "Cheyenne51",
                                "uQTy2uzCLOkrlH48CHKQb": "Kay57@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Licensed Fresh Chicken",
                                "BwjQzDEEZXjbWGDE3t30f": "Pakistan",
                                "d4sw1hBeUI4LFnSSTDhIe": "49dcaf0e-1827-4c9f-8a6f-9e0af4e875ea",
                                "EKhG-DhiMLHhN-OnOkMKb": "221 Paula Flat",
                                "uEZJrsWD42Qg3cpQVVjGK": 430
                            },
                            {
                                "id": 12,
                                "H5RQl_G1UwnulqFWogf3K": 13,
                                "DlONm1HLhUO1IqsMWLpzA": "Sydnie_Wuckert49",
                                "uQTy2uzCLOkrlH48CHKQb": "Noemi34@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Ergonomic Cotton Pizza",
                                "BwjQzDEEZXjbWGDE3t30f": "Virgin Islands, U.S.",
                                "d4sw1hBeUI4LFnSSTDhIe": "5e0dcfd1-a669-4942-9faa-aaac39bb1fe6",
                                "EKhG-DhiMLHhN-OnOkMKb": "64091 Timmothy Extension",
                                "uEZJrsWD42Qg3cpQVVjGK": 897
                            },
                            {
                                "id": 13,
                                "H5RQl_G1UwnulqFWogf3K": 14,
                                "DlONm1HLhUO1IqsMWLpzA": "Nova55",
                                "uQTy2uzCLOkrlH48CHKQb": "Waino90@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Modern Plastic Bike",
                                "BwjQzDEEZXjbWGDE3t30f": "Mali",
                                "d4sw1hBeUI4LFnSSTDhIe": "f47a754e-26ba-45b4-8ac9-3b149cca9f21",
                                "EKhG-DhiMLHhN-OnOkMKb": "676 Ariel Roads",
                                "uEZJrsWD42Qg3cpQVVjGK": 427
                            },
                            {
                                "id": 14,
                                "H5RQl_G1UwnulqFWogf3K": 15,
                                "DlONm1HLhUO1IqsMWLpzA": "Lilyan18",
                                "uQTy2uzCLOkrlH48CHKQb": "Dexter_Shanahan@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Sleek Plastic Tuna",
                                "BwjQzDEEZXjbWGDE3t30f": "Democratic People's Republic of Korea",
                                "d4sw1hBeUI4LFnSSTDhIe": "10487630-9c34-42e2-942d-619c7eb18c27",
                                "EKhG-DhiMLHhN-OnOkMKb": "4656 Prosacco Coves",
                                "uEZJrsWD42Qg3cpQVVjGK": 16
                            },
                            {
                                "id": 15,
                                "H5RQl_G1UwnulqFWogf3K": 16,
                                "DlONm1HLhUO1IqsMWLpzA": "Malinda_Nolan",
                                "uQTy2uzCLOkrlH48CHKQb": "Kellen_Lubowitz@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Rustic Wooden Car",
                                "BwjQzDEEZXjbWGDE3t30f": "Sudan",
                                "d4sw1hBeUI4LFnSSTDhIe": "db8279a6-6cff-4547-a5bb-fd5715afef85",
                                "EKhG-DhiMLHhN-OnOkMKb": "192 Dibbert Avenue",
                                "uEZJrsWD42Qg3cpQVVjGK": 314
                            },
                            {
                                "id": 16,
                                "H5RQl_G1UwnulqFWogf3K": 17,
                                "DlONm1HLhUO1IqsMWLpzA": "Evalyn_Rodriguez10",
                                "uQTy2uzCLOkrlH48CHKQb": "Dameon.Fisher43@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handcrafted Steel Hat",
                                "BwjQzDEEZXjbWGDE3t30f": "Saint Vincent and the Grenadines",
                                "d4sw1hBeUI4LFnSSTDhIe": "2acd6e37-f208-402a-b6d8-3c51c4b9aa5b",
                                "EKhG-DhiMLHhN-OnOkMKb": "2243 Schmitt Center",
                                "uEZJrsWD42Qg3cpQVVjGK": 967
                            },
                            {
                                "id": 17,
                                "H5RQl_G1UwnulqFWogf3K": 18,
                                "DlONm1HLhUO1IqsMWLpzA": "Hal49",
                                "uQTy2uzCLOkrlH48CHKQb": "Adolph_Shields@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Electronic Frozen Mouse",
                                "BwjQzDEEZXjbWGDE3t30f": "Equatorial Guinea",
                                "d4sw1hBeUI4LFnSSTDhIe": "3ea68bf1-e8cf-4518-ae7e-a7182cd83410",
                                "EKhG-DhiMLHhN-OnOkMKb": "23189 Goyette Summit",
                                "uEZJrsWD42Qg3cpQVVjGK": 325
                            },
                            {
                                "id": 18,
                                "H5RQl_G1UwnulqFWogf3K": 19,
                                "DlONm1HLhUO1IqsMWLpzA": "Leatha_Braun49",
                                "uQTy2uzCLOkrlH48CHKQb": "Winona_Crist46@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Refined Plastic Shoes",
                                "BwjQzDEEZXjbWGDE3t30f": "Poland",
                                "d4sw1hBeUI4LFnSSTDhIe": "70ad5c40-975d-42d0-b246-801a7839de43",
                                "EKhG-DhiMLHhN-OnOkMKb": "845 Fatima Crest",
                                "uEZJrsWD42Qg3cpQVVjGK": 371
                            },
                            {
                                "id": 19,
                                "H5RQl_G1UwnulqFWogf3K": 20,
                                "DlONm1HLhUO1IqsMWLpzA": "Scarlett59",
                                "uQTy2uzCLOkrlH48CHKQb": "Emelia.VonRueden37@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Small Fresh Hat",
                                "BwjQzDEEZXjbWGDE3t30f": "Togo",
                                "d4sw1hBeUI4LFnSSTDhIe": "4182c70d-7409-432f-89ce-6b63a0572a63",
                                "EKhG-DhiMLHhN-OnOkMKb": "1730 Rupert Point",
                                "uEZJrsWD42Qg3cpQVVjGK": 230
                            },
                            {
                                "id": 20,
                                "H5RQl_G1UwnulqFWogf3K": 21,
                                "DlONm1HLhUO1IqsMWLpzA": "Mireille.Douglas31",
                                "uQTy2uzCLOkrlH48CHKQb": "Deven38@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Refined Metal Gloves",
                                "BwjQzDEEZXjbWGDE3t30f": "United Kingdom",
                                "d4sw1hBeUI4LFnSSTDhIe": "e0c9b5ff-2f7c-4fde-bf8a-04d1d91eebeb",
                                "EKhG-DhiMLHhN-OnOkMKb": "05705 Shyanne Plains",
                                "uEZJrsWD42Qg3cpQVVjGK": 859
                            },
                            {
                                "id": 21,
                                "H5RQl_G1UwnulqFWogf3K": 22,
                                "DlONm1HLhUO1IqsMWLpzA": "Willow_Mante79",
                                "uQTy2uzCLOkrlH48CHKQb": "Dennis69@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Unbranded Fresh Fish",
                                "BwjQzDEEZXjbWGDE3t30f": "Botswana",
                                "d4sw1hBeUI4LFnSSTDhIe": "b8e5dc20-f201-4d6d-848b-7424f60c7f69",
                                "EKhG-DhiMLHhN-OnOkMKb": "3033 Melody Forks",
                                "uEZJrsWD42Qg3cpQVVjGK": 141
                            },
                            {
                                "id": 22,
                                "H5RQl_G1UwnulqFWogf3K": 23,
                                "DlONm1HLhUO1IqsMWLpzA": "Jairo.Zulauf81",
                                "uQTy2uzCLOkrlH48CHKQb": "Tad_Deckow@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handmade Granite Bacon",
                                "BwjQzDEEZXjbWGDE3t30f": "Bahrain",
                                "d4sw1hBeUI4LFnSSTDhIe": "61d28357-b0c9-4e8f-9932-ad3c8e77eb29",
                                "EKhG-DhiMLHhN-OnOkMKb": "80246 Morissette Unions",
                                "uEZJrsWD42Qg3cpQVVjGK": 424
                            },
                            {
                                "id": 23,
                                "H5RQl_G1UwnulqFWogf3K": 24,
                                "DlONm1HLhUO1IqsMWLpzA": "Darby25",
                                "uQTy2uzCLOkrlH48CHKQb": "Ollie_Goldner97@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Elegant Bronze Sausages",
                                "BwjQzDEEZXjbWGDE3t30f": "Vietnam",
                                "d4sw1hBeUI4LFnSSTDhIe": "7fe1fa44-f56c-422a-95a8-f3ca8eab7421",
                                "EKhG-DhiMLHhN-OnOkMKb": "691 Michele Plain",
                                "uEZJrsWD42Qg3cpQVVjGK": 985
                            },
                            {
                                "id": 24,
                                "H5RQl_G1UwnulqFWogf3K": 25,
                                "DlONm1HLhUO1IqsMWLpzA": "Dakota45",
                                "uQTy2uzCLOkrlH48CHKQb": "Ursula_Schneider83@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Unbranded Rubber Hat",
                                "BwjQzDEEZXjbWGDE3t30f": "French Guiana",
                                "d4sw1hBeUI4LFnSSTDhIe": "3415aa96-9662-4696-906b-976a9749a0e0",
                                "EKhG-DhiMLHhN-OnOkMKb": "4244 Emelie Springs",
                                "uEZJrsWD42Qg3cpQVVjGK": 399
                            },
                            {
                                "id": 25,
                                "H5RQl_G1UwnulqFWogf3K": 26,
                                "DlONm1HLhUO1IqsMWLpzA": "Winston_Hagenes",
                                "uQTy2uzCLOkrlH48CHKQb": "Jaleel40@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handcrafted Rubber Car",
                                "BwjQzDEEZXjbWGDE3t30f": "Cote d'Ivoire",
                                "d4sw1hBeUI4LFnSSTDhIe": "8d4f6851-6a0e-4b1c-9919-1ed03fe08650",
                                "EKhG-DhiMLHhN-OnOkMKb": "642 Labadie Causeway",
                                "uEZJrsWD42Qg3cpQVVjGK": 106
                            },
                            {
                                "id": 26,
                                "H5RQl_G1UwnulqFWogf3K": 27,
                                "DlONm1HLhUO1IqsMWLpzA": "Stephan.Terry90",
                                "uQTy2uzCLOkrlH48CHKQb": "Samanta93@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Electronic Plastic Pizza",
                                "BwjQzDEEZXjbWGDE3t30f": "French Southern Territories",
                                "d4sw1hBeUI4LFnSSTDhIe": "fd620e9d-7f6c-4001-8e1c-80c8461b0a86",
                                "EKhG-DhiMLHhN-OnOkMKb": "51686 Yundt Ford",
                                "uEZJrsWD42Qg3cpQVVjGK": 872
                            },
                            {
                                "id": 27,
                                "H5RQl_G1UwnulqFWogf3K": 28,
                                "DlONm1HLhUO1IqsMWLpzA": "Guy_Hodkiewicz",
                                "uQTy2uzCLOkrlH48CHKQb": "Marvin_Wuckert6@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Recycled Granite Mouse",
                                "BwjQzDEEZXjbWGDE3t30f": "Cambodia",
                                "d4sw1hBeUI4LFnSSTDhIe": "8370f4c8-63cf-4f7e-a4f5-b66255702233",
                                "EKhG-DhiMLHhN-OnOkMKb": "2806 Emory Mill",
                                "uEZJrsWD42Qg3cpQVVjGK": 328
                            },
                            {
                                "id": 28,
                                "H5RQl_G1UwnulqFWogf3K": 29,
                                "DlONm1HLhUO1IqsMWLpzA": "Katrina68",
                                "uQTy2uzCLOkrlH48CHKQb": "Carter.Bednar26@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Generic Concrete Car",
                                "BwjQzDEEZXjbWGDE3t30f": "Sao Tome and Principe",
                                "d4sw1hBeUI4LFnSSTDhIe": "10df60f5-dac0-4b18-ada4-22aa8ae48fca",
                                "EKhG-DhiMLHhN-OnOkMKb": "864 Sandy Harbors",
                                "uEZJrsWD42Qg3cpQVVjGK": 247
                            },
                            {
                                "id": 29,
                                "H5RQl_G1UwnulqFWogf3K": 30,
                                "DlONm1HLhUO1IqsMWLpzA": "Orlando_Ebert",
                                "uQTy2uzCLOkrlH48CHKQb": "Louisa74@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Awesome Plastic Shoes",
                                "BwjQzDEEZXjbWGDE3t30f": "Egypt",
                                "d4sw1hBeUI4LFnSSTDhIe": "7b4a6835-80db-4094-aa03-b93a7e0545ed",
                                "EKhG-DhiMLHhN-OnOkMKb": "8008 Leon Flat",
                                "uEZJrsWD42Qg3cpQVVjGK": 556
                            },
                            {
                                "id": 30,
                                "H5RQl_G1UwnulqFWogf3K": 31,
                                "DlONm1HLhUO1IqsMWLpzA": "Brisa_Pfannerstill13",
                                "uQTy2uzCLOkrlH48CHKQb": "Gavin_Wisozk56@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Refined Soft Salad",
                                "BwjQzDEEZXjbWGDE3t30f": "Myanmar",
                                "d4sw1hBeUI4LFnSSTDhIe": "24b1a218-7172-4635-a16b-c6ed19a98862",
                                "EKhG-DhiMLHhN-OnOkMKb": "4293 Henri Plaza",
                                "uEZJrsWD42Qg3cpQVVjGK": 122
                            },
                            {
                                "id": 31,
                                "H5RQl_G1UwnulqFWogf3K": 32,
                                "DlONm1HLhUO1IqsMWLpzA": "Hailee82",
                                "uQTy2uzCLOkrlH48CHKQb": "Ethelyn16@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Intelligent Frozen Shoes",
                                "BwjQzDEEZXjbWGDE3t30f": "Chile",
                                "d4sw1hBeUI4LFnSSTDhIe": "a1514595-91c9-428c-8388-8b9cd396f3ed",
                                "EKhG-DhiMLHhN-OnOkMKb": "9696 Baumbach Rest",
                                "uEZJrsWD42Qg3cpQVVjGK": 137
                            },
                            {
                                "id": 32,
                                "H5RQl_G1UwnulqFWogf3K": 33,
                                "DlONm1HLhUO1IqsMWLpzA": "Raina_Kling3",
                                "uQTy2uzCLOkrlH48CHKQb": "Llewellyn.Hammes@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Fantastic Bronze Chicken",
                                "BwjQzDEEZXjbWGDE3t30f": "Uzbekistan",
                                "d4sw1hBeUI4LFnSSTDhIe": "89c1e9cf-5915-4195-8d2a-1338456fc411",
                                "EKhG-DhiMLHhN-OnOkMKb": "5094 Hyatt Landing",
                                "uEZJrsWD42Qg3cpQVVjGK": 249
                            },
                            {
                                "id": 33,
                                "H5RQl_G1UwnulqFWogf3K": 34,
                                "DlONm1HLhUO1IqsMWLpzA": "Carmella_Erdman",
                                "uQTy2uzCLOkrlH48CHKQb": "Frieda.McKenzie@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Recycled Soft Towels",
                                "BwjQzDEEZXjbWGDE3t30f": "Liberia",
                                "d4sw1hBeUI4LFnSSTDhIe": "53ac69ec-c3c0-4ea9-9307-174d975d7aa8",
                                "EKhG-DhiMLHhN-OnOkMKb": "446 Mante Mountain",
                                "uEZJrsWD42Qg3cpQVVjGK": 60
                            },
                            {
                                "id": 34,
                                "H5RQl_G1UwnulqFWogf3K": 35,
                                "DlONm1HLhUO1IqsMWLpzA": "Susan.Runolfsdottir2",
                                "uQTy2uzCLOkrlH48CHKQb": "Baylee.Schneider@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Elegant Granite Pizza",
                                "BwjQzDEEZXjbWGDE3t30f": "Iceland",
                                "d4sw1hBeUI4LFnSSTDhIe": "28cf39c7-5bc7-4d6b-b86f-07315cc49e29",
                                "EKhG-DhiMLHhN-OnOkMKb": "62062 Fadel Manors",
                                "uEZJrsWD42Qg3cpQVVjGK": 788
                            },
                            {
                                "id": 35,
                                "H5RQl_G1UwnulqFWogf3K": 36,
                                "DlONm1HLhUO1IqsMWLpzA": "Donny5",
                                "uQTy2uzCLOkrlH48CHKQb": "Samara55@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Refined Plastic Salad",
                                "BwjQzDEEZXjbWGDE3t30f": "Nicaragua",
                                "d4sw1hBeUI4LFnSSTDhIe": "0b164c71-2c53-4210-8818-38c84a0636cb",
                                "EKhG-DhiMLHhN-OnOkMKb": "3877 Lang Path",
                                "uEZJrsWD42Qg3cpQVVjGK": 780
                            },
                            {
                                "id": 36,
                                "H5RQl_G1UwnulqFWogf3K": 37,
                                "DlONm1HLhUO1IqsMWLpzA": "Oma.Fritsch16",
                                "uQTy2uzCLOkrlH48CHKQb": "Kory.Herman@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handmade Wooden Hat",
                                "BwjQzDEEZXjbWGDE3t30f": "Senegal",
                                "d4sw1hBeUI4LFnSSTDhIe": "6a5050ae-b685-466b-97a6-6a4ffd891d1b",
                                "EKhG-DhiMLHhN-OnOkMKb": "2617 Stracke Groves",
                                "uEZJrsWD42Qg3cpQVVjGK": 994
                            },
                            {
                                "id": 37,
                                "H5RQl_G1UwnulqFWogf3K": 38,
                                "DlONm1HLhUO1IqsMWLpzA": "Marielle.Simonis",
                                "uQTy2uzCLOkrlH48CHKQb": "Orrin_Harvey@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Rustic Concrete Chair",
                                "BwjQzDEEZXjbWGDE3t30f": "Guatemala",
                                "d4sw1hBeUI4LFnSSTDhIe": "67402166-635a-4ef2-a8de-143be9b78e2c",
                                "EKhG-DhiMLHhN-OnOkMKb": "149 Schimmel Summit",
                                "uEZJrsWD42Qg3cpQVVjGK": 956
                            },
                            {
                                "id": 38,
                                "H5RQl_G1UwnulqFWogf3K": 39,
                                "DlONm1HLhUO1IqsMWLpzA": "Ashley_Kutch",
                                "uQTy2uzCLOkrlH48CHKQb": "Makenzie95@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Recycled Frozen Soap",
                                "BwjQzDEEZXjbWGDE3t30f": "Guadeloupe",
                                "d4sw1hBeUI4LFnSSTDhIe": "bc9bf0c7-d9b9-481c-b453-216bd1052cb4",
                                "EKhG-DhiMLHhN-OnOkMKb": "43846 Gleason Ville",
                                "uEZJrsWD42Qg3cpQVVjGK": 619
                            },
                            {
                                "id": 39,
                                "H5RQl_G1UwnulqFWogf3K": 40,
                                "DlONm1HLhUO1IqsMWLpzA": "Rudy40",
                                "uQTy2uzCLOkrlH48CHKQb": "Austin_Turner91@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Electronic Cotton Tuna",
                                "BwjQzDEEZXjbWGDE3t30f": "Uganda",
                                "d4sw1hBeUI4LFnSSTDhIe": "323f43bb-7a1c-4cb0-9655-ab5ce7530445",
                                "EKhG-DhiMLHhN-OnOkMKb": "326 Jeffery Drives",
                                "uEZJrsWD42Qg3cpQVVjGK": 88
                            },
                            {
                                "id": 40,
                                "H5RQl_G1UwnulqFWogf3K": 41,
                                "DlONm1HLhUO1IqsMWLpzA": "Carol48",
                                "uQTy2uzCLOkrlH48CHKQb": "Shakira.Raynor@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Practical Bronze Cheese",
                                "BwjQzDEEZXjbWGDE3t30f": "Gibraltar",
                                "d4sw1hBeUI4LFnSSTDhIe": "e8be9170-d194-4d57-bba6-8c62a3fc0e0b",
                                "EKhG-DhiMLHhN-OnOkMKb": "998 Feil Center",
                                "uEZJrsWD42Qg3cpQVVjGK": 12
                            },
                            {
                                "id": 41,
                                "H5RQl_G1UwnulqFWogf3K": 42,
                                "DlONm1HLhUO1IqsMWLpzA": "Beaulah_Murazik",
                                "uQTy2uzCLOkrlH48CHKQb": "Adolf17@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Ergonomic Bronze Gloves",
                                "BwjQzDEEZXjbWGDE3t30f": "Kenya",
                                "d4sw1hBeUI4LFnSSTDhIe": "3bed2ca3-a572-4d87-b43a-74c1c0c41e54",
                                "EKhG-DhiMLHhN-OnOkMKb": "4050 Ferry Rapids",
                                "uEZJrsWD42Qg3cpQVVjGK": 758
                            },
                            {
                                "id": 42,
                                "H5RQl_G1UwnulqFWogf3K": 43,
                                "DlONm1HLhUO1IqsMWLpzA": "Gilberto_Yundt",
                                "uQTy2uzCLOkrlH48CHKQb": "Nayeli.Balistreri54@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Gorgeous Soft Gloves",
                                "BwjQzDEEZXjbWGDE3t30f": "France",
                                "d4sw1hBeUI4LFnSSTDhIe": "747ca14f-69b3-4389-9cf2-065e054ddf06",
                                "EKhG-DhiMLHhN-OnOkMKb": "914 Genoveva Flats",
                                "uEZJrsWD42Qg3cpQVVjGK": 940
                            },
                            {
                                "id": 43,
                                "H5RQl_G1UwnulqFWogf3K": 44,
                                "DlONm1HLhUO1IqsMWLpzA": "Alford.Leannon27",
                                "uQTy2uzCLOkrlH48CHKQb": "Name_Jast@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Electronic Cotton Car",
                                "BwjQzDEEZXjbWGDE3t30f": "Cayman Islands",
                                "d4sw1hBeUI4LFnSSTDhIe": "eb3c4220-fdb2-47cf-8bb3-aabb5a819e89",
                                "EKhG-DhiMLHhN-OnOkMKb": "257 Sonia Mall",
                                "uEZJrsWD42Qg3cpQVVjGK": 741
                            },
                            {
                                "id": 44,
                                "H5RQl_G1UwnulqFWogf3K": 45,
                                "DlONm1HLhUO1IqsMWLpzA": "Yasmine50",
                                "uQTy2uzCLOkrlH48CHKQb": "Idell_Franey@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Gorgeous Bronze Shirt",
                                "BwjQzDEEZXjbWGDE3t30f": "Aruba",
                                "d4sw1hBeUI4LFnSSTDhIe": "fb11f50d-913f-4f1c-a65c-82e8499acae6",
                                "EKhG-DhiMLHhN-OnOkMKb": "460 Quitzon Underpass",
                                "uEZJrsWD42Qg3cpQVVjGK": 695
                            },
                            {
                                "id": 45,
                                "H5RQl_G1UwnulqFWogf3K": 46,
                                "DlONm1HLhUO1IqsMWLpzA": "Freeman_Runte",
                                "uQTy2uzCLOkrlH48CHKQb": "Dawn47@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Practical Frozen Salad",
                                "BwjQzDEEZXjbWGDE3t30f": "Benin",
                                "d4sw1hBeUI4LFnSSTDhIe": "678961bf-fdd1-489b-a6e1-ebc40ec1b598",
                                "EKhG-DhiMLHhN-OnOkMKb": "00330 Welch Well",
                                "uEZJrsWD42Qg3cpQVVjGK": 792
                            },
                            {
                                "id": 46,
                                "H5RQl_G1UwnulqFWogf3K": 47,
                                "DlONm1HLhUO1IqsMWLpzA": "Lincoln89",
                                "uQTy2uzCLOkrlH48CHKQb": "Elijah97@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Unbranded Frozen Pants",
                                "BwjQzDEEZXjbWGDE3t30f": "Saint Pierre and Miquelon",
                                "d4sw1hBeUI4LFnSSTDhIe": "6e022e34-fd19-4681-8e13-46addb5eb388",
                                "EKhG-DhiMLHhN-OnOkMKb": "63750 Lang Shoal",
                                "uEZJrsWD42Qg3cpQVVjGK": 739
                            },
                            {
                                "id": 47,
                                "H5RQl_G1UwnulqFWogf3K": 48,
                                "DlONm1HLhUO1IqsMWLpzA": "Gregory33",
                                "uQTy2uzCLOkrlH48CHKQb": "Alda37@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Refined Metal Chips",
                                "BwjQzDEEZXjbWGDE3t30f": "American Samoa",
                                "d4sw1hBeUI4LFnSSTDhIe": "cc24efa7-26d9-427f-a8b9-71437252c726",
                                "EKhG-DhiMLHhN-OnOkMKb": "03398 Homenick Wall",
                                "uEZJrsWD42Qg3cpQVVjGK": 716
                            },
                            {
                                "id": 48,
                                "H5RQl_G1UwnulqFWogf3K": 49,
                                "DlONm1HLhUO1IqsMWLpzA": "Brenna16",
                                "uQTy2uzCLOkrlH48CHKQb": "Gideon.Farrell36@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Small Fresh Towels",
                                "BwjQzDEEZXjbWGDE3t30f": "French Guiana",
                                "d4sw1hBeUI4LFnSSTDhIe": "09d374f1-6e97-4a2c-9a4c-2fd612fb05c0",
                                "EKhG-DhiMLHhN-OnOkMKb": "028 Abshire Cliff",
                                "uEZJrsWD42Qg3cpQVVjGK": 956
                            },
                            {
                                "id": 49,
                                "H5RQl_G1UwnulqFWogf3K": 50,
                                "DlONm1HLhUO1IqsMWLpzA": "Ebony10",
                                "uQTy2uzCLOkrlH48CHKQb": "Bryana30@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Intelligent Frozen Shirt",
                                "BwjQzDEEZXjbWGDE3t30f": "Bulgaria",
                                "d4sw1hBeUI4LFnSSTDhIe": "feb5dafa-f856-4a7e-8e22-c6cf16e0ce3a",
                                "EKhG-DhiMLHhN-OnOkMKb": "392 Schmidt Port",
                                "uEZJrsWD42Qg3cpQVVjGK": 656
                            },
                            {
                                "id": 50,
                                "H5RQl_G1UwnulqFWogf3K": 51,
                                "DlONm1HLhUO1IqsMWLpzA": "Lavonne8",
                                "uQTy2uzCLOkrlH48CHKQb": "Betsy_Altenwerth@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Fantastic Metal Sausages",
                                "BwjQzDEEZXjbWGDE3t30f": "Mayotte",
                                "d4sw1hBeUI4LFnSSTDhIe": "9196ba46-1161-45e0-ab65-6c3d6193358a",
                                "EKhG-DhiMLHhN-OnOkMKb": "43463 Bettye Extension",
                                "uEZJrsWD42Qg3cpQVVjGK": 738
                            },
                            {
                                "id": 51,
                                "H5RQl_G1UwnulqFWogf3K": 52,
                                "DlONm1HLhUO1IqsMWLpzA": "Alda.Cremin",
                                "uQTy2uzCLOkrlH48CHKQb": "Madelynn_Kreiger@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Awesome Plastic Shirt",
                                "BwjQzDEEZXjbWGDE3t30f": "El Salvador",
                                "d4sw1hBeUI4LFnSSTDhIe": "436995cb-2854-40dd-b782-cb00bb164e50",
                                "EKhG-DhiMLHhN-OnOkMKb": "192 Waters Brook",
                                "uEZJrsWD42Qg3cpQVVjGK": 576
                            },
                            {
                                "id": 52,
                                "H5RQl_G1UwnulqFWogf3K": 53,
                                "DlONm1HLhUO1IqsMWLpzA": "Verner_Corkery",
                                "uQTy2uzCLOkrlH48CHKQb": "Vivian_Moore82@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Tasty Frozen Chicken",
                                "BwjQzDEEZXjbWGDE3t30f": "Antarctica (the territory South of 60 deg S)",
                                "d4sw1hBeUI4LFnSSTDhIe": "96d45a14-f28e-4be5-8586-5efcd85e8140",
                                "EKhG-DhiMLHhN-OnOkMKb": "622 Wayne Causeway",
                                "uEZJrsWD42Qg3cpQVVjGK": 9
                            },
                            {
                                "id": 53,
                                "H5RQl_G1UwnulqFWogf3K": 54,
                                "DlONm1HLhUO1IqsMWLpzA": "Leonora.Bednar12",
                                "uQTy2uzCLOkrlH48CHKQb": "Benjamin2@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Tasty Granite Pants",
                                "BwjQzDEEZXjbWGDE3t30f": "Wallis and Futuna",
                                "d4sw1hBeUI4LFnSSTDhIe": "d11d55ba-25cd-41b3-8b8d-4004a15122ff",
                                "EKhG-DhiMLHhN-OnOkMKb": "01306 Lavina Tunnel",
                                "uEZJrsWD42Qg3cpQVVjGK": 460
                            },
                            {
                                "id": 54,
                                "H5RQl_G1UwnulqFWogf3K": 55,
                                "DlONm1HLhUO1IqsMWLpzA": "Arnaldo.Kassulke4",
                                "uQTy2uzCLOkrlH48CHKQb": "Aron_Quitzon38@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Licensed Rubber Fish",
                                "BwjQzDEEZXjbWGDE3t30f": "Maldives",
                                "d4sw1hBeUI4LFnSSTDhIe": "19d960e4-9988-4f63-8b59-0546986c6b2a",
                                "EKhG-DhiMLHhN-OnOkMKb": "4371 Sydney Knoll",
                                "uEZJrsWD42Qg3cpQVVjGK": 833
                            },
                            {
                                "id": 55,
                                "H5RQl_G1UwnulqFWogf3K": 56,
                                "DlONm1HLhUO1IqsMWLpzA": "Nicklaus_Lowe28",
                                "uQTy2uzCLOkrlH48CHKQb": "Celine.Harvey54@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Bespoke Fresh Salad",
                                "BwjQzDEEZXjbWGDE3t30f": "Palau",
                                "d4sw1hBeUI4LFnSSTDhIe": "4adfccf6-a2c8-4ba3-9b33-2d7ce0f0a1d6",
                                "EKhG-DhiMLHhN-OnOkMKb": "489 Gracie View",
                                "uEZJrsWD42Qg3cpQVVjGK": 208
                            },
                            {
                                "id": 56,
                                "H5RQl_G1UwnulqFWogf3K": 57,
                                "DlONm1HLhUO1IqsMWLpzA": "Israel.Schiller75",
                                "uQTy2uzCLOkrlH48CHKQb": "Keith.Corwin89@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Bespoke Plastic Ball",
                                "BwjQzDEEZXjbWGDE3t30f": "Finland",
                                "d4sw1hBeUI4LFnSSTDhIe": "db724f5b-30e5-4be3-af21-76dbfaeba913",
                                "EKhG-DhiMLHhN-OnOkMKb": "88845 Sylvan Tunnel",
                                "uEZJrsWD42Qg3cpQVVjGK": 228
                            },
                            {
                                "id": 57,
                                "H5RQl_G1UwnulqFWogf3K": 58,
                                "DlONm1HLhUO1IqsMWLpzA": "Dortha_Schultz19",
                                "uQTy2uzCLOkrlH48CHKQb": "Jon67@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Bespoke Plastic Towels",
                                "BwjQzDEEZXjbWGDE3t30f": "Spain",
                                "d4sw1hBeUI4LFnSSTDhIe": "d3823fac-2a6e-4d4e-a93e-5f7e2d173bc6",
                                "EKhG-DhiMLHhN-OnOkMKb": "97381 Macejkovic Burgs",
                                "uEZJrsWD42Qg3cpQVVjGK": 469
                            },
                            {
                                "id": 58,
                                "H5RQl_G1UwnulqFWogf3K": 59,
                                "DlONm1HLhUO1IqsMWLpzA": "Mireya_Reichel70",
                                "uQTy2uzCLOkrlH48CHKQb": "Kira77@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Modern Bronze Table",
                                "BwjQzDEEZXjbWGDE3t30f": "Pakistan",
                                "d4sw1hBeUI4LFnSSTDhIe": "aae4e393-ff1f-4f84-9bf6-2ba366c9e838",
                                "EKhG-DhiMLHhN-OnOkMKb": "03217 O'Reilly Courts",
                                "uEZJrsWD42Qg3cpQVVjGK": 474
                            },
                            {
                                "id": 59,
                                "H5RQl_G1UwnulqFWogf3K": 60,
                                "DlONm1HLhUO1IqsMWLpzA": "Liliane74",
                                "uQTy2uzCLOkrlH48CHKQb": "Alexandrine_Beier90@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Awesome Granite Table",
                                "BwjQzDEEZXjbWGDE3t30f": "Israel",
                                "d4sw1hBeUI4LFnSSTDhIe": "b0594261-7155-42e0-8861-28023ce4da21",
                                "EKhG-DhiMLHhN-OnOkMKb": "667 Johnston Union",
                                "uEZJrsWD42Qg3cpQVVjGK": 725
                            },
                            {
                                "id": 60,
                                "H5RQl_G1UwnulqFWogf3K": 61,
                                "DlONm1HLhUO1IqsMWLpzA": "Barry81",
                                "uQTy2uzCLOkrlH48CHKQb": "Kaci.Hand@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Modern Fresh Table",
                                "BwjQzDEEZXjbWGDE3t30f": "Ghana",
                                "d4sw1hBeUI4LFnSSTDhIe": "9b936919-d714-419c-8b2f-134a94af6cb7",
                                "EKhG-DhiMLHhN-OnOkMKb": "0620 Eveline Extension",
                                "uEZJrsWD42Qg3cpQVVjGK": 333
                            },
                            {
                                "id": 61,
                                "H5RQl_G1UwnulqFWogf3K": 62,
                                "DlONm1HLhUO1IqsMWLpzA": "Christ23",
                                "uQTy2uzCLOkrlH48CHKQb": "Jessie.Buckridge@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Recycled Wooden Towels",
                                "BwjQzDEEZXjbWGDE3t30f": "Botswana",
                                "d4sw1hBeUI4LFnSSTDhIe": "c343c099-b1c8-424d-b1b6-73baf6691537",
                                "EKhG-DhiMLHhN-OnOkMKb": "600 Franey Point",
                                "uEZJrsWD42Qg3cpQVVjGK": 94
                            },
                            {
                                "id": 62,
                                "H5RQl_G1UwnulqFWogf3K": 63,
                                "DlONm1HLhUO1IqsMWLpzA": "Dayana.Cormier67",
                                "uQTy2uzCLOkrlH48CHKQb": "Verner21@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handmade Steel Towels",
                                "BwjQzDEEZXjbWGDE3t30f": "Greece",
                                "d4sw1hBeUI4LFnSSTDhIe": "512c639c-f2c9-458f-b7bd-f37dc6bbf65f",
                                "EKhG-DhiMLHhN-OnOkMKb": "98924 Senger Coves",
                                "uEZJrsWD42Qg3cpQVVjGK": 352
                            },
                            {
                                "id": 63,
                                "H5RQl_G1UwnulqFWogf3K": 64,
                                "DlONm1HLhUO1IqsMWLpzA": "Micheal.Stiedemann",
                                "uQTy2uzCLOkrlH48CHKQb": "Graham71@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Recycled Wooden Mouse",
                                "BwjQzDEEZXjbWGDE3t30f": "Yemen",
                                "d4sw1hBeUI4LFnSSTDhIe": "fb02a390-694b-4055-8130-306863110801",
                                "EKhG-DhiMLHhN-OnOkMKb": "885 Stokes Skyway",
                                "uEZJrsWD42Qg3cpQVVjGK": 678
                            },
                            {
                                "id": 64,
                                "H5RQl_G1UwnulqFWogf3K": 65,
                                "DlONm1HLhUO1IqsMWLpzA": "Uriel23",
                                "uQTy2uzCLOkrlH48CHKQb": "Esteban.Ullrich7@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Modern Metal Shirt",
                                "BwjQzDEEZXjbWGDE3t30f": "Sudan",
                                "d4sw1hBeUI4LFnSSTDhIe": "ae36e166-3fc0-43df-bfa7-1ce95e68d1d2",
                                "EKhG-DhiMLHhN-OnOkMKb": "500 Zulauf Keys",
                                "uEZJrsWD42Qg3cpQVVjGK": 925
                            },
                            {
                                "id": 65,
                                "H5RQl_G1UwnulqFWogf3K": 66,
                                "DlONm1HLhUO1IqsMWLpzA": "Elisabeth.Hermann90",
                                "uQTy2uzCLOkrlH48CHKQb": "Adolph_Gottlieb19@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Ergonomic Plastic Table",
                                "BwjQzDEEZXjbWGDE3t30f": "Cote d'Ivoire",
                                "d4sw1hBeUI4LFnSSTDhIe": "32ab2063-bd6a-4273-bed0-29681599783e",
                                "EKhG-DhiMLHhN-OnOkMKb": "633 Reichel Extensions",
                                "uEZJrsWD42Qg3cpQVVjGK": 546
                            },
                            {
                                "id": 66,
                                "H5RQl_G1UwnulqFWogf3K": 67,
                                "DlONm1HLhUO1IqsMWLpzA": "Mathew_Grant",
                                "uQTy2uzCLOkrlH48CHKQb": "Amparo_Farrell20@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Elegant Cotton Hat",
                                "BwjQzDEEZXjbWGDE3t30f": "Central African Republic",
                                "d4sw1hBeUI4LFnSSTDhIe": "a58ad04b-db0f-47de-838c-c2b15d8abaf2",
                                "EKhG-DhiMLHhN-OnOkMKb": "9946 Mellie Fords",
                                "uEZJrsWD42Qg3cpQVVjGK": 471
                            },
                            {
                                "id": 67,
                                "H5RQl_G1UwnulqFWogf3K": 68,
                                "DlONm1HLhUO1IqsMWLpzA": "Hellen_Cole9",
                                "uQTy2uzCLOkrlH48CHKQb": "Salvatore.Hauck@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Refined Steel Cheese",
                                "BwjQzDEEZXjbWGDE3t30f": "Guinea-Bissau",
                                "d4sw1hBeUI4LFnSSTDhIe": "27c0bccd-f79f-4168-80cc-09747efaff01",
                                "EKhG-DhiMLHhN-OnOkMKb": "053 Hermann Valley",
                                "uEZJrsWD42Qg3cpQVVjGK": 171
                            },
                            {
                                "id": 68,
                                "H5RQl_G1UwnulqFWogf3K": 69,
                                "DlONm1HLhUO1IqsMWLpzA": "Clyde25",
                                "uQTy2uzCLOkrlH48CHKQb": "Francis_Kshlerin85@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handmade Fresh Table",
                                "BwjQzDEEZXjbWGDE3t30f": "Lao People's Democratic Republic",
                                "d4sw1hBeUI4LFnSSTDhIe": "27b6ed47-c3ce-4376-a39c-87ba2278e950",
                                "EKhG-DhiMLHhN-OnOkMKb": "9646 Elliott Camp",
                                "uEZJrsWD42Qg3cpQVVjGK": 829
                            },
                            {
                                "id": 69,
                                "H5RQl_G1UwnulqFWogf3K": 70,
                                "DlONm1HLhUO1IqsMWLpzA": "Zakary.Larson19",
                                "uQTy2uzCLOkrlH48CHKQb": "Yasmin_Weber71@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Bespoke Soft Bike",
                                "BwjQzDEEZXjbWGDE3t30f": "British Indian Ocean Territory (Chagos Archipelago)",
                                "d4sw1hBeUI4LFnSSTDhIe": "6ea0fed9-c9a3-4089-b6a2-df58057f3f9a",
                                "EKhG-DhiMLHhN-OnOkMKb": "975 Schimmel Views",
                                "uEZJrsWD42Qg3cpQVVjGK": 6
                            },
                            {
                                "id": 70,
                                "H5RQl_G1UwnulqFWogf3K": 71,
                                "DlONm1HLhUO1IqsMWLpzA": "Adella.Lowe41",
                                "uQTy2uzCLOkrlH48CHKQb": "Oran_West39@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Intelligent Fresh Car",
                                "BwjQzDEEZXjbWGDE3t30f": "Spain",
                                "d4sw1hBeUI4LFnSSTDhIe": "6d95b963-5374-4cbf-b195-bd9cbc3d032b",
                                "EKhG-DhiMLHhN-OnOkMKb": "1134 Kunze Walk",
                                "uEZJrsWD42Qg3cpQVVjGK": 871
                            },
                            {
                                "id": 71,
                                "H5RQl_G1UwnulqFWogf3K": 72,
                                "DlONm1HLhUO1IqsMWLpzA": "Angelina_Walker",
                                "uQTy2uzCLOkrlH48CHKQb": "Wilhelmine.Watsica61@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Oriental Rubber Ball",
                                "BwjQzDEEZXjbWGDE3t30f": "Kiribati",
                                "d4sw1hBeUI4LFnSSTDhIe": "f5b214ca-ff4c-4c1f-83d9-157f65b2777d",
                                "EKhG-DhiMLHhN-OnOkMKb": "5608 Cartwright Avenue",
                                "uEZJrsWD42Qg3cpQVVjGK": 592
                            },
                            {
                                "id": 72,
                                "H5RQl_G1UwnulqFWogf3K": 73,
                                "DlONm1HLhUO1IqsMWLpzA": "Carmel.Hoppe",
                                "uQTy2uzCLOkrlH48CHKQb": "Cyrus_Raynor@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Practical Concrete Gloves",
                                "BwjQzDEEZXjbWGDE3t30f": "Panama",
                                "d4sw1hBeUI4LFnSSTDhIe": "3ada2911-40dd-4083-8954-a46b59f1d5b2",
                                "EKhG-DhiMLHhN-OnOkMKb": "94008 Adams Common",
                                "uEZJrsWD42Qg3cpQVVjGK": 447
                            },
                            {
                                "id": 73,
                                "H5RQl_G1UwnulqFWogf3K": 74,
                                "DlONm1HLhUO1IqsMWLpzA": "Catharine.Kilback30",
                                "uQTy2uzCLOkrlH48CHKQb": "Hermina_Bruen83@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Unbranded Wooden Bike",
                                "BwjQzDEEZXjbWGDE3t30f": "Guinea",
                                "d4sw1hBeUI4LFnSSTDhIe": "2f237822-fd94-4517-ae64-3dee1c8a910d",
                                "EKhG-DhiMLHhN-OnOkMKb": "4666 Gottlieb Corner",
                                "uEZJrsWD42Qg3cpQVVjGK": 971
                            },
                            {
                                "id": 74,
                                "H5RQl_G1UwnulqFWogf3K": 75,
                                "DlONm1HLhUO1IqsMWLpzA": "Gwen66",
                                "uQTy2uzCLOkrlH48CHKQb": "Kali83@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Recycled Plastic Table",
                                "BwjQzDEEZXjbWGDE3t30f": "Burkina Faso",
                                "d4sw1hBeUI4LFnSSTDhIe": "59c4b295-1a9a-4703-a777-0c50c0adf00b",
                                "EKhG-DhiMLHhN-OnOkMKb": "57043 Dietrich Grove",
                                "uEZJrsWD42Qg3cpQVVjGK": 968
                            },
                            {
                                "id": 75,
                                "H5RQl_G1UwnulqFWogf3K": 76,
                                "DlONm1HLhUO1IqsMWLpzA": "Ludwig.McClure34",
                                "uQTy2uzCLOkrlH48CHKQb": "Roger73@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handmade Granite Computer",
                                "BwjQzDEEZXjbWGDE3t30f": "Svalbard & Jan Mayen Islands",
                                "d4sw1hBeUI4LFnSSTDhIe": "8bc392f3-1054-4310-bfbd-a9730901d9ed",
                                "EKhG-DhiMLHhN-OnOkMKb": "8623 Adrain Path",
                                "uEZJrsWD42Qg3cpQVVjGK": 79
                            },
                            {
                                "id": 76,
                                "H5RQl_G1UwnulqFWogf3K": 77,
                                "DlONm1HLhUO1IqsMWLpzA": "Nestor.Oberbrunner",
                                "uQTy2uzCLOkrlH48CHKQb": "Tara.Purdy59@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Modern Wooden Gloves",
                                "BwjQzDEEZXjbWGDE3t30f": "Germany",
                                "d4sw1hBeUI4LFnSSTDhIe": "1d44adbd-003d-497d-880c-c75c1ed21e41",
                                "EKhG-DhiMLHhN-OnOkMKb": "541 Stella Glens",
                                "uEZJrsWD42Qg3cpQVVjGK": 6
                            },
                            {
                                "id": 77,
                                "H5RQl_G1UwnulqFWogf3K": 78,
                                "DlONm1HLhUO1IqsMWLpzA": "Luz_Lynch",
                                "uQTy2uzCLOkrlH48CHKQb": "Allie32@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Luxurious Fresh Chicken",
                                "BwjQzDEEZXjbWGDE3t30f": "Cayman Islands",
                                "d4sw1hBeUI4LFnSSTDhIe": "835d74ca-694e-4b24-b015-2c8a5b568be6",
                                "EKhG-DhiMLHhN-OnOkMKb": "05372 Fisher Harbors",
                                "uEZJrsWD42Qg3cpQVVjGK": 885
                            },
                            {
                                "id": 78,
                                "H5RQl_G1UwnulqFWogf3K": 79,
                                "DlONm1HLhUO1IqsMWLpzA": "Ahmed71",
                                "uQTy2uzCLOkrlH48CHKQb": "Oran_Funk@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Luxurious Metal Bike",
                                "BwjQzDEEZXjbWGDE3t30f": "Antigua and Barbuda",
                                "d4sw1hBeUI4LFnSSTDhIe": "7fae20f5-146b-4aae-a804-b63b8c6b4f5e",
                                "EKhG-DhiMLHhN-OnOkMKb": "096 Marcellus Oval",
                                "uEZJrsWD42Qg3cpQVVjGK": 300
                            },
                            {
                                "id": 79,
                                "H5RQl_G1UwnulqFWogf3K": 80,
                                "DlONm1HLhUO1IqsMWLpzA": "Kaley_Lowe64",
                                "uQTy2uzCLOkrlH48CHKQb": "Dawn.Ondricka67@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Ergonomic Soft Shoes",
                                "BwjQzDEEZXjbWGDE3t30f": "Palau",
                                "d4sw1hBeUI4LFnSSTDhIe": "82ccacd9-d260-49d5-ad07-86e75d6a79e7",
                                "EKhG-DhiMLHhN-OnOkMKb": "071 Al Course",
                                "uEZJrsWD42Qg3cpQVVjGK": 643
                            },
                            {
                                "id": 80,
                                "H5RQl_G1UwnulqFWogf3K": 81,
                                "DlONm1HLhUO1IqsMWLpzA": "Dana92",
                                "uQTy2uzCLOkrlH48CHKQb": "Johan.Renner@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Generic Metal Cheese",
                                "BwjQzDEEZXjbWGDE3t30f": "Brunei Darussalam",
                                "d4sw1hBeUI4LFnSSTDhIe": "d1b8808d-1c49-4020-b99c-741af28b1c3b",
                                "EKhG-DhiMLHhN-OnOkMKb": "074 Clementine Via",
                                "uEZJrsWD42Qg3cpQVVjGK": 149
                            },
                            {
                                "id": 81,
                                "H5RQl_G1UwnulqFWogf3K": 82,
                                "DlONm1HLhUO1IqsMWLpzA": "Isabella.Gibson21",
                                "uQTy2uzCLOkrlH48CHKQb": "Garth.Armstrong@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Fantastic Frozen Mouse",
                                "BwjQzDEEZXjbWGDE3t30f": "Bangladesh",
                                "d4sw1hBeUI4LFnSSTDhIe": "1c52c430-9f4b-49b1-ad47-d66cbce85713",
                                "EKhG-DhiMLHhN-OnOkMKb": "59983 Bayer River",
                                "uEZJrsWD42Qg3cpQVVjGK": 711
                            },
                            {
                                "id": 82,
                                "H5RQl_G1UwnulqFWogf3K": 83,
                                "DlONm1HLhUO1IqsMWLpzA": "Chelsie_Ledner",
                                "uQTy2uzCLOkrlH48CHKQb": "Titus_Marquardt6@gmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Intelligent Frozen Chips",
                                "BwjQzDEEZXjbWGDE3t30f": "Bahamas",
                                "d4sw1hBeUI4LFnSSTDhIe": "187149db-bc37-4501-9fb5-c89bab66b76a",
                                "EKhG-DhiMLHhN-OnOkMKb": "684 Jackson Loop",
                                "uEZJrsWD42Qg3cpQVVjGK": 578
                            },
                            {
                                "id": 83,
                                "H5RQl_G1UwnulqFWogf3K": 84,
                                "DlONm1HLhUO1IqsMWLpzA": "Pablo.Muller57",
                                "uQTy2uzCLOkrlH48CHKQb": "Elliott.Gorczany@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handmade Wooden Pants",
                                "BwjQzDEEZXjbWGDE3t30f": "Costa Rica",
                                "d4sw1hBeUI4LFnSSTDhIe": "c5326bbe-b69f-4628-b5e0-0ba192906992",
                                "EKhG-DhiMLHhN-OnOkMKb": "936 Nella Freeway",
                                "uEZJrsWD42Qg3cpQVVjGK": 841
                            },
                            {
                                "id": 84,
                                "H5RQl_G1UwnulqFWogf3K": 85,
                                "DlONm1HLhUO1IqsMWLpzA": "Claud91",
                                "uQTy2uzCLOkrlH48CHKQb": "Julius31@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Fantastic Fresh Chips",
                                "BwjQzDEEZXjbWGDE3t30f": "Niger",
                                "d4sw1hBeUI4LFnSSTDhIe": "904d1751-dc58-44a1-bc7b-cdb9974cae6a",
                                "EKhG-DhiMLHhN-OnOkMKb": "2677 Nicklaus Ports",
                                "uEZJrsWD42Qg3cpQVVjGK": 488
                            },
                            {
                                "id": 85,
                                "H5RQl_G1UwnulqFWogf3K": 86,
                                "DlONm1HLhUO1IqsMWLpzA": "Hilton_Lynch97",
                                "uQTy2uzCLOkrlH48CHKQb": "Stephania7@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Electronic Frozen Mouse",
                                "BwjQzDEEZXjbWGDE3t30f": "Guadeloupe",
                                "d4sw1hBeUI4LFnSSTDhIe": "3cb6705b-8756-4413-a578-61974bd9ecb6",
                                "EKhG-DhiMLHhN-OnOkMKb": "22363 Isabella Green",
                                "uEZJrsWD42Qg3cpQVVjGK": 428
                            },
                            {
                                "id": 86,
                                "H5RQl_G1UwnulqFWogf3K": 87,
                                "DlONm1HLhUO1IqsMWLpzA": "Americo.Denesik51",
                                "uQTy2uzCLOkrlH48CHKQb": "Susanna69@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handmade Frozen Computer",
                                "BwjQzDEEZXjbWGDE3t30f": "Niue",
                                "d4sw1hBeUI4LFnSSTDhIe": "ee2ce8ed-b7a3-4f95-97c0-2be8b0fba3b3",
                                "EKhG-DhiMLHhN-OnOkMKb": "54222 Marlen Greens",
                                "uEZJrsWD42Qg3cpQVVjGK": 923
                            },
                            {
                                "id": 87,
                                "H5RQl_G1UwnulqFWogf3K": 88,
                                "DlONm1HLhUO1IqsMWLpzA": "Zachery69",
                                "uQTy2uzCLOkrlH48CHKQb": "Merlin36@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Fantastic Cotton Shoes",
                                "BwjQzDEEZXjbWGDE3t30f": "Falkland Islands (Malvinas)",
                                "d4sw1hBeUI4LFnSSTDhIe": "a1c718a6-4fe9-454b-a281-41bbfa925f2a",
                                "EKhG-DhiMLHhN-OnOkMKb": "43380 Reichert Highway",
                                "uEZJrsWD42Qg3cpQVVjGK": 985
                            },
                            {
                                "id": 88,
                                "H5RQl_G1UwnulqFWogf3K": 89,
                                "DlONm1HLhUO1IqsMWLpzA": "Delmer9",
                                "uQTy2uzCLOkrlH48CHKQb": "Horacio_Harber50@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Rustic Bronze Car",
                                "BwjQzDEEZXjbWGDE3t30f": "Norway",
                                "d4sw1hBeUI4LFnSSTDhIe": "455247bd-4cf7-4180-b8ae-caed528cf0ea",
                                "EKhG-DhiMLHhN-OnOkMKb": "31965 Kuphal Unions",
                                "uEZJrsWD42Qg3cpQVVjGK": 317
                            },
                            {
                                "id": 89,
                                "H5RQl_G1UwnulqFWogf3K": 90,
                                "DlONm1HLhUO1IqsMWLpzA": "Nya.Bahringer50",
                                "uQTy2uzCLOkrlH48CHKQb": "Beverly_Kuhn95@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Practical Frozen Mouse",
                                "BwjQzDEEZXjbWGDE3t30f": "Singapore",
                                "d4sw1hBeUI4LFnSSTDhIe": "0c0d0344-fdb9-420e-98f1-674157b7c2f0",
                                "EKhG-DhiMLHhN-OnOkMKb": "07400 Quitzon Points",
                                "uEZJrsWD42Qg3cpQVVjGK": 201
                            },
                            {
                                "id": 90,
                                "H5RQl_G1UwnulqFWogf3K": 91,
                                "DlONm1HLhUO1IqsMWLpzA": "Gustave50",
                                "uQTy2uzCLOkrlH48CHKQb": "Favian_Kiehn75@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handmade Granite Pants",
                                "BwjQzDEEZXjbWGDE3t30f": "Paraguay",
                                "d4sw1hBeUI4LFnSSTDhIe": "b5cd49a1-7b96-4d0f-a31d-0b856855c7f5",
                                "EKhG-DhiMLHhN-OnOkMKb": "9614 Kemmer Ways",
                                "uEZJrsWD42Qg3cpQVVjGK": 353
                            },
                            {
                                "id": 91,
                                "H5RQl_G1UwnulqFWogf3K": 92,
                                "DlONm1HLhUO1IqsMWLpzA": "Pearlie.Nitzsche",
                                "uQTy2uzCLOkrlH48CHKQb": "Walker_Wisoky@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Bespoke Bronze Fish",
                                "BwjQzDEEZXjbWGDE3t30f": "New Zealand",
                                "d4sw1hBeUI4LFnSSTDhIe": "5702fe57-bc62-4734-bdd5-8d8c7d968f43",
                                "EKhG-DhiMLHhN-OnOkMKb": "65076 Weimann Estates",
                                "uEZJrsWD42Qg3cpQVVjGK": 630
                            },
                            {
                                "id": 92,
                                "H5RQl_G1UwnulqFWogf3K": 93,
                                "DlONm1HLhUO1IqsMWLpzA": "Pinkie_Altenwerth",
                                "uQTy2uzCLOkrlH48CHKQb": "Jaclyn37@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Elegant Steel Computer",
                                "BwjQzDEEZXjbWGDE3t30f": "Lebanon",
                                "d4sw1hBeUI4LFnSSTDhIe": "5ec3f82c-7b31-40e8-8236-b1d479f3e2fb",
                                "EKhG-DhiMLHhN-OnOkMKb": "922 Taurean Rest",
                                "uEZJrsWD42Qg3cpQVVjGK": 213
                            },
                            {
                                "id": 93,
                                "H5RQl_G1UwnulqFWogf3K": 94,
                                "DlONm1HLhUO1IqsMWLpzA": "Zena.Stroman11",
                                "uQTy2uzCLOkrlH48CHKQb": "Afton24@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Handmade Metal Gloves",
                                "BwjQzDEEZXjbWGDE3t30f": "India",
                                "d4sw1hBeUI4LFnSSTDhIe": "7ef163ea-f021-4bb4-8514-b15aa113502e",
                                "EKhG-DhiMLHhN-OnOkMKb": "6021 Cole Land",
                                "uEZJrsWD42Qg3cpQVVjGK": 77
                            },
                            {
                                "id": 94,
                                "H5RQl_G1UwnulqFWogf3K": 95,
                                "DlONm1HLhUO1IqsMWLpzA": "Amanda_Vandervort26",
                                "uQTy2uzCLOkrlH48CHKQb": "Alvis_Nicolas79@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Tasty Concrete Bike",
                                "BwjQzDEEZXjbWGDE3t30f": "Marshall Islands",
                                "d4sw1hBeUI4LFnSSTDhIe": "22b32fc5-2a4c-43a4-bd98-9431163bf630",
                                "EKhG-DhiMLHhN-OnOkMKb": "573 Litzy Throughway",
                                "uEZJrsWD42Qg3cpQVVjGK": 342
                            },
                            {
                                "id": 95,
                                "H5RQl_G1UwnulqFWogf3K": 96,
                                "DlONm1HLhUO1IqsMWLpzA": "Elwyn86",
                                "uQTy2uzCLOkrlH48CHKQb": "Oswald_Legros89@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Recycled Soft Car",
                                "BwjQzDEEZXjbWGDE3t30f": "Afghanistan",
                                "d4sw1hBeUI4LFnSSTDhIe": "75a33f67-bdf0-4197-8d96-cf1b46cc6963",
                                "EKhG-DhiMLHhN-OnOkMKb": "0159 Kunde Pike",
                                "uEZJrsWD42Qg3cpQVVjGK": 150
                            },
                            {
                                "id": 96,
                                "H5RQl_G1UwnulqFWogf3K": 97,
                                "DlONm1HLhUO1IqsMWLpzA": "Annamarie.Haag82",
                                "uQTy2uzCLOkrlH48CHKQb": "Melyssa.Franecki57@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Practical Frozen Pizza",
                                "BwjQzDEEZXjbWGDE3t30f": "Svalbard & Jan Mayen Islands",
                                "d4sw1hBeUI4LFnSSTDhIe": "8ca95e3d-d995-46cc-9cc3-544d5c57a625",
                                "EKhG-DhiMLHhN-OnOkMKb": "722 McKenzie Forest",
                                "uEZJrsWD42Qg3cpQVVjGK": 7
                            },
                            {
                                "id": 97,
                                "H5RQl_G1UwnulqFWogf3K": 98,
                                "DlONm1HLhUO1IqsMWLpzA": "Mary.Gutmann",
                                "uQTy2uzCLOkrlH48CHKQb": "Pearlie.Berge@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Intelligent Concrete Mouse",
                                "BwjQzDEEZXjbWGDE3t30f": "Moldova",
                                "d4sw1hBeUI4LFnSSTDhIe": "7f84d036-1b6b-40f5-b1c7-83911a6c2961",
                                "EKhG-DhiMLHhN-OnOkMKb": "62130 Quinten Locks",
                                "uEZJrsWD42Qg3cpQVVjGK": 155
                            },
                            {
                                "id": 98,
                                "H5RQl_G1UwnulqFWogf3K": 99,
                                "DlONm1HLhUO1IqsMWLpzA": "Hazle_Johnston11",
                                "uQTy2uzCLOkrlH48CHKQb": "Carolyn41@hotmail.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Recycled Cotton Towels",
                                "BwjQzDEEZXjbWGDE3t30f": "Denmark",
                                "d4sw1hBeUI4LFnSSTDhIe": "39043f24-ab8d-4732-bc99-9ee930c4dc1b",
                                "EKhG-DhiMLHhN-OnOkMKb": "3949 Juana Bypass",
                                "uEZJrsWD42Qg3cpQVVjGK": 501
                            },
                            {
                                "id": 99,
                                "H5RQl_G1UwnulqFWogf3K": 100,
                                "DlONm1HLhUO1IqsMWLpzA": "Aurelio_Hauck98",
                                "uQTy2uzCLOkrlH48CHKQb": "Gaetano_Trantow@yahoo.com",
                                "gLWpEMs-pCciz3ZzX6JO2": "2023-02-21T14:46:37.733Z",
                                "qo6mU6ba0aAWSxRHUYJqw": "Tasty Plastic Computer",
                                "BwjQzDEEZXjbWGDE3t30f": "Saudi Arabia",
                                "d4sw1hBeUI4LFnSSTDhIe": "9bcb9fd2-4259-425b-a07d-6c04d009ce22",
                                "EKhG-DhiMLHhN-OnOkMKb": "645 Price Glen",
                                "uEZJrsWD42Qg3cpQVVjGK": 584
                            }
                        ],
                        "columns": [
                            {
                                "headerName": "#",
                                "field": "H5RQl_G1UwnulqFWogf3K",
                                "editable": true,
                                "sortable": false,
                                "index": 0,
                                "type": "number",
                                "dataType": "index",
                                "width": 10,
                                "align": "left",
                                "headerAlign": "left",
                                "valueOptions": []
                            },
                            {
                                "headerName": "User Name",
                                "field": "DlONm1HLhUO1IqsMWLpzA",
                                "editable": true,
                                "sortable": false,
                                "index": 1,
                                "type": "string",
                                "dataType": "username",
                                "width": 120,
                                "align": "left",
                                "headerAlign": "left",
                                "valueOptions": []
                            },
                            {
                                "headerName": "Email",
                                "field": "uQTy2uzCLOkrlH48CHKQb",
                                "editable": true,
                                "sortable": false,
                                "index": 2,
                                "type": "string",
                                "dataType": "email",
                                "width": 120,
                                "align": "left",
                                "headerAlign": "left",
                                "valueOptions": []
                            },
                            {
                                "headerName": "Purchase Date",
                                "field": "gLWpEMs-pCciz3ZzX6JO2",
                                "editable": true,
                                "sortable": false,
                                "index": 3,
                                "type": "date",
                                "dataType": "day",
                                "width": 80,
                                "align": "left",
                                "headerAlign": "left",
                                "valueOptions": []
                            },
                            {
                                "headerName": "Product",
                                "field": "qo6mU6ba0aAWSxRHUYJqw",
                                "editable": true,
                                "sortable": false,
                                "index": 4,
                                "type": "string",
                                "dataType": "productname",
                                "width": 120,
                                "align": "left",
                                "headerAlign": "left",
                                "valueOptions": []
                            },
                            {
                                "headerName": "Country",
                                "field": "BwjQzDEEZXjbWGDE3t30f",
                                "editable": true,
                                "sortable": false,
                                "index": 5,
                                "type": "string",
                                "dataType": "countries",
                                "width": 120,
                                "align": "left",
                                "headerAlign": "left",
                                "valueOptions": []
                            },
                            {
                                "headerName": "Invoice ID",
                                "field": "d4sw1hBeUI4LFnSSTDhIe",
                                "editable": true,
                                "sortable": false,
                                "index": 6,
                                "type": "string",
                                "dataType": "uuid",
                                "width": 120,
                                "align": "left",
                                "headerAlign": "left",
                                "valueOptions": []
                            },
                            {
                                "headerName": "Deliver To",
                                "field": "EKhG-DhiMLHhN-OnOkMKb",
                                "editable": true,
                                "sortable": false,
                                "index": 7,
                                "type": "string",
                                "dataType": "address",
                                "width": 120,
                                "align": "left",
                                "headerAlign": "left",
                                "valueOptions": []
                            },
                            {
                                "headerName": "Price",
                                "field": "uEZJrsWD42Qg3cpQVVjGK",
                                "editable": true,
                                "sortable": false,
                                "index": 8,
                                "type": "number",
                                "dataType": "currency",
                                "width": 80,
                                "align": "left",
                                "headerAlign": "left",
                                "valueOptions": []
                            }
                        ],
                        "chartOptions": {
                            "layout": {
                                "padding": {
                                    "top": 0,
                                    "right": 0,
                                    "left": 0,
                                    "bottom": 5
                                }
                            },
                            "plugins": {
                                "title": {
                                    "text": "Table title",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 10,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": [
                                        "rgba(69,90,100,1)"
                                    ],
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "subtitle": {
                                    "text": "Table description...",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 5
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "italic",
                                        "size": 11,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "cells": {
                                    "text": "",
                                    "padding": {},
                                    "color": "rgba(64, 64, 64, 1)",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "normal",
                                        "size": 11,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "headers": {
                                    "text": "",
                                    "padding": {},
                                    "color": "rgba(64, 64, 64, 1)",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                }
                            }
                        }
                    },
                    {
                        "key": "ikXzaqfuZaWdvLx-b9fz-",
                        "createdAt": 1676990813565,
                        "chartVariant": [
                            "date"
                        ],
                        "chartType": "filter",
                        "tabId": "4-D-gS9EqnjCC6QzCmbPC",
                        "isActive": false,
                        "chartSettings": {
                            "renderedAt": 1676990813565,
                            "updatedAt": 1676992250109,
                            "positionX": 70,
                            "positionY": 20,
                            "width": 350,
                            "height": 80,
                            "backgroundColor": "rgba(255,255,255,1)",
                            "borderColor": "rgba(204, 204, 204, 1)",
                            "borderRadius": 0,
                            "borderWidth": 1,
                            "activeBoxType": null,
                            "description": null,
                            "isDataDraggable": false,
                            "zIndex": 4
                        },
                        "chartOptions": {
                            "layout": {
                                "padding": {
                                    "top": 0,
                                    "right": 0,
                                    "left": 0,
                                    "bottom": 5
                                }
                            },
                            "plugins": {
                                "title": {
                                    "text": "Date Filter Title",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 0
                                    },
                                    "display": true,
                                    "color": [
                                        "rgba(69,90,100,1)"
                                    ],
                                    "position": "top",
                                    "font": {
                                        "weight": "bold",
                                        "style": "normal",
                                        "size": 14,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "startDatePicker": {
                                    "text": "From:",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 25
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "italic",
                                        "size": 11,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "endDatePicker": {
                                    "text": "To:",
                                    "align": "start",
                                    "padding": {
                                        "right": 0,
                                        "top": 0,
                                        "left": 0,
                                        "bottom": 25
                                    },
                                    "display": true,
                                    "color": "rgba(58, 81, 102, 1)",
                                    "position": "top",
                                    "font": {
                                        "weight": "lighter",
                                        "style": "italic",
                                        "size": 11,
                                        "family": "'Arial', sans-serif"
                                    }
                                },
                                "startDate": "2023-02-21T14:46:53.566Z",
                                "endDate": "2023-02-21T14:46:53.566Z",
                                "dragData": false
                            }
                        }
                    }
                ],
                "isActive": false
            }
        ],
        "boardName": "Marketing Performance Example"
    },
    "doc": {
        "objective": "",
        "dashboardType": "",
        "audiance": "",
        "decisions": "",
        "questions": [],
        "metrics": [],
        "filters": "",
        "requirements": null
    },
    "theme": {
        "charts": {
            "bar": {
                "data": {
                    "datasets": 2,
                    "length": 5
                }
            },
            "datagrid": {
                "chartOptions": {
                    "plugins": {
                        "title": {
                            "align": "start",
                            "font": {
                                "family": "'Arial', sans-serif",
                                "weight": "bold",
                                "style": "normal",
                                "size": 16
                            },
                            "padding": {
                                "top": 10,
                                "right": 0,
                                "bottom": 0,
                                "left": 0
                            },
                            "display": true,
                            "color": "rgba(58, 81, 102, 1)",
                            "text": "Table title",
                            "position": "top"
                        },
                        "header": {
                            "font": {
                                "size": 14,
                                "style": "normal",
                                "weight": "bold",
                                "family": "'Arial', sans-serif"
                            },
                            "color": "rgba(64, 64, 64, 1)"
                        },
                        "subtitle": {
                            "display": true,
                            "padding": {
                                "left": 0,
                                "top": 0,
                                "bottom": 5,
                                "right": 0
                            },
                            "font": {
                                "family": "'Arial', sans-serif",
                                "weight": "lighter",
                                "style": "italic",
                                "size": 11
                            },
                            "align": "start",
                            "position": "top",
                            "text": "Table description...",
                            "color": "rgba(58, 81, 102, 1)"
                        },
                        "cell": {
                            "font": {
                                "weight": "lighter",
                                "style": "normal",
                                "family": "'Arial', sans-serif",
                                "size": 11
                            },
                            "color": "rgba(64, 64, 64, 1)"
                        }
                    }
                }
            },
            "pie": {
                "chartOptions": {
                    "valuesDataType": "number",
                    "plugins": {
                        "datalabels": {
                            "padding": {
                                "left": 0,
                                "bottom": 5,
                                "top": 0,
                                "right": 0
                            },
                            "align": "center",
                            "display": "auto",
                            "anchor": "center",
                            "color": "rgba(255,255,255,0)",
                            "font": {
                                "size": 14,
                                "style": "normal",
                                "weight": "lighter",
                                "family": "'Arial', sans-serif"
                            }
                        }
                    },
                    "labelType": "department",
                    "seriesDataType": "department"
                },
                "chartData": {
                    "datasets": 1,
                    "labelType": "category",
                    "length": 2
                }
            },
            "default": {
                "chartOptions": {
                    "labelType": "month",
                    "plugins": {
                        "datalabels": {
                            "align": "end",
                            "font": {
                                "size": 12,
                                "family": "'Arial', sans-serif",
                                "style": "normal",
                                "weight": "lighter"
                            },
                            "anchor": "end",
                            "source": "data",
                            "padding": {
                                "bottom": 5,
                                "right": 0,
                                "left": 0,
                                "top": 0
                            },
                            "display": "auto",
                            "color": "rgba(58, 81, 102, 1)",
                            "borderRadius": 3
                        },
                        "legend": {
                            "labels": {
                                "pointStyle": "circle",
                                "color": "rgba(125, 125, 125, 1)",
                                "textAlign": "right",
                                "padding": 10,
                                "boxWidth": 6,
                                "boxHeight": 6,
                                "font": {
                                    "family": "'Arial', sans-serif",
                                    "weight": "lighter",
                                    "size": 14,
                                    "style": "normal"
                                },
                                "usePointStyle": true,
                                "radius": 6
                            },
                            "position": "bottom",
                            "display": true,
                            "title": {
                                "text": "Legend Title",
                                "position": "bottom",
                                "align": "center",
                                "font": {
                                    "weight": "lighter",
                                    "size": 12,
                                    "style": "normal",
                                    "family": "'Arial', sans-serif"
                                },
                                "padding": {
                                    "bottom": 5,
                                    "left": 0,
                                    "right": 0,
                                    "top": 0
                                },
                                "color": "rgba(58, 81, 102, 1)",
                                "display": false
                            },
                            "align": "center"
                        },
                        "subtitle": {
                            "padding": {
                                "right": 0,
                                "top": 0,
                                "left": 0,
                                "bottom": 25
                            },
                            "font": {
                                "weight": "lighter",
                                "style": "italic",
                                "family": "'Arial', sans-serif",
                                "size": 11
                            },
                            "position": "top",
                            "text": "Chart Sub title",
                            "display": true,
                            "color": "rgba(58, 81, 102, 1)",
                            "align": "start"
                        },
                        "title": {
                            "align": "start",
                            "display": true,
                            "color": [
                                "rgba(69,90,100,1)"
                            ],
                            "position": "top",
                            "font": {
                                "family": "'Arial', sans-serif",
                                "style": "normal",
                                "weight": "bold",
                                "size": 14
                            },
                            "padding": {
                                "right": 0,
                                "top": 0,
                                "left": 0,
                                "bottom": 0
                            },
                            "text": "Chart title"
                        }
                    },
                    "valuesDataType": "number",
                    "indexAxis": "x",
                    "seriesDataType": "department",
                    "layout": {
                        "padding": {
                            "top": 10,
                            "bottom": 10,
                            "right": 20,
                            "left": 10
                        }
                    },
                    "scales": {
                        "x": {
                            "type": "time",
                            "ticks": {
                                "min": 0,
                                "display": true,
                                "max": 1000,
                                "minRotation": 45,
                                "font": {
                                    "family": "'Arial', sans-serif",
                                    "style": "normal",
                                    "weight": "lighter",
                                    "size": 8
                                },
                                "autoSkip": true,
                                "maxRotation": 0,
                                "color": "rgba(58, 81, 102, 1)",
                                "source": "labels"
                            },
                            "grid": {
                                "display": true,
                                "lineWidth": 1,
                                "color": [
                                    "rgba(200, 200,200, 0.2)"
                                ],
                                "drawBorder": true,
                                "drawTicks": false,
                                "borderWidth": 1,
                                "drawOnChartArea": true
                            },
                            "stacked": false,
                            "display": true,
                            "title": {
                                "padding": {
                                    "left": 0,
                                    "right": 0,
                                    "top": 10,
                                    "bottom": 5
                                },
                                "align": "center",
                                "text": "Index",
                                "position": "bottom",
                                "display": true,
                                "color": "rgba(125, 125, 125, 1)",
                                "font": {
                                    "weight": "lighter",
                                    "style": "normal",
                                    "family": "'Arial', sans-serif",
                                    "size": 12
                                }
                            },
                            "bounds": "data"
                        },
                        "y": {
                            "stacked": false,
                            "ticks": {
                                "min": 0,
                                "max": 1000,
                                "autoSkip": true,
                                "display": true,
                                "source": "labels",
                                "font": {
                                    "family": "'Arial', sans-serif",
                                    "size": 8,
                                    "style": "normal",
                                    "weight": "lighter"
                                },
                                "color": "rgba(58, 81, 102, 1)",
                                "maxRotation": 0,
                                "minRotation": 45
                            },
                            "display": true,
                            "type": "linear",
                            "grid": {
                                "lineWidth": 1,
                                "drawTicks": false,
                                "borderWidth": 1,
                                "drawBorder": true,
                                "color": "rgba(200, 200,200, 0.2)",
                                "display": true,
                                "drawOnChartArea": true
                            },
                            "title": {
                                "color": "rgba(125, 125, 125, 1)",
                                "align": "center",
                                "position": "bottom",
                                "padding": {
                                    "left": 0,
                                    "right": 0,
                                    "bottom": 5,
                                    "top": 10
                                },
                                "text": "Values",
                                "display": true,
                                "font": {
                                    "style": "normal",
                                    "size": 8,
                                    "family": "'Arial', sans-serif",
                                    "weight": "lighter"
                                }
                            }
                        }
                    }
                },
                "chartData": {
                    "datasets": 2,
                    "length": 8
                },
                "chartSettings": {
                    "backgroundColor": "rgba(255,255,255,1)",
                    "height": 300,
                    "borderRadius": 0,
                    "layout": {
                        "padding": {
                            "left": 0,
                            "top": 0,
                            "bottom": 5,
                            "right": 0
                        }
                    },
                    "borderColor": "rgba(204, 204, 204, 1)",
                    "borderWidth": 1,
                    "width": 400,
                    "updatedAt": 1676992250109
                }
            },
            "spark": {
                "chartOptions": {
                    "plugins": {
                        "submetric": {
                            "text": "SALES",
                            "display": true,
                            "padding": {
                                "right": 0,
                                "top": 0,
                                "bottom": 5,
                                "left": 0
                            },
                            "font": {
                                "weight": "normal",
                                "style": "normal",
                                "family": "'Arial', sans-serif",
                                "size": 14
                            },
                            "position": "top",
                            "color": "rgba(58, 81, 102, 1)",
                            "align": "start"
                        },
                        "startAdornment": {
                            "position": "top",
                            "display": true,
                            "color": "rgba(58, 81, 102, 1)",
                            "font": {
                                "style": "normal",
                                "size": 36,
                                "family": "'Arial', sans-serif",
                                "weight": "bold"
                            },
                            "align": "start",
                            "text": "$",
                            "padding": {
                                "left": 0,
                                "top": 0,
                                "right": 0,
                                "bottom": 5
                            }
                        },
                        "endAdornment": {
                            "align": "start",
                            "padding": {
                                "left": 0,
                                "top": 0,
                                "bottom": 5,
                                "right": 0
                            },
                            "display": true,
                            "position": "top",
                            "color": "rgba(58, 81, 102, 1)",
                            "font": {
                                "size": 36,
                                "style": "normal",
                                "family": "'Arial', sans-serif",
                                "weight": "bold"
                            },
                            "text": "M"
                        },
                        "metric": {
                            "position": "top",
                            "display": true,
                            "font": {
                                "weight": "bold",
                                "family": "'Arial', sans-serif",
                                "style": "normal",
                                "size": 48
                            },
                            "align": "start",
                            "padding": {
                                "bottom": 5,
                                "left": 0,
                                "top": 0,
                                "right": 0
                            },
                            "color": "rgba(58, 81, 102, 1)",
                            "text": "42"
                        }
                    }
                }
            },
            "kpi": {
                "chartOptions": {
                    "plugins": {
                        "endAdornment": {
                            "position": "top",
                            "display": true,
                            "color": "rgba(58, 81, 102, 1)",
                            "padding": {
                                "left": 0,
                                "top": 0,
                                "bottom": 5,
                                "right": 0
                            },
                            "align": "start",
                            "font": {
                                "weight": "bold",
                                "size": 36,
                                "style": "normal",
                                "family": "'Arial', sans-serif"
                            },
                            "text": "M"
                        },
                        "submetric": {
                            "padding": {
                                "right": 0,
                                "left": 0,
                                "top": 0,
                                "bottom": 5
                            },
                            "color": "rgba(80, 227, 194, 1)",
                            "position": "top",
                            "text": "⬆ 7% from last week",
                            "font": {
                                "weight": "normal",
                                "size": 14,
                                "family": "'Arial', sans-serif",
                                "style": "normal"
                            },
                            "align": "start",
                            "display": true
                        },
                        "startAdornment": {
                            "align": "start",
                            "display": true,
                            "color": "rgba(58, 81, 102, 1)",
                            "text": "$",
                            "position": "top",
                            "padding": {
                                "right": 0,
                                "bottom": 5,
                                "left": 0,
                                "top": 0
                            },
                            "font": {
                                "family": "'Arial', sans-serif",
                                "weight": "bold",
                                "style": "normal",
                                "size": 36
                            }
                        },
                        "metric": {
                            "color": "rgba(58, 81, 102, 1)",
                            "display": true,
                            "font": {
                                "weight": "bold",
                                "size": 48,
                                "family": "'Arial', sans-serif",
                                "style": "normal"
                            },
                            "align": "start",
                            "text": "42",
                            "position": "top",
                            "padding": {
                                "right": 0,
                                "top": 0,
                                "bottom": 5,
                                "left": 0
                            }
                        }
                    }
                }
            }
        },
        "board": {
            "backgroundColor": "white"
        },
        "colors": {
            "palette": {
                "secondary": {
                    "main": "rgba(58, 81, 102, 1)"
                },
                "title": {
                    "main": "rgba(58, 81, 102, 1)"
                },
                "primary": {
                    "main": "rgba(58, 81, 102, 1)"
                }
            },
            "success": "rgba(238,143,143,1)",
            "datasets": [
                "rgba(76, 148, 247, 1)",
                "rgba(193, 159, 248, 1)",
                "rgba(86,191,156,1)",
                "rgba(165, 252, 242, 1)",
                "rgba(250,240,150,1)",
                "rgba(247, 76, 76, 1)",
                "rgba(233,27,27,0.47843137254901963)"
            ],
            "danger": "rgba(240,199,199,1)"
        }
    },
    "currentUid": null,
    "currentTabUid": "3Y30nQ_5X8bJAsbSjQokP",
    "thumbnailUrl": ""
}

export const getExampleBoard = () => Object.assign({}, exampleBoard)