
import { Divider, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from "react";
import '../../index.css';
import { BoardStore } from '../../Stores/Stores';
import EditChartSection from './EditorSections/EditChartSection';
import EditGridSection from './EditorSections/EditGridSection';
import EditorSection from './EditorSections/EditorSection';
import LabelsEditSection from './EditorSections/LabelsEditSection';
import EditLegendSection from './EditorSections/LegendEditSection';
import EditMetricWidget from './EditorWidgets/EditMetricWidget';
import EditTitleWidget from './EditorWidgets/EditTitleWidget';
import FontSelectWidget from './EditorWidgets/FontSelectWidget';
import FontStyleWidget from './EditorWidgets/FontStyleWidget';
import HideableInputWidget from './EditorWidgets/HideableInputWidget';
import { PropertyToggleWidget } from './EditorWidgets/PropertyToggleWidget';
import TickEditWidget from './EditorWidgets/TickEditWidget';
// import DescriptionTextbox from '../Elements/TitleBox';
import TextField from '@mui/material/TextField';
import NumberInputWidget from './EditorWidgets/NumberInputWidget';
import { PropertyColorWidget } from './EditorWidgets/PropertyColorWidget';
// import PaddingWidget from './EditorWidgets/PaddingWidget';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { FormGroup } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
// import persistSchema from '../Stores/PersistManager';
import RestorePageOutlinedIcon from '@mui/icons-material/RestorePageOutlined';
import ScreenshotMonitorOutlinedIcon from '@mui/icons-material/ScreenshotMonitorOutlined';
import download from 'downloadjs';
import { toPng } from 'html-to-image';
import { ThemeStore } from '../../Stores/ThemeManager';
// import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LabelActionWidget } from "./EditorWidgets/LabelActionWidget";
import Editor from "./TextEditor/Editor";
// import EditIcon from '@mui/icons-material/Edit';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import EditIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DataGridEditor from "./DataGridEditor";
import HideablePropertyWidget from './EditorWidgets/HideablePropertyWidget';
import PaddingWidget from './EditorWidgets/PaddingWidget';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
// import ScreenshotMonitorOutlinedIcon from '@mui/icons-material/ScreenshotMonitorOutlined';

// function hexToRgb(color) {
//   return `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
// }


export const printBoard = () => {
  // const currentGrid = 
  BoardStore.showGridDots(false)
  toPng(document.getElementById('main-board'), { backgroundColor: BoardStore.board.backgroundColor[0], })
    .then(function (dataUrl) {
      download(dataUrl, `${BoardStore.board.title.text}.png`);
      BoardStore.showGridDots(true)
    });
  // BoardStore.saveBoardThumbnail()
}


export const BoardZoomWidget = ({ label }) => <FormGroup >
  <FormControlLabel
    control={<ButtonGroup disableElevation variant="contained" >

      {[
        { 'action': 'Zoom-In', icon: <ZoomOutIcon />,  onClick: (event) => BoardStore.updateBoardScaleDown()},
        { 'action': 'Zoom-Out', icon: <ZoomInIcon />, onClick: (event) => BoardStore.updateBoardScaleUp()},
        { 'action': 'Re-Center', icon: <CenterFocusWeakIcon />, onClick: (event) => BoardStore.resetBoardScale()},
        ].map( boardAction =>  <Tooltip title={boardAction.action} arrow>
        <IconButton sx={{ padding: 0.5 }} aria-label={boardAction.action} disableFocusRipple onClick={boardAction.onClick}>
          {boardAction.icon}
        </IconButton>
      </Tooltip>)
        }

    </ButtonGroup>}
    sx={{ justifyContent: 'space-between', marginLeft: 0, paddingX: 0, '& .MuiSvgIcon-root': { color: 'rgba(64,64,64,1)' } }}
    labelPlacement="start"
    label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>{label}</Typography>} />
</FormGroup>;

export const BoardStyleEditor = observer(() => <>
  {/* <EditorSection key="board-style" cardid='board-style' cardtitle='Board Style' sx={{ height: '100%' }}> */}
  <Box key="board-style" sx={{ height: '100%', width: '85%', marginX: 'auto', marginTop: 2, }}>

    <Box sx={{ opacity: BoardStore.editMode ? 1 : 0.33, display: 'flex', flexDirection: 'column', gap: 1.2 }}>
      <HideableInputWidget titleObj={BoardStore.board.title}
        titleType={"boardTitle"}
        disableToggleHide={true} />

      <PropertyColorWidget
        colorValue={BoardStore.board.backgroundColor[0]}
        onValueChange={(color) => BoardStore.updateBoardBackgroundColor(color)}
        allowEditColor={true}
        label={"Background Color"} />


      <PropertyToggleWidget isChecked={BoardStore.board.dottedBackground}
        // onCheck={(e) =>  BoardStore.board.dottedBackground = e.target.checked} 
        onCheck={(e) => BoardStore.showGridDots(e.target.checked)}
        label={"Show Grid Dots"} />

      <NumberInputWidget numericValue={BoardStore.board.width}
        onValueChange={(e) => BoardStore.updateBoardDimentions(e.target.value, "width")}
        label={"Width"}
        inputAdornment={"px"}
        charLength={4}
      />

      <NumberInputWidget numericValue={BoardStore.board.height}
        onValueChange={(e) => BoardStore.updateBoardDimentions(e.target.value, "height")}
        label={"Height"}
        inputAdornment={"px"}
        charLength={4}
      />
      <BoardZoomWidget label={"Zoom Board"} />
    </Box>

      <LabelActionWidget
        label={"Download tab"}
        // disabled={!BoardStore.editMode}
        onAction={() => printBoard()}
        icon={<DownloadOutlinedIcon/>} />
        

      <LabelActionWidget
        label={"Clear Board"}
        // disabled={!BoardStore.editMode}
        // variant="danger"
        onAction={() => BoardStore.clearStore()}        
        icon={<RestorePageOutlinedIcon/>} />


    <Divider sx={{ marginTop: 1.5 }}></Divider>

    {BoardStore.editMode && <>
      {/* <Button size="small" variant="outlined"
        onClick={(event) => BoardStore.clearStore()}
        sx={{ margin: '1em auto', marginTop: 3, display: 'flex', justifyContent: "center", fontSize: 10, fontWeight: 'regular', color: '#404040', border: '1px solid #404040' }}>
        <RestorePageOutlinedIcon sx={{ fontSize: 16, marginRight: 1 }} /> Clear Board </Button>

      <Button size="small" variant="outlined"
        onClick={() => printBoard()}
        sx={{ margin: '1em auto', marginTop: 2, display: 'flex', justifyContent: "center", fontSize: 10, fontWeight: 'regular', color: '#404040', border: '1px solid #404040' }}>
        <ScreenshotMonitorOutlinedIcon sx={{ fontSize: 16, marginRight: 1 }} /> Screenshot </Button> */}

      {/* <Button size="small" variant="outlined"
        onClick={() => BoardStore.printStore()}
        sx={{ margin: '1em auto', marginTop: 2, display: 'flex', justifyContent: "center", fontSize: 10, fontWeight: 'regular', color: '#404040', border: '1px solid #404040' }}>
        <ScreenshotMonitorOutlinedIcon sx={{ fontSize: 16, marginRight: 1 }} /> Print template </Button> */}
    </>}

    {/* </EditorSection> */}
  </Box>
</>)

export const ThemeEditor = observer(() => {
  return <>

    <Box sx={{ marginX: 2, marginY: 2 }}>


      <PropertyToggleWidget isChecked={BoardStore.themeApplyToAll}
        onCheck={(e) => BoardStore.themeApplyToAll = e.target.checked}
        // size={'medium'}
        disabled={!BoardStore.editMode}
        label={"Apply to all charts"} />
      <LabelActionWidget
        label={"Reset to default theme"}
        disabled={!BoardStore.editMode}
        onAction={() => ThemeStore.resetTheme()}
        icon={<RestartAltIcon></RestartAltIcon>} />

    </Box>


    <EditorSection key="chart-theme-style" cardid='chart-theme-style' cardtitle='Deafult Chart Style'
      accordionDetailsProps={{ sx: { height: '100%', display: 'flex', flexDirection: 'column', gap: 1 } }}
    >

      {/* <PropertyColorWidget colorValue={ThemeStore.default.chartSettings.backgroundColor}
        onValueChange={(color) => ThemeStore.default.chartSettings.backgroundColor = hexToRgb(color)}
        label={"Default Background Color"} /> */}

      {console.log("theme editor settings", ThemeStore.default.chartSettings)}

      <PropertyColorWidget colorValue={ThemeStore.default.chartSettings.backgroundColor}
        allowEditColor={true}
        onValueChange={(color) => BoardStore.updateBackgroundColor({ color: color, editMode: 'theme' })}
        label={"Background Color"} />


      <PropertyColorWidget colorValue={ThemeStore.default.chartSettings.borderColor}
        allowEditColor={true}
        onValueChange={(color) => BoardStore.updateBorderColor({ color: color, editMode: 'theme' })}
        label={"Border Color"} />

      <NumberInputWidget numericValue={ThemeStore.default.chartSettings.borderWidth}
        onValueChange={(e) => BoardStore.updateBorderStroke({ width: e.target.value, editMode: 'theme' })}
        label={"Border Stroke"}
        inputAdornment={"px"} />

      <NumberInputWidget numericValue={ThemeStore.default.chartSettings.borderRadius}
        onValueChange={(e) => BoardStore.updateBorderRadius({ radius: e.target.value, editMode: 'theme' })}
        label={"Default Border Radius"}
        inputAdornment={"px"} />

      {/* <NumberInputWidget numericValue={ThemeStore.default.chartSettings.width}
        onValueChange={(e) => ThemeStore.default.chartSettings["width"] = Number(e.target.value)}
        label={"Default Chart Width"}
        inputAdornment={"px"}
      />
      <NumberInputWidget numericValue={ThemeStore.default.chartSettings.height}
        onValueChange={(e) => ThemeStore.default.chartSettings["height"] = Number(e.target.value)}
        label={"Default Chart Height"}
        inputAdornment={"px"}
      /> */}
      <PaddingWidget allowXaxis={true} titleObj={ThemeStore.default.chartOptions.layout} titleType={"layout"} editMode={'theme'} />
    </EditorSection>


    <EditorSection key="titles" cardid='titles' cardtitle='Default Titles Style' m={0} p={0}>

      <EditTitleWidget key="title"
        titleObj={ThemeStore.default.chartOptions.plugins.title}
        titleType={"title"}
        editMode="theme"
        titleLabel={"Chart Title"} />

      <Divider sx={{ marginY: 1.5 }}></Divider>

      <EditTitleWidget key="subtitle"
        titleObj={ThemeStore.default.chartOptions.plugins.subtitle}
        titleType={"subtitle"}
        editMode="theme"
        titleLabel={"Chart Subtitle"} />

    </EditorSection>

    <EditorSection key="x-axis" cardid='x-axis' cardtitle='Default X Axis '>

      <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Default Title</Typography>
      <EditTitleWidget key="x-axis" 
                      titleObj={ThemeStore.default.chartOptions.scales.x.title}
                      titleType={"x"} 
                      titleLabel={"X Axis"} 
                      editMode="theme"/>
      <Divider sx={{ marginY: 1 }}></Divider>
      <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Default Ticks</Typography>
      <TickEditWidget axis={'x'} storeObj={ThemeStore.default} editType={'theme'} />

    </EditorSection>

    <EditorSection key="y-axis" cardid='y-axis' cardtitle='Default Y Axis'>

      <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Title</Typography>
      <EditTitleWidget key="y-axis" titleObj={ThemeStore.default.chartOptions.scales.y.title}
        titleType={"y"} titleLabel={"Y Axis"} editMode="theme"/>
      <Divider sx={{ marginY: 1 }}></Divider>
      <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Ticks</Typography>
      <TickEditWidget axis={'y'} storeObj={ThemeStore.default} editType={'theme'} />

    </EditorSection>

    <EditorSection key="grid" cardid='grid' cardtitle='Default Grid Style'>
      {/* {EditGridSection()} */}
      <EditGridSection storeObj={ThemeStore.default} editType={'theme'} />
    </EditorSection>

    <EditorSection key="legend-card" cardid='legend' cardtitle='Default Legend Style'>
      <EditLegendSection storeObj={ThemeStore.default} editType={'theme'} />
    </EditorSection>

    <EditorSection key="labels-card" cardid='labels' cardtitle='Default Data Labels'>
      <LabelsEditSection storeObj={ThemeStore.default} editType={'theme'} />
    </EditorSection>

    <EditorSection key="colors-card" cardid='colors' cardtitle='Default Colors'>

      {ThemeStore.colors.datasets.map((dsColor, idx) => {
        return <PropertyColorWidget colorValue={ThemeStore.colors.datasets[idx]}
          // onValueChange={(color) => ThemeStore.updateColor(0, color)}
          onValueChange={(color) => BoardStore.updateDSColor({ dsIdx: idx, color, editMode: "theme" })}
          label={`${(idx + 1) + (["st", "nd", "rd"][((idx + 1 + 90) % 100 - 10) % 10 - 1] || "th")} Dataset`} allowEditColor={true} />
      })}


      <Divider sx={{ marginY: 0.5 }}></Divider>

      <PropertyColorWidget colorValue={ThemeStore.colors.success}
        onValueChange={(color) => ThemeStore.updateSuccessColor(color)}
        label={"Success"} allowEditColor={true} />
      <PropertyColorWidget colorValue={ThemeStore.colors.danger}
        onValueChange={(color) => ThemeStore.updateDangerColor(color)}
        label={"Danger"} allowEditColor={true} />
    </EditorSection>


    <Divider sx={{ marginTop: 0 }}></Divider>

  </>
})

const EditorFactory = observer(({ chart }) => {

  const getElementEditor = (element) => {


    if (element.chartType === 'bar') return dashChartEditor();
    if (element.chartType === 'line') return dashChartEditor();
    if (element.chartType === 'bubble') return dashChartEditor();
    if (element.chartType === 'area') return dashChartEditor();
    if (element.chartType === 'doughnut') return dashChartEditor();
    if (element.chartType === 'scatter') return dashChartEditor();

    if (element.chartType === 'pie') return piehChartEditor();

    if (element.chartType === 'box') return boxEditor();

    if (element.chartType === 'textbox') return textboxEditor();
    if (element.chartType === 'datagrid') return <DataGridEditor chart={element}></DataGridEditor>;
    if (element.chartType === 'kpi') return KPIEditor(element.chartVariant);
    // if (element.chartType == 'spark') return KPIEditor('bar');
    if (element.chartType === 'filter' && element.chartVariant.includes('date')) return dateFilterEditor();
    if (element.chartType === 'filter' && element.chartVariant.includes('range')) return rangeFilterEditor();
    if (element.chartType === 'filter' && element.chartVariant.includes('list')) return listFilterEditor();
    // chart.chartType == "filter" && chart.chartVariant.includes('date')
  }

  const boxEditor = () => {
    return <>

      <EditorSection key="chart-style" cardid='chart-style' cardtitle='Box Style' sx={{ height: '100%' }}>
        <EditChartSection />
      </EditorSection>
      <Divider sx={{ marginTop: 0 }}></Divider>
    </>
  }
  const listFilterEditor = () => {
    return <>

      <EditorSection key="chart-desc" cardid='chart-desc' cardtitle='Filter Description' sx={{ height: '100%' }}>
        {/* <DescriptionBox updateObj={(content) => BoardStore.updateChartDescription(content)}
          storeObj={BoardStore.currentElem?.chartSettings.description} isEditMode={BoardStore.editMode} /> */}
        <ElementEditorBox />
      </EditorSection>

      <EditorSection key="chart-style" cardid='chart-style' cardtitle='Filter Style' sx={{ height: '100%' }}>
        <EditChartSection />
      </EditorSection>

      <EditorSection key="titles" cardid='titles' cardtitle='Filter Title' m={0} p={0}>

        <EditTitleWidget key="title"
          titleObj={BoardStore.currentElem.chartOptions.plugins.title}
          titleType={"title"} titleLabel={"Filter Title"} />
      </EditorSection>

      <EditorSection key="list-options" cardid='list-options' cardtitle='List Options' m={0} p={0}>

        {/* placeholder */}
        <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Placeholder: </Typography>
        <HideableInputWidget titleObj={BoardStore.currentElem.chartOptions.plugins.placeholder}
          titleType={'placeholder'}
          disableToggleHide={true} />

        <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Options: </Typography>
        <TextField id="outlined-basic"
          //  label="Outlined"
          sx={{ width: '100%', maxHeight: 200, overflow: 'scroll' }}
          multiline
          rows={5}
          // minRows={5}
          inputProps={{
            sx: { fontSize: 12 }
          }}
          value={BoardStore.currentElem.chartOptions.plugins.listOptions.join("\n")}
          onChange={(event) => BoardStore.updateListOptions(event.target.value)}
          helperText="One option per row"
          placeholder={"Option 1\nOption 2\n ..."}
          variant="outlined"
        />

        {/* --- Not implemented --- */}
        {/* <PropertyToggleWidget isChecked={ChartStore.current.chartOptions.plugins.listSettings.isMultiple} 
                            onCheck={(e) => ChartStore.toggleIsMultiple()} 
                            label={"Allow Multiple Selections"}/>
                             */}

      </EditorSection>
      <Divider sx={{ marginTop: 0 }}></Divider>
    </>
  }

  const rangeFilterEditor = () => {
    return <>

      <EditorSection key="chart-desc" cardid='chart-desc' cardtitle='Chart Description' sx={{ height: '100%' }}>
        <ElementEditorBox />
      </EditorSection>

      <EditorSection key="chart-style" cardid='chart-style' cardtitle='Chart Style' sx={{ height: '100%' }}>
        <EditChartSection />
      </EditorSection>

      <EditorSection key="titles" cardid='titles' cardtitle='Filter Title' m={0} p={0}>

        <EditTitleWidget key="title"
          titleObj={BoardStore.currentElem.chartOptions.plugins.title}
          titleType={"title"} titleLabel={"Chart Title"} />
      </EditorSection>

      <EditorSection key="slider" cardid='slider' cardtitle='Slider Settings' m={0} p={0}>

        <PropertyColorWidget colorValue={BoardStore.currentElem.chartOptions.plugins.range.color}
          onValueChange={(color) => BoardStore.updateSliderColor(color)}
          label={"Slider Color"}
        />

        <NumberInputWidget numericValue={BoardStore.currentElem.chartOptions.plugins.range.min}
          onValueChange={(e) => BoardStore.updateSliderBounds(e.target.value, "min")}
          label={"Minumun value"}
        //  inputAdornment={""} 
        />

        <NumberInputWidget numericValue={BoardStore.currentElem.chartOptions.plugins.range.max}
          onValueChange={(e) => BoardStore.updateSliderBounds(e.target.value, "max")}
          label={"Maximum value"}
        //  inputAdornment={""} 
        />
      </EditorSection>
      <Divider sx={{ marginTop: 0 }}></Divider>
    </>
  }

  const dateFilterEditor = () => {
    return <>

      <EditorSection key="chart-desc" cardid='chart-desc' cardtitle='Chart Description' sx={{ height: '100%' }}>
        {/* <DescriptionBox updateObj={(content) => BoardStore.updateChartDescription(content)} storeObj={BoardStore.currentElem?.chartSettings.description} isEditMode={BoardStore.editMode} /> */}
        <ElementEditorBox />

      </EditorSection>

      <EditorSection key="chart-style" cardid='chart-style' cardtitle='Chart Style' sx={{ height: '100%' }}>
        <EditChartSection />
      </EditorSection>

      <EditorSection key="titles" cardid='titles' cardtitle='Filter Title' m={0} p={0}>

        <EditTitleWidget key="title"
          titleObj={BoardStore.currentElem.chartOptions.plugins.title}
          titleType={"title"} titleLabel={"Chart Title"} />
      </EditorSection>

      <EditorSection key="labels" cardid='labels' cardtitle='Dates' m={0} p={0}>

        {/* <HideablePropertyWidget = observer(({ hideableObj, onToggleHide, label }) => { */}
        <HideablePropertyWidget hideableObj={BoardStore.currentElem.chartOptions.plugins.startDatePicker}
          onToggle={() => {
            BoardStore.currentElem.chartOptions.plugins.startDatePicker.display = !BoardStore.currentElem.chartOptions.plugins.startDatePicker.display
            BoardStore.updateCurrent()
          }}
          label={"Display Date"} />

        <HideableInputWidget titleObj={BoardStore.currentElem.chartOptions.plugins.startDatePicker} titleType={"startDatePicker"} />

        <FontStyleWidget
          titleType={"startDatePicker"}
          hideDisplayToggle={true}
          shouldDisplay={BoardStore.currentElem.chartOptions.plugins.startDatePicker}
          toggleDisplay={() => { }}
          font={BoardStore.currentElem.chartOptions.plugins.startDatePicker.font}
          color={BoardStore.currentElem.chartOptions.plugins.startDatePicker}
          label={"Start Label"}
        />
        <FontSelectWidget titleObj={BoardStore.currentElem.chartOptions.plugins.startDatePicker} titleType={"startDatePicker"} />

        <Divider sx={{ marginY: 1 }}></Divider>

        <HideableInputWidget titleObj={BoardStore.currentElem.chartOptions.plugins.endDatePicker} titleType={"endDatePicker"} />

        <FontStyleWidget
          titleType={"endDatePicker"}
          hideDisplayToggle={true}
          shouldDisplay={BoardStore.currentElem.chartOptions.plugins.endDatePicker}
          toggleDisplay={() => { }}
          font={BoardStore.currentElem.chartOptions.plugins.endDatePicker.font}
          color={BoardStore.currentElem.chartOptions.plugins.endDatePicker}
          label={"End Label"}
        />

        <FontSelectWidget titleObj={BoardStore.currentElem.chartOptions.plugins.endDatePicker} titleType={"endDatePicker"} />


      </EditorSection>
      <Divider sx={{ marginTop: 0 }}></Divider>
    </>
  }

  const textboxEditor = () => {
    return <>
      <EditorSection key="chart-style" cardid='chart-style' cardtitle='Chart Style' sx={{ height: '100%' }}>
        <EditChartSection />
      </EditorSection>

      <EditorSection key="titles" cardid='titles' cardtitle='Chart Titles' m={0} p={0}>

        <EditTitleWidget key="title"
          titleObj={BoardStore.currentElem.chartOptions.plugins.title}
          titleType={"title"} titleLabel={"Chart Title"} />

        {/* <Divider sx={{ marginTop: 0 }}></Divider> */}

      </EditorSection>
      <Divider sx={{ marginTop: 0 }}></Divider>
    </>
  }

  /////////////////

  ///////TO DELETE 

  /////////////////



  const KPIEditor = (chartVariant) => {
    return <>

      <EditorSection key="chart-desc" cardid='chart-desc' cardtitle='Chart Description' sx={{ height: '100%' }}>
        {/* <DescriptionBox updateObj={(content) => BoardStore.updateChartDescription(content)} storeObj={BoardStore.currentElem?.chartSettings.description} isEditMode={BoardStore.editMode} /> */}
        <ElementEditorBox />
      </EditorSection>

      <EditorSection key="chart-style" cardid='chart-style' cardtitle='Chart Style' sx={{ height: '100%' }}>
        <EditChartSection />
      </EditorSection>

      <EditorSection key="titles" cardid='titles' cardtitle='Chart Titles' m={0} p={0}>

        <EditTitleWidget key="title"
          titleObj={BoardStore.currentElem.chartOptions.plugins.title}
          titleType={"title"} titleLabel={"Chart Title"} />

        <Divider sx={{ marginTop: 1 }}></Divider>

        <EditTitleWidget key="subtitle"
          titleObj={BoardStore.currentElem.chartOptions.plugins.subtitle}
          titleType={"subtitle"} titleLabel={"Chart Subtitle"} />
      </EditorSection>

      <EditorSection key="metric" cardid='metric' cardtitle='Main Metric' m={0} p={0}>

        <EditMetricWidget key="metric"
          titleObj={BoardStore.currentElem.chartOptions.plugins.metric}
          titleType={"metric"}
          titleLabel={"Main Metric"} />

      </EditorSection>

      <EditorSection key="metric-adornment-start" cardid='metric-adornment-start' cardtitle='Metric Prefix' m={0} p={0}>

        <EditMetricWidget key="metric-adornment-start"
          titleObj={BoardStore.currentElem.chartOptions.plugins.startAdornment}
          titleType={"startAdornment"}
          titleLabel={"Unit Adornment"} />
      </EditorSection>

      <EditorSection key="metric-adornment-end" cardid='metric-adornment-end' cardtitle='Metric Suffix' m={0} p={0}>

        <EditMetricWidget key="metric-adornment-end"
          titleObj={BoardStore.currentElem.chartOptions.plugins.endAdornment}
          titleType={"endAdornment"}
          titleLabel={"Unit Adornment"} />
      </EditorSection>

      <EditorSection key="submetric" cardid='submetric' cardtitle='Trend Metric' m={0} p={0}>

        <HideableInputWidget titleObj={BoardStore.currentElem.chartOptions.plugins.submetric}
          titleType={"submetric"} />

        {BoardStore.currentElem.chartOptions.plugins.submetric.display ? <>
          {/* <PropertyToggleWidget isChecked={BoardStore.currentElem.isUp}
            onCheck={(e) => { BoardStore.currentElem.isUp = !BoardStore.currentElem.isUp; BoardStore.updateCurrent() }}
            label={"Toggle Arrow Direction"} /> */}

          <FontStyleWidget
            titleType={"submetric"}
            font={BoardStore.currentElem.chartOptions.plugins.submetric.font}
            color={BoardStore.currentElem.chartOptions.plugins.submetric.color}
            label={"Style"}
            hideDisplayToggle={true}            
          />

          <FontSelectWidget titleObj={BoardStore.currentElem.chartOptions.plugins.submetric}
            titleType={"submetric"} />
        </> : ''}
      </EditorSection>

      {chartVariant.length > 0 ?
        <EditorSection key="spark" cardid='spark' cardtitle='Sparkline' m={0} p={0}>

          <PropertyColorWidget colorValue={BoardStore.currentElem.chartOptions.plugins.spark.color}
            onValueChange={(color) => BoardStore.updateSparkColor(color)}
            label={"Spark Color"} />

          <NumberInputWidget numericValue={BoardStore.currentElem.chartOptions.plugins.spark.thikness}
            onValueChange={(e) => BoardStore.updateSparkWidth(e.target.value)}
            label={"Line Stroke"}
            inputAdornment={"px"} />

        </EditorSection>
        : ''}
      <Divider sx={{ marginTop: 0 }}></Divider>

    </>
  }

  const piehChartEditor = () => {
    return <>

      <EditorSection key="chart-desc" cardid='chart-desc' cardtitle='Chart Description' sx={{ height: '100%' }}>
        {/* <DescriptionBox updateObj={(content) => BoardStore.updateChartDescription(content)} storeObj={BoardStore.currentElem?.chartSettings.description} isEditMode={BoardStore.editMode} /> */}
        <ElementEditorBox />
        {/* <Editor initState={BoardStore.doc.requirements} placeholderMsg={""} onChange={(state) => BoardStore.updateDocRequirements(state)}/> */}
      </EditorSection>

      <EditorSection key="chart-style" cardid='chart-style' cardtitle='Chart Style' sx={{ height: '100%' }}>
        <EditChartSection />
      </EditorSection>

      <EditorSection key="titles" cardid='titles' cardtitle='Chart Titles' m={0} p={0}>

        <EditTitleWidget key="title"
          titleObj={BoardStore.currentElem.chartOptions.plugins.title}
          titleType={"title"} titleLabel={"Chart Title"} />

        <Divider sx={{ marginTop: 1 }}></Divider>

        <EditTitleWidget key="subtitle"
          titleObj={BoardStore.currentElem.chartOptions.plugins.subtitle}
          titleType={"subtitle"} titleLabel={"Chart Subtitle"} />

      </EditorSection>

      <EditorSection key="legend-card" cardid='legend' cardtitle='Legend'>
        <EditLegendSection storeObj={BoardStore.currentElem} />
      </EditorSection>

      <EditorSection key="labels-card" cardid='labels' cardtitle='Data Labels'>
        <LabelsEditSection storeObj={BoardStore.currentElem} editType={"theme"} />
      </EditorSection>

      {/* <EditorSection key="edit-data" cardid='edit-data' cardtitle='Edit Chart Data' accordionDetailsProps={{ sx: { padding: 0 } }}>
        <EditDataSection></EditDataSection>
      </EditorSection>
      <Divider sx={{ marginTop: 0 }}></Divider> */}
    </>
  }

const ElementEditorBox = observer(() => {
    // const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      // setOpen(true);
      BoardStore.showChartDescriptionDialog = true;
    };

    const handleClose = () => {
      // setOpen(false);
      BoardStore.showChartDescriptionDialog = false;
      BoardStore.updateCurrent()
    };

    return (
      // BoardStore.currentElem.chartSettings.description &&
      <Box >
        {BoardStore.editMode && <IconButton aria-label="edit" sx={{ position: 'absolute', top: '56px', zIndex: 10, right: '22px', width: 24, height: 24 }}
          onClick={handleClickOpen}>
          <EditIcon sx={{ fontSize: 18 }} />
        </IconButton>
        }

        <Box onClick={handleClickOpen} sx={{ maxHeight: 300, overflow: 'scroll' }}>
          <Editor style={{ maxHeight: 300 }} placeholderMsg={'Business logic, data sources...'} anchorKey={'side-editor'} editMode={false}
            initState={BoardStore.currentElem?.chartSettings.description}
          // onChange={(state) => BoardStore.updateChartDescription(state)} 
          />
        </Box>

        {/* <Dialog open={open} onClose={handleClose}  */}
        <Dialog open={BoardStore.showChartDescriptionDialog} onClose={handleClose}
          fullWidth
          maxWidth={'md'}

        >
          <DialogTitle>Chart Description
            <IconButton aria-label="edit" sx={{ position: 'absolute', top: '10px', zIndex: 10, right: '10px', width: 24, height: 24 }} onClick={handleClose}>

              <ClearOutlinedIcon />

            </IconButton>
          </DialogTitle>
          {/* <DialogContent sx={{minHeight: 900, display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
          <DialogContent sx={{ paddingBottom: '100px' }}>

            <Editor anchorKey={'side-editor-modal'} editMode={BoardStore.editMode}
              initState={BoardStore.currentElem?.chartSettings.description}
              onChange={(state) => BoardStore.updateChartDescription({ newDescription: state })}
            />
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
      </Box>
    );
  })

  const dashChartEditor = () => {
    return <>

      <EditorSection key="chart-desc" cardid='chart-desc' cardtitle='Chart Description' sx={{ height: '100%' }}>
        <ElementEditorBox />
        {/* <DescriptionBox updateObj={(content) => BoardStore.updateChartDescription(content)} storeObj={BoardStore.currentElem?.chartSettings.description} isEditMode={BoardStore.editMode} /> */}

      </EditorSection>

      <EditorSection key="chart-style" cardid='chart-style' cardtitle='Chart Style' sx={{ height: '100%' }}>
        <EditChartSection />
      </EditorSection>

      <EditorSection key="titles" cardid='titles' cardtitle='Chart Titles' m={0} p={0}>

        <EditTitleWidget key="title"
          titleObj={BoardStore.currentElem.chartOptions.plugins.title}
          titleType={"title"} titleLabel={"Chart Title"} />

        <Divider sx={{ marginTop: 1 }}></Divider>

        <EditTitleWidget key="subtitle"
          titleObj={BoardStore.currentElem.chartOptions.plugins.subtitle}
          titleType={"subtitle"} titleLabel={"Chart Subtitle"} />

      </EditorSection>

      <EditorSection key="x-axis" cardid='x-axis' cardtitle='X Axis'>

        {/* <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Title</Typography> */}
        <EditTitleWidget key="x-axis" titleObj={BoardStore.currentElem.chartOptions.scales.x.title}
          titleType={"x"} titleLabel={"X Axis"} />
        <Divider sx={{ marginY: 1 }}></Divider>
        <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Ticks</Typography>
        <TickEditWidget axis={'x'} storeObj={BoardStore.currentElem} />

      </EditorSection>

      <EditorSection key="y-axis" cardid='y-axis' cardtitle='Y Axis'>

        {/* <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Title</Typography> */}
        <EditTitleWidget key="y-axis" titleObj={BoardStore.currentElem.chartOptions.scales.y.title}
          titleType={"y"} titleLabel={"Y Axis"} />
        <Divider sx={{ marginY: 1 }}></Divider>
        <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Ticks</Typography>
        <TickEditWidget axis={'y'} storeObj={BoardStore.currentElem} />

        {/* <Divider sx={{ marginY: 1 }}></Divider>
        <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Range</Typography>
        <FormGroup >
          <FormControlLabel
            control={<ButtonGroup disableElevation variant="contained">
              <AxisMinMaxFields axis={'y'} />
            </ButtonGroup>}
            sx={{ justifyContent: 'space-between', marginLeft: 0, paddingX: 0, alignItems: 'baseline' }}
            labelPlacement="start"
            label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>Random Values</Typography>} />
        </FormGroup> */}
      </EditorSection>


      {BoardStore.currentElem.chartType === 'line' ?
        <EditorSection key="pointline" cardid='pointline' cardtitle='Line and Points'>
          <PropertyToggleWidget isChecked={BoardStore.currentElem.chartOptions.elements.line.tension > 0}
            onCheck={(e) => BoardStore.updateLineTension({ tension: e.target.checked })}
            label={"Curved Line"} />
          <NumberInputWidget numericValue={BoardStore.currentElem.chartOptions.elements.point.radius}
            onValueChange={(e) => BoardStore.setDatapointRadius({ value: e.target.value })}
            label={"Point Radius"}
            inputAdornment={"px"}
          />
        </EditorSection>
        : ''}
      <EditorSection key="grid" cardid='grid' cardtitle='Grid'>
        <EditGridSection storeObj={BoardStore.currentElem} />
      </EditorSection>

      <EditorSection key="legend-card" cardid='legend' cardtitle='Legend'>
        <EditLegendSection storeObj={BoardStore.currentElem} />
      </EditorSection>

      <EditorSection key="labels-card" cardid='labels' cardtitle='Data Labels'>
        <LabelsEditSection storeObj={BoardStore.currentElem} />
      </EditorSection>
      {/* 
      <EditorSection key="edit-data" cardid='edit-data' cardtitle='Edit Chart Data' accordionDetailsProps={{ sx: { padding: 0 } }}>
        <EditDataSection></EditDataSection>
      </EditorSection> */}

      <Divider sx={{ marginTop: 0 }}></Divider>
    </>
  }

  return (<>
    {/* {ChartStore.currentTab.charts.get(ChartStore.currentUid)  ? <> */}

    {/* {boardStyle()} */}
    {/* <BoardStyleEditor/> */}
    {/* {BoardStore.editMode &&  */}
    {BoardStore.currentElem ? <>

      {!BoardStore.editMode && <Box sx={{ margin: 1, }}>
        <Typography color="textSecondary" sx={{ fontSize: 12, marginY: 1, display: 'flex', alignItems: 'center' }}>
          Chart Description
          <IconButton aria-label="edit" sx={{ width: 24, height: 24 }}
            onClick={() => BoardStore.showChartDescriptionDialog = true}>
            <OpenInNewIcon sx={{ fontSize: 14 }} />
          </IconButton>
        </Typography>
        <ElementEditorBox />
      </Box>}


      {getElementEditor(BoardStore.currentElem)}
      {/* {!BoardStore.editMode ? '' : <ElementEditorBox/>} */}

      {/* {BoardStore.editMode && <Button size="small" variant="outlined" color="error"
        onClick={(event) => BoardStore.deleteChart(BoardStore.currentElem)}
        sx={{ margin: '3em auto', display: 'flex', justifyContent: "center", fontSize: 9, fontWeight: 'regular' }}> Delete Chart </Button>
      } */}
    </>
      : <Typography color="textSecondary" sx={{ fontSize: 12, fontStyle: 'italic', textAlign: 'center', my: '2em' }}>Click on a chart to edit it's style...</Typography>
    }
    {/* } */}

    {/* <Button size="small" variant="outlined" 
              onClick={(event) => ChartStore.board.scale = 0.5}
              sx={{ margin: '3em auto', display: 'flex', justifyContent: "center", fontSize: 9, fontWeight: 'regular' }}> scale down </Button>
            <Button size="small" variant="outlined" 
              onClick={(event) => ChartStore.board.scale = 1.5}
              sx={{ margin: '3em auto', display: 'flex', justifyContent: "center", fontSize: 9, fontWeight: 'regular' }}> scale up </Button> */}
    {/* <Button size="small" variant="outlined" 
              onClick={(event) => googleSignIn()}
              sx={{ margin: '3em auto', display: 'flex', justifyContent: "center", fontSize: 9, fontWeight: 'regular' }}> GOOGLE </Button>  */}
  </>)
})

export default EditorFactory;




