import { logEvent } from "firebase/analytics";
import mixpanel from 'mixpanel-browser';
import { BoardStore, UserStore } from '../Stores/Stores';
import { fbAnalytics } from './DBService';
import Smartlook from 'smartlook-client';

mixpanel.init('849905ee49f5da930674cc4837ffaa30', {debug: true}); 
// mixpanel.init('849905ee49f5da930674cc4837ffaa30');

// let smartlookKey = "84f1ed513478255e14261a25a629e0493ae2610d"
// Smartlook.init(smartlookKey)

// const fbAnalytics = getAnalytics();

const isLocalHost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"
const isDebug = true;

const _track = (eventName, eventProperties) => {
    try{

        // if(isLocalHost) return;
        logEvent(fbAnalytics, eventName, eventProperties);
        mixpanel.track(eventName, eventProperties);
        if(isDebug){
            console.log("TRACK", eventName, eventProperties)
        }    
    }catch(e){
        console.error("failed to track analytics", e)
    }
}

const chartStoreSuperProps = () => {
    
    let superProps = {
        boardId: BoardStore.board?.id,
        tabId: BoardStore.currentTabUid,
        numOfTabs: BoardStore.board?.tabs.length,
        chartsInTab: BoardStore.currentTab?.charts.length,        
    }
    if(BoardStore.currentElem){
        superProps['chartId'] = BoardStore.currentUid
        superProps['chartType'] = BoardStore.currentElem?.chartType
        superProps['chartVariant'] = BoardStore.currentElem?.chartVariant
    }
    return superProps;
    // board/Id: ChartStore.board.id,
}

class Analytics_Imple{

    signIn(userId){
        mixpanel.identify(userId);
        Smartlook.identify(userId)
        console.log("analytics sign in as ", userId)
        
        mixpanel.people.set({ 
            "email": UserStore.email, 
            "displayName": UserStore.displayName, 
            "isAnonymous": UserStore.isAnonymous, 
            "createdAt": UserStore.createdAt, 
     });       
        // this.email = fbUser?.email;
        // this.emailVerified = fbUser?.emailVerified
        // this.displayName = fbUser?.displayName || fbUser?.providerData ?  fbUser?.providerData[0]?.displayName : null
        // this.isAnonymous = fbUser?.isAnonymous
        // this.photoURL = fbUser?.photoURL || fbUser?.providerData ? fbUser?.providerData[0]?.photoURL : null
        // this.createdAt = fbUser?.createdAt
        // this.lastLoginAt = fbUser?.lastLoginAt
    }
    
    googleConnect(){
        mixpanel.people.set({ "auth_provider": "google" });       
        _track('google_connect', {...chartStoreSuperProps() })
    }
    
    trackBoardLoad = () => {        
        _track('load_board', {...chartStoreSuperProps() })
    }
    
    trackNewBoard = () => {        
        _track('create_board', {...chartStoreSuperProps() })
    }
        
    trackClearBoard = () => {        
        _track('clear_board', {...chartStoreSuperProps() })
    }
    
    trackNewChart = (chartType, chartVariant) => {        
        _track('create_chart', {...chartStoreSuperProps(), chartType: chartType, chartVariant: chartVariant })
    }

    trackDeleteChart = (chartId, chartType, chartVariant) => {        
        // mixpanel.track('delete_chart', {...chartStoreSuperProps(), chartId: chartId });
        _track('delete_chart', {...chartStoreSuperProps(), chartId, chartType, chartVariant });
    }
    
    trackNewTab = () => {        
        _track('create_tab', {...chartStoreSuperProps() });
    }

    trackDeleteTab = (tabId) => {        
        _track('delete_tab', {...chartStoreSuperProps(), tabId: tabId });
    }

    trackChartDragged = () => {        
        _track('drag_chart', {...chartStoreSuperProps()});
    }

    trackChartResized = () => {        
        _track('resize_chart', {...chartStoreSuperProps()});
    }

    trackChartEdit = ({...props}) => {        
        _track('edit_chart', {...chartStoreSuperProps(), ...props});
    }

    trackBoardEdit = (edit_type) => {        
        _track('edit_board', {...chartStoreSuperProps(), edit_type});
    }

    trackBoardTitleEdit = () => {        
        _track('edit_board_title', {...chartStoreSuperProps()});
    }

    trackCopyChart = () => {        
        _track('copy_chart', {...chartStoreSuperProps()});
    }

    trackPasteChart = () => {        
        _track('paste_chart', {...chartStoreSuperProps()});
    }

    trackUpdateLabelType = (labelType) => {        
        _track('update_label_type', {...chartStoreSuperProps(), labelType});
    }
    
    trackUpdateLabelStep = (labelsStep) => {        
        _track('update_label_step', {...chartStoreSuperProps(), labelsStep});
    }
    
    trackUpdateLabelMin = (labelsMin) => {        
        _track('update_label_min', {...chartStoreSuperProps(), labelsMin});
    }
    
    trackUpdateLabelMax = (labelsMax) => {        
        _track('update_label_max', {...chartStoreSuperProps(), labelsMax});
    }
        
    trackUpdateLabelDate = (labelsDate) => {        
        _track('update_label_date', {...chartStoreSuperProps(), labelsDate});
    }
    
    trackClickChartTree = () => {        
        _track('click_chart_tree', {...chartStoreSuperProps()});
    }
        
    trackClickSendToBack = () => {        
        _track('send_to_back', {...chartStoreSuperProps()});
    }
    
    trackClickSendToFront = () => {        
        _track('send_to_front', {...chartStoreSuperProps()});
    }
    
    trackAddChartMenu = (chartType) => {        
        _track('hover_chart_menu', {...chartStoreSuperProps(), chartType});
    }
    
    trackClickGallery = (chartType) => {        
        _track('click_gallery', {...chartStoreSuperProps(), chartType});
    }    

    trackSearchBoards = () => {
        _track('search_boards', {...chartStoreSuperProps()});
    }

    trackClickNewBoard = (source) => {
        _track('click_new_board', {...chartStoreSuperProps(), source});
    }
    
    trackToggleBoardPrivacy = (isPrivate) => {
        isPrivate ? 
        _track('private_board', {...chartStoreSuperProps()}) :
        _track('shareable_board', {...chartStoreSuperProps()});
    }
    
    trackUpdatePlanningDoc = ({...props}) => {
        _track('plan_doc_edit', {...chartStoreSuperProps(), ...props});
    }

    trackInviteCollaborator = ({...props}) => {
        _track('invite', {...chartStoreSuperProps(), ...props});
    }

    trackRemoveCollaborator = ({...props}) => {
        _track('uninvite', {...chartStoreSuperProps(), ...props});
    }

    trackCanvasMode = ({...props}) => {
        _track('switch_view', {...chartStoreSuperProps(), ...props});
    }

    trackClickedShare = ({...props}) => {
        _track('click_share', {...chartStoreSuperProps(), ...props});
    }

    trackClickedFAQ = ({...props}) => {
        _track('click_FAQ', {...chartStoreSuperProps(), ...props});
    }

    trackCopyInviteLink = ({...props}) => {
        _track('copy_invite_link', {...chartStoreSuperProps(), ...props});
    }

    trackSendMagicLink = ({...props}) => {
        _track('send_magic_link', {...chartStoreSuperProps(), ...props});
    }

    trackShowAuthDialog = ({...props}) => {
        _track('show_auth_dialog', {...chartStoreSuperProps(), ...props});
    }

    trackSignupQuestin = ({...props}) => {
        _track('signup_questin', {...chartStoreSuperProps(), ...props});
    }

    trackClickEditorTab = ({...props}) => {
        _track('click_editor_tab', {...chartStoreSuperProps(), ...props});
    }


}

export const Analytics = new Analytics_Imple();

