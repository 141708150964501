import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { FormGroup } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';

const HideableInputWidget = observer(({titleObj, titleType, disableToggleHide, hideLabel, editMode}) => {
    return <FormGroup row m={0} p={0}>
        <TextField size="small"
            style={{ width: '100%' }}
            disabled={!titleObj.display && BoardStore.isEditMode}
            
            label={hideLabel ? '' : <span style={{fontSize: 12}}>{titleType}</span>}
            variant="standard"
            margin="dense"
            value={titleObj.text}
            onKeyDown={e=>{ e.stopPropagation()}}
            onChange={(event) => {BoardStore.updateTitle({text: event.target.value, titleType: titleType, editMode: editMode})}}
            InputProps={{
                style: { fontSize: 12 }, 
                // disableUnderline: true, 
                endAdornment: disableToggleHide ? '' : <InputAdornment position="end">
                    <IconButton aria-label="toggle title visibility"
                        onClick={(e) => { BoardStore.toggleShowTitle({titleType: titleType, shouldDisplay: !titleObj.display, editMode: editMode}); e.stopPropagation(); }}
                        edge="end" size="small">
                        {titleObj.display ? <VisibilityOutlinedIcon sx={{ fontSize: 14 }} /> : <VisibilityOffOutlinedIcon sx={{ fontSize: 14 }} />}
                    </IconButton>
                </InputAdornment>
            }} />

    </FormGroup>;
})
export default HideableInputWidget;