import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { observer } from 'mobx-react-lite'
import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import {
  CirclePicker, SketchPicker
} from 'react-color'
import reactCSS from 'reactcss'
// import {COLORS_PALLETE} from '../../ThemeManager'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import { Box, Typography } from '@mui/material'
import { amber, blue, blueGrey, brown, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow } from '@mui/material/colors'
import { ThemeStore } from '../../../Stores/ThemeManager'
const grays = ['rgba(255,255,255,1)', 'rgba(236, 239, 241, 1)', 'rgba(207, 216, 220, 1)', 'rgba(176, 190, 197, 1)', 'rgba(144, 164, 174, 1)', 'rgba(120, 144, 156, 1)', 'rgba(96, 125, 139, 1)', 'rgba(69, 90, 100, 1)', 'rgba(38, 50, 56, 1)']


const MUI_COLORS = [deepOrange, deepPurple, green, blue, amber, blueGrey, brown, cyan, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow,]
export const MUI_COLORS_LIGHT = MUI_COLORS.map(c => c[300])
export const avatarColors = MUI_COLORS.map(c => c[500])
export const DASHING_GREEN = 'rgba(10, 191, 83, 1)'; //'#0ABF53'

const ColorPickerWidget = observer(({ color, updateColor, hideArrow, allowEditColor = false }) => {

  const [display, shouldDisplay] = useState(false)
  const [themeColors, setThemeColors] = useState([...ThemeStore.colors.datasets])
  
  const handleClick = () => {
    shouldDisplay(!display)
  };

  const handleClose = () => {
    shouldDisplay(false)
  };

  const handleChange = (color) => {
    updateColor(color)
    if(!allowEditColor) {
      handleClose()
    }
  };

  const styles = reactCSS({

    'default': {
      zIndex: '15000',

      color: {
        height: '16px',
        width: '16px',

        background: color,
        borderRadius: '50%',
        border: "1px solid #ddd",
      },
      swatch: {
        // border: '1px solid red',
        width: hideArrow ? '-32px' : '32px',
        // borderRadius: '1px',
        cursor: 'pointer',
      },
      icon: {
        // display: !hideArrow,
        // marginRight: hideArrow ? 0 : '-32px',
        marginRight: '-32px',
        fontSize: '16px',
      },
      popover: {        
        position: 'fixed',
        marginLeft: 20,
        zIndex: '2500',
      },
      circlePopover: {        
        backgroundColor: 'white',
        border: '1px solid rgba(200,200,200, 0.5)',
        borderRadius: '4px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        paddingRight: '10px',
        paddingLeft: '10px',
        paddingTop: 10,
      },
      cover: {
        // backgroundColor: 'red',
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },

    },
  });

  const RGBAToHexA = (rgba) => {
    if (Array.isArray(rgba)) rgba = rgba[0]
    if (!rgba || rgba.startsWith('#')) return rgba;
    let sep = rgba.indexOf(",") > -1 ? "," : " ";
    rgba = rgba.substr(5).split(")")[0].split(sep);

    // Strip the slash if using space-separated syntax
    if (rgba.indexOf("/") > -1)
      rgba.splice(3, 1);

    for (let R in rgba) {
      let r = rgba[R];
      if (r.indexOf("%") > -1) {
        let p = r.substr(0, r.length - 1) / 100;

        if (R < 3) {
          rgba[R] = Math.round(p * 255);
        } else {
          rgba[R] = p;
        }
      }
    }
    let r = (+rgba[0]).toString(16),
      g = (+rgba[1]).toString(16),
      b = (+rgba[2]).toString(16),
      a = Math.round(+rgba[3] * 255).toString(16);

    if (r.length === 1)
      r = "0" + r;
    if (g.length === 1)
      g = "0" + g;
    if (b.length === 1)
      b = "0" + b;
    if (a.length === 1)
      a = "0" + a;

    return "#" + r + g + b + a;
  }

  return <div>
    <div style={styles.swatch} >
      <div style={styles.color} onClick={handleClick} >
        {hideArrow ? '' : <ArrowDropDownIcon style={styles.icon} />}
      </div>
      {display ? <div style={{...styles.popover, ...(allowEditColor ? [] : styles.circlePopover)}}>

        <div style={styles.cover} onClick={handleClose} />

        {/* <SketchPicker color={ RGBAToHexA(color) }  onChange={ handleChange }  presetColors={colors} /> */}
        {allowEditColor ?
          
            <SketchPicker key={nanoid()} color={RGBAToHexA(color)} onChange={handleChange} presetColors={[...themeColors, ...grays]} />
          
          :
          <>
          <Box sx={{'& .circle-picker span div span div'  : {border: '1px solid rgba(200,200,200, 0.7)'}}}>
          <CirclePicker
            style={{}}
            onChange={handleChange}
            width={200}
            circleSize={16}
            circleSpacing={8}
            // styles={{ backgroundColor: 'blue', border: '1px solid red' }}
            colors={
              [...ThemeStore.theme.colors.datasets, ...grays]
              // MUI_COLORS_LIGHT
            } />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 1 }}>
          <TipsAndUpdatesOutlinedIcon sx={{ fontSize: 12, marginRight: 0.5 }} />
          <Typography color="textSecondary" sx={{ fontSize: 10, fontStyle: 'italic', textTransform: 'none', marginTop: 0 }}>
            Edit your colors in Theme tab
          </Typography>
        </Box>
        </>
        }
      </div>
        : null}
    </div>
  </div>
})

export default ColorPickerWidget;