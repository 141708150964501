
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { BoardStore } from '../../Stores/Stores';
import ElementContainer from './ElementContainer';
import GridChart from '../Elements/GridChart';

import '../../index.css';
// import DescriptionTextbox from '../Elements/TitleBox';
// import persistSchema from '../Stores/PersistManager';


const DataGridContainer = observer(({ chart }) => {

  const [quickEditor, setQuickEditor] = useState(null)
  const [isHoverEditable, setIsHoverEditable] = useState(false)
  const [disableDragging, setDisableDragging] = useState(false)


  return <ElementContainer chart={chart} quickEditor={quickEditor} 
                           isHoverEditable={isHoverEditable} 
                           shouldDisableDrag={disableDragging}>
    <Box
      sx={
        {
          position: "relative",
          height: '100%',
          display: "flex",
          flexDirection: "column",
          alignItems: chart.chartOptions.plugins.title.align,
          // justifyContent: "center",
          justifyContent: "flex-start",
          backgroundColor: chart.chartSettings.backgroundColor,

        }}>

      <TextField
        type="text"
        autoFocus
        onClick={(e) => { setQuickEditor('title'); e.stopPropagation() }}
        onMouseEnter={(e) => { setIsHoverEditable(true) }}
        onMouseLeave={(e) => { setIsHoverEditable(false) }}
        inputProps={{
          sx: {
            height: 'auto',
            weight: 'auto',
            paddingTop: `${chart.chartOptions.plugins.title.padding}px`,
            paddingBottom: `${chart.chartOptions.plugins.title.padding}px`,
            paddingX: '10px',
            fontStyle: chart.chartOptions.plugins.title.font.style,
            fontWeight: chart.chartOptions.plugins.title.font.weight,
            color: chart.chartOptions.plugins.title.color,
            fontSize: chart.chartOptions.plugins.title.font.size,
            fontFamily: chart.chartOptions.plugins.title.font.family,
            textAlign: chart.chartOptions.plugins.title.align,
          }
        }}
        InputProps={{
          disableUnderline: true,
        }}
        fullWidth
        multiline

        value={chart.chartOptions.plugins.title.text}
        onChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: "title"})}
        variant="standard" />

      <TextField
        type="text"
        autoFocus
        onClick={(e) => { setQuickEditor('subtitle'); e.stopPropagation() }}
        onMouseEnter={(e) => { setIsHoverEditable(true) }}
        onMouseLeave={(e) => { setIsHoverEditable(false) }}
        inputProps={{
          sx: {
            height: 'auto',
            weight: 'auto',
            paddingTop: `${chart.chartOptions.plugins.subtitle.padding.top}px`,
            paddingBottom: `${chart.chartOptions.plugins.subtitle.padding.bottom}px`,
            paddingX: '10px',
            fontStyle: chart.chartOptions.plugins.subtitle.font.style,
            fontWeight: chart.chartOptions.plugins.subtitle.font.weight,
            color: chart.chartOptions.plugins.subtitle.color,
            fontSize: chart.chartOptions.plugins.subtitle.font.size,
            fontFamily: chart.chartOptions.plugins.subtitle.font.family,
            textAlign: chart.chartOptions.plugins.subtitle.align,
          }
        }}
        InputProps={{
          disableUnderline: true,
        }}
        fullWidth
        multiline

        value={chart.chartOptions.plugins.subtitle.text}
        onChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: "subtitle"})}
        variant="standard" />

      <GridChart chart={chart} onClick={(e) => {e.stopPropagation()}} onDisableDrag={(value) => {setDisableDragging(value); console.log("setDisableDragging", value)}}></GridChart>
    </Box>

  </ElementContainer>

});

export default DataGridContainer;

