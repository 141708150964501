import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import React from 'react';
import Switch from '@mui/material/Switch';


export function PropertyToggleWidget({ isChecked, onCheck, label, size='small', disabled }) {

  const getFontSize = (size) => {
    if(size === 'small') return 12;
    if(size === 'medium') return 14;
    if(size === 'large') return 18;
  }
  return <FormGroup sx={{width: '100%'}}>
    <FormControlLabel
      control={<Switch
      size={'small'}
        checked={isChecked}
        // disabled={!BoardStore.editMode} TODO enable
        disabled={disabled}
        onChange={(e) => onCheck(e)}
        color="default" />}
      sx={{ justifyContent: 'space-between', marginLeft: 0,  }}
      labelPlacement="start"
      label={<Typography component={'span'} color="textSecondary" sx={{ fontSize: getFontSize(size)}}>{label}</Typography>} />
  </FormGroup>;
}
