import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function RangeSliderWidget({ value, minValue, maxValue, updateValues, color }) {
    
    const handleSliderChange = (event, newValue) => {
        event.stopPropagation(); event.preventDefault()
        updateValues(newValue);
    };

    const handleInputChange = (event, idx) => {
        
        if (idx == 0) {
            updateValues([Number(event.target.value), value[1]]);
        } else {
            updateValues([value[0], Number(event.target.value)]);
        }
    };

    //   const handleBlur = () => {
    //     if (value[0] < 0) {
    //       setValue(0);
    //     } else if (value > 100) {
    //       setValue(100);
    //     }
    //   };


    const sliderInput = (idx) => <TextField
        id="outlined-number"
        size="small"
        type="number"
        variant="standard"
        sx={{ input: { textAlign: "center", paddingX: 1, paddingY: 0 } }}
        style={{ padding: 0, marginRight: '1ch' }}

        value={value[idx]}
        onChange={(event) => handleInputChange(event, idx)}
        inputProps={{            
            style: {
                fontSize: 14, textAlign: 'right', margin: 0, padding: 0, width: '4ch', min: minValue, max: maxValue,
            }
        }}
        InputProps={{
            style: { fontSize: 12 },
            disableUnderline: true,
            inputMode: "decimal",
            pattern: '[0-9]*',

            //   endAdornment: <InputAdornment position="end">
            //     <Box componant="span" sx={{ fontSize: 11, textAlign: 'left' }}> {inputAdornment}</Box>
            //   </InputAdornment>
        }} />

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={2} alignItems="center" onClick={(e) => { }}>
                <Grid item>
                    {sliderInput(0)}
                </Grid>
                <Grid item xs style={{ paddingLeft: 0 }}>
                    <Slider
                        sx={{
                            // color: 'rgba(64,64,64,1)',
                            color: color,
                            height: 3,
                            padding: '13px 0',
                            '& .MuiSlider-thumb': {
                                border: '1px solid currentColor',
                                '&:hover': {
                                    boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
                                },
                            },
                            '& .MuiSlider-rail': {
                                //   color: 'rgba(64,64,64,1)',
                                color: color,
                                opacity: undefined,
                                height: 3,
                            },
                        }}

                        // valueLabelDisplay={'on'}
                        min={Number(minValue)}
                        max={Number(maxValue)}
                        // min={0}
                        // max={1000}
                        size="small"
                        value={value}
                        onMouseDown={event => {event.stopPropagation()}}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                    />
                </Grid>
                <Grid item style={{ paddingLeft: 5 }}>
                {sliderInput(1)}
                </Grid>
            </Grid>
        </Box>
    );
}
