import { observer } from 'mobx-react-lite';
import * as React from 'react';
import FontSelectWidget from './FontSelectWidget';
import HideableInputWidget from './HideableInputWidget';
import TextStyleWidget from './TextEditWidget';
import PaddingWidget from './PaddingWidget';
import HideablePropertyWidget from './HideablePropertyWidget';
import { BoardStore } from '../../../Stores/Stores';
import { Box } from '@mui/material';

// const EditTitle = ({titleObj, updateTitle, toggleShowTitle, setFontSize, setFontFamily}) => {
const EditTitleWidget = observer(({ titleObj, titleType, titleLabel, editMode }) => {
    return <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
        
        {editMode === 'theme' ? 
        <HideablePropertyWidget  hideableObj={titleObj}
        onToggle={() => {
            BoardStore.toggleShowTitle({titleType: titleType, shouldDisplay: !titleObj.display, editMode: editMode});
        }} 
        label={`Display ${titleLabel}`}/> :
        
        <HideableInputWidget titleObj={titleObj} titleType={titleType} editMode={editMode} />
    }

        {titleObj.display ?
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                <TextStyleWidget titleObj={titleObj} titleType={titleType} editMode={editMode}/>
                <FontSelectWidget titleObj={titleObj} titleType={titleType} editMode={editMode}/>
                <PaddingWidget titleObj={titleObj} titleType={titleType} editMode={editMode}/>
            </Box>
            : ''}
    </Box>
}
)

export default EditTitleWidget;


