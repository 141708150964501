
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { BoardStore } from '../../Stores/Stores';
import Editor from '../Editors/TextEditor/Editor';
import ElementContainer from './ElementContainer';

const TextboxContainer = observer(({ chart }) => {

  const [quickEditor, setQuickEditor] = useState(null)
  // const [isHoverEditable, setIsHoverEditable] = useState(false)



  useEffect(() => {

  }, [chart.isActive])

  return <ElementContainer chart={chart} quickEditor={quickEditor}>
    <Box
      sx={
        {
          position: "relative",
          height: '100%',
          display: "flex",
          flexDirection: "column",
          alignItems: chart.chartOptions.plugins.title.align,
          // justifyContent: "center",
          justifyContent: chart.chartVariant.includes('placeholder') ? 'center' : "flex-start",
                    
          // background: chart.chartVariant.includes('placeholder') ? "repeating-linear-gradient(135deg, #fafafa, #ccc 2px, #fafafa 2px, #fafafa 50%);" :  '' ,          
          
          //     rgba(0,0,0,0) 0%,
          //     rgba(0,0,0,0) calc(50% - 1px),
          //     rgba(64,64,64,0.3) 50%,
          //     rgba(0,0,0,0) calc(50% + 1px),
          //     rgba(0,0,0,0) 100%),
          // linear-gradient(to top right,
          //     rgba(255,255,255,1) 0%,
          //     rgba(255,255,255,1) calc(50% - 1px),
          //     rgba(64,64,64,0.3) 50%,
          //     rgba(255,255,255,1) calc(50% + 1px),
          //     rgba(255,255,255,1) 100%);`,
          background: !chart.chartVariant.includes('placeholder') ? chart.chartSettings.backgroundColor : `linear-gradient(to top left,            
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0) calc(50% - 1px),
            rgba(64,64,64,0.3) 50%,
            rgba(0,0,0,0) calc(50% + 1px),
            rgba(0,0,0,0) 100%),
        linear-gradient(to top right,
          ${chart.chartSettings.backgroundColor} 0%,
          ${chart.chartSettings.backgroundColor} calc(50% - 1px),
          ${chart.chartSettings.backgroundColor} 50%,
          ${chart.chartSettings.backgroundColor} calc(50% + 1px),
          ${chart.chartSettings.backgroundColor} 100%);`,
            
            // backgroundColor: chart.chartVariant.includes('placeholder') ? 'white' :  chart.chartSettings.backgroundColor ,
          
          // paddingTop: `${chart.chartOptions.layout.padding.top}px`,
          // paddingBottom: `${chart.chartOptions.layout.padding.bottom}px`,
          // paddingRight: `${chart.chartOptions.layout.padding.right}px`,
          // paddingLeft: `${chart.chartOptions.layout.padding.left}px`,
          // background: "repeating-linear-gradient(135deg, #fafafa, #ccc 2px, #fafafa 2px, #fafafa 80px);"
          // borderTopRightRadius: "5px 225px",
          // borderTopLeftRadius: "255px 5px",
          // borderBottomLeftRadius: '225px 5px',
          // borderBottomRightRadius: '5px 255px',
          // border: 'solid 3px #41403E',

        }
      }>
        {true ?//|| chart.chartVariant.includes('placeholder') ? 
      <TextField 
        type="text"
        autoFocus
        onClick={(e) => {setQuickEditor('title'); e.stopPropagation()}}
        // onMouseEnter={(e) => {setIsHoverEditable(true)}}
        // onMouseLeave={(e) => {setIsHoverEditable(false)}}
        inputProps={{
          sx: {
            height: 'auto',
            weight: 'auto',
            lineHeight: '1.8ch',
            // padding: chart.chartOptions.layout.padding,
            padding: '20px',
            fontStyle: chart.chartOptions.plugins.title.font.style,
            fontWeight: chart.chartOptions.plugins.title.font.weight,
            color: chart.chartOptions.plugins.title.color,
            fontSize: chart.chartOptions.plugins.title.font.size,
            fontFamily: chart.chartOptions.plugins.title.font.family,
            textAlign: chart.chartOptions.plugins.title.align,
          }
        }}
        InputProps={{
          disableUnderline: true,
        }}
        fullWidth
        multiline

        value={chart.chartOptions.plugins.title.text}
        onChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: "title"})}
        variant="standard" /> 
        
        :
      
        <>{chart.isActive ? 
          <>
            <Editor anchorKey={'side-editor-modal'} 
            // style={{marginTop: -10}}
            editMode={BoardStore.editMode}
            initState={chart.chartSettings.description}
            onChange={(state) => BoardStore.updateChartDescription({ newDescription: state })}
            /> 
            {console.log("rendering with edit")}
            </>
          
          :
          <>
          <Editor anchorKey={'side-editor-modal'} 
            editMode={false}
            initState={chart.chartSettings.description}
            onChange={(state) => BoardStore.updateChartDescription({ newDescription: state })}
            /> 
            {console.log("rendering no edit")}
            </>
        }
        </>
      }
    </Box>
  </ElementContainer>

});

export default TextboxContainer;

