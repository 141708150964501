import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Box } from '@mui/system';
import * as React from 'react'

export class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      
      // logErrorToMyService(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <Box sx={{ //width: '100%', height: '100%', 
                          position: 'absolute', 
                         left: '50%',
                         top: '50%',
                         transform: 'translate(-50%, -50%)',
                         color: 'rgba(64,64,64,1)',
                         display: 'flex', 
                         flexDirection: 'column', 
                         alignItems:'center', 
                         justifyContent: 'center'
                        }}>
                
                <SentimentVeryDissatisfiedOutlinedIcon sx={{fontSize: 64}}/>
                
                <Box sx={{fontSize: '22px', fontWeight: '500', marginY: 2}}>
                  Oops.. something went wrong
                  </Box>

                  <Box sx={{fontStyle: 'italic', marginY: 2}}>
                  Refresh the page, or <a href = "mailto:support@dashin.app?subject = Something is wrong"> email our support </a>
                  </Box>
          </Box>
      }
  
      return this.props.children; 
    }
  }