import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { observer } from "mobx-react-lite";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import DashingApp from './DashingApp';
// import { signIn } from './Stores/DBService';


const App = observer(() => {
  
  // signIn()

  return (<>
  <BrowserRouter>
    <LocalizationProvider dateAdapter={AdapterMoment}>      
      <DashingApp/>
    </LocalizationProvider>
    </BrowserRouter>
  </>)
})

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);




