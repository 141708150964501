
import ClearIcon from '@mui/icons-material/Clear';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Rnd } from "react-rnd";
import { Analytics } from '../../Services/Analytics';
import { defaultDragDataPlugin } from '../../Stores/Models';
import { BoardStore, GRID_SIZE } from '../../Stores/Stores';
import Editor from '../Editors/TextEditor/Editor';
import { AxisQuickEditWidget, DataQuickEditWidget, LegendQuickEditWidget, PieDataQuickEditWidget, TitleQuickEditTitle } from './QuickEditors';




const TooltipBox = observer(({ chart, boxType, hoverX, hoverY }) => {

  if (!chart || !chart.isActive || hoverX === undefined || hoverY === undefined) {
    return;
  }
  // useEffect(()=> {console.log("hover changed")}, [hoverX, hoverY])
  let isMac = window.navigator.platform.includes('Mac')
  let metaKey = isMac ? '⌘' : 'Ctrl'
  const dataMessage = `${metaKey} + Drag data point`

  const tooltipText = !boxType ? '' : boxType === "chartArea" ? dataMessage : "Edit " + boxType.charAt(0).toUpperCase() + boxType.slice(1).replace('-', ' ');

  return chart && chart.isActive &&

    <Box sx={{
      color: 'white',
      border: '1px solid rgba(64,64,64,1)',
      borderRadius: '0 6px 6px 6px',
      paddingY: 0.5,
      paddingX: 1.5,
      fontSize: 12,
      textAlign: 'left',
      backgroundColor: 'rgba(64,64,64,1)',
      zIndex: 10000,
      userSelect: "none",
      position: "absolute",
      left: hoverX + chart.chartSettings.positionX + 25,
      top: hoverY + chart.chartSettings.positionY + 50,

    }}>
      {tooltipText}
    </Box>
})


export const ACTIVE_BOXSHADOW = 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
// const LIGHT_BOXSHADOW =  'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;'
const LIGHT_BOXSHADOW = 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
// const NO_BOXSHADOW = '0px 0px 0px rgba(0, 0, 0, 0.24)'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    maxHeight: 250,
    overflow: 'scroll',
    minWidth: 240,
    padding: 5,
    margin: 0,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


// const arrowsMove = (event) => {
//   let charCode = String.fromCharCode(event.which).toLowerCase();
//   if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
//     // event.preventDefault();
//     // event.stopPropagation();
//     BoardStore.moveElementLocation(event.key);
//   }
// }

const QuickEditor = observer(({ quickEditor, chart, setEditorOpen }) => {

  const editorProps = { chart, onClose: () => setEditorOpen(false) }
  // const QuickEditor = ({quickEditor, chart, setEditorOpen}) => {
  switch (quickEditor) {
    case 'chartArea':
      if (chart.chartType === 'pie') return <PieDataQuickEditWidget {...editorProps} />;
      return <DataQuickEditWidget {...editorProps} />;
    case 'subtitle':
    case 'metric':
    case 'title':
    case 'startAdornment':
    case 'submetric':
    case 'endAdornment':
      return <TitleQuickEditTitle titleType={quickEditor} {...editorProps} />;
    case 'y-axis':
      return <AxisQuickEditWidget axis={"y"}  {...editorProps} />;
    case 'x-axis':
      return <AxisQuickEditWidget axis={"x"}  {...editorProps} />;
    case 'legend':
      return <LegendQuickEditWidget  {...editorProps} />;
    default:
      return ''
  }
})


const ElementContainer = observer(({ children, chart, quickEditor, isHoverEditable, shouldDisableDrag, hoverX, hoverY }) => {

  // const [displayClear, setDisplayClear] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [editorOpen, setEditorOpen] = React.useState(false);

  const [isMouseOver, setIsMouseOver] = React.useState(null);

  const handleKeyUp = (event) => {
    if ((event.ctrlKey || event.metaKey || event.key === "Meta" || event.key === "Control")) {
      chart.chartOptions.plugins.dragData = false;
      // BoardStore.updateCurrent();
      BoardStore._rerenderElement()
    }
  }

  const handleKeyDown = (event) => {
    // arrowsMove(event)
    if ((event.ctrlKey || event.metaKey) && chart.chartSettings.isDataDraggable && BoardStore.editMode) {
      chart.chartOptions.plugins.dragData = defaultDragDataPlugin();
      // BoardStore.updateCurrent()
      BoardStore._rerenderElement()
    }
  }

  const chartClicked = (e) => {
    e.stopPropagation();
    if (chart.isActive) {
      setEditorOpen(true)
    } else {
      BoardStore.setActive(chart)

    }

    // if (!(e.ctrlKey || e.metaKey)) {
    // BoardStore.setActive(chart)
    // }
  }
  useEffect(() => {
  }, [chart.chartSettings.renderedAt, chart.chartSettings.updatedAt, chart.isActive]
  )

  useEffect(() => {
    if(quickEditor === 'chartArea') {
      BoardStore.editorTab = 'data';
      return;
    }else{
      BoardStore.editorTab = 'element';
    }
    if(quickEditor === 'title' || quickEditor === 'subtitle' ){
      BoardStore.editorSection = 'titles';
    }else{
      BoardStore.editorSection = quickEditor
    }
    
  }, [quickEditor]
  )

  // quickEditor()


  // return <div onClick={chartClicked} id={`${chart.key}-${chart.version}-container`}>
  return <Box onMouseDown={chartClicked}
    onKeyDown={handleKeyDown}
    onKeyUp={handleKeyUp}
    onDoubleClick={handleKeyUp}
    tabIndex={1}
    sx={{ padding: chart.chart }}
    id={`${chart.key}-${chart.chartSettings.updatedAt}-container`}>

    {chart.isActive && isMouseOver && !isDragging && 
      <TooltipBox chart={chart} boxType={isHoverEditable} hoverX={hoverX} hoverY={hoverY} />
    }

    <Box onMouseEnter={() => { setIsMouseOver(true) }}
         onMouseLeave={() =>    { setIsMouseOver(false) }}>
          

    <Rnd
      key={chart.key}
      scale={BoardStore.board.scale}

      disableDragging={shouldDisableDrag || !BoardStore.editMode || (chart.chartSettings.isDataDraggable && chart.chartOptions.plugins.dragData !== false)}
      // disableDragging={true}

      enableResizing={BoardStore.editMode}
      size={{
        width: chart.chartSettings.width,
        height: chart.chartSettings.height,
      }}

      position={{
        x: chart.chartSettings.positionX,
        y: chart.chartSettings.positionY
      }}

      onDragStart={(e) => {
        setEditorOpen(false);
        setIsDragging(true);
      }} //add set active

      onDragStop={(e, d) => {
        if (d.deltaX !== 0 & d.deltaY !== 0) {
          setEditorOpen(false);
        }
        BoardStore.updateElementLocation(d);
        setIsDragging(false)
      }}

      onResizeStart={() => {
        setIsResizing(true)
        if (!chart.isActive) {
          BoardStore.setActive(chart)
        }
      }}

      onResizeStop={(e, direction, ref, delta, position) => { setIsResizing(false); 
        // BoardStore.updateElementLocation(position); 
        Analytics.trackChartDragged();
      }}

      onResize={(e, direction, ref, delta, position) => {
        // console.log("direction", direction, "ref", ref, "delta", delta, "position", position)
        chart.chartSettings.width = ref.offsetWidth;
        chart.chartSettings.height = ref.offsetHeight;
        BoardStore.updateElementLocation(position);
        e.stopPropagation();
      }}

      bounds=".board"
      dragGrid={[GRID_SIZE, GRID_SIZE]}
      resizeGrid={[GRID_SIZE, GRID_SIZE]}
      style={{
        overflow: "hidden",
        cursor: !BoardStore.editMode ? 'auto' :
          chart.isActive && isHoverEditable && isHoverEditable !== 'chartArea' && !isDragging ?
            'url("/images/pencil-cursor.png"), auto' :
            chart.chartSettings.isDataDraggable && chart.chartOptions.plugins.dragData !== false ?
              'ns-resize' : 'grab', // grab vs move // TODO horizontal chartVariant cursro ew-resize

        // zIndex: chart.isActive && chart.chartType !== 'box' ? 1000 : chart.chartSettings.zIndex,
        zIndex: BoardStore.currentTab.charts.indexOf(BoardStore.currentElem) + 10,

        // boxShadow: chart.isActive ?  ACTIVE_BOXSHADOW : NO_BOXSHADOW,
        boxShadow: chart.isActive ? ACTIVE_BOXSHADOW : chart.chartSettings.borderWidth === 0 ? 'none' : LIGHT_BOXSHADOW,
        border: `${chart.chartSettings.borderWidth + "px"} solid ${chart.chartSettings.borderColor}`,
        borderRadius: chart.chartSettings.borderRadius,

      }}
    >

      {chart.isActive ? <>
        {BoardStore.editMode && <IconButton aria-label="delete" size="small"
          onClick={(event) => { event.stopPropagation(); BoardStore.deleteChart(chart); }}
          disableFocusRipple
          disableRipple
          sx={{ position: 'absolute', zIndex: 100, right: 5, top: 5, }}>
          <ClearIcon fontSize="inherit" sx={{ color: 'rgba(204, 204, 204, 1)' }} /></IconButton>
        }
        <HtmlTooltip arrow placement="bottom-start" onClick={() => BoardStore.showChartDescriptionDialog = true}
          title={
            chart.isActive ?
              <React.Fragment>
                {chart.chartSettings.description ?
                  <Box sx={{ overflow: 'scroll' }} onClick={() => BoardStore.showChartDescriptionDialog}>
                    <Editor anchorKey={'side-editor'} editMode={false} initState={chart?.chartSettings.description} />
                  </Box> : 'No description yet for this chart'}
              </React.Fragment> : ''
          }>
          <IconButton aria-label="info" size="small"

            // onClick={(event) => { if (!isDragging) BoardStore.deleteChart(chart); event.stopPropagation() }}
            // onClick={(event) => { event.stopPropagation(); BoardStore.deleteChart(chart);  }}
            // onMouseOver={() => setDisplayClear(true)}
            // onMouseOut={() => setDisplayClear(false)}
            onClick={() => BoardStore.showChartDescriptionDialog = true}
            disableFocusRipple
            disableRipple
            sx={{
              position: 'absolute',
              zIndex: 100,
              right: BoardStore.editMode ? 25 : 5,
              top: 5,
            }}
          >
            <InfoOutlinedIcon fontSize="inherit" sx={{ color: 'rgba(204, 204, 204, 1)' }} />

          </IconButton>
        </HtmlTooltip>
      </> : ''}


      {children}
      {isResizing ? <Box sx={{ position: 'absolute', zIndex: 100, right: 10, bottom: 10, fontSize: 11, color: 'rgba(204, 204, 204, 1)' }}>
        {chart.chartSettings.width + " X " + chart.chartSettings.height}</Box> : ''}

    </Rnd>

    </Box>
    {BoardStore.editMode && chart.isActive 
      && !isDragging
      && editorOpen
      ?
      <>
        <QuickEditor quickEditor={quickEditor} chart={chart} key={`quick-editor-${quickEditor}`} setEditorOpen={(val) => setEditorOpen(val)} />
      </>
      : ''}
  </Box>
});


export default ElementContainer;
