import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BoardCanvas from "./Components/Board/BoardCanvas";
import AppContainer from './Layout/AppContainer';
import LeftDrawer from "./Layout/LeftDrawer";
import { RightDrawer } from "./Layout/RightDrawer";
import HomeSection from "./Pages/HomePage";
import PlanningDoc from "./Pages/PlanningDoc";
import { anonSignIn, fbAuth, getBoard, handleGoogleConnect, initAuthChangeListner } from './Services/DBService';
import { BoardStore, UserStore } from './Stores/Stores';
import { useAuthState } from 'react-firebase-hooks/auth';
import { HomeDrawers } from "./Layout/HomeDrawers";
import { BoardsList } from "./Stores/Stores";
import { ErrorBoundary } from "./Utils/ErrorBoundary";
import { Analytics } from "./Services/Analytics";


const loadScript = (src) =>
    new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) {
        console.log("document.querySelector found")
        return resolve()
      }
      const script = document.createElement('script')
      script.src = src
      script.crossorigin=""
      script.onload = () => resolve()
      script.onerror = (err) => reject(err)
      document.body.appendChild(script)
      console.log("load script done")
})

// const id = "260456464637-vngo9ie5u1c059af046n8lcrt51benuu.apps.googleusercontent.com"
const id = "260456464637-9oga8ua9umcqruq4f56siciepsioc9vd.apps.googleusercontent.com"

const initGoogleSignin = async () => {
    console.log('loading google auth...')
    loadScript('https://accounts.google.com/gsi/client').then(() => {
        console.log('loaded google auth')
        /*global google*/
        if(window.google){
            console.log('found window google auth')
            google.accounts.id.initialize({
                client_id: id,
                callback: handleCredentialResponse,
            })
        }else{
            console.error("Google auth script is not defined")
        }
    })
}

function handleCredentialResponse(response) {
  handleGoogleConnect(response)
  console.log("handleCredentialResponse ", response);
}

const getBoardIdfromPath = (path) => {
    console.log("getBoardIdfromPath", path)
    var parts = path.split('/')
    if (parts.length >= 3 && parts[1] === "board") {
        let boardId = parts[2]
        // console.log("found boardid", boardId)
        return boardId;
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 0, }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const DashingApp = observer(() => {

    const location = useLocation();
    const urlParams = useParams();
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(fbAuth);
    
    // initGoogleSignin()
    // initAuthChangeListner()

    // If user, load url board, or load last board, of not, cerate board
    // const loadOrCreateBoard = () => {
    //     if (BoardStore.boardId != null) {
    //         getBoard(BoardStore.boardId).then(
    //             (boardFromDb) => BoardStore.init(boardFromDb),
    //             (d) => {
    //                 if (BoardStore.boardId == BoardStore.board?.id) return;
    //                 window.alert("Looks like you don't have access to this board")
    //                 console.log("failed to get board", BoardStore.boardId, "user id", UserStore.currentUser?.uid, d)
    //                 // TODO: error tried to load private board (break loop)
    //             })
    //     } else {
    //         BoardStore.init();
    //         BoardStore.boardId = BoardStore.board.id
    //         navigate(`/board/${BoardStore.board.id}`);
    //         console.log("navigated to new board", BoardStore.board.id)
    //     }
    // }

    const loadURLBoard = () => {
        getBoard(BoardStore.boardId).then(
            (boardFromDb) => BoardStore.init(boardFromDb),
            (d) => {
                if (BoardStore.boardId === BoardStore.board?.id) return;
                // BoardStore.canvasMode = 'home'
                Analytics.trackCanvasMode({mode: 'home'})
                window.alert("Looks like you don't have access to this board")
                // console.log("failed to get board", BoardStore.boardId, "user id", UserStore.userId, d)
            })
    }

    const createNewBoard = () =>{
        BoardStore.init();
        BoardStore.boardId = BoardStore.board.id
        navigate(`/board/${BoardStore.board.id}`);
        console.log("navigated to new board", BoardStore.board.id)
    }

    // onAuthChange(() => loadOrCreateBoard())

    const onAppFirstLoad = async () => {
        // console.log("app first load...")
        initAuthChangeListner()
        return initGoogleSignin()
    }

    // useEffect(() => {
    //     onAppFirstLoad()
    // }, [])


    useEffect(() => {
        onAppFirstLoad().then(_ => {
        let pathBoardId = getBoardIdfromPath(location.pathname)
        if(!loading){ // loading from local storage and validating with FB
            if(!user){ 
                if(location.pathname === '/login'){
                    // BoardStore.canvasMode = 'home';
                    // Analytics.trackCanvasMode({mode: 'home'})
                } else {
                    console.log("No User found, anonSignIn")
                    anonSignIn();
                }
                return
            }else{
                // if (pathBoardId != null && pathBoardId === BoardStore.boardId) return;
                BoardsList.refresh();
                if(pathBoardId){
                    BoardStore.boardId = pathBoardId;
                    loadURLBoard();
                }else{
                    createNewBoard();
                } 
            } 
        }         })
    }, [loading, location, user])


    useEffect(() => {
             if(error) console.log("auth error", error)  
    }, [error])

    // useEffect(() => {
    //     let pathBoardId = getBoardIdfromPath(location.pathname)
    //     if (pathBoardId != null && pathBoardId === BoardStore.boardId) return;
    //     if (UserStore.currentUser == null) {
    //         BoardStore.boardId = pathBoardId
    //         anonSignIn();
    //     }

    // }, [
    //     location,
    // ]);

    return <>
        <ErrorBoundary>
            <AppContainer>
                <TabPanel value={BoardStore.canvasMode} index={'home'}>
                    <ErrorBoundary>
                    <HomeDrawers>
                        <HomeSection />
                    </HomeDrawers>
                    </ErrorBoundary>
                </TabPanel>
                <TabPanel value={BoardStore.canvasMode} index={'doc'}>
                    <ErrorBoundary>
                    {BoardStore.board ? <PlanningDoc/> : ''}
                    </ErrorBoundary>
                </TabPanel>
                <TabPanel value={BoardStore.canvasMode} index={'design'}>
                    <ErrorBoundary>
                    <LeftDrawer />
                            {BoardStore.board ? <BoardCanvas board={BoardStore.board} /> : ''}                        
                    <RightDrawer />
                    </ErrorBoundary>
                </TabPanel>
            </AppContainer>
            </ErrorBoundary>
    </>
})

export default DashingApp;





