import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
// import EditorFactory from './Editors/EditorFactory';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import { observer } from 'mobx-react-lite';
import { BoardStore } from '../Stores/Stores';
import { Drawer, drawerWidth } from "./AppTopBar";
import ChartEditor, { BoardStyleEditor, ThemeEditor } from '../Components/Editors/EditorFactory';
import EditDataSection from '../Components/Editors/EditorSections/EditDataSection';
import { Analytics } from '../Services/Analytics';
const MenuTabs = styled(Tabs)({
  // borderBottom: '1px solid #e8e8e8',
  // borderBottom: 0,

  minHeight: 20,
  '& .MuiTabs-indicator': {
    backgroundColor: '#888',
    // width: '100%'
    display: 'none'
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-evenly',
  },
});

const MenuTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  // width: '48px',
  height: '3em',
  minHeight: 0,
  margin: 1,
  padding: 0,
  fontSize: 12,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    // color: '#1890ff',
    color: '#000',
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
    paddingBottom: 2,
    fontSize: 12,
  },
  '&.Mui-focusVisible': {
    // backgroundColor: '#d1eaff',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 0, }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function getIdProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ViewModeMessage = observer(() => {
  return <><Box sx={{ display: BoardStore.editMode ? 'none' : 'flex', 
                    alignItems: 'center', justifyContent: 'center',
                    flexGrow: 1, 
                    marginRight: 3,
                    marginY:2, 
                    color: 'rgba(64,64,64,0.87)', }}>
    {/* <Box sx={{ width: 40, height: 40, border: '1px solid', padding: 1, borderRadius: '50%', color: 'rgba(64,64,64,0.5)', }}>
      <EditOffOutlinedIcon sx={{ fontSize: 24, }} />
    </Box> */}
    {/* <Box sx={{
      // fontSize: 18,
      margin:0,
      fontWeight: 'lighter',
      display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px', paddingY: 0, 
    }}> */}
            <EditOffOutlinedIcon sx={{fontSize: 16,  marginRight: 1, color: 'rgba(100,100,100,0.8)'}} />
            <Typography sx={{fontSize: 14}} >View Mode</Typography>
      {/* </Box> */}
  </Box>
      {/* <Divider sx={{width:'100%', marginBottom: 1, height: 0 }}></Divider> */}
  </>



})


const LeftDrawer = observer(() => {
  // const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    BoardStore.editorTab = newValue
    Analytics.trackClickEditorTab({tab: newValue})
  };

  return (<>
    <Drawer variant="permanent"
      anchor="left"
      sx={{
        // width: drawerWidth / 2,
        // height: '100vh',
        flexShrink: 0,
        backgroundColor: 'rgba(235,236,240,1)',
        
        
        // [`& .MuiDrawer-paper`]: { width: drawerWidth + (BoardStore.editorTab === 'data' ? drawerWidth * 0.5 : 0), boxSizing: 'border-box' },
        [`& .MuiDrawer-paper`]: { width: drawerWidth , boxSizing: 'border-box', backgroundColor: 'rgba(235,236,240,1)', },
        // [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }} >

      {/* <Toolbar disableGutters sx={{height: '48px', minHeight: 'unset !important', display: 'flex'}}/> */}
      {/* <Box sx={{height}}></Box> */}
      <Box sx={{ width: '100%', height: '100%', marginTop: '50px', backgroundColor: 'white',}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider',  }}>
          <MenuTabs value={BoardStore.editorTab} onChange={handleChange} aria-label="edit tabs" sx={{ justifyContent: 'space-evenly', display: 'flex' }}>
            {/* <MenuTabs value={value} onChange={handleChange} aria-label="edit tabs" sx={{justifyContent: 'space-evenly', display: 'flex'}}> */}
            <MenuTab label="Theme" value={'theme'}   {...getIdProps("theme")} />
            <MenuTab label="Board" value={'board'}   {...getIdProps("board")} />
            <MenuTab label="Element" value={'element'} {...getIdProps("element")} />
            <MenuTab label="Data" value={'data'}    {...getIdProps("data")} />
          </MenuTabs>
        </Box>

        {BoardStore.board && <Box sx={{marginBottom: '300px', overflow: 'scroll'}}>
          <TabPanel value={BoardStore.editorTab} index={"theme"}>
            <ViewModeMessage />
            <ThemeEditor />
          </TabPanel>
          <TabPanel value={BoardStore.editorTab} index={"board"}>
            <ViewModeMessage />
            <BoardStyleEditor />
          </TabPanel>
          <TabPanel value={BoardStore.editorTab} index={"element"}>
            <ViewModeMessage />
            <ChartEditor />
          </TabPanel>
          <TabPanel value={BoardStore.editorTab} index={"data"}>

            {/* <EditorSection key="edit-data" cardid='edit-data' cardtitle='Edit Chart Data' accordionDetailsProps={{ sx: { padding: 0 } }}> */}
            {BoardStore.currentElem?.chartData ?

              <>
                <ViewModeMessage />
                <EditDataSection></EditDataSection>
              </>

              : <Typography color="textSecondary" sx={{ fontSize: 12, fontStyle: 'italic', textAlign: 'center', my: '2em' }}>The selected element has no data series</Typography>}
            {/* </EditorSection> */}
          </TabPanel>
        </Box>}
      </Box>
    </Drawer>
  </>
  );
})

export default LeftDrawer;