import React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import NumberInputWidget from '../EditorWidgets/NumberInputWidget';
import { PropertyColorWidget } from '../EditorWidgets/PropertyColorWidget';
import { PropertyToggleWidget } from '../EditorWidgets/PropertyToggleWidget';
import { observer } from 'mobx-react-lite';

const EditGridSection = observer(({storeObj, editType}) => {

  return <>

    <PropertyToggleWidget isChecked={storeObj.chartOptions.scales.x.grid.display} 
                          onCheck={(e) => BoardStore.toggleGrid({axis: 'x', editMode: editType})} 
                          label={"Show X Grid lines"}/>
    
    <PropertyToggleWidget isChecked={storeObj.chartOptions.scales.y.grid.display} 
                          onCheck={(e) => {BoardStore.toggleGrid({axis: 'y', editMode: editType})}} 
                          label={"Show Y Grid lines"}/>

    <PropertyColorWidget colorValue={storeObj.chartOptions.scales.y.grid.color}
                         onValueChange={(color) => BoardStore.updateGridColor({color, editMode: editType})}
                         label={"Grid Line Color"} />

    <NumberInputWidget  numericValue={storeObj.chartOptions.scales.y.grid.borderWidth}
                        onValueChange={(e) => BoardStore.updateGridBorderWidth({width: e.target.value, editMode: editType})}
                        label={"Line Thickness"}
                        inputAdornment={"px"} />
  </>
})

export default EditGridSection;


