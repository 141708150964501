import { Alert } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Rnd } from "react-rnd";
import '../../index.css';
import { drawerWidth } from '../../Layout/AppTopBar';
import TransitionAlerts from '../../Layout/DissmissableAlert';
import { ContainerFactory } from '../../Stores/Models';
import { BoardStore, UserStore } from '../../Stores/Stores';
import { CopySnackbar } from '../Elements/CopySnackbar';
import AddChartMenu from './AddChartMenu';
import BoardTabs from './BoardTabs';

const BoardCanvas = observer(({ board }) => {
  
  const [open, setOpen] = React.useState(false);
  const didMount = useRef(false);
  const [isMetaPressed, setIsMetaPressed] = React.useState(false);
  const [isResizing, setIsResizing] = React.useState(false);

  useEffect(() => {
    if (!didMount.current) {
      // setBoardZoomFuncs()
    } else didMount.current = true;

  }, [board.updatedAt, board.id, board.height, board.width, board.scale, board.positionX, board.positionY]);

  // function SlideTransition(props) {
  //   return <Slide {...props} direction="down" />;
  // }

  const handleKeyUp = (event) => {
    if ((event.ctrlKey || event.metaKey || event.key === "Meta" || event.key === "Control")) {
      setIsMetaPressed(false)
    }
  }

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey)) {
      setIsMetaPressed(true)

      if (charCode === 's') {
        BoardStore.saveStore();
        event.preventDefault();
      } else if (charCode === 'c') {
        BoardStore.onCopy();
        setOpen(true);
      } else if (charCode === 'v') {
        BoardStore.onPaste();
        event.preventDefault();
        event.stopPropagation();
      } else if (charCode === 'z') {
        BoardStore.loadStore();
      } else if (event.key === '-') {
        BoardStore.updateBoardScaleDown()
        event.preventDefault();
      } else if (event.key === '=') {
        BoardStore.updateBoardScaleUp()
        event.preventDefault();
      } else if (event.key === 'd') {
        BoardStore.onCopy();
        BoardStore.onPaste();
        event.preventDefault();
        event.stopPropagation();
        setIsMetaPressed(false)
      }
      else if ((event.key === "Backspace")) {
        if (BoardStore.currentElem) BoardStore.deleteChart(BoardStore.currentElem);
      }
    } else if ((event.key === "Escape")) {
      BoardStore.unsetActiveChart();
      setIsMetaPressed(false)
    }

    //  else if (event.key == 'Escape') {
    // }
    else if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
      if(BoardStore.currentElem){
        event.preventDefault();
        event.stopPropagation();
        BoardStore.moveElementLocation(event.key);
      }
    }
  }

  const onBoardClick = (e) => {
    BoardStore.unsetActiveChart()
    BoardStore.editorTab = "board"
  }

  return (
    BoardStore.isRelaoding ? '' :

      // container
      <Box sx={{ backgroundColor: 'rgba(235,236,240,1)'}}>

        <AppBar position="fixed" sx={{ marginTop: '48px', bgcolor: 'white' }}>
          <Box sx={{ display: 'flex', justifySelf: 'center', alignSelf: 'center', flexDirection: 'column'}}>
            <AddChartMenu />
          </Box>
        {/* <Alert sx={{width: '500px', position:'fixed', left: '500px', top: '84px'}} severity="warning">This is a warning alert — check it out!</Alert> */}
        {UserStore.isAnonymous && BoardStore.elementsCount > 1 && <TransitionAlerts sx={{}} severity="warning" txt={"You are not signed in, your work might not be saved"}></TransitionAlerts>}
        </AppBar>

        <Box id="board-container"
          component={"div"}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onMouseLeave={handleKeyUp}

          //  onScroll={(e) => onScroll(e)}
          tabIndex={0}
          sx={{
            position: "absolute",
            backgroundColor: 'rgba(235,236,240,1)',
            padding: 0,
            width: `calc(${BoardStore.board.width}px + 80vh)`,
            minWidth: '100vw', 
            marginBottom: 200,
            paddingX: `${drawerWidth}px`,
            
            height: `calc(${BoardStore.board.height}px + 80vh)`,
            paddingY: '48px',

            marginTop: `${200 *BoardStore.board.scale }px)`,
            overflow: 'auto',
            left: 0,
            right: 0
            
          }}>


          <div tabIndex={0} style={{
            transform: `scale(${BoardStore.board.scale})`,
            transformOrigin: '50% 0',
            height: '100%', 
            // marginLeft: '200px',
            // marginRight: '200px',
            minWidth: '100vw', 
            margin: '0 auto',
            // width: `${BoardStore.board.width * 2}px`,
          }}>
            {/* <div style={{ transform: `scale(${BoardStore.board.scale})`, marginLeft: drawerWidth, height: window.innerHeight, marginTop: 15, marginBottom: 50 }} > */}

            <Rnd
              // disableDragging={!isMetaPressed}
              disableDragging={true}
              enableResizing={BoardStore.editMode}
              scale={BoardStore.board.scale}
              position={{
                x: BoardStore.board.positionX,
                y: BoardStore.board.positionY,
              }}
              size={{
                width: BoardStore.board.width,
                height: BoardStore.board.height + 36, 
                // height: BoardStore.board.height, 
              }}

              bounds="#board-container"

              onDragStop={(e, d) => {
                BoardStore.updateBoardLocation(d);
              }}

              onResizeStart={() => setIsResizing(true)}
              onResizeStop={() => setIsResizing(false)}

              onResize={(e, direction, ref, delta, position) => {
                // console.log("direction", direction, "ref", ref, "delta", delta, "position", position)
                // chart.chartSettings.width = ref.offsetWidth;
                // chart.chartSettings.height = ref.offsetHeight;
                // e.stopPropagation();
                BoardStore.updateBoardDimentions(ref.offsetWidth, 'width')
                BoardStore.updateBoardDimentions(ref.offsetHeight, 'height')
                BoardStore.updateBoardLocation(position);
              }}

              style={{
                // overflow: "hidden",
                // marginBottom: '200px',
                padding: 0,
                zIndex: 0,

                // marginTop: 36,
              }}
            >

              <BoardTabs />
              <Box
                onMouseDown={onBoardClick}
                className="board"
                id="main-board"

                sx={{
                  backgroundColor: BoardStore.board.backgroundColor,
                  margin: 0,
                  padding: 0,
                  width: BoardStore.board.width,
                  height: BoardStore.board.height,

                  // width: '100%',
                  // height: '100%',
                  border: '1px solid #ccc',
                  // borderRadius: '1px 0 3px 4px;',
                  // background: 'rgba(100, 100, 100, 0.01)',
                  backgroundImage: BoardStore.board.dottedBackground ? 'radial-gradient(#ccc 1px, transparent 0)' : 'none',
                  backgroundSize: '20px 20px',
                  backgroundPosition: '-10px -10px',
                }} >

                {isResizing ?
                  <Box sx={{
                    position: 'absolute',
                    left: "50%",
                    zIndex: 100,
                    transform: "translate(-50%, 0)"
                  }}>
                    <Box component={"span"} sx={{ fontWeight: 'bold', fontSize: 48, color: 'rgba(64,64,64,0.6)', }}>{BoardStore.board.width}</Box>
                    <Box component={"span"} sx={{ marginX: 2, fontWeight: 'bold', fontSize: 36, color: 'rgba(64,64,64,0.6)', }}>X</Box>
                    <Box component={"span"} sx={{ fontWeight: 'bold', fontSize: 48, color: 'rgba(64,64,64,0.6)', }}>{BoardStore.board.height}</Box>
                  </Box>
                  : ''}

                {
                   BoardStore.currentTab?.charts.map((chart) => {
                    return chart ? ContainerFactory(chart.key, chart) : ''
                  })
                }

              </Box>
              
            </Rnd>
          </div>
          {CopySnackbar(open, setOpen)}
        </Box>
      </Box>
  );
}
);

export default BoardCanvas;


