
import Chart from 'chart.js/auto';
import ChartDataLabels from "chartjs-plugin-datalabels";
// import 'chartjs-plugin-dragdata';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { ChartBoxPlugin } from '../../Utils/ChartClickPlugin';
import { ChartJSdragDataPlugin } from '../../Utils/DragDataPlugin';
import ElementContainer from './ElementContainer';
// Chart.register(...registerables)
import * as React from 'react';

import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

Chart.register(ChartjsPluginStacked100);
Chart.register(ChartDataLabels);

const backgroundPlugin = (color) => {
  return {

    id: 'custom_canvas_background_color',
    beforeDraw: (chart, args, options) => {
      const ctx = chart.canvas.getContext('2d');
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = color || 'white';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  }
};

// Hook
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}




// const TooltipBox = observer(({ chart, boxType, hoverX, hoverY }) => {

//   if (!(chart && chart.isActive)) {
//     return;
//   }
//   // useEffect(()=> {console.log("hover changed")}, [hoverX, hoverY])
//   let isMac = window.navigator.platform.includes('Mac')
//   let metaKey = isMac ? '⌘' : 'Ctrl'
//   const dataMessage = `${metaKey} + Drag data point`

//   const tooltipText = !boxType ? '' : boxType === "chartArea" ? dataMessage : "Edit " + boxType.charAt(0).toUpperCase() + boxType.slice(1).replace('-', ' ');

//   return chart && chart.isActive &&

//     <Box sx={{
//       color: 'white',
//       border: '1px solid rgba(64,64,64,1)',
//       borderRadius: '0 6px 6px 6px',
//       paddingY: 0.5,
//       paddingX: 1.5,
//       fontSize: 12,
//       textAlign: 'left',
//       backgroundColor: 'rgba(64,64,64,1)',
//       zIndex: 10000,
//       userSelect: "none",
//       position: "absolute",
//       left: hoverX + chart.chartSettings.positionX + 25,
//       top: hoverY + chart.chartSettings.positionY + 50,

//     }}>
//       {tooltipText}
//     </Box>
// })


const ChartContainer = observer(({ chart }) => {

  const chartReference = useRef(null);
  const [chartjsObj, setChartjsObj] = useState(null);
  const prevVersion = usePrevious(chart.chartSettings.renderedAt);
  // const prevUpdatedAt = usePrevious(chart.chartSettings.updatedAt);
  // const prevDragData = usePrevious(chart.chartOptions.plugins.dragData);

  const [clickedBox, setClickedBox] = React.useState(null);

  // const [hoveredBox, setHoveredBox] = React.useState(false);
  const [hoverBoxType, setHoverBoxType] = React.useState(null);
  const [hoverX, setHoverX] = React.useState(null);
  const [hoverY, setHoverY] = React.useState(null);

  // const [isMouseOver, setIsMouseOver] = React.useState(null);

  // const [boxType, setBoxType] = React.useState(null);
  // const handleTooltipClose = () => {
  //   setEditorOpen(null);
  // };

  const onBoxClick = (boxType) => {
    setClickedBox(boxType);
    // setIsMouseOver(false);
  };

  const onBoxHover = (boxType, box, hoverX, hoverY) => {
    // setBoxType(boxType); 
    setHoverBoxType(boxType);
    // setHoveredBox(box);
    setHoverX(hoverX)
    setHoverY(hoverY)
  };


  useEffect(() => {
    // console.log("chart container use effect")
    try {
      // todo: remove after turning json form server to class obj chart type
      // chart.chartOptions.plugins.tooltip = BoardStore.editMode && chart.chartType !== 'pie' ? getTooltip() : {enabled: false}


      var pluginsList = [ backgroundPlugin(chart.chartSettings.backgroundColor),
                          ChartJSdragDataPlugin(),
                          ChartBoxPlugin({
                            onBoxClick: (boxType, box, hoverX, hoverY) => onBoxClick(boxType),
                            onBoxHover: (boxType, box, hoverX, hoverY) => onBoxHover(boxType, box, hoverX, hoverY)
                          }),]

      if (chartjsObj == null) { // init first run
        setChartjsObj(new Chart(chartReference.current.getContext("2d"), {
          type: chart.chartType,
          data: chart.chartData,
          options: chart.chartOptions,
          plugins: pluginsList,
        }))
        // console.log("created chart...")

      } else if (prevVersion < chart.chartSettings.renderedAt) {
        // console.log("destroying chart...")
        chartjsObj.destroy();
        setChartjsObj(new Chart(chartReference.current.getContext("2d"), {
          type: chart.chartType,
          data: chart.chartData,
          options: chart.chartOptions,
          plugins: pluginsList, // todo move to settings
        }))
        // console.log("destroy and updagin chart")
        chartjsObj.update()
        // console.log("updated chart...")
      }
      // else if (prevUpdatedAt < chart.chartSettings.updatedAt) {
      //   chartjsObj.update();
      // } 
      else {
        // console.log("simple chart update")
        chartjsObj.update();
      }
    } catch (e) {
      // console.error("FAILED TO UPDATE CHART")
      // console.log(e)
      // ignore, sometimes setChartjsObj takes time to run 
    }
  }, [chart.chartSettings.renderedAt, chart.chartSettings.updatedAt]
  )

  return <>

    {/* {chart.isActive && isMouseOver && 
      <TooltipBox chart={chart} boxType={hoverBoxType} hoverX={hoverX} hoverY={hoverY} />
    }

    <Box onMouseEnter={() => { setIsMouseOver(true) }}
         onMouseLeave={() =>    { setIsMouseOver(false) }}> */}


      <ElementContainer chart={chart} id={"ElementContainer-" + chart.key} quickEditor={clickedBox} isHoverEditable={hoverBoxType} 
      hoverX={hoverX} hoverY={hoverY} 
      >

          <canvas
            id={chart.key}
            ref={chartReference}
            style={{ position: "relative" }}
            data-sl="canvas-mq" // screen recoding 
            />
      </ElementContainer>
    {/* </Box> */}
  </>


});

export default ChartContainer;
