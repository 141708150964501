
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { observer } from 'mobx-react-lite';
import DatePickerWidget from '../Editors/EditorWidgets/DatePickerWidget';
import ElementContainer from './ElementContainer';
import React, { useEffect, useRef, useState } from 'react';

const DateFilterContainer = observer(({ chart }) => {
  const [quickEditor, setQuickEditor] = useState(null);
  const [isHoverEditable, setIsHoverEditable] = useState(false);

  return <ElementContainer chart={chart} quickEditor={quickEditor} isHoverEditable={isHoverEditable} >
    <Box
      sx={
        {          
          backgroundColor: chart.chartSettings.backgroundColor,
          position: "relative",
          height: '100%',
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // TODO: add padding config
          padding: `${chart.chartOptions.layout.padding.top}px ${chart.chartOptions.layout.padding.right}px ${chart.chartOptions.layout.padding.bottom}px ${chart.chartOptions.layout.padding.left}px`
        }
      }>
        {chart.chartOptions.plugins.title.display ? 
      <Box
        display="flex"        
        onClick={(e) => {setQuickEditor('title'); e.stopPropagation()}}
        onMouseEnter={(e) => {setIsHoverEditable(true)}}
        onMouseLeave={(e) => {setIsHoverEditable(false)}}
        sx={{
          marginX: 2,
          marginBottom: 0.5,
          display: chart.chartOptions.plugins.title.display,
          fontWeight: chart.chartOptions.plugins.title.font.weight,
          fontSize: chart.chartOptions.plugins.title.font.size,
          fontStyle: chart.chartOptions.plugins.title.font.style,
          fontFamily: chart.chartOptions.plugins.title.font.family,
          alignSelf: chart.chartOptions.plugins.title.align, //'center', 
          color: chart.chartOptions.plugins.title.color, //'center', 
        }}> {chart.chartOptions.plugins.title.text }
        </Box>
        : ''}
 
      <Stack direction="row" spacing={3} sx={{    justifyContent: 'space-between', marginRight: 2}}>
        <Box sx={{display: chart.chartOptions.plugins.startDatePicker.display ? 'inherit' : 'none'}}>

        <DatePickerWidget pluginName={"startDate"} 
                          dateLabel={chart.chartOptions.plugins.startDatePicker} 
                          dateObj={chart.chartOptions.plugins.startDate}></DatePickerWidget>
                          </Box>
        <DatePickerWidget pluginName={"endDate"} 
                          dateLabel={chart.chartOptions.plugins.endDatePicker}
                          dateObj={chart.chartOptions.plugins.endDate}></DatePickerWidget>
      </Stack>
    </Box>
  </ElementContainer>

});

export default DateFilterContainer;


