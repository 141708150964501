import ClearIcon from '@mui/icons-material/Clear';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React from 'react';
import { BoardStore } from '../../Stores/Stores';
import HideableInputWidget from '../Editors/EditorWidgets/HideableInputWidget';
import NumberInputWidget from '../Editors/EditorWidgets/NumberInputWidget';
import TextStyleWidget from '../Editors/EditorWidgets/TextEditWidget';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FormGroup } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
// import React from 'react';
import FontSelectWidget from '../Editors/EditorWidgets/FontSelectWidget';
import FontStyleWidget from '../Editors/EditorWidgets/FontStyleWidget';
import InputColorWidget from '../Editors/EditorWidgets/InputColorWidget';
// import LabelTypleSelectWidget from '../Editors/EditorWidgets/LabelTypleSelectWidget';
import LegendAlignmentWidget from '../Editors/EditorWidgets/LegendAlignmentWidget';
import LegendPositionWidget from '../Editors/EditorWidgets/LegendPositionWidget';
import DSMinMaxFields, { AxisMinMaxFields } from '../Editors/EditorWidgets/MinMaxFields';
import TickEditWidget from '../Editors/EditorWidgets/TickEditWidget';
// import { ACTIVE_BOXSHADOW } from './ElementContainer';
// import {Grow as Transition} from '@mui/material';
import { Fade as Transition } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { EditDataProps } from '../Editors/EditorSections/EditDataSection';

const borderSettings = {
  position: "absolute",
  backgroundColor: 'white',
  zIndex: 'modal',
  border: '1px solid #ccc', 
  // boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
  borderRadius: 2, 
  borderWidth: 1,
}

export const TitleQuickEditTitle = observer(({chart, titleType, onClose}) => {
  let align = chart.chartOptions.plugins[titleType].align
  let leftAlign = align === 'start' ? 0 : align === 'center' ? chart.chartSettings.width / 2 - 200 : chart.chartSettings.width - 400

  return <Transition in={true}>
    <Box component={"div"}
      sx={{
        ...borderSettings,
        paddingX: 2,
        paddingY: 0.5,
        left: chart.chartSettings.positionX + leftAlign,
        top: chart.chartSettings.positionY - 10,
      }}>

      <IconButton aria-label="delete" size="small"
        onClick={(event) => onClose()}
        disableFocusRipple
        disableRipple
        sx={{ position: 'absolute', zIndex: 'modal', right: 0, top: 1, paddingLeft: 0, fontSize: 14 }}
      >
        <ClearIcon fontSize="inherit" sx={{ color: 'rgba(204, 204, 204, 1)' }} /></IconButton>
      <Stack direction="row" spacing={0} sx={{ justifyContent: 'flex-start', paddingRight: 1 }}>

        <HideableInputWidget titleObj={chart.chartOptions.plugins[titleType]}
          titleType={titleType} hideLabel={true} />

        <Divider flexItem orientation="vertical" sx={{ marginLeft: 0.5, my: 1 }} />

        <TextStyleWidget id="blas" titleObj={chart.chartOptions.plugins[titleType]} titleType={titleType}
          sx={{ maxWidth: '50px', border: '1px solid red' }} />
        <NumberInputWidget
          style={{ paddingRight: 1, alignSelf: 'flex-start' }}
          numericValue={chart.chartOptions.plugins[titleType].font.size}
          onValueChange={(e) => BoardStore.setFontSize({ fontSize: e.target.value, titleType })}
          label={""}
          inputAdornment={"px"} />
      </Stack>
    </Box>
  </Transition>
})

export const AxisQuickEditWidget = observer(({chart, axis, onClose})  => {

  const width = 200;
  return <Transition in={true}>

    <Box component={"div"}
      sx={{
        // position: "absolute",
        // backgroundColor: 'white',
        // zIndex: '1500',
        // border: '1px solid rgba(204, 204, 204, 1)', borderRadius: 2, borderWidth: 2, 
        ...borderSettings,
        margin: 0.5,
        padding: 1,
        width: width,
        overflow: 'auto',
        left: chart.chartSettings.positionX < width ? chart.chartSettings.positionX + chart.chartSettings.width + 20 : chart.chartSettings.positionX - width - 20,
        top: chart.chartSettings.positionY + chart.chartSettings.height / 2 - 180,
      }}>

      <IconButton aria-label="delete" size="small"
        onClick={(event) => onClose()}
        disableFocusRipple
        disableRipple
        sx={{ position: 'absolute', zIndex: 10001, right: 0, top: 0, fontSize: 14 }}
      >
        <ClearIcon fontSize="inherit" sx={{ color: 'rgba(204, 204, 204, 1)' }} /></IconButton>

      <Stack direction="column" spacing={0} sx={{ justifyContent: 'space-between', paddingX: 1, width: '100%', }}>

        <Typography sx={{ fontSize: 14, textAlign: 'center', color: 'rgba(64, 64, 64, 0.87)', marginTop: 1 }}>{axis.toUpperCase()} Axis </Typography>
        {/* <Divider sx={{ marginY: 0, border: '0', width: '90%', marginX: 'auto', fontSize: 14, color: 'rgba(64,64,64,0.87)' }}>Label</Divider> */}

        <HideableInputWidget titleObj={chart.chartOptions.scales[axis].title} titleType={axis} hideLabel={false} />
        {/* <Divider flexItem orientation="vertical" sx={{ marginLeft: 0.5, my: 1 }} /> */}
        <Box></Box>
        <TextStyleWidget titleObj={chart.chartOptions.scales[axis].title} titleType={axis} />
        <FontSelectWidget titleObj={chart.chartOptions.scales[axis].title} titleType={axis} />
      </Stack>

      <Divider sx={{ marginY: 2, width: '90%', marginX: 'auto', fontSize: 14, color: 'rgba(64,64,64,0.87)' }}>Ticks</Divider>
      <Stack direction="column" spacing={0}

        sx={{
          justifyContent: 'space-between',
          paddingX: 1,
          // width: '140px',
          zIndex: 'modal'
        }}>
        {/* <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)', marginTop: 0 }}>Ticks</Typography> */}
        <TickEditWidget axis={axis} storeObj={chart} noLabel={false} />

      </Stack>
      {/* {chart.chartOptions.scales[axis].type == 'linear' ?  */}
      {/* <> */}

      {chart.chartOptions.scales[axis].type === 'linear' && <>
        <FormGroup >

          {/* <Divider sx={{ marginY: 2, width: '90%', marginX: 'auto' }}></Divider> */}
          <Divider sx={{ marginY: 2, width: '90%', marginX: 'auto', fontSize: 14, color: 'rgba(64,64,64,0.87)' }}>Range</Divider>

          <Stack direction="column" spacing={0} sx={{ justifyContent: 'space-between', paddingLeft: 2, paddingRight: 4, zIndex: 'modal' }}>
            <FormControlLabel
              control={<ButtonGroup disableElevation variant="contained">
                <AxisMinMaxFields axis={axis} />
              </ButtonGroup>}
              sx={{ justifyContent: 'space-between', marginLeft: 0, paddingX: 0, alignItems: 'baseline' }}
              labelPlacement="start"
              label={<Typography color="textSecondary" sx={{ fontSize: 11 }}>Range</Typography>} />

          </Stack>
        </FormGroup>
      </>
      }
      {/* // </> : ''} */}
    </Box>
  </Transition>
})

export const DataQuickEditWidget = observer(({chart, onClose}) => {
  return <Transition in={true}>
    <Box component={"div"}
      sx={{
        // position: "absolute",
        // backgroundColor: 'white',
        // zIndex: 'modal',
        // border: '1px solid rgba(204, 204, 204, 1)', borderRadius: 2, borderWidth: 2, 
        ...borderSettings,

        paddingY: 0.5,
        paddingX: 0.5,
        // width: '360px',
        // paddingLeft: 1,
        // paddingRight: 1.8,
        left: chart.chartSettings.positionX + chart.chartSettings.width / 2 - 180,
        top: chart.chartSettings.positionY + chart.chartSettings.height + 50,
      }}>
      <IconButton aria-label="delete" size="small"
        onClick={(event) => onClose()}
        disableFocusRipple
        disableRipple
        sx={{ position: 'absolute', zIndex: 10001, right: 0, top: 0, fontSize: 14 }}
      >
        <ClearIcon fontSize="inherit" sx={{ color: 'rgba(204, 204, 204, 1)' }} /></IconButton>
      <Typography sx={{ fontSize: 14, textAlign: 'center', color: 'rgba(64, 64, 64, 0.87)', marginBottom: 1 }}>Edit Data</Typography>
      <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', paddingRight: 1 }}>
      

        {/* <Stack direction="column" spacing={3} sx={{ justifyContent: 'flex-start', paddingRight: 0, marginRight: 1 }}>

          <FormGroup >
            <FormControlLabel
              control={<ButtonGroup disableElevation variant="contained">
                <IconButton aria-label="add_datapoint" disableFocusRipple disableRipple sx={{ transform: 'scale(0.8)', width: '20px' }} onClick={(event) => BoardStore.addDataset()}> <AddCircleOutlineIcon /> </IconButton>
                <IconButton aria-label="remove_datapoint" disableFocusRipple disableRipple sx={{ transform: 'scale(0.8)', width: '20px' }} onClick={(event) => BoardStore.removeDataset()}> <RemoveCircleOutlineIcon /> </IconButton>
              </ButtonGroup>}
              sx={{ justifyContent: 'space-between', marginLeft: 0, paddingLeft: 1, height: '25px', width: 140 }}
              labelPlacement="start"
              label={<Typography color="textSecondary" sx={{ fontSize: 12, marginRight: 0 }}>Data Series</Typography>} />
          </FormGroup >

          <FormGroup >
            <FormControlLabel
              control={<ButtonGroup disableElevation variant="contained" onClick={() => { }}>
                <IconButton aria-label="add_datapoint" disableFocusRipple disableRipple sx={{ transform: 'scale(0.8)', width: '20px' }} onClick={(event) => BoardStore.addDatapoint()}> <AddCircleOutlineIcon /> </IconButton>
                <IconButton aria-label="remove_datapoint" disableFocusRipple disableRipple sx={{ transform: 'scale(0.8)', width: '20px' }} onClick={(event) => BoardStore.deleteDatapoint()}> <RemoveCircleOutlineIcon /> </IconButton>
              </ButtonGroup>}
              sx={{ justifyContent: 'space-between', marginLeft: 0, paddingLeft: 1, height: '25px', width: 140 }}
              labelPlacement="start"
              label={<Typography color="textSecondary" sx={{ fontSize: 12, marginRight: 0.5 }}>Data Point</Typography>} />
          </FormGroup>

          <FormGroup sx={{ }}>
            <LabelTypleSelectWidget />
          </FormGroup>
        </Stack> */}
        
            <Box sx={{width: '200px'}}>
            <EditDataProps/>
              </Box>
        <Divider flexItem orientation="vertical" sx={{ paddingLeft: 0, my: 1 }} />

        <FormGroup sx={{ paddingX: 0, display: 'flex', }}>
          
          {chart.chartData.datasets.map((ds, dsIdx) =>

            <Stack direction="row" spacing={1} sx={{ paddingX: 0, marginLeft: 0}} key={`dsidx-qegroup-${dsIdx}`} >
              
              <Box sx={{ width: '100px', height: '25px' }}>
                {/* <InputColorWidget dsIdx={dsIdx} key={`dsidx-qe-${dsIdx}`} /> */}
                <InputColorWidget txt={chart.chartData.datasets[dsIdx].label}
                  updateTxt={(e) => BoardStore.updateSeriesName(dsIdx, e.target.value)}
                  color={chart.chartData.datasets[dsIdx].backgroundColor}
                  updateColor={(color, e) => BoardStore.updateDSColor({ dsIdx, color })}
                  key={`dsidx-qe-${dsIdx}`} />
              </Box>

              <Box sx={{marginTop: '10px !important', paddingLeft: 1}}> 
                <DSMinMaxFields dsIdx={dsIdx} key={`minmax-qe-${dsIdx}`} shouldRefreshData={true} />
              </Box>

              <IconButton aria-label="delete" size="small"
                onClick={(event) => BoardStore.removeDataset(dsIdx)}
                disableFocusRipple
                disableRipple
                sx={{ fontSize: 14, padding: 0, marginX:0 }}
              >
                <ClearIcon fontSize="inherit" sx={{ color: '#aaa' }} /></IconButton>
            </Stack>
          )}
        </FormGroup>



      </Stack>
      {/* <Stack direction="column" spacing={0} sx={{ justifyContent: 'flex-start', paddingRight: 1 }}> */}


    </Box>
  </Transition>
})

export const PieDataQuickEditWidget = observer(({chart, onClose}) => {
  return <Transition in={true}>
    <Box component={"div"}
      sx={{

        ...borderSettings,

        // width: '200px',
        padding: 1,
        left: chart.chartSettings.positionX + chart.chartSettings.width / 2 - 100,
        top: chart.chartSettings.positionY + chart.chartSettings.height + 50,
      }}>
        <Typography sx={{ textAlign: 'center', fontSize: 14, marginX: 0,  marginTop:0, marginBottom: 1 }}>Data Points</Typography>

      <IconButton aria-label="delete" size="small"
        onClick={(event) => onClose()}
        disableFocusRipple
        disableRipple
        sx={{ position: 'absolute', zIndex: 10001, right: 0, top: 0, fontSize: 14 }}
      >
        <ClearIcon fontSize="inherit" sx={{ color: '#aaa' }} /></IconButton>

      <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>

        <FormGroup >
          
          {console.log("PIE lavels", chart.chartData.labels)}
          {chart.chartData.labels.map((label, idx) => {
            return <Stack direction="row" spacing={2} sx={{ paddingX: 0, alignItems: 'flex-end', marginBottom:1,}} key={`dsidx-qegroup-${idx}`} >
              
              <Box sx={{ width: '120px',}}>
                <InputColorWidget txt={label}
                  updateTxt={(e) => BoardStore.updateLabel({ idx: idx, text: e.target.value })}
                  color={chart.chartData.datasets[0].backgroundColor[idx]}
                  updateColor={(color, e) => BoardStore.updateLabelColor({ idx, color })}
                  key={`dsidx-qe-${idx}`} />
              </Box>


              <Box sx={{ alignSelf: 'flex-end', }}>

                <NumberInputWidget
                  showUnderline={true}
                  style={{}}
                  // helperText={'value'}
                  numericValue={chart.chartData.datasets[0].data[idx]}
                  onValueChange={(e) => BoardStore.updateCell(0, idx, e.target.value)}
                  // label={""}
                  inputAdornment={""} />
              </Box>

              <IconButton aria-label="delete" size="small"
                onClick={(event) => BoardStore.deleteDatapointAtIndex(idx)}
                disableFocusRipple
                disableRipple
                sx={{ fontSize: 14, padding: 0 }}
              >
                <ClearIcon fontSize="inherit" sx={{ color: '#aaa' }} /></IconButton>
            </Stack>
          }
          )}
        </FormGroup>
        </Stack>
        <FormControlLabel
            control={<ButtonGroup disableElevation variant="contained" onClick={() => { }}>
              <IconButton aria-label="add_datapoint" disableFocusRipple disableRipple sx={{ transform: 'scale(0.8)', width: '20px' }} onClick={(event) => BoardStore.addDatapoint()}> <AddCircleOutlineIcon /> </IconButton>
              {/* <IconButton aria-label="remove_datapoint" disableFocusRipple disableRipple sx={{ transform: 'scale(0.7)', width: '20px' }} onClick={(event) => BoardStore.deleteDatapoint()}> <RemoveCircleOutlineIcon /> </IconButton> */}
            </ButtonGroup>}
            sx={{ justifyContent: 'center', margin: 0, alignItems: 'center', width: '100%' }}
            labelPlacement="start"
            label={<Typography color="textSecondary" sx={{ fontSize: 12, marginRight: 2,  }}>Add Data Point</Typography>} />
      
    </Box>
  </Transition>
})

export const LegendQuickEditWidget = observer(({chart, onClose}) => {
  return <Transition in={true}>
    <Box component={"div"}
      sx={{
        ...borderSettings,
        // width: '320px',
        paddingY: 1,
        paddingX: 1,
        left: chart.chartSettings.positionX + chart.chartSettings.width / 2 - 160,
        top: chart.chartSettings.positionY + chart.chartSettings.height + 50,
      }}>
      <IconButton aria-label="delete" size="small"
        onClick={(event) => onClose()}
        disableFocusRipple
        disableRipple
        sx={{ position: 'absolute', zIndex: 10001, right: 0, top: 0, fontSize: 14 }}
      >
        <ClearIcon fontSize="inherit" sx={{ color: 'rgba(204, 204, 204, 1)' }} /></IconButton>
      <Typography sx={{ fontSize: 14, textAlign: 'center', color: 'rgba(64, 64, 64, 0.87)', marginBottom:1}}>Legend</Typography>
      
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-around', width: 'auto' }}>

        {/* Legend   */}

        <Stack direction="column" spacing={2} sx={{ justifyContent: 'flex-start', }}>
          <LegendPositionWidget storeObj={chart} />
          <Box sx={{marginTop: 1}}>

          <LegendAlignmentWidget storeObj={chart} />
          </Box>
        </Stack>


        <Divider flexItem orientation="vertical" sx={{ my: 1 }} />


        <Stack direction="column" spacing={1} sx={{ alignItems: 'flex-start'}} onClick={e => {e.stopPropagation(); e.preventDefault()}}>
          
          <FontStyleWidget
            titleType={"legendlabels"}
            shouldDisplay={chart.chartOptions.plugins.legend.display}
            toggleDisplay={() => BoardStore.toggleShowLegend({editMode: 'element'})}
            font={chart.chartOptions.plugins.legend.labels.font}
            color={chart.chartOptions.plugins.legend.labels.color}
            
            // label={""}
          />
          <FontSelectWidget titleType={"legendlabels"} noLabel={true}
            titleObj={chart.chartOptions.plugins.legend.labels} />
        </Stack>

      </Stack>
    </Box>
  </Transition>
})
