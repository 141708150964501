import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import ColorPickerWidget from './DashingColors';



const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        // margin: 5,
        padding: theme.spacing(0.5),
        border: 0,
        // '&.Mui-disabled': {
        // border: 0,
        // },
        '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
        },
    },
    '& .MuiToggleButton-root': {
        '& .MuiSvgIcon-root': {
            fontSize: 14
        },  
    },
}));


const TextAlignWidget = observer(({titleObj, titleType,  directions, onUpdate, editMode}) => {
  
    const [alignment, setAlignment] = React.useState('left');
    const handleAlignment = (event, newAlignment, ) => {
      setAlignment(newAlignment);
    };

    const getIcon = (direction)=> {
      if(direction === "start") return <FormatAlignLeftIcon/>
      if(direction === "center") return <FormatAlignCenterIcon/>
      if(direction === "end") return <FormatAlignRightIcon/>
    }

    return <StyledToggleButtonGroup
    size="small"
    value={alignment}
    exclusive
    onChange={handleAlignment}
    aria-label="text alignment" >

    {(directions? directions : ['start', 'center', 'end']).map((direction) => 
          <ToggleButton key={`text-edit-${direction}`} value={direction} aria-label="aligned" 
                        // selected={ChartStore.current.chartOptions.plugins.title.align === 'start'}
                        selected={titleObj.align === direction}
                        onClick={(event, newAlignment) => onUpdate? onUpdate({align: newAlignment, titleType, editMode}) : BoardStore.setTitleAlignment({align: newAlignment, titleType, editMode})}>
            {getIcon(direction)}
          </ToggleButton>
    )}

</StyledToggleButtonGroup>
})

// export default function TextEditMenu({titleObj, titleType}) {
const TextStyleWidget = observer(({titleObj, titleType, editMode}) => {
  // const [alignment, setAlignment] = React.useState('left');
  // const [formats, setFormats] = React.useState(() => ['italic']);

  // const handleFormat = (event, newFormats, ) => {
  //   setFormats(newFormats);
  // };

  // const handleAlignment = (event, newAlignment, ) => {
  //   setAlignment(newAlignment);
  // };

  // const getIcon = (direction)=> {
  //   if(direction === "start") return <FormatAlignLeftIcon/>
  //   if(direction === "center") return <FormatAlignCenterIcon/>
  //   if(direction === "end") return <FormatAlignRightIcon/>
  // }

  return   <Paper elevation={0} 
                  style ={{background: 'none'}}
                  sx={{ display: 'flex', alignItems: 'center',
                  justifyContent: 'space-between',
                   flexWrap: 'wrap' }} >
        
        <TextAlignWidget titleObj={titleObj} titleType={titleType} editMode={editMode}/>
        
        
        {/* <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} /> */}
        
        <StyledToggleButtonGroup
          size="small"
          aria-label="text formatting">
          <ToggleButton key={`text-edit-weight`} value="bold" aria-label="bold" 
                        selected={titleObj.font.weight === 'bold'}
                        onClick={(event) => BoardStore.toggleBold({titleType, editMode})}>
            <FormatBoldIcon/>
          </ToggleButton>
          <ToggleButton key={`text-edit-style`} value="italic" aria-label="italic"
                selected={titleObj.font.style === 'italic'}
                onClick={(event) => BoardStore.toggleItalic({titleType, editMode})}>
            <FormatItalicIcon/>
          </ToggleButton>

          <ToggleButton key={`text-edit-color`} value="color" aria-label="color">
            <ColorPickerWidget color={ titleObj.color} hideArrow={true}
                               updateColor={ (color, e) => BoardStore.updateTitleColor({color, titleType, editMode}) }/>
          </ToggleButton>

        </StyledToggleButtonGroup>
      </Paper>
})
export default TextStyleWidget;