
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { FormGroup } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import { observer } from 'mobx-react-lite';
import { BoardStore } from '../../../Stores/Stores';
// import IconButton from '@mui/material/IconButton';

const PlusMinusWidget = observer(({ onPlus, onMinus, label, fontSize = 12 }) => {
  return <FormGroup>
    <FormControlLabel

      control={<ButtonGroup disableElevation variant="contained" disabled={!BoardStore.editMode}>
        {onPlus && <IconButton disabled={!BoardStore.editMode} aria-label="add" disableFocusRipple disableRipple sx={{ transform: 'scale(0.8)', width: '30px' }} onClick={(event) => onPlus(event.target.value)}> <AddCircleOutlineIcon /> </IconButton>}
        {onMinus && <IconButton disabled={!BoardStore.editMode} aria-label="remove" disableFocusRipple disableRipple sx={{ transform: 'scale(0.8)', width: '30px' }} onClick={(event) => onMinus(event.target.value)}> <RemoveCircleOutlineIcon /> </IconButton>}
      </ButtonGroup>}
      sx={{ justifyContent: 'space-between', marginLeft: 0, paddingX: 0 }}
      labelPlacement="start"
      label={<Typography color="textSecondary" sx={{ fontSize: fontSize }}>{label}</Typography>} />
  </FormGroup>
})


export default PlusMinusWidget;