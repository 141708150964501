
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { BoardStore } from '../../Stores/Stores';
import RangeSliderWidget from '../Editors/EditorWidgets/RangeSliderWidget';
import ElementContainer from './ElementContainer';

const RangeFilterContainer = observer(({ chart }) => {
  // const [isHoverEditable, setIsHoverEditable] = useState(false);
  const [quickEditor, setQuickEditor] = useState(null);

  return <ElementContainer chart={chart} quickEditor={quickEditor}>
    <Box
      sx={
        {
          backgroundColor: chart.chartSettings.backgroundColor,
          position: "relative",
          height: '100%',
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // TODO: add padding config
        }
      }>
  {chart.chartOptions.plugins.title.display ?  
      <Box
        display="flex"
        alignItems="flex-start"
        alignSelf="flex-start"
        justifyContent="flex-start"
        onClick={(e) => {setQuickEditor('title'); e.stopPropagation()}}
        sx={{
          marginX: 2,
          marginBottom: 0.5,
          display: chart.chartOptions.plugins.title.display,
          fontWeight: chart.chartOptions.plugins.title.font.weight,
          fontSize: chart.chartOptions.plugins.title.font.size,
          fontStyle: chart.chartOptions.plugins.title.font.style,
          fontFamily: chart.chartOptions.plugins.title.font.family,
          alignSelf: chart.chartOptions.plugins.title.align, //'center', 
          color: chart.chartOptions.plugins.title.color, //'center', 

          alignContent: 'stretch',
          height: '20%',
          paddingY: '10px',
          paddingX: '15px',
          flexGrow: 1
        }}> {chart.chartOptions.plugins.title.text} 
        </Box>
        : ''}
      <Box sx={{ width: '100%', padding: '15px' }}>
        <RangeSliderWidget minValue={chart.chartOptions.plugins.range.min}
          maxValue={chart.chartOptions.plugins.range.max}
          value={chart.chartOptions.plugins.range.value}
          color={chart.chartOptions.plugins.range.color}
          updateValues={(newValues) => BoardStore.updateSliderRange({newValues})}
        />
      </Box>
    </Box>
  </ElementContainer>

});

export default RangeFilterContainer;


