
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import ElementContainer from './ElementContainer';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ListFilterContainer = observer(({ chart }) => {

  const [isHoverEditable, setIsHoverEditable ] = useState(null)

  return <ElementContainer chart={chart} isHoverEditable={isHoverEditable}>
    <Box
      sx={
        {
          // border: `${chart.chartSettings.borderWidth + "px"} solid ${chart.chartSettings.borderColor}`,
          // borderRadius: chart.chartSettings.borderRadius,
          backgroundColor: chart.chartSettings.backgroundColor,
          position: "relative",
          height: '100%',
          display: "flex",
          flexDirection: "column",
          alignItems: chart.chartOptions.plugins.title.align,
          justifyContent: "center",
          // boxShadow: chart.isActive ? '3px 3px 3px rgba(0, 0, 0, 0.24)' : '0px 0px 0px rgba(0, 0, 0, 0.24)',
          // TODO: add padding config
        }
      }>
      {chart.chartOptions.plugins.title.display ?
        <Box
          display="flex"
          alignItems="flex-start"
          alignSelf="flex-start"
          justifyContent="flex-start"
          onMouseEnter={(e) => {setIsHoverEditable('title')}}
          onMouseLeave={(e) => {setIsHoverEditable(null)}}

          sx={{
            fontWeight: chart.chartOptions.plugins.title.font.weight,
            fontSize: chart.chartOptions.plugins.title.font.size,
            fontStyle: chart.chartOptions.plugins.title.font.style,
            fontFamily: chart.chartOptions.plugins.title.font.family,
            color: chart.chartOptions.plugins.title.color, //'center', 
            alignSelf: chart.chartOptions.plugins.title.align, //'center', 
            alignContent: 'stretch',
            height: '20%',
            paddingY: '10px',
            paddingX: '15px',
            flexGrow: 1,
          }}> {chart.chartOptions.plugins.title.text}</Box>

        : ''}


      {/* <Stack spacing={3} sx={{ width: 500 }}> */}
      <Box sx={{ width: '100%', padding: '15px' }}>
        <Autocomplete
          // multiple={chart.chartOptions.plugins.listSettings.isMultiple}           
          multiple // TODO: different type of chart for single select
          size="small"
          id="tags-standard"
          options={chart.chartOptions.plugins.listOptions.filter((x) => x !== '')}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ margin: 0, padding: 0, paddingRight: 3 }}
                checked={selected}
              />
              <Box sx={{ fontSize: 12 }}>{option}</Box>
            </li>
          )}

          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                style: { fontSize: 12, height: 14 }
              }}
              placeholder={chart.chartOptions.plugins.placeholder.text}
            />
          )}
        />
      </Box>
    </Box>
  </ElementContainer>

});
export default ListFilterContainer;

