import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { observer } from 'mobx-react-lite';


const initial = Array.from({ length: 10 }, (v, k) => k).map(k => {
  const custom = {
    id: `id-${k}`,
    content: `Quote ${k}`
  };

  return custom;
});

const grid = 4;
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DeraggableItem = styled.div`
  margin-bottom: ${grid}px;
  width: 100%;
  background-color: white;
  padding: ${grid}px 0;
`;

function DraggableItemContainer({ item, index }) {
  return (
    <Draggable draggableId={`draggableId-${index}`} index={index} style={{padding:0, marginRight: 0}}>
      {provided => (
        <DeraggableItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {item}
        </DeraggableItem>
      )}
    </Draggable>
  );
}

// const QuoteList = React.memo(function QuoteList({ quotes }) {
//   return quotes.map((quote, index) => (
//     <DraggableItemContainer quote={quote} index={index} key={quote.id} />
//   ));
// });


const DraggableList = observer(({items, onMoveCol}) => {
  // const [state, setState] = useState({ quotes: initial });

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    onMoveCol(
      result.source.index,
      result.destination.index
    )

    // const quotes = reorder(
    //   state.quotes,
    //   result.source.index,
    //   result.destination.index
    // );

    // setState({ quotes });
    // items.splice(result.destination.index, 0, this.splice(result.source.index, 1)[0]);
  }

  useEffect(() => {
  }, [items.length])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {/* <QuoteList quotes={state.quotes} /> */}
            {items.map((item, index) => (
                <DraggableItemContainer item={item} index={index} key={`draggableKey-${index}`} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});
export default DraggableList;