import { action, extendObservable, makeAutoObservable, makeObservable, observable, toJS } from 'mobx';
// import { v4 as uuidv4 } from 'uuid';
import 'chartjs-adapter-moment';
import { nanoid } from 'nanoid';
// import {
//     createModelSchema, custom, deserialize, identifier, list, object, primitive, raw, serialize
// } from "serializr";
import { UserStore } from './Stores';
import BoxContainer from '../Components/Containers/BoxContainer';
import ChartContainer from '../Components/Containers/ChartContainer';
import DataGridContainer from '../Components/Containers/DataGridContainer';
import DateFilterContainer from '../Components/Containers/DateFilterContainer';
import KPIContainer from '../Components/Containers/KPIContainer';
import ListFilterContainer from '../Components/Containers/ListFilterContainer';
import RangeFilterContainer from '../Components/Containers/RangeFilterContainer';
import SparkContainer from '../Components/Containers/SparkContainer';
import TextboxContainer from '../Components/Containers/TextboxContainer';
import { ThemeStore } from './ThemeManager';
import { defulatTimeFormats, generateDataGridRows, generateDataPoint, generateOrderedLabels, generateSeries, getDataTypeObj, getIndexScale } from '../Utils/ElementsUtils';



export const ContainerFactory = (key, chart) => {
    if (chart.chartType === "pie") return <ChartContainer key={key} chart={chart} />;
    if (chart.chartType === "bar") return <ChartContainer key={key} chart={chart} />;
    if (chart.chartType === "line") return <ChartContainer key={key} chart={chart} />;
    if (chart.chartType === "doughnut") return <ChartContainer key={key} chart={chart} />;
    if (chart.chartType === "scatter") return <ChartContainer key={key} chart={chart} />;
    if (chart.chartType === "bubble") return <ChartContainer key={key} chart={chart} />;
    if (chart.chartType === "textbox") return <TextboxContainer key={key} chart={chart} />;
    if (chart.chartType === "datagrid") return <DataGridContainer key={key} chart={chart} />;
    if (chart.chartType === "kpi" && chart.chartVariant.length === 0) return <KPIContainer key={key} chart={chart} />;
    if (chart.chartType === "kpi") return <SparkContainer key={key} chart={chart} />;

    if (chart.chartType === "box") return <BoxContainer key={key} chart={chart} />;

    if (chart.chartType === "filter" && chart.chartVariant.includes('date')) return <DateFilterContainer key={key} chart={chart} />;
    if (chart.chartType === "filter" && chart.chartVariant.includes('range')) return <RangeFilterContainer key={key} chart={chart} />;
    if (chart.chartType === "filter" && chart.chartVariant.includes('list')) return <ListFilterContainer key={key} chart={chart} />;
}

export const ElementsFactory = (chartType, chartVariant, tabId) => {

    switch (chartType) {
        case 'bar':
            return new BarChart(chartType, chartVariant, tabId)
        case 'line':
            return new LineChart(chartType, chartVariant, tabId)
        case 'pie':
            return new PieChart(chartType, chartVariant, tabId)
        case 'doughnut':
            return new PieChart(chartType, chartVariant, tabId)
        case 'scatter':
            return new ScatterChart(chartType, chartVariant, tabId)
        case 'bubble':
            return new BubbleChart(chartType, chartVariant, tabId)

        case 'kpi':
            if (chartVariant?.length === 0) {
                return new KPIElement(chartType, chartVariant, tabId);
            } else {
                return new SparkElement(chartType, chartVariant, tabId);
            }

        // case 'spark':
        //     return new SparkElement(chartType, chartVariant, tabId);

        case 'textbox':
            return new TextBoxElement(chartType, chartVariant, tabId)


        case 'datagrid':
            return new DataGridElement(chartType, chartVariant, tabId)

        case 'filter':
            if (chartVariant.includes('date')) {
                console.log("chartVariant", chartVariant)
                return new DateFilterElement(chartType, chartVariant, tabId)
            } else if (chartVariant.includes('range')) {
                return new RangeFilterElement(chartType, chartVariant, tabId)
            } else if (chartVariant.includes('list')) {
                return new ListFilterElement(chartType, chartVariant, tabId)
            }
            break;

        case 'box':
            return new BoxElement(chartType, chartVariant, tabId)

        case 'table':
            break;
        default:
            break;
        //TODO 
    }

}

export class Board {

    constructor() {
        // this.id = uuidv4()
        this.id = nanoid();
        this.ownerId = UserStore.userId;
        this.ownerEmail = UserStore.verifiedEmail;
        this.isPrivate = true;
        this.updatedAt = new Date().getTime();
        this.createdAt = new Date().getTime();
        // this.boardName = ''
        this.scale = 1
        this.title = new ChartTitle(ThemeStore.default.chartOptions.plugins.title, 'Untitled Board')
        // this.backgroundColor = ['rgba(100, 100,100, 0.1)'];
        this.backgroundColor = ['rgba(255,255,255,1)'];
        this.dottedBackground = true;

        // this.width = Math.max(Math.min(window ? window.innerWidth - drawerWidth - 10 : 1280,  1280), 800);
        this.width = 1080;
        this.height = 800;
        // this.positionX = Math.max(240, window.innerWidth / 2, this.width /2);
        this.positionX = 0;
        this.positionY = 0;
        this.tabs = observable.array();

        makeAutoObservable(this, {
            id: observable,
            // ownerId: observable,
            // ownerEmail: observable,
            // boardName: observable,
            title: observable,
            tabs: observable,
            backgroundColor: observable,
            width: observable,
            height: observable,
            scale: observable,
            positionX: observable,
            positionY: observable,
            isPrivate: observable,
            collaborators: observable,
        });
    }
}

export class Tab {
    constructor(tabName) {
        // this.id = uuidv4();
        this.id = nanoid();
        this.tabName = tabName
        // this.charts = observable.map();
        this.charts = observable.array();
        this.isActive = false;
        makeAutoObservable(this, {
            id: observable,
            tabName: observable,
            charts: observable,
            isActive: observable,
        });
    }
}


export class DashElement {

    chartType = ''
    chartVariant = '';
    tabId = ''

    constructor(chartType, chartVariant, tabId) {
        this.key = nanoid();
        this.createdAt = new Date().getTime();
        this.isActive = false;
        let chartSettings = new ChartSettings();
        // chartSettings.description = null;

        this.chartType = chartType;
        this.chartVariant = chartVariant;
        this.tabId = tabId;

        makeObservable(this, {
            key: observable,
            createdAt: observable,
            chartVariant: observable,
            chartType: observable,
            tabId: observable,
            isActive: observable,
            // isDataDraggable: observable,
        })
        extendObservable(this, { chartSettings });
    }
}

export class BoxElement extends DashElement {
    constructor(chartType, chartVariant, tabId) {
        super(chartType, chartVariant, tabId)
        var chartOptions = {
            layout: { padding: { top: 0, right: 0, left: 0, bottom: 5 } },
            plugins: {
                title: new ChartTitle(ThemeStore.default.chartOptions.plugins.title),
            }
        };
        this.chartSettings.width = 400;
        this.chartSettings.height = 300;
        this.chartSettings.borderRadius = 0;
        extendObservable(this, { chartOptions });

    }
}


export class DataGridColumn {
    constructor(idx, dgType, dataType, headerName, valueOptions) {


    //     valueFormatter: params => 
    //  moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        // MUI Data types

        // 'string' (default)
        // 'number'
        // 'date'
        // 'dateTime'
        // 'boolean'
        // 'singleSelect'

        this.index = idx;
        this.type = dgType;        
        this.dataType = dataType;
        this.headerName = headerName;
        // this.field = headerName.toLowerCase().replace(' ', '_') + "_" + idx;
        this.field = nanoid();
        this.width = dataType === 'index' ? 10 : this.type === 'string' ? 120 : 80;
        this.editable = true;
        this.sortable = false;
        this.align = 'left';
        this.headerAlign = 'left';
        this.valueOptions = valueOptions || [];

        makeObservable(this, {
            index: observable,
            type: observable,
            dataType: observable,
            width: observable,
            align: observable,
            headerAlign: observable,
            valueOptions: observable,
            })

        // const currencyFormatter = new Intl.NumberFormat('en-US', {
        //     style: 'currency',
        //     currency: 'USD',
        // });

        // const usdPrice = {            
        //     valueFormatter: ({ value }) => currencyFormatter.format(value),
        //     cellClassName: 'font-tabular-nums',
        // };
    }
}

export class DataGridElement extends DashElement {
    
    constructor(chartType, chartVariant, tabId) {
        super(chartType, chartVariant, tabId)

        // console.log("DataGridElement" ,ThemeStore.chartTheme('datagrid'))
        let chartTheme = ThemeStore.chartTheme('datagrid');
        var chartOptions = {
            layout: { padding: { top: 0, right: 0, left: 0, bottom: 5 } },
            plugins: {
                title: new ChartTitle(chartTheme.chartOptions.plugins.title),
                subtitle: new ChartTitle(chartTheme.chartOptions.plugins.subtitle),
                cells: new ChartTitle(chartTheme.chartOptions.plugins.cell),
                headers: new ChartTitle(chartTheme.chartOptions.plugins.header),
            }
        };
        this.chartSettings.width = 950;
        this.chartSettings.height = 400;
        this.chartSettings.borderRadius = 0;
        this.chartSettings.rowsPerPage = 100;
        
        this.chartSettings.dragData = false
        this.chartSettings.isDataDraggable = false  


        this.columns = [
            new DataGridColumn(0, 'number', 'index', "#"),
            new DataGridColumn(1, 'string', 'username', "User Name"),
            new DataGridColumn(2, 'string', 'email', "Email"),
            new DataGridColumn(3, 'date', 'day', "Purchase Date"),
            new DataGridColumn(4, 'string', 'productname', "Product"),
            new DataGridColumn(5, 'string', 'countries', "Country"),
            new DataGridColumn(6, 'string', 'uuid', "Invoice ID"),
            new DataGridColumn(7, 'string', 'address', "Deliver To"),
            new DataGridColumn(8, 'number', 'currency', "Price"),
        ]

        this.rows = generateDataGridRows(100, 0, this.columns);

        
        // const currencyFormatter = new Intl.NumberFormat('en-US', {
        //     style: 'currency',
        //     currency: 'USD',
        // });

        // const usdPrice = {
        //     type: 'number',
        //     width: 70,
        //     valueFormatter: ({ value }) => currencyFormatter.format(value),
        //     cellClassName: 'font-tabular-nums',
        // };


        // this.columns = [
        //     { field: 'name', 
        //       headerName: 'Name', 
        //       width: 100, 
        //       datatype: 'text',
        //       editable: true, 
        //       sortable: false },
        //     { field: 'age', 
        //       headerName: 'Age',
        //       datatype: 'text', 
        //       editable: true, 
        //       ...usdPrice },
        //     {
        //         field: 'dateCreated',
        //         headerName: 'Date Created',
        //         type: 'date',
        //         datatype: 'date',
        //         width: 180,
        //         editable: true, sortable: false
        //     }, {
        //         field: 'country',
        //         headerName: 'Country',
        //         type: 'singleSelect',
        //         datatype: 'custom',
        //         editable: true,
        //         valueOptions: ['United Kingdom', 'Spain', 'Brazil']
        //     },{
        //         field: 'lastLogin',
        //         headerName: 'Last Login',
        //         type: 'dateTime',
        //         datatype: 'date',
        //         width: 220,
        //         editable: true, sortable: false

        //     },
        // ];


       makeObservable(this, {
            rows: observable,
            columns: observable,
        })
        extendObservable(this, { chartOptions });

    }
}

export class TextBoxElement extends DashElement {

    constructor(chartType, chartVariant, tabId) {
        super(chartType, chartVariant, tabId)
        var chartOptions = {
            layout: { padding: { top: 10, right: 20, left: 20, bottom: 0 } },
            plugins: {
                title: new ChartTitle(ThemeStore.default.chartOptions.plugins.title),
            }
        };
        this.chartSettings.width = 400;
        this.chartSettings.height = 70;
        if (chartVariant.includes('placeholder')) {
            this.chartSettings.width = 400;
            this.chartSettings.height = 300;
            chartOptions.plugins.title.text = 'Chart \n\n Placeholder'
            chartOptions.plugins.title.align = 'center'
            chartOptions.plugins.title.color = "rgba(64, 64, 64, 0.8)"
            chartOptions.plugins.title.font.size = 36
            this.chartSettings.borderWidth = 2
            this.chartSettings.borderColor = "rgba(64, 64, 64, 0.8)"
        }

        this.chartSettings.borderRadius = 0;
        extendObservable(this, { chartOptions });
    }
}

export class ListFilterElement extends DashElement {
    constructor(chartType, chartVariant, tabId) {
        super(chartType, chartVariant, tabId)
        var chartOptions = {
            layout: { padding: { top: 0, right: 0, left: 0, bottom: 5 } },
            plugins: {
                title: new ChartTitle(ThemeStore.default.chartOptions.plugins.title, "List Filter TItle"),
                placeholder: new ChartTitle(ThemeStore.default.chartOptions.plugins.title, "Items"),
                listOptions: [],
                listSettings: {
                    isMultiple: true, //currently ignored
                    listName: 'Movies',
                }
            }
        };
        this.chartSettings.width = 350;
        this.chartSettings.height = 80;
        this.chartSettings.borderRadius = 0;
        extendObservable(this, { chartOptions });

        
    }
}

export class RangeFilterElement extends DashElement {
    constructor(chartType, chartVariant, tabId) {
        super(chartType, chartVariant, tabId)
        var chartOptions = {
            layout: { padding: { top: 0, right: 0, left: 0, bottom: 5 } },
            plugins: {
                title: new ChartTitle(ThemeStore.default.chartOptions.plugins.title, "Range Filter Title"),
                range: {
                    value: [120, 690],
                    color: ['rgba(10, 10,10, 0.8)'],
                    min: 0,
                    max: 1000
                }
            }
        };
        this.chartSettings.width = 350;
        this.chartSettings.height = 80;
        this.chartSettings.borderRadius = 0;
        extendObservable(this, { chartOptions });
    }
}

export class DateFilterElement extends DashElement {
    constructor(chartType, chartVariant, tabId) {
        super(chartType, chartVariant, tabId)

        var chartOptions = {
            layout: { padding: { top: 0, right: 0, left: 0, bottom: 5 } },
            plugins: {
                title: new ChartTitle(ThemeStore.default.chartOptions.plugins.title, "Date Filter Title"),
                startDatePicker: new ChartTitle(ThemeStore.default.chartOptions.plugins.subtitle, "From:"),
                endDatePicker: new ChartTitle(ThemeStore.default.chartOptions.plugins.subtitle, "To:"),
                startDate: new Date(),
                endDate: new Date(),
                dragData: false
            }
        };
        this.chartSettings.width = 350;
        this.chartSettings.height = 80;
        this.chartSettings.borderRadius = 0;
        extendObservable(this, { chartOptions });
    }
}

export class KPIElement extends DashElement {

    constructor(chartType, chartVariant, tabId) {
        super(chartType, chartVariant, tabId)
        this.isUp = true;
        var chartTheme = ThemeStore.chartTheme('kpi');
        var chartOptions = {
            layout: { padding: { top: 0, right: 0, left: 0, bottom: 5 } },
            plugins: {
                title: new ChartTitle(chartTheme.chartOptions.plugins.title),
                subtitle: new ChartTitle(chartTheme.chartOptions.plugins.subtitle),
                metric: new ChartTitle(chartTheme.chartOptions.plugins.metric),
                startAdornment: new ChartTitle(chartTheme.chartOptions.plugins.startAdornment),
                endAdornment: new ChartTitle(chartTheme.chartOptions.plugins.endAdornment),
                submetric: new ChartTitle(chartTheme.chartOptions.plugins.submetric),
            },
        }
        extendObservable(this, { chartOptions });

        this.chartSettings.height = 230;
        this.chartSettings.width = 250;
    }
}
export class SparkElement extends DashElement {

    constructor(chartType, chartVariant, tabId) {
        console.log("SparkElement")
        super(chartType, chartVariant, tabId)
        this.isUp = true;
        // var ctx = ThemeStore.default;
        let chartTheme  = ThemeStore.chartTheme('spark')
        var chartOptions = {
            layout: { padding: { top: 0, right: 0, left: 0, bottom: 5 } },
            plugins: {
                // title: new ChartTitle(ThemeStore.default.chartOptions.plugins.title),
                // subtitle: new ChartTitle(ThemeStore.default.chartOptions.plugins.subtitle),
                // metric: new ChartTitle(ThemeStore.default.kpi.metric),
                // startAdornment: new ChartTitle(ThemeStore.default.kpi.startAdornment),
                // endAdornment: new ChartTitle(ThemeStore.default.kpi.endAdornment),
                // submetric: new ChartTitle(ThemeStore.default.kpi.submetric),
                title: new ChartTitle(chartTheme.chartOptions.plugins.title),
                subtitle: new ChartTitle(chartTheme.chartOptions.plugins.subtitle),
                metric: new ChartTitle(chartTheme.chartOptions.plugins.metric),
                startAdornment: new ChartTitle(chartTheme.chartOptions.plugins.startAdornment),
                endAdornment: new ChartTitle(chartTheme.chartOptions.plugins.endAdornment),
                submetric: new ChartTitle(chartTheme.chartOptions.plugins.submetric),
                spark: {
                    // TODO: move to theme
                    color: 'rgba(58,81,102,1)',
                    thikness: 2,
                    borderColor: 'rgba(0,0,0,1)',
                    backgroundColor: 'rgba(58,81,102,0.1)',
                    sparkType: chartVariant?.includes('bar') ? 'bar' : 'line',
                }
            },
        }
        extendObservable(this, { chartOptions });

        this.chartSettings.height = 230;
        this.chartSettings.width = 250;

    }
}

export class DashChart extends DashElement {

    constructor(chartType, chartVariant, tabId) {

        super(chartType, chartVariant, tabId)
        var chartOptions = new ChartOptions(chartType, chartVariant)
        var chartData = new ChartData(chartType, chartVariant)
        // this.chartSettings.zIndex = 2;
        this.chartSettings.isDataDraggable = true;
        extendObservable(this, { chartOptions });
        extendObservable(this, { chartData });

    }
}

export class BarChart extends DashChart {

    constructor(chartType, chartVariant) {
        super(chartType, chartVariant);
        // setTimeScale(this.chartOptions, 'x', this.chartSettings.labelType)
        if (chartVariant) {
            if (chartVariant.includes("100")) {
                this.bar100()
            }
            if (chartVariant.includes("horizontal")) {
                this.horizontal()
            }
            if (chartVariant.includes("stacked")) {
                this.stack()
            }
        }
        
    }

    bar100 = () => {
        this.chartOptions.plugins.stacked100.enable = true
        // console.log("curr min", this.chartOptions.scales[this.chartOptions.indexAxis].min)
        // console.log("curr max", this.chartOptions.scales[this.chartOptions.indexAxis].max)
        this.chartOptions.scales[this.chartOptions.indexAxis === 'x' ? 'y' : 'x'].min = 0
        this.chartOptions.scales[this.chartOptions.indexAxis === 'y' ? 'x' : 'y'].max = 100
    }

    stack = () => {
        this.chartOptions.scales.x.stacked = true
        this.chartOptions.scales.y.stacked = true
    }

    horizontal = () => {
        this.chartOptions.indexAxis = 'y'
        let valAxis = this.chartOptions.scales['y']
        let indexAxis = this.chartOptions.scales['x']
        indexAxis.axis = 'y'
        valAxis.axis = 'x'
        // const tempMin = valAxis.min;
        // const tempMax = valAxis.max;
        // indexAxis.min = valAxis.min
        // indexAxis.max = valAxis.max
        this.chartOptions.scales.x = valAxis
        this.chartOptions.scales.y = indexAxis
    }
}

class LineChart extends DashChart {
    constructor(chartType, chartVariant) {
        super(chartType, chartVariant);
        // fill: 'origin',
        if (chartVariant) {
            if (chartVariant.includes("fill")) {
                this.fill()
            }
            if (chartVariant.includes("stacked")) {
                this.stack()
            }
        }
        // this.chartData.datasets.forEach(ds => {
        //     // ds.backgroundColor = 'red'   
        // });        
    }

    stack = () => {
        this.chartOptions.scales.x.stacked = true
        this.chartOptions.scales.y.stacked = true
    }

    fill = () => {

        // this.chartData.datasets.forEach((ds) => {
        //     ds.fill = 'origin'
        //     let rgba = ds.backgroundColor.match(/[\d\.]+/g)
        //     ds.backgroundColor = `rgba(${rgba[0]},${rgba[1]},${rgba[2]},0.3)`
        //     ds.pointBackgroundColor = ds.backgroundColor;
        //     ds.pointBorderColor = ds.backgroundColor;
        // })
    }

}

class PieChart extends DashChart {
    constructor(chartType, chartVariant) {
        super(chartType, chartVariant);
        this.chartOptions.scales.x.display = false
        this.chartOptions.scales.y.display = false

        let colors = [
            ThemeStore.colors.datasets[0],
            ThemeStore.colors.datasets[1],
        ]
        this.chartData.datasets[0].backgroundColor = [...colors];
        this.chartData.datasets[0].hoverBackgroundColor = [...colors];
        this.chartData.datasets[0].pointBackgroundColor = [...colors];
        this.chartData.datasets[0].pointBorderColor = [...colors];
        this.chartData.datasets[0].borderColor = [...colors];
    }
}

class ScatterChart extends DashChart {
    constructor(chartType, chartVariant) {
        super();
    }
}

class BubbleChart extends DashChart {
    constructor(chartType, chartVariant) {
        super(chartType, chartVariant);
        this.chartOptions.scales.x.position = 'center';
        this.chartOptions.scales.y.position = 'center';
    }
}

// class AreaChart extends DashChart{
//     constructor(chartType, chartVariant) {
//         super();
//       }
// }



//////////////


export class ChartData {
    // constructor(numOfDs, lengthOfDs, labelType) {
    constructor(chartType, chartVariant) {
        var chartTheme = ThemeStore.chartTheme(chartType)
        
        this.labelType = chartTheme.chartOptions.labelType;
        this.valuesDataType = chartTheme.chartOptions.valuesDataType;
        const seriesDataType = chartTheme.chartOptions.seriesDataType;
        this.seriesDataType = seriesDataType
        this.labelsStep = 100;
        this.labelsMax = 1000;
        this.labelsMin = 0;
        this.labelsToDate = new Date();
        this.valueOptions = observable.array();
        
        this.labels = generateOrderedLabels({len: chartTheme.chartData.length, 
            labelType: this.labelType, 
            min: this.labelsMin, 
            max: this.labelsMax, 
            valueOptions: this.valueOptions});
        
        let isFill = chartVariant.includes("fill")
        let isStacked = chartVariant.includes("stacked")
        this.datasets = Array.from(Array(chartTheme.chartData.datasets)).map((x, i) => {
            return new Dataset(chartTheme.chartData.length,
                                generateDataPoint({dataType: seriesDataType}), 
                                ThemeStore.colors.datasets[i], 
                                this.valuesDataType, 
                                isFill,
                                isStacked,
                                chartTheme.chartData.datasets)
        });

        makeAutoObservable(this, {
            labelType: observable,
            seriesDataType: observable,
            valuesDataType: observable,
            // labelSubType: observable,
            labels: observable,
            labelsStep: observable,
            labelsMin: observable,
            labelsMax: observable,
            labelsToDate: observable,

            datasets: observable,
        });

        // console.log("these are my labels", this.labels)
        // createModelSchema(ChartData, {
        // labelType: primitive(),
        // labels: list(),

        // labels: custom(
        //     // ls => ls.map(c => {console.log("serlizing ", c.toDate().getTime()); return c.toDate().getTime()}), 
        //     ls => [1661979600000, 1659301200000, 1656622800000, 1654030800000, 1651352400000, 1648760400000, 1646085600000, 1643666400000], 
        //     ls => ls.map(c => {console.log("deserlizing label", c); return moment.unix(c)}), 
        // ), 
        // datasets: list(object(Dataset))
        // });
    }

    // changeDataType(labelType) {
    //     this.labelType = labelType;
    //     this.labels = generateLabels(this.labels.length, this.labelType);
    //     // console.log("new labels")
    //     // console.log("new labels", this.labels)
    //     // this.datasets = [new Dataset(this.labels.length, "Dataset 1", 'rgba(0, 0, 0, 0.2)'), 
    //     //  new Dataset(this.labels.length, "Dataset 2", 'rgba(53, 162, 235, 0.5)')]        
    // }
}

export class Dataset {


    constructor(len, datasetName, color, dataType, isFill, isStacked, totalDatasets) {
        
        this.id = nanoid();
        this.min = 100;  // TODO: get from theme ? 
        this.max = isStacked && totalDatasets !== undefined ? 1000 / totalDatasets : 1000;
        this.label = datasetName || 'Dataset';
        this.backgroundColor = color;
        this.borderColor = color
        this.dataType = dataType || 'integer'
        // this.data = generateData(len, this.min, this.max)
        this.data = generateSeries({len: len, dataType: dataType, min: this.min, max: this.max})
        
        this.fill = '';
        if(isFill){
            this.fill = 'origin'
            let rgba = this.backgroundColor.match(/[\d\.]+/g)
            this.backgroundColor = `rgba(${rgba[0]},${rgba[1]},${rgba[2]},0.3)`
            this.pointBackgroundColor = this.backgroundColor;
            this.pointBorderColor = this.backgroundColor;
        }
        // this.pointBackgroundColor = color;
        // this.pointBorderColor = color;
        // this.pointRadius = '3';
        // this.pointHoverRadius = '3';
        // this.pointStyle = 'circle';

        console.log("this.dataType", this.dataType)
        makeAutoObservable(this, {
            label: observable,
            data: observable,
            dataType: observable,
            min: observable,
            max: observable,
            backgroundColor: observable,
            fill: observable,
        })

        
    }
}


export class Grid {
    constructor() {
        var gridTheme = ThemeStore.default.chartOptions.scales.x.grid
        this.display = gridTheme.display;
        this.drawBorder = gridTheme.drawBorder;
        this.drawOnChartArea = true;
        this.drawTicks = false;
        this.borderWidth = gridTheme.borderWidth;
        this.lineWidth = gridTheme.lineWidth;
        this.color = gridTheme.color;

        makeAutoObservable(this, {
            display: observable,
            drawBorder: observable,
            // dataType: observable,
            drawOnChartArea: observable,
            drawTicks: observable,
            borderWidth: observable,
            lineWidth: observable,
            color: observable,
        })

    
    }
}

class Scale {
    constructor(titleName, labelType, axis) { //add type
        this.display = true;
        this.labelType = labelType;
        this.stacked = false; // check chart variant        
        // this.labelSubType = labelSubType;
        this.min = null;
        this.max = null;

        makeObservable(this, {
            display: observable,
            labelType: observable,
            stacked: observable,
            // labelSubType: observable,
            min: observable,
            max: observable,
        });

        extendObservable(this, {
            title: new ChartTitle(Object.assign({}, ThemeStore.default.chartOptions.scales[axis].title), titleName), // defulatTitle(titleName);
            grid: new Grid(),
            ticks: new Ticks(Object.assign({}, ThemeStore.default.chartOptions.scales[axis].ticks), this.labelType,),
        })
    }
}

export class LiniarScale extends Scale {

    constructor(titleName, labelType, labelSubType, axis) {
        super(titleName, labelType, labelSubType, axis);
        this.type = 'linear'
        this.min = 0;
        this.max = 1000;
    }
}

export class CategoryScale extends Scale {
    constructor(titleName, labelType, axis) {
        super(titleName, labelType, axis);
        this.type = 'category'
        // this.labels = ['Germany', 'Japan', 'Canada', 'U.K,', 'France', 'China'];
        // this.labels = ['download_mp4', 'share_free', 'share_pro', 'share_proplus_agency', 'remove_watermark', 'extend_timeline', 'download_slideshare'];
        // this.labels = _timeLabels(12, 'month');
        // createModelSchema(CategoryScale, {
        //     extends: Scale
        // })
    }
}

export class TimeScale extends Scale {

    constructor(titleName, labelType, axis) {
        super(titleName, labelType, axis);

        this.bounds = 'data';
        this.type = 'time';
        // this.unitStepSize =1
        // this.ticks = new Ticks(labelType)
        let time = {
            // unit: labelSubType,
            // unit: 'month',
            unit: labelType,
            displayFormats: defulatTimeFormats(),
        }
        extendObservable(this, { time })

        // createModelSchema(TimeScale, {
        //     extends: Scale
        // });

    }
}

export class Ticks {

    constructor(themeCtx, labelType) {
        this.autoSkip = true;
        this.source = 'labels'
        this.display = themeCtx.display;
        this.labelType = labelType;
        // this.labelSubType = labelSubType;
        
        this.color = themeCtx.color;
        // this.min = 0; // ???
        // this.max = 1000; // ???
        // this.padding = {top: 10, bottom: 0, left: 0, right: 0}
        
        this.callback = function(value, index, ticks) {
            if(this.type === 'category') return this.getLabelForValue(value);
            if(this.type === 'linear') return value;
            if(this.type === 'time') return value;
            return value;
        }

        this.updateFontSize = (fontSize) => {
            this.font.size = fontSize
        }

        makeAutoObservable(this, {
            // labelType: observable,
            // labelSubType: observable,
            display: observable,
            color: observable,
            // font: observable,
            // min: observable,
            // max: observable,
        });
        extendObservable(this, {font: new TitleFont(themeCtx.font)})           
    }
    
}

class DataLabels {

    constructor(chartTheme) {
        this.display = chartTheme.display; //true; //auto
        this.source = 'data';

        this.borderRadius = chartTheme.radius;
        this.padding = chartTheme.padding;
        this.align = chartTheme.align;
        this.anchor = chartTheme.anchor;
        this.color = ThemeStore.colors.palette.primary.main;
        this.font = new TitleFont(Object.assign({}, chartTheme.font)); //defaultFont();
        // this.color = function (context) {
        //     return context.dataset.backgroundColor;
        // }

        makeAutoObservable(this, {
            display: observable,
            borderRadius: observable,
            padding: observable,
            align: observable,
            anchor: observable,
            font: observable,
        })
    }
}

export class LegendLabels {

    constructor() {
        // console.log("legend labels", themeCtx)
        this.usePointStyle = ThemeStore.default.chartOptions.plugins.legend.labels.usePointStyle;
        // this.usePointStyle = true;
        this.pointStyle = ThemeStore.default.chartOptions.plugins.legend.labels.pointStyle;
        this.textAlign = ThemeStore.default.chartOptions.plugins.legend.labels.textAlign;

        this.radius = ThemeStore.default.chartOptions.plugins.legend.labels.radius;
        this.boxWidth = ThemeStore.default.chartOptions.plugins.legend.labels.boxWidth;
        this.boxHeight = ThemeStore.default.chartOptions.plugins.legend.labels.boxHeight;
        this.color = ThemeStore.default.chartOptions.plugins.legend.labels.color;
        this.padding = ThemeStore.default.chartOptions.plugins.legend.labels.padding;

        makeObservable(this, {
            // usePointStyle: observable,
            pointStyle: observable,
            textAlign: observable,

            radius: observable,
            boxWidth: observable,
            boxHeight: observable,
            color: observable,
            padding: observable,
        })

        var font = new TitleFont(Object.assign({}, ThemeStore.default.chartOptions.plugins.legend.labels.font));
        extendObservable(this, { font })

        // createModelSchema(LegendLabels, {
        //     font: object(TitleFont),
        //     textAlign: primitive(),
        //     radius: primitive(),
        //     color: list(primitive()),
        //     // padding: raw(),            
        // });

    }
}

export class LegendPlugin {
    constructor() {
        this.display = ThemeStore.default.chartOptions.plugins.legend.display;
        this.position = ThemeStore.default.chartOptions.plugins.legend.position;
        this.align = ThemeStore.default.chartOptions.plugins.legend.align;
        // this.padding = {...ThemeStore.default.chartOptions.plugins.legend.padding};
        this.padding = 0.40;
        this.title = new ChartTitle(ThemeStore.default.chartOptions.plugins.legend.title, "Legend Title"); // defulatTitle(titleName);
        // this.maxHeight
        // this.maxWidth
        //this.rtl
        makeObservable(this, {
            display: observable,
            position: observable,
            align: observable,
            // padding: observable,
            title: observable,
        })

        // var labels = Object.assign({}, new LegendLabels(ThemeStore.default.chartOptions.plugins.legend.labels)) // TODO: Fix. remmove ...unpacking breaks legend 
        // var labels = new LegendLabels(ThemeStore.default.chartOptions.plugins.legend.labels) // TODO: Fix. remmove ...unpacking breaks legend 
        // extendObservable(this, {labels})
        // TODO: Fix. remmove ...unpacking breaks legend 
        extendObservable(this, { labels: toJS(new LegendLabels()) })
    }

    onClick(){

    }
}

class Elements {

    constructor() {
        this.point = new PointElement();
        this.line = new LineElement();
        makeAutoObservable(this, {
            point: observable,
            line: observable,
        })
    }
}

class LineElement {
    constructor() {
        this.tension = 0.5
        makeAutoObservable(this, {
            tension: observable,
        })
    }
}

class PointElement {

    constructor(radius, backgroundColor, borderColor, pointStyle) {
        this.radius = 2;
        this.pointStyle = 'circle'; //'cross' 'crossRot' 'dash' 'line' 'rect' 'rectRounded' 'rectRot' 'star' 'triangle'"
        // this.borderColor = 'rgba(0,0,0,0)'
        // this.backgroundColor = 'rgba(0,0,0,0)'
        this.hitRadius = 2
        this.hoverBorderWidth = 2
        makeAutoObservable(this, {
            radius: observable,
            borderColor: observable,
            backgroundColor: observable,
        })

        this.onHover = (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'cell' : 'default';
        }
    }
    
}


export const defaultDragDataPlugin = () => {
    return {
        round: 0, // rounds the values to n decimal places 
        // in this case 1, e.g 0.1234 => 0.1)
        showTooltip: true, // show the tooltip while dragging [default = true]
        // dragX: true // also enable dragging along the x-axis.
        // this solely works for continous, numerical x-axis scales (no categories or dates)!
        onDragStart: function (e, element) {
            /*
            // e = event, element = datapoint that was dragged
            // you may use this callback to prohibit dragging certain datapoints
            // by returning false in this callback
            if (element.datasetIndex === 0 && element.index === 0) {
                // this would prohibit dragging the first datapoint in the first
                // dataset entirely
                return false
            }
            */
        },
        onDrag: function (e, datasetIndex, index, value) {
            /*     
            // you may control the range in which datapoints are allowed to be
            // dragged by returning `false` in this callback
            if (value < 0) return false // this only allows positive values
            if (datasetIndex === 0 && index === 0 && value > 20) return false 
            */
        },
        onDragEnd: function (e, datasetIndex, index, value) {
            // you may use this callback to store the final datapoint value
            // (after dragging) in a database, or update other UI elements that
            // dependent on it
        },

    }
}
const updateTooltipShow = (chart, enabled) => {
    chart.options.plugins.tooltip.enabled = enabled;
    chart.update();
  }

class Plugins {
    
    constructor(chartTheme) {
        this.legend = new LegendPlugin();
        // this.title = new ChartTitle(theme.charts.default.chartOptions.plugins.title, 'Chart title');
        this.title = new ChartTitle(chartTheme.chartOptions.plugins.title, 'Chart title');
        this.subtitle = new ChartTitle(chartTheme.chartOptions.plugins.subtitle, 'Chart Sub title');
        this.datalabels = new DataLabels(chartTheme.chartOptions.plugins.datalabels);
        this.dragData = false;
        this.stacked100 = { enable: false, replaceTooltipLabel: false }  //TODO
        this.tooltip = {enabled: false,}
        makeAutoObservable(this, {
            legend: observable,
            title: observable,
            subtitle: observable,
            datalabels: observable,
            dragData: observable,
            stacked100: observable,
        });

        // createModelSchema(Plugins, {
        //     legend: object(LegendPlugin),
        //     title: object(ChartTitle),
        //     subtitle: object(ChartTitle),
        //     datalabels: object(DataLabels),
        // });
    }
}

class Layout {
    // padding: { top: 10, right: 20, left: 20, bottom: 0 } };
    constructor(themePadding) {
        this.padding = { top: 10, right: 20, left: 20, bottom: 0 }
        if (themePadding) {
            this.padding.top = themePadding.top || 0;
            this.padding.right = themePadding.right || 0;
            this.padding.left = themePadding.top || 0;
            this.padding.bottom = themePadding.bottom || 0;
        }
        makeAutoObservable(this, {
            padding: observable,
        });
        
    }
}


class Scales {

    constructor(labelType, indexAxis) {

        makeObservable(this, {})

        let dataTypeObj = getDataTypeObj(labelType)
        extendObservable(this, {
            y: new LiniarScale('Values', labelType, 'y'),
            x: getIndexScale(dataTypeObj, labelType, 'x'),
        });
    }   
}

export class ChartOptions {

    constructor(chartType, chartVariant) {

        let chartTheme = ThemeStore.chartTheme(chartType)
        
        this.maintainAspectRatio = false;
        this.responsive = true
        this.animation = true;
        this.interaction = {
            mode: 'index',
            intersect: false,
        }
        
        this.indexAxis = 'x';
        this.layout = new Layout(chartTheme.chartOptions.layout.padding)
        
        this.labelType = chartTheme.chartOptions.labelType;        
        this.seriesDataType = chartTheme.chartOptions.seriesDataType;        
        this.valuesDataType = chartTheme.chartOptions.valuesDataType;        
        
        this.elements = new Elements();
        makeAutoObservable(this, {
            scales: observable,
            responsive: observable,
            maintainAspectRatio: observable,
            layout: observable,
            elements: observable,
            labelType: observable,
            // labelSubType: observable,
            plugins: observable,
        })

        // this.setLabelType(labelType)

        let plugins = new Plugins(chartTheme);
        let scales = new Scales(this.labelType)
        extendObservable(this, { plugins })
        extendObservable(this, { scales })
        // this.scales = {
        //     y: new LiniarScale("Revenue", this.labelType),
        //     x: new TimeScale("Month", this.labelType),
        //     // x: new CategoryScale("Month"),
        // }
        // createModelSchema(ChartOptions, {
        //     maintainAspectRatio: primitive(),
        //     responsive: primitive(),     
        //     indexAxis: primitive(),     
        //     animation: primitive(),     
        //     layout: object(Layout),     
        //     elements: raw(),
        //     scales: object(Scales),
        //     plugins: object(Plugins)
        // });
    }
}



export class ChartSettings {

    // layout = { padding: { top: 10, right: 10, left: 10, bottom: 10 } };

    constructor() {
        // this.layout = { padding: { top: 10, right: 10, left: 100, bottom: 0 } };
        // console.log("ThemeStore.default.chartSettings.layout.padding", ThemeStore.default.chartSettings.layout.padding)
        // this.layout = new Layout(ThemeStore.default.chartSettings.layout.padding);
        this.renderedAt = new Date().getTime();
        this.updatedAt = new Date().getTime();
        this.positionX = 0;
        this.positionY = 0;
        this.width = ThemeStore.default.chartSettings.width;
        this.height = ThemeStore.default.chartSettings.height;
        this.backgroundColor = ThemeStore.default.chartSettings.backgroundColor;
        this.borderColor = ThemeStore.default.chartSettings.borderColor;
        this.borderRadius = ThemeStore.default.chartSettings.borderRadius;
        this.borderWidth = ThemeStore.default.chartSettings.borderWidth;
        this.activeBoxType = null
        this.description = null;
        this.isDataDraggable = false;
        
        makeAutoObservable(this, {
            layout: observable,
            version: observable,
            updatedAt: observable,

            positionX: observable,
            positionY: observable,
            width: observable,
            height: observable,
            zIndex: observable,
            backgroundColor: observable,
            borderColor: observable,
            borderRadius: observable,

            isDataDraggable: observable,
            activeBoxType: observable,
            description: observable,
        });
    }
}

export class ChartTitle {

    constructor(themeCtx, titleText) {
        this.text = titleText ? titleText : themeCtx?.text || '';
        this.align = themeCtx?.align;
        this.padding = Object.assign({}, themeCtx?.padding);
        this.display = themeCtx?.display;
        this.color = themeCtx?.color;
        this.position = themeCtx?.position;
        this.font = new TitleFont(themeCtx?.font);

        makeAutoObservable(this, {
            display: observable,
            text: observable,
            align: observable,
            padding: observable,
            color: observable,
            position: observable,
            font: observable,
            setText: action,
        })
        
    }

    onClick = (e, titleBlock) => {
        // console.log("Clicked title!");
    }
    onHover = (e, titleBlock) => {
        // console.log("Hovered title!");
    }
    onLeave = (e, titleBlock) => {
        // console.log("Leaved title!");
    }
}

export class TitleFont {

    constructor(themeCtx) {
        // console.log("TitleFont", themeCtx)
        this.weight = themeCtx?.weight;
        this.style = themeCtx?.style;
        this.size = themeCtx?.size;
        this.family = themeCtx?.family;

        makeAutoObservable(this, {
            weight: observable,
            style: observable,
            size: observable,
            family: observable,
        })

        // createModelSchema(TitleFont, {
        //     weight: primitive(),
        //     style: primitive(),
        //     size: primitive(),
        //     family: primitive(),
        // });
    }
}


/* META MODELS */

export const COLLABORATOR_ROLE_OWNER = 'owner';
export const COLLABORATOR_ROLE_EDITOR = 'editor';
export const COLLABORATOR_ROLE_COMMENTER = 'commenter';
export const COLLABORATOR_ROLE_VIEWER = 'viewer';

// export const COLLABORATOR_STATUS_SENT = 'sent';
// export const COLLABORATOR_STATUS_ACTIVE = 'active';


export class Collaborator {
    
    // constructor({userId=null, email=null, role=null, inviteStatus=null}) {
    constructor(email, role) { //, inviteStatus) {
        this.id = nanoid();
        this.createdAt = new Date();
        this.activatedAt = null;
        this.email = email;
        this.role = role; // owner, editor, commenter, viewer
        this.photoURL = null; // owner, editor, commenter, viewer

        this.userId = null;
        this.displayName = null;
        // this.inviteStatus = inviteStatus; //sent, active, disabled

        makeAutoObservable(this, {
            userId: observable,
            email: observable,
            displayName: observable,
            role: observable,
            inviteStatus: observable,
        })
    }
}