import ColorPickerWidget from './DashingColors';
import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import { observer } from 'mobx-react-lite';
import { BoardStore } from '../../../Stores/Stores';

export const PropertyColorWidget = observer(({ colorValue, onValueChange, label, allowEditColor }) => {
  return <FormGroup>
    <FormControlLabel
      control={<ToggleButton key="updateGridColor" value="color" aria-label="color"
        disableRipple disableFocusRipple
        disabled={!BoardStore.editMode}
        sx={{ border: 'none', padding: 1, 
        // '&.Mui-disabled': {border: 0} 
      }}>
        <ColorPickerWidget color={colorValue}
                           updateColor={onValueChange}
                           allowEditColor={allowEditColor}
                            />
                           {/* updateColor={(color) => onValueChange(color)} /> */}
      </ToggleButton>}
      sx={{ justifyContent: 'space-between', marginLeft: 0 }}
      labelPlacement="start"
      label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>{label}</Typography>} />
  </FormGroup>;
})

//  PropertyColorWidget;
