import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ToggleButton from '@mui/material/ToggleButton';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import StyledToggleButtonGroup from './StyledToggleButtonGroup';

const LegendAlignmentWidget = observer(({ storeObj, editType, updateAlignment }) => {

      const getIcon = (direction) => {
        if (direction === "start") return <FormatAlignLeftIcon />
        if (direction === "center") return <FormatAlignCenterIcon />
        if (direction === "end") return <FormatAlignRightIcon />
      }
      return <StyledToggleButtonGroup valueObj={storeObj.chartOptions.plugins.legend.align} txt={'Alignment'}>
  
       {['start', 'center', 'end'].map((direction) => 
            <ToggleButton key={`legend-aligned-${direction}`}
              value={direction} aria-label={`legend-aligned-${direction}`}
              selected={storeObj.chartOptions.plugins.legend.align === direction}
            //   onClick={(event, newAlignment) => BoardStore.alignLegend(newAlignment, editType)}>
              onClick={(event, newAlignment) => BoardStore.alignLegend({align: newAlignment, editMode: editType})}>
              {getIcon(direction)}
            </ToggleButton>
            )}
  
      </StyledToggleButtonGroup>
}

)

export default LegendAlignmentWidget;


