
import Container from '@mui/material/Container';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { BoardStore } from '../../Stores/Stores';
import ElementContainer from './ElementContainer';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';

const KPIContainer = observer(({ chart }) => {

  // const chartReference = useRef(null);
  // const [chartjsObj, setChartjsObj] = useState(null);
  // const [kpiValue, setKpiValue] = useState("42");
  // const [kpiFontSize, setKpiFontSize] = useState(48);
  const [quickEditor, setQuickEditor] = useState(null);
  const [isHoverEditable, setIsHoverEditable] = useState(false);

  return <ElementContainer chart={chart} quickEditor={quickEditor} isHoverEditable={isHoverEditable}>
    <Container p={0} m={0} 
      onClick={(e) => {setQuickEditor(null); e.stopPropagation()}}
      sx={{
      width: '100%',
      padding: '5px',
      margin: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: "center",
      flex: 1,
      
      // border: `${chart.chartSettings.borderWidth + "px"} solid ${chart.chartSettings.borderColor}`,
      // borderRadius: chart.chartSettings.borderRadius,
      backgroundColor: chart.chartSettings.backgroundColor,
    }}>

      {/* Title */}
      <Box
        display="flex"
        // alignItems="flex-start"
        
        alignSelf="flex-start"
        justifyContent="flex-start"
        onClick={(e) => {setQuickEditor('title'); e.stopPropagation()}}
        onMouseEnter={(e) => {setIsHoverEditable('title')}}
        onMouseLeave={(e) => {setIsHoverEditable(null)}}
        sx={{
          // borderColor: "red",
          fontWeight: chart.chartOptions.plugins.title.font.weight,
          fontSize: chart.chartOptions.plugins.title.font.size,
          fontStyle: chart.chartOptions.plugins.title.font.style,
          fontFamily: chart.chartOptions.plugins.title.font.family,
          color: chart.chartOptions.plugins.title.color, //'center', 
          alignSelf: chart.chartOptions.plugins.title.align, //'center', 
          alignContent: 'stretch',
          height: '20%',
          // padding: '15px',
          flexGrow: 1,
        }}> {chart.chartOptions.plugins.title.display ? chart.chartOptions.plugins.title.text : ''}</Box>

      <Box component="div" sx={{
            display: 'flex',
            borderColor: "red",
          }}>
        <TextField id="standard-basic"
          sx={{justifyContent: 'end',  }}
        onClick={(e) => {setQuickEditor('startAdornment'); e.stopPropagation()}}
        onKeyDown={e=>{ e.stopPropagation()}}
          inputProps={{
                sx: {
                  
                  maxWidth: `${chart.chartOptions.plugins.startAdornment.text.length}ch`,
                  width: 'auto',
                  height: 'auto',
                  minWidth: '1ch',
                  fontStyle: chart.chartOptions.plugins.startAdornment.font.style,
                  fontWeight: chart.chartOptions.plugins.startAdornment.font.weight,
                  fontSize: chart.chartOptions.plugins.startAdornment.font.size,
                  fontFamily: chart.chartOptions.plugins.startAdornment.font.family,
                  color: chart.chartOptions.plugins.startAdornment.color,
                  textAlign: 'right',
                  '&.Mui-focused ': {
                    borderColor: 'green',
                  },
                }
              }}
              InputProps={{
                disableUnderline: true,
                sx: {'&:hover':  {boxShadow: '0 0 10px 5px rgba(74,144,126,0.2)'}}
              }}

              value={chart.chartOptions.plugins.startAdornment.text}
              onChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: "startAdornment"})}
              variant="standard" />


          <TextField id="standard-basic"
          sx={{justifyContent: 'end', padding:0, margin: 0}}
          onClick={(e) => {setQuickEditor('metric'); e.stopPropagation()}}
          onKeyDown={e=>{ e.stopPropagation()}}
          inputProps={{
            sx: {
              height: 'auto',
              maxWidth: `${chart.chartOptions.plugins.metric.text.length * 0.6}em`,
              width: 'auto', 
              padding:0, margin: 0,
              minWidth: '1ch',
              fontStyle: chart.chartOptions.plugins.metric.font.style,
              fontWeight: chart.chartOptions.plugins.metric.font.weight,
              fontSize: chart.chartOptions.plugins.metric.font.size,
              fontFamily: chart.chartOptions.plugins.metric.font.family,
              color: chart.chartOptions.plugins.metric.color,
              textAlign: 'center',
            }
          }}
          InputProps={{
            disableUnderline: true,
            sx: {'&:hover':  {boxShadow: '0 0 10px 5px rgba(74,144,126,0.2)'}}
          }}
          value={chart.chartOptions.plugins.metric.text}
          onChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: "metric" })}
          variant="standard" />


          <TextField id={`main-metric-${chart.key}`}
          sx={{justifyContent: 'end',}}
          onClick={(e) => {setQuickEditor('endAdornment'); e.stopPropagation()}}
          onKeyDown={e=>{ e.stopPropagation()}}
          inputProps={{
            sx: {
              justifyContent: 'end',
              maxWidth: `${chart.chartOptions.plugins.endAdornment.text.length}em`,
              width: 'auto',
              minWidth: '1ch',
              height: 'auto',
              fontStyle: chart.chartOptions.plugins.endAdornment.font.style,
              fontWeight: chart.chartOptions.plugins.endAdornment.font.weight,
              fontSize: chart.chartOptions.plugins.endAdornment.font.size,
              fontFamily: chart.chartOptions.plugins.endAdornment.font.family,
              color: chart.chartOptions.plugins.endAdornment.color,
              textAlign: 'left',
            }
          }}
          InputProps={{
            disableUnderline: true,
            sx: {'&:hover':  {boxShadow: '0 0 10px 5px rgba(74,144,126,0.2)'}}
          }}

          value={chart.chartOptions.plugins.endAdornment.text}
          onChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: "endAdornment" })}
          variant="standard" />
      </Box>

      {/* submetric */}
      {chart.chartOptions.plugins.submetric.display ?


        <TextField id={`submetric-${chart.key}`}
        onKeyDown={e=>{ e.stopPropagation()}}
        onClick={(e) => {setQuickEditor('submetric'); e.stopPropagation()}}
        sx={{width: '100%',}}
          inputProps={{
            sx: {
              height: 'auto',              
              fontStyle: chart.chartOptions.plugins.submetric.font.style,
              fontWeight: chart.chartOptions.plugins.submetric.font.weight,
              fontSize: chart.chartOptions.plugins.submetric.font.size,
              fontFamily: chart.chartOptions.plugins.submetric.font.family,
              color: chart.chartOptions.plugins.submetric.color,
              textAlign: 'center',
            }
          }}
          InputProps={{
            disableUnderline: true,
            sx: {'&:hover':  {boxShadow: '0 0 10px 5px rgba(74,144,126,0.2)'}}
          }}

          value={chart.chartOptions.plugins.submetric.text}
          onChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: "submetric" })}
          variant="standard" />


        : ''}
      <Box
        display="flex"
        alignItems="flex-end"
        alignSelf="flex-end"
        justifyContent="flex-end"
        onClick={(e) => {setQuickEditor('subtitle'); e.stopPropagation()}}
        onMouseEnter={(e) => {setIsHoverEditable('subtitle')}}
        onMouseLeave={(e) => {setIsHoverEditable(null)}}
        style={{
          display: chart.chartOptions.plugins.subtitle.display,
          fontWeight: chart.chartOptions.plugins.subtitle.font.weight,
          fontSize: chart.chartOptions.plugins.subtitle.font.size,
          fontStyle: chart.chartOptions.plugins.subtitle.font.style,
          fontFamily: chart.chartOptions.plugins.subtitle.font.family,
          alignSelf: chart.chartOptions.plugins.subtitle.align, //'center', 
          color: chart.chartOptions.plugins.subtitle.color, //'center', 
          alignContent: 'stretch',
          height: '20%',
          // padding: '15px',
          flexGrow: 1,
        }}> {chart.chartOptions.plugins.subtitle.display ? chart.chartOptions.plugins.subtitle.text : ''}</Box>
    </Container>
  </ElementContainer>

});

export default KPIContainer;

