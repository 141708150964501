import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';


const rangeField = (limitType, updateLimit, limitValue) => {
    return  <TextField
             size="small"
             type="number"
             variant="standard"
             sx={{ alignItems: 'center', input: { textAlign: "center", paddingX: 1, paddingY: 1.5, }, textAlign: 'center', '& .MuiFormHelperText-root': { margin: 0, fontSize: 9 } }}
             helperText={<span style={{ fontSize: 9, textAlign: 'center', margin: 0 }}>{limitType}</span>}
             value={limitValue}                
             onChange={(e) => {updateLimit(e.target.value)}}             
             inputProps={{ style: { fontSize: 11, textAlign: 'center', margin: 0, padding: 0, width: '4ch', maxWidth: '4ch' } }}
             InputProps={{
                 inputMode: "decimal",
                 pattern: '[0-9]*',
                 sx: { textAlign: 'center', padding: '2px' },
             }} />
         }

const DSMinMaxFields = observer(({ dsIdx, shouldRefreshData=false }) => {
    
    return <Stack direction="row" spacing={2} sx={{ marginRight: 0 }}>

                {rangeField('min', (value) => BoardStore.setDSRangeMinimum(dsIdx, value, shouldRefreshData), BoardStore.currentElem.chartData.datasets[dsIdx]['min'])}
                {rangeField('max', (value) => BoardStore.setDSRangeMaximum(dsIdx, value, shouldRefreshData), BoardStore.currentElem.chartData.datasets[dsIdx]['max'])}
        </Stack>
    
})

export const AxisMinMaxFields = observer(({ axis }) => {
    
    return <Stack direction="row" spacing={2} sx={{ marginRight: 0 }}>

                {rangeField('min', (value) => BoardStore.setAxisRangeLimit(axis, 'min', value), BoardStore.currentElem.chartOptions.scales[axis]['min'])}
                {rangeField('min', (value) => BoardStore.setAxisRangeLimit(axis, 'max', value), BoardStore.currentElem.chartOptions.scales[axis]['max'])}
        </Stack>
    
})

export default DSMinMaxFields;
