import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { observer } from "mobx-react-lite";
import * as React from 'react';
import { BoardStore, UserStore } from '../Stores/Stores';

import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
// import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LinkIcon from '@mui/icons-material/Link';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { nanoid } from 'nanoid';
// import { COLLABORATOR_STATUS_SENT } from '../Stores/Models';
import { avatarColors, DASHING_GREEN } from '../Components/Editors/EditorWidgets/DashingColors';
import { PropertyToggleWidget } from '../Components/Editors/EditorWidgets/PropertyToggleWidget';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';


const Transition = React.forwardRef(function Transition(props, ref) {
    //   return <Slide direction="down" ref={ref} {...props} />;
    return <Collapse direction="down" ref={ref} {...props} />;
});


const InviteesList = observer(({ collaborators }) => {

    return (<List dense sx={{ width: '100%', marginX: 2, bgcolor: 'background.paper' }}>

        <ListItem sx={{ marginY: 0.5 }} disableGutters>
            <ListItemAvatar sx={{ minWidth: 'unset', marginRight: 1 }}>
                <Avatar
                    sx={{
                        width: 24, height: 24, fontSize: "14px", margin: 0,
                        // backgroundColor: avatarColors[Math.floor(Math.random()*avatarColors.length)][300]}}
                        backgroundColor: avatarColors[0]
                    }}
                    alt={UserStore.displayName || UserStore.email}
                    src={UserStore.photoURL}
                />
            </ListItemAvatar>
            <ListItemText id={'you-label'} primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Typography sx={{ fontSize: 14, color: 'rgba(64,64,64,1)' }}>
                        {UserStore.displayName}
                    </Typography>
                    <Typography sx={{ fontSize: 11, color: '#888' }}>
                        {UserStore.userId === BoardStore.board?.ownerId ? 'Owner ' : 'Viewer '}
                        {UserStore.userId === BoardStore.board?.ownerId ? '(you)' : ''}
                    </Typography>
                </Box>

            } />
        </ListItem>


        {collaborators ? collaborators.map((collaborator, idx) => {
            const labelId = `checkbox-list-secondary-label-${idx}`;
            return (
                <ListItem
                    key={idx}
                    sx={{marginY: 2}}
                    secondaryAction={
                        <Tooltip title="Remove" arrow>
                            <IconButton aria-label="upload picture" component="label" onClick={() => BoardStore.removeCollaborator(collaborator.id)}>
                                {/* <DeleteOutlineIcon sx={{fontSize: 18}}/> */}
                                <ClearOutlinedIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                    }
                    disablePadding
                >
                    {/* <ListItem sx={{ marginY: 0.5 }} disableGutters > */}
                    <ListItemAvatar sx={{ minWidth: 'unset', marginRight: 1 }}>
                        {/* {console.log("collaborator", collaborator)} */}
                        {!collaborator.userId ?

                            <Avatar sx={{
                                width: 24, height: 24, fontSize: "14px", margin: 0, border: '1px dashed #ccc',
                                backgroundColor: 'white'
                            }}
                            />
                            : 
                            <>
                                <Avatar sx={{
                                    width: 24, height: 24, fontSize: "14px", margin: 0,
                                    backgroundColor: avatarColors[(idx + 1) % avatarColors.length]
                                }}
                                    alt={collaborator.displayName || collaborator.email}
                                src={collaborator.photoURL}
                                />
                                {/* {<sm>{collaborator.userId}</sm>} */}
                            </>


                        }
                    </ListItemAvatar>
                    <ListItemText id={labelId} primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <Typography sx={{
                                fontSize: 14,
                                color: collaborator.uid ?
                                    'rgb(136,136,136)' : 'rgba(64,64,64,1)'
                            }}>
                                {collaborator.displayName || collaborator.email}
                            </Typography>
                            {!collaborator.userId ?
                                <Typography sx={{ fontSize: 11, color: '#888' }}>
                                    <i>invite sent</i>
                                </Typography>
                                :
                                <Typography sx={{ fontSize: 11, color: '#888' }}>
                                    {collaborator.userId === BoardStore.board?.ownerId ? 'Owner' : collaborator.role?.charAt(0).toUpperCase() + collaborator.role?.slice(1)}
                                    {UserStore.userId === collaborator.userId ? '(you)' : ''}
                                </Typography>
                            }
                        </Box>

                    } />
                </ListItem>
                // </ListItem>
            );
        }) : ''}
    </List>

    );
})

// export const AuthForm = observer(() => {
//     return <Container sx={{ width: '500px', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 0, }} >

//         <IconButton aria-label="delete" size="large"
//             onClick={() => BoardStore.showAuthDialog = false}
//             disableFocusRipple
//             disableRipple
//             sx={{ position: 'absolute', zIndex: 100, left: 5, top: 5, }}>
//             <ClearIcon fontSize="inherit" sx={{ color: 'rgba(204, 204, 204, 1)' }} /></IconButton>

//     </Container>
// })


const InviteDialog = observer(() => {
    const [inviteEmail, setInviteEmail] = React.useState('');
    const [alreadyInvited, setAlreadyInvited] = React.useState(false);

    // const isValidEmail = false;

    const handleChange = (event) => {
        setInviteEmail(event.target.value);
    };

    React.useEffect(() => {
        let isAlreadyInvited = BoardStore.collaborators.filter((c) => c.email === inviteEmail)?.length > 0
        setAlreadyInvited(isAlreadyInvited)
    }, [inviteEmail])

    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const sendInvite = () => {
        if (UserStore.authStatus !== UserStore.AUTHED_USER) {
            BoardStore.showAuthDialog = true;
        } else {
            BoardStore.addCollaborator(inviteEmail)
            setInviteEmail('')
        }
    }

    // const invitees = [
    //     { name: 'Otniel Ben Amara', email: 'hello@hello.com', userId: 'lkadsjhfsakdfh', photoUrl: 'https://....' },
    //     { name: 'Shimi James', email: 'hello@hello.com', userId: 'lkadsjhfsakdfh', photoUrl: 'https://....' },
    //     { name: 'Alphred Beauro', email: 'hello@hello.com', userId: 'lkadsjhfsakdfh', photoUrl: 'https://....' },
    // ]

    return (<Dialog
        fullWidth
        maxWidth={'xs'}
        sx={{
            '& .MuiDialog-container': {
                // width: 400
            },
            '& .MuiDialogContent-root': {
                // padding: theme.spacing(2),
                // height: 600,
                // top: 200,
            },
            '& .MuiDialogActions-root': {
                // padding: theme.spacing(1),
            },
            // width: 400
        }}
        open={BoardStore.showInviteDialog}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => BoardStore.showInviteDialog = false}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle >
            <Stack direction={'row'} spacing={2} sx={{ paddingBottom: 1 }}>
                <TextField
                    // fullWidth
                    id={`invite-email-${nanoid()}`}
                    variant='outlined'
                    placeholder='Enter email...'
                    helperText={alreadyInvited ? 'This email is already invited to this board' : ''}
                    // error={alreadyInvited}
                    size="small"
                    sx={{ width: '70%', height: 18, "& fieldset": { border: '1px solid #ccc', }, }}
                    inputProps={{
                        type: 'email',
                        sx: { height: '12px', fontSize: 14, color: 'rgba(64,64,64,1)', }
                    }}
                    InputProps={{
                        type: 'email',
                        height: '12px',
                    }}
                    value={inviteEmail}
                    onChange={handleChange}
                />


                <Button variant='contained' size="small" sx={{ marginX: 0, height: '30px', bgcolor: DASHING_GREEN, textTransform: 'none', ':hover': { bgcolor: DASHING_GREEN, } }}
                    // onClick={() => BoardStore.showInviteDialog = true}                    
                    onClick={() => sendInvite()}
                    disabled={!emailRegex.test(inviteEmail) || alreadyInvited}
                >Send Invite</Button>

                {/* </Box> */}
            </Stack>
            {/* <Typography>Invite Team Members</Typography> */}

        </DialogTitle>


        <DialogContent sx={{ paddingBottom: 0, paddingX: 0 }}>
            {/* <Box sx={{ display: 'flex',  justifyContent: 'space-between', alignItems: 'center' }}> */}

            <Divider sx={{ marginY: 0 }}></Divider>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 1.5, marginRight: 4 }}>
                {/* {invitees.map((invitee) => {
                        return <Box>
                        {invitee.name || invitee.name}
                        </Box>
                    })} */}

                <InviteesList collaborators={BoardStore.collaborators} />
                <Button sx={{ fontSize: 12, marginLeft: 4, textTransform: 'none', fontWeight: 400, paddingLeft: 0, ':hover': { backgroundColor: 'white' } }}
                    onClick={() => navigator.clipboard.writeText(window.location)}>
                    <LinkIcon sx={{ fontSize: 18, marginRight: 1, transform: "rotate(-45deg)" }} />
                    Copy Link to Board</Button>
            </Box>
            <Divider sx={{ marginY: 0.5 }}></Divider>

            <Box sx={{ marginTop: 1.5, marginLeft: 3, paddingRight: 5 }}>

                <PropertyToggleWidget
                    isChecked={!BoardStore.board?.isPrivate}
                    size={'medium'}
                    onCheck={(e) => BoardStore.toggleBoardPrivacy(e.target.value)}
                    label={<Box sx={{
                        display: 'flex', alignItems: 'center',
                        color: BoardStore.board?.isPrivate ? '#aaa' : 'rgba(64,64,64,1)'
                    }}>
                        <LanguageOutlinedIcon sx={{ fontSize: 18, marginRight: 1 }} />
                        Anyone with a link can view
                    </Box>} />
            </Box>
        </DialogContent>
        <DialogActions sx={{ marginY: 0, marginLeft: 3, paddingRight: 5 }}>

            {/* <Button variant='contained' onClick={handleClose}>Done!</Button> */}
        </DialogActions>
    </Dialog>
    );
})

export default InviteDialog;


