
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Chart from 'chart.js/auto';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { BoardStore } from '../../Stores/Stores';
import { generateData } from '../../Utils/ElementsUtils';
import ElementContainer from './ElementContainer';

import { Tooltip } from 'chart.js';

Chart.register([Tooltip])

const SparkContainer = observer(({ chart }) => {

  // const chartReference = useRef(null);
  const [chartjsObj, setChartjsObj] = useState(null);
  // const [kpiValue, setKpiValue] = useState("42");
  // const [kpiFontSize, setKpiFontSize] = useState(48);
  const [quickEditor, setQuickEditor] = useState(null);
  const [isHoverEditable, setIsHoverEditable] = useState(false);
  const chartReference = useRef(null);

  
  useEffect(() => {
    console.log("chart container")
    if(chartjsObj != null){
      chartjsObj.destroy()
    }
    const chartJs = new Chart(chartReference.current.getContext("2d"), {
      type: chart.chartOptions.plugins.spark.sparkType,
      // type: 'bar',
      showTooltips: true,
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [{data: generateData(12, 100,300), 
                    fill: chart.chartVariant.includes('area') ? 'origin' : false,
                    // fill: {
                    // target: 'origin',
                    // above: 'rgb(255, 0, 0)',   // Area will be red above the origin
                    // below: 'rgb(0, 0, 255)'    // And blue below the origin
                    // },
                    backgroundColor: chart.chartOptions.plugins.spark.sparkType === 'bar' ? [chart.chartOptions.plugins.spark.color] : chart.chartOptions.plugins.spark.backgroundColor,
                    // backgroundColor: chart.chartOptions.plugins.spark.backgroundColor,
                    pointBackgroundColor: 'rgba(0,0,0,0)',
                    pointBorderColor: 'rgba(0,0,0,0)',
                    // borderColor: chart.chartOptions.plugins.spark.color,
                    barPercentage: 1, 
                    categoryPercentage: 0.9}]
      },
      options: {        
        responsive: true,
        plugins :{ legend: { display: false }, datalabels: { display: false }, tooltip: {enabled: true} },
        elements: {
          line: {
            borderColor: chart.chartOptions.plugins.spark.color,
            tension: 0.2
          },
          point: { //transparent point for tooltip
            radius: 5,
            borderColor: 'rgba(0,0,0,0)',
            backgroundColor: 'rgba(0,0,0,0)',
          }
        },
        scales: {
          y: { display: false, min: 0, max: 350,},
          x: { display: false }
        }
      }
      });
      setChartjsObj(chartJs)

  }, [chart.chartOptions.plugins.spark.backgroundColor, chart.chartOptions.plugins.spark.color, chart.chartOptions.plugins.spark.sparkType, chart.chartSettings.renderedAt, chart.chartVariant]
// id]
  )
  

  return <ElementContainer chart={chart} quickEditor={quickEditor} isHoverEditable={isHoverEditable}>
    <Container p={0} m={0} 
      onClick={(e) => {setQuickEditor(null); e.stopPropagation()}}
      sx={{
      width: '100%',
      padding: '5px',
      margin: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: "center",
      flex: 0,      
      backgroundColor: chart.chartSettings.backgroundColor,
    
    }}>

      {/* Title */}
      <Box
        display="flex"
        // alignItems="flex-start"

        alignSelf="flex-start"
        justifyContent="flex-start"
        onClick={(e) => {setQuickEditor('title'); e.stopPropagation()}}
        onMouseEnter={(e) => {setIsHoverEditable(true)}}
        onMouseLeave={(e) => {setIsHoverEditable(false)}}
        sx={{
          // borderColor: "red",
          fontWeight: chart.chartOptions.plugins.title.font.weight,
          fontSize: chart.chartOptions.plugins.title.font.size,
          fontStyle: chart.chartOptions.plugins.title.font.style,
          fontFamily: chart.chartOptions.plugins.title.font.family,
          color: chart.chartOptions.plugins.title.color, //'center', 
          alignSelf: chart.chartOptions.plugins.title.align, //'center', 
          alignContent: 'stretch',
          height: '20%',

        }}> {chart.chartOptions.plugins.title.display ? chart.chartOptions.plugins.title.text : ''}</Box>

      <Box component="div" sx={{
            display: 'flex',
            // alignContent:
          }}>
        <TextField id="standard-basic"
          sx={{justifyContent: 'end',  }}
        onClick={(e) => {setQuickEditor('startAdornment'); e.stopPropagation()}}
        onKeyDown={e=>{ e.stopPropagation()}}
          inputProps={{
                sx: {
                  
                  maxWidth: `${chart.chartOptions.plugins.startAdornment.text.length}ch`,
                  width: 'auto',
                  height: 'auto',
                  minWidth: '1ch',
                  fontStyle: chart.chartOptions.plugins.startAdornment.font.style,
                  fontWeight: chart.chartOptions.plugins.startAdornment.font.weight,
                  fontSize: chart.chartOptions.plugins.startAdornment.font.size,
                  fontFamily: chart.chartOptions.plugins.startAdornment.font.family,
                  color: chart.chartOptions.plugins.startAdornment.color,
                  textAlign: 'right',
                  '&.Mui-focused ': {
                    borderColor: 'green',
                  },
                }
              }}
              InputProps={{
                disableUnderline: true,
                sx: {'&:hover':  {boxShadow: '0 0 10px 5px rgba(74,144,126,0.2)'}}
              }}

              value={chart.chartOptions.plugins.startAdornment.text}
              onChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: "startAdornment"})}
              variant="standard" />


          <TextField id="standard-basic"
          sx={{justifyContent: 'end', padding:0, margin: 0}}
          onClick={(e) => {setQuickEditor('metric'); e.stopPropagation()}}
          onKeyDown={e=>{ e.stopPropagation()}}
          inputProps={{
            sx: {
              height: 'auto',
              maxWidth: `${chart.chartOptions.plugins.metric.text.length * 0.6}em`,
              width: 'auto', 
              padding:0, margin: 0,
              minWidth: '1ch',
              fontStyle: chart.chartOptions.plugins.metric.font.style,
              fontWeight: chart.chartOptions.plugins.metric.font.weight,
              fontSize: chart.chartOptions.plugins.metric.font.size,
              fontFamily: chart.chartOptions.plugins.metric.font.family,
              color: chart.chartOptions.plugins.metric.color,
              textAlign: 'center',
            }
          }}
          InputProps={{
            disableUnderline: true,
            sx: {'&:hover':  {boxShadow: '0 0 10px 5px rgba(74,144,126,0.2)'}}
          }}
          value={chart.chartOptions.plugins.metric.text}
          onChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: "metric"})}
          variant="standard" />


          <TextField id={`main-metric-${chart.key}`}
          sx={{justifyContent: 'end',}}
          onClick={(e) => {setQuickEditor('endAdornment'); e.stopPropagation()}}
          onKeyDown={e=>{ e.stopPropagation()}}
          inputProps={{
            sx: {
              justifyContent: 'end',
              maxWidth: `${chart.chartOptions.plugins.endAdornment.text.length}em`,
              width: 'auto',
              minWidth: '1ch',
              height: 'auto',
              fontStyle: chart.chartOptions.plugins.endAdornment.font.style,
              fontWeight: chart.chartOptions.plugins.endAdornment.font.weight,
              fontSize: chart.chartOptions.plugins.endAdornment.font.size,
              fontFamily: chart.chartOptions.plugins.endAdornment.font.family,
              color: chart.chartOptions.plugins.endAdornment.color,
              textAlign: 'left',
            }
          }}
          InputProps={{
            disableUnderline: true,
            sx: {'&:hover':  {boxShadow: '0 0 10px 5px rgba(74,144,126,0.2)'}}
          }}

          value={chart.chartOptions.plugins.endAdornment.text}
          onChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: "endAdornment"})}
          variant="standard" />
      </Box>

       {/* submetric */}
      {chart.chartOptions.plugins.submetric.display ?


        <TextField id={`submetric-${chart.key}`}
        onKeyDown={e=>{ e.stopPropagation()}}
        onClick={(e) => {setQuickEditor('submetric'); e.stopPropagation()}}
        sx={{width: '100%',}}
          inputProps={{
            sx: {
              height: 'auto',              
              fontStyle: chart.chartOptions.plugins.submetric.font.style,
              fontWeight: chart.chartOptions.plugins.submetric.font.weight,
              fontSize: chart.chartOptions.plugins.submetric.font.size,
              fontFamily: chart.chartOptions.plugins.submetric.font.family,
              color: chart.chartOptions.plugins.submetric.color,
              textAlign: 'center',
            }
          }}
          InputProps={{
            disableUnderline: true,
            sx: {'&:hover':  {boxShadow: '0 0 10px 5px rgba(74,144,126,0.2)'}}
          }}

          value={chart.chartOptions.plugins.submetric.text}
          onChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: "submetric"})}
          variant="standard" />


        : ''}

      <Box component="div" sx={{
            // display: 'flex',
            // alignContent:
            position: "relative",
            height: '50%',
            width: '100%',
            maxHeight: '100px'
            // paddingX: 5,
            // paddingy: 5
          }}>
      <canvas
        style={{width: '100%', height: '100%', position: "relative"}}
        id={"KPIContainer-" + chart.chartSettings.updatedAt}
        ref={chartReference}
      />
    </Box>
     
      <Box
        display="flex"
        alignItems="flex-end"
        alignSelf="flex-end"
        justifyContent="flex-end"
        onClick={(e) => {setQuickEditor('subtitle'); e.stopPropagation()}}
        onMouseEnter={(e) => {setIsHoverEditable(true)}}
        onMouseLeave={(e) => {setIsHoverEditable(false)}}
        style={{
          display: chart.chartOptions.plugins.subtitle.display,
          fontWeight: chart.chartOptions.plugins.subtitle.font.weight,
          fontSize: chart.chartOptions.plugins.subtitle.font.size,
          fontStyle: chart.chartOptions.plugins.subtitle.font.style,
          fontFamily: chart.chartOptions.plugins.subtitle.font.family,
          alignSelf: chart.chartOptions.plugins.subtitle.align, //'center', 
          color: chart.chartOptions.plugins.subtitle.color, //'center', 
          alignContent: 'stretch',
          height: '20%',
          // padding: '15px',
          flexGrow: 1,
        }}> {chart.chartOptions.plugins.subtitle.display ? chart.chartOptions.plugins.subtitle.text : ''}</Box>
    </Container>

  </ElementContainer>

});

export default SparkContainer;

