import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { Analytics } from '../../Services/Analytics';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
margin: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.8rem' }} />}
    {...props}
  />
))(({ theme }) => ({
    marginLeft: 10,
    padding: 0,
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: '0px',
    // paddingLeft: 1,
    // color: 'red',
//   backgroundColor:
    // theme.palette.mode === 'dark'
    //   ? 'rgba(255, 255, 255, .05)'
    //   : 'rgba(0, 0, 0, .03)',
    whiteSpace: 'normal',
    //  width: 220, 
    fontSize: 14,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(0.5),
  },
'& .MuiTypography-root': {
    fontWeight: '500', 
    fontSize: 14,
    color: 'rgba(64,64,64,1)'
},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    // padding: theme.spacing(2),
    whiteSpace: 'normal',
    //  width: 220, 
    // borderTop: '1px solid rgba(0, 0, 0, .125)',
    '& .MuiTypography-root': {
        fontSize: 14,
        color: 'rgba(64,64,64,1)'
    },
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if(newExpanded) Analytics.trackClickedShare({questin: panel});
  };

  return (
    <Box sx={{marginLeft:1, marginRight: 2 }}>
        
        {/* <Box  > */}

          <Typography sx={{fontSize: 18, marginLeft: 1,  fontWeight: '500', marginY: 3, display: 'flex', alignItems: 'center', }}>
            <Box sx={{backgroundColor:'#FFDD00', width: 42, height: 42, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', marginRight: 2}}> 
                <TipsAndUpdatesOutlinedIcon sx={{fontSize: '18px', marginLeft: 0.5, color: 'rgba(64,64,64,1)'}}/>
            </Box>
          F.A.Q & Tips</Typography>
        {/* </Box> */}
        
      <Accordion expanded={expanded === 'how_do_data_professionals_use_dashing'} onChange={handleChange('how_do_data_professionals_use_dashing')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>How do data professionals use Dashing?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography >
          <b>Step 1:</b> Create a new board and fill out the requirements document
          <br/>
          <b>Step 2:</b> Based on those requirements, draw a rough mockup of your dashboard layout and charts
          <br/>
          <b>Step 3:</b> Share your design with your stakeholders and collect their feedback
          <br/>
          <b>Step 4:</b> iterate with your team on the design, business logic, and more
          <br/>
          <b>Step 5:</b> Everyone aligned? great! go ahead and build your dashboard with confidence
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'how_to_share_my_design_with_my_colleagues'} onChange={handleChange('how_to_share_my_design_with_my_colleagues')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>How to share my design with my colleagues</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Dashing let's you share your boards with specific people or make your board public. once shared, boards are available to collaborators in View Only mode. Multiple Editors features are coming soon!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'how_do_i_connect_my_data_into_dashing'} onChange={handleChange('how_do_i_connect_my_data_into_dashing')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>How do I connect my data into Dashing?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The simple answer is: you don’t need to connect your data. Dashing was build to help teams focus on business planning, design, and UX. Wrangling real-life data takes time, and people often get lost in details, which defeats Dashing’s purpose of rapid prototyping. Dashing has a strong and flexible mock-data generator that helps you mimic your data structure and concepts, without the hassle of queries and csv files
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}