import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TabIcon from '@mui/icons-material/Tab';
import { Divider } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from "@mui/material/IconButton";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Analytics } from '../Services/Analytics';
import { getBoard } from '../Services/DBService';
import { BoardsList, BoardStore } from '../Stores/Stores';

export const BoardSearch =  observer(({isLight}) => {

  const [open, setOpen] = React.useState(false);
  // const [myBoards, setOptions] = React.useState([]);
  // const loading = open && myBoards.length === 0;
  const navigate = useNavigate();

  // React.useEffect(() => {
  //   let active = true;

  //   // if (!loading) return undefined;
  //   // (async () => {
  //   //   if(BoardStore.board) // TODO remove this
  //   //   getUserBoards().then((boards) => {
  //   //     boards.sort((a, b) => b.updatedAt - a.updatedAt)
  //   //     setOptions(boards)
  //   //     active = false
  //   //   })
  //   //   if (active) {
  //   //     //   setOptions(boards)
  //   //     // setOptions([...topFilms]);
  //   //   }
  //   // })();
  //   Analytics.trackSearchBoards();

  //   return () => {
  //     active = false;
  //   };
  // }, [loading]);

  React.useEffect(() => {
    if (!open) {
      // setOptions([]);
      BoardsList.refresh()
    }
  }, [open]);

  return <Autocomplete
      id="search-boards"
      sx={{
        // width: '80%', margin: '0 auto',
        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            border: isLight ? '0.5px solid #ccc' : "1px solid black",
          }
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          "& > fieldset": {
            border: isLight ? '0.5px solid  #ccc' :  "1px solid #444",
            
          }
        }
      }}
      open={open}
      size={"small"}
      onOpen={() => { setOpen(true); }}
      onClose={() => { setOpen(false); }}

      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.title}
      onChange={(e, value) => {
        console.log("loading board", value.id)
        BoardStore.boardId = value.id
        getBoard(BoardStore.boardId).then(
          (boardFromDb) => {
            BoardStore.init(boardFromDb);
            navigate(`/board/${BoardStore.board.id}`)
          },
          (d) => console.log("Failed to load board"))
      }
      }

      // onSelect={(e) => console.log("selectedOption", e.target.value)}
      // options={options}
      options={BoardsList.boards}
      loading={BoardsList.loading}
      // options={myBoards.sort((a, b) => a.updatedAt > b.updatedAt)}
      // loading={loading}
      renderOption={(props, board) => (
        <Box component="li" id={board.id} {...props}
          sx={{ backgroundColor: board.id === BoardStore.board.id ? '#e8e8e8' : 'white' }}
          key={board.id}>
          <div>
            <Typography component={"div"} sx={{ fontSize: 14 }}>
              {board.title}
              {board.id === BoardStore.board.id ?
                <Chip size="small" label={"Current"} sx={{ fontSize: 9, height: 14, marginLeft: 1, backgroundColor: 'rgba(153, 209, 245, 1)' }} /> : ''}
            </Typography>


            <Typography color="textSecondary" sx={{ fontSize: 12, fontStyle: 'italic' }}>
              {/* {"Created at: " + moment.unix(board.updatedAt).format('MMM ddd, YY')} */}
              {"Edited " + moment.unix(board.updatedAt / 1000).fromNow()}
            </Typography>
          </div>

          <Divider></Divider>
        </Box>
      )}
      //   sx={{}}
      renderInput={(params) => (
        <TextField
          variant='outlined'
          {...params}
          //   label="Search Boards..."
          hiddenLabel
          placeholder={"Search Boards..."}
          inputProps={{
            ...params.inputProps,
            sx: {
              "&::placeholder": {
                color: isLight ? 'white' : 'inherit',
                opacity: 1,
              },}
          }}
          InputLabelProps={{
            style: {color: 'red'}
            // classes: {
            //     root: classes.label,
            //   }
          }}
          InputProps={{
            ...params.InputProps,
            // sx: { fontSize: 14, color: isLight ? 'white' : 'inherit', border: isLight ? 'white' : 'inherit', },
            sx: { fontSize: 14, color: isLight ? 'white' : 'inherit', border:  isLight ? '0.5px solid #ccc' : 'inherit', 
            
          },
            endAdornment: (
              <React.Fragment>
                {BoardsList.loading ? <CircularProgress color="inherit" size={20} /> : <SearchOutlinedIcon sx={{ color: isLight ? 'white' : '#888' }} />}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
})

const BoardsManager = observer(({ shrink, drawerClick }) => {
  const navigate = useNavigate();
  return (<>

    {shrink ? <>

    <Stack direction="column" alignItems={'center'} spacing={3}>

    <Tooltip title="New Board">
          {/* // onClick={() => { BoardStore.init(); navigate(`/board/${BoardStore.board.id}`); Analytics.trackClickNewBoard() }}> */}
          {/* onClick={() => { BoardStore.init(); navigate(`/board/${BoardStore.board.id}`); Analytics.trackClickNewBoard() }}> */}
    <IconButton sx={{marginX: 'auto'}} onClick={(e) => drawerClick()}>
        <AddCircleOutlineIcon sx={{width: '24px', height: '24px', color: 'rgba(64,64,64,1)'}}/>
      </IconButton>
      </Tooltip>
      <Tooltip title="Search your boards">
      <IconButton sx={{marginX: 'auto'}} onClick={(e) => drawerClick()}>
        <SearchIcon sx={{width: '24px', height: '24px', color: 'rgba(64,64,64,1)'}}/>
      </IconButton>
      </Tooltip>
      <Tooltip title="Chart tree">
      <IconButton sx={{marginX: 'auto'}} onClick={(e) => drawerClick()}>
        <TabIcon sx={{width: '18px', height: '18px', color: 'rgba(64,64,64,1)'}}/>
      </IconButton>
      </Tooltip>

    </Stack>
    </> : 
      <>
    <Box sx={{ width: '80%', margin: '0 auto', marginBottom: 0 }}>
      <Button variant="outlined"
        sx={{ width: '100%', fontSize: 12, fontWeight: 'bold', color: "rgb(44, 44, 44)", borderColor: "rgb(44, 44, 44)", }}
        onClick={() => { BoardStore.init(); navigate(`/board/${BoardStore.board.id}`); Analytics.trackClickNewBoard() }} //trackClickNewBoard
      >{shrink ? '+' : 'NEW BOARD +'}</Button>

    </Box>
    <Typography color="textSecondary" sx={{ fontSize: 12, fontStyle: 'italic', textAlign: 'center' }}>or</Typography>
    <Box sx={{width: '80%', margin: '0 auto',}}>
      <BoardSearch/>
    </Box>
</>}
  </>
  );
})


export default BoardsManager;