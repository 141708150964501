import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import ColorPickerWidget from './DashingColors';
import StyledToggleButtonGroup from './StyledToggleButtonGroup';



const FontStyleWidget = observer(({titleType, shouldDisplay, toggleDisplay, font, color, label, hideDisplayToggle, editMode})  => {

    return <>
        {/* <Paper elevation={0}
            style={{ width: '100%' }}
            sx={{
                display: 'flex', 
                alignItems: 'center',
                // alignSelf: 'flex-start',
                backgroundColor: 'rgba(0,0,0,0)',
                justifyContent: 'space-between',
                flexWrap: 'wrap'
            }} > */}
            <StyledToggleButtonGroup
                            onClick={e => e.stopPropogation()}
                            // txt={<Typography color="textSecondary" 
                            // label='start'
                            // onClick={(event) => {event.preventDefault()}} 
                            //  sx={{ fontSize: 12 }}>{label}</Typography>}
                            txt={label}
                            size="small"

                            aria-label="text formatting" >
                                
                            {hideDisplayToggle ? '' :
                            <ToggleButton key={`font-style-display`} value="display" aria-label="display"
                                selected={shouldDisplay}
                                onClick={(event) => {toggleDisplay(); event.preventDefault()}}>
                                {shouldDisplay ? <VisibilityOutlinedIcon sx={{ fontSize: 14 }} /> : <VisibilityOffOutlinedIcon sx={{ fontSize: 14 }} />}
                            </ToggleButton>
                            }
                            <ToggleButton key={`font-style-weight`} value="bold" aria-label="bold"
                                selected={font.weight === 'bold'}
                                onClick={(event) => BoardStore.toggleBold({titleType})}>
                                <FormatBoldIcon />
                            </ToggleButton>
                            <ToggleButton key={`font-style-style`} value="italic" aria-label="italic"
                                selected={font.style === 'italic'}
                                onClick={(event) => BoardStore.toggleItalic({titleType})}>
                                <FormatItalicIcon />
                            </ToggleButton>
                            <ToggleButton key={`font-style-color-${nanoid()}`} value="color" aria-label="color">
                                <ColorPickerWidget  hideArrow={true}
                                                    color={color ? color.color && Array.isArray(color.color) ? color.color : color : color}
                                                    updateColor={(color, e) => BoardStore.updateTitleColor({color, titleType, editMode})} />
                            </ToggleButton>
                        </StyledToggleButtonGroup>
    </>
})
export default FontStyleWidget;