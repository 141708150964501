import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import FontSelectWidget from './FontSelectWidget';
import FontStyleWidget from './FontStyleWidget';
import NumberInputWidget from './NumberInputWidget';


const EditMetricWidget = observer(({ titleObj, titleType, titleLabel }) => {
    return <div>
                <NumberInputWidget  numericValue={titleObj.text}
                                    onValueChange={(e) => BoardStore.updateTitle({text: e.target.value, titleType: titleType})}
                                    label={titleLabel} 
                                    allowText={true}
                                    inputAdornment={"  "}/> 

                <FontStyleWidget
                    titleType={titleType}
                    font={titleObj.font}
                    color={titleObj.color}
                    label={"Style"}
                    hideDisplayToggle={true}
                />
            
                <FontSelectWidget titleObj={titleObj} titleType={titleType} />
        </div>
}
)

export default EditMetricWidget;