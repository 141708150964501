
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from "react";
import '../../index.css';
import { BoardStore } from '../../Stores/Stores';
import EditChartSection from './EditorSections/EditChartSection';
import EditorSection from './EditorSections/EditorSection';
import EditTitleWidget from './EditorWidgets/EditTitleWidget';
import FontSelectWidget from './EditorWidgets/FontSelectWidget';
import FontStyleWidget from './EditorWidgets/FontStyleWidget';
// import DescriptionTextbox from '../Elements/TitleBox';
import NumberInputWidget from './EditorWidgets/NumberInputWidget';
// import PaddingWidget from './EditorWidgets/PaddingWidget';
import { FormGroup } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
// import persistSchema from '../Stores/PersistManager';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ColumnInputWidget, { ColumnDataTypeStyledMenu } from './EditorWidgets/ColumnInputWidget';
import DraggableList from './EditorWidgets/DraggableList';
// import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import ExpandOutlinedIcon from '@mui/icons-material/ExpandOutlined';
import { DASHING_DATA_TYPES } from '../../Utils/ElementsUtils';
import { ColumnDataTypeMenuWidget } from "./EditorWidgets/ColumnInputWidget";
// import EditIcon from '@mui/icons-material/Edit';

// const StyledMenu = styled((props) => (
//     <Menu
//       elevation={0}
//       anchorOrigin={{
//         vertical: 'bottom',
//         horizontal: 'right',
//       }}
//       transformOrigin={{
//         vertical: 'top',
//         horizontal: 'right',
//       }}
//       {...props}
//     />
//   ))(({ theme }) => ({
//     '& .MuiPaper-root': {
//       borderRadius: 6,
//       marginTop: theme.spacing(1),
//       minWidth: 240,
//       // padding: 4,
//       color:
//         theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
//       boxShadow:
//         'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
//       '& .MuiMenu-list': {
//         padding: '4px 0',
//       },
//       '& .MuiMenuItem-root': {
//         '& .MuiSvgIcon-root': {
//           fontSize: 18,
//           color: theme.palette.text.secondary,
//           marginRight: theme.spacing(1.5),
//         },
//         '&:active': {
//           backgroundColor: alpha(
//             theme.palette.primary.main,
//             theme.palette.action.selectedOpacity,
//           ),
//         },
//       },
//     },
//   }));

  const DataGridEditor = observer(({ chart }) => {
    // console.log("DataGridEditor", chart)
    return <>
      <EditorSection key="chart-style" cardid='chart-style' cardtitle='Table Style' sx={{ height: '100%' }}>
        <EditChartSection />
      </EditorSection>

      <EditorSection key="titles" cardid='titles' cardtitle='Table Title' m={0} p={0}>

        <EditTitleWidget key="title"
          titleObj={BoardStore.currentElem.chartOptions.plugins.title}
          titleType={"title"} titleLabel={"Table Title"} />

        <EditTitleWidget key="subtitle"
          titleObj={BoardStore.currentElem.chartOptions.plugins.subtitle}
          titleType={"subtitle"} titleLabel={"Table Subtitle"} />

        {/* {BoardStore.currentElem.columns.length} */}
      </EditorSection>

      <EditorSection key="headers-cells" cardid='headers-cells' cardtitle='Headers & Cells' m={0} p={0} accordionDetailsProps={{ sx: { padding: 2 } }}>
        {/* <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Column Headers</Typography> */}

        <FontStyleWidget
          titleType={"headers"}
          // shouldDisplay={BoardStore.currentElem.chartOptions.plugins.cells}
          hideDisplayToggle={true}
          font={BoardStore.currentElem.chartOptions.plugins.headers.font}
          color={BoardStore.currentElem.chartOptions.plugins.headers.color}
          label={"Headers Style"}
        />
        <FontSelectWidget titleObj={BoardStore.currentElem.chartOptions.plugins.headers} titleType={"headers"} />

        {/* <Typography sx={{ marginTop: 2, fontSize: 12, fontWeight: 'bold', color: 'rgba(68, 68, 68, 0.5)' }}>Cells Style</Typography> */}
        <Divider sx={{ marginY: 1 }}></Divider>
        <FontStyleWidget

          titleType={"cells"}
          // shouldDisplay={BoardStore.currentElem.chartOptions.plugins.cells}
          hideDisplayToggle={true}
          font={BoardStore.currentElem.chartOptions.plugins.cells.font}
          color={BoardStore.currentElem.chartOptions.plugins.cells.color}
          label={"Cells Style"}
        />
        <FontSelectWidget titleObj={BoardStore.currentElem.chartOptions.plugins.cells} titleType={"cells"} />

      </EditorSection>

      <EditorSection key="columns" cardid='columns' cardtitle='Table Columns' m={0} p={0} accordionDetailsProps={{ sx: { padding: 1 } }}>

        <FormGroup sx={{ marginX: 1 }}>

          <NumberInputWidget numericValue={BoardStore.currentElem.rows.length}
            onValueChange={(e) => BoardStore.updateRows(e.target.value)}
            label={"# of rows"}
          // inputAdornment={"1-100"}
          />

          <NumberInputWidget numericValue={BoardStore.currentElem.chartSettings.rowsPerPage}
            onValueChange={(e) => BoardStore.updateRowsPerPage(e.target.value)}
            label={<span>Rows per Page   <small>(1-100)</small></span>}
          // inputAdornment={"1-100"}
          />


          <FormControlLabel
            control={<ButtonGroup disableElevation variant="contained" onClick={() => { }}>
              <IconButton aria-label="distribute column" disableFocusRipple disableRipple sx={{ transform: 'rotate(90deg)', marginRight: 0.5, stroke: '#fff', strokeWidth: 0.5 }}
                onClick={() => BoardStore.distributeColumnsEvenly()}> <ExpandOutlinedIcon /> </IconButton>
            </ButtonGroup>}
            sx={{ justifyContent: 'space-between', marginLeft: 0, paddingX: 0 }}
            labelPlacement="start"
            label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>Disribute Columns Evenly</Typography>} />
        </FormGroup>

        <Divider sx={{ marginBottom: 2 }}></Divider>

        <AddColumnWidget chart={chart} />

        <ColumnListWidget chart={chart} />

      </EditorSection>

      <Divider sx={{ marginTop: 0 }}></Divider>

    </>
  })

  export default DataGridEditor;

const ColumnListWidget = observer(({chart}) => {
    return <DraggableList onMoveCol={(fromIndex, toIndex) => BoardStore.reorderColumns(fromIndex, toIndex)}
            items={BoardStore.currentElem.columns.map((col, idx) => {
            return <Box sx={{ marginTop: 0 }} key={`dg-col-${idx}`}>
                <ColumnInputWidget chart={chart} columnIdx={idx} />
            </Box>;
        })} />;
})


const AddColumnWidget = ({chart}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [columnDataType, setColumnDataType] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, selectedColumnDataType) => {
      setAnchorEl(null);
    };
    return <Box>
        <FormGroup sx={{ margin: 2 }}>

            <FormControlLabel
                control={<ButtonGroup disableElevation variant="contained" onClick={() => { } }>
                    <IconButton aria-label="add column" disableFocusRipple disableRipple sx={{ transform: 'scale(1.2)' }}
                        onClick={handleClick}> <AddCircleOutlineIcon /> </IconButton>
                </ButtonGroup>}
                sx={{ justifyContent: 'space-between', marginLeft: 0, paddingX: 0 }}
                labelPlacement="start"
                label={<Typography color="textSecondary" sx={{ fontSize: 14 }}>Add Column</Typography>} />
        </FormGroup>

        <ColumnDataTypeStyledMenu
            id="column-data-type"
            MenuListProps={{
                'aria-labelledby': 'column-data-type',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <ColumnDataTypeMenuWidget 
                chart={chart} 
                // dataType={chart.columns[columnIdx]?.dataType}
                
                dataTypes={DASHING_DATA_TYPES}
                handleClose={handleClose}
                onSelect={(columnIdx, dgType, dataType, valueOptions) => { BoardStore.addColumn(dgType, dataType, valueOptions); } } />

        </ColumnDataTypeStyledMenu>
    </Box>;
}

