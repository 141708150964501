import BarChartIcon from '@mui/icons-material/BarChart';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import PieChartIcon from '@mui/icons-material/PieChart';
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import TitleIcon from '@mui/icons-material/Title';
import UndoIcon from '@mui/icons-material/Undo';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PopupState, { bindHover, bindMenu } from 'material-ui-popup-state';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Analytics } from '../../Services/Analytics';
import { BoardStore } from '../../Stores/Stores';
// import {ReactComponent as KPIIcon} from '../Assets/svgs/kpi-icon.svg';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import SevenKOutlinedIcon from '@mui/icons-material/SevenKOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { BoardZoomWidget, printBoard } from '../Editors/EditorFactory';
import { IconFactory } from '../Elements/ChartIcons.js';
import ScreenshotMonitorOutlinedIcon from '@mui/icons-material/ScreenshotMonitorOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
const StyledMenu = styled((props) => (
  <HoverMenu

    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    // borderRadius: 6,
    fontSize: 12,
    // backgroundColor: "rgb(44, 44, 44)",
    border: 0,
    marginTop: theme.spacing(1),
    minWidth: 180,
    
    color: "rgb(44, 44, 44)", // theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      fontSize: 14,
      '& .MuiSvgIcon-root': {
        fontSize: 22,
        color: "rgb(44, 44, 44)", // theme.palette.grey[300],
      },
      '&:active': {
        // backgroundColor: 'rgba(68,68,68,1)'
        backgroundColor: 'rgba(100,100,100,1)'
      },
      
    },
  },
}));


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  
  
  '& .MuiToggleButtonGroup-grouped': {
    padding: theme.spacing(0.5),
    opacity: BoardStore.editMode ? 1 : 0.33,
    color: 'rgba(64,64,64,1)',
    border: 'none',

    '&:not(:first-of-type)': {
      //   borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      //   borderRadius: theme.shape.borderRadius,
    },
    '& .MuiSvgIcon-root': {
      // color: 'white',
      // color: 'rbga(64,64,64,1)',
      color: 'rgba(64,64,64,1) !important',
      opacity: 1,
      //   transform: 'scale(0.8)',
      fontSize: 24,
    },
    
    '& .MuiToggleButton-root': {
      color: 'rbga(64,64,64,1) !important',
      
      border: '0 !important',
    },
    '& .MuiToggleButtonGroup-root': {
      // color: 'white',
      // color: 'rbga(64,64,64,1) !important',
      // color: 'red',
      width: '20px !important',
      minWidth: 0,
      margin: 0,
      padding: 0,
    },
    '& .MuiButtonBase-root': {
      // color: 'white',
      color: 'rbga(64,64,64,1) !important',
      opacity: 1,
      width: '20px !important',
      minWidth: 0,
      margin: 0,
      padding: 0,
      '& .MuiToggleButton-root': {
        color: 'rbga(64,64,64,1) !important',
        // color: 'red',
        border: 0,
      },
    },
  },
}));

const pies = [
  // { label: 'Pie Chart', icon: <BarChartIcon />, onClick: () => BoardStore.addChart('pie', []) },
  { label: 'Pie Chart', icon: IconFactory('pie', []), onClick: () => BoardStore.addChart('pie', []) },
  // { label: 'Doughnut Chart', icon: IconFactory('doughnut', []), onClick: () => BoardStore.addChart('doughnut', []) },
]

const bars = [
  { label: 'Grouped Bar Chart', icon: IconFactory('bar', []), onClick: () => BoardStore.addChart('bar', []) },
  { label: 'Stacked Bar Chart', icon: IconFactory('bar', ["stacked"]), onClick: () => BoardStore.addChart('bar', ["stacked"]) },
  { label: 'Horizontal Bar Chart', icon: IconFactory('bar', ["horizontal"]), onClick: () => BoardStore.addChart('bar', ["horizontal"]) },
  { label: 'Horizontal Stacked', icon: IconFactory('bar', ["stacked", "horizontal"]), onClick: () => BoardStore.addChart('bar', ["stacked", "horizontal"]) },
  { label: 'Horizontal Stacked 100%', icon: IconFactory('bar', ["stacked", "horizontal", "100"]), onClick: () => BoardStore.addChart('bar', ["stacked", "horizontal", "100"]) },
  { label: 'Stacked 100%', icon: IconFactory('bar', ["stacked", "100"]), onClick: () => BoardStore.addChart('bar', ["stacked", "100"]) },
]

const lines = [
  { label: 'Line Chart', icon: IconFactory('line', []), onClick: () => BoardStore.addChart('line', []) },
  { label: 'Area Chart', icon: IconFactory('line', ["stacked"]), onClick: () => BoardStore.addChart('line', ["fill"]) },
  { label: 'Stacked Area', icon: IconFactory('line', ["stacked", "fill"]), onClick: () => BoardStore.addChart('line', ["stacked", "fill"]) },
]

const filters = [
  { label: 'Date Filter', icon: IconFactory('filter', ["date"]), onClick: () => BoardStore.addChart('filter', ['date']) },
  { label: 'Range Filter', icon: IconFactory('filter', ["range"]), onClick: () => BoardStore.addChart('filter', ["range"]) },
  { label: 'List Filter', icon: IconFactory('filter', ["list"]), onClick: () => BoardStore.addChart('filter', ["list"]) },
]

const kpis = [
  { label: 'Big Number', icon: IconFactory('kpi', []), onClick: () => BoardStore.addChart('kpi', []) },
  { label: 'BN with Sparkline', icon: IconFactory('kpi', ["line"]), onClick: () => BoardStore.addChart('kpi', ["line"]) },
  { label: 'BN with Sparkline area', icon: IconFactory('kpi', ["line", "area"]), onClick: () => BoardStore.addChart('kpi', ["line", "area"]) },
  { label: 'BN with Bars', icon: IconFactory('kpi', ["bar"]), onClick: () => BoardStore.addChart('kpi', ["bar"]) },
]

const ChartTypeMenu = (chartType, mainIcon, chartsOptions) => {

  const [galleryText, setGalleryText] = React.useState('Gallery...');
  return <PopupState variant="popover" popupId={`add-chart-popup-${chartType}`}>

    {(popupState) => (
      <React.Fragment>
        <ToggleButton value={chartType} aria-label={chartType} disabled={!BoardStore.editMode}
          // {...bindTrigger(popupState)}
          {...bindHover(popupState)}
          // onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
          sx={{
            color: 'rgba(64,64,64,1)',
            outline: 'none !important',
            opacity: BoardStore.editMode ? 1 : 0.33,
            padding: 1,
            border: 0,
            paddingRight: 0,
            '& .MuiSvgIcon-root': {
              color: 'rgba(44,44,44,0.87)',
              fontSize: 24,
            },
            // '&.Mui-disabled': {
            //   border: '0 !important',              
            // },
            '& .KeyboardArrowDown': {
              fontSize: '24px',
              stroke: 'white',
              strokeWidth: 0.5,
              marginLeft: -0.5,
              width: 18,
            }
          }}
          disableFocusRipple disableRipple>
          {mainIcon}
          <KeyboardArrowDown className={"KeyboardArrowDown"} />
        </ToggleButton>
        <StyledMenu {...bindMenu(popupState)}
          // popupState.open()
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <MenuList>
            {chartsOptions.map((chartType) =>
              <MenuItem key={`menu-item-${chartType.label}`}
                onClick={() => {
                  if (BoardStore.editMode) chartType.onClick();
                  popupState.close()
                }}>
                <ListItemIcon>
                  {/* <BarChartIcon /> */}
                  {chartType.icon}
                </ListItemIcon>
                <Typography variant="inherit">{chartType.label}</Typography>
              </MenuItem>)}
            <Divider sx={{ my: 0, backgroundColor: 'rgba(200, 200, 200, 0.5)' }} />
            <MenuItem onClick={() => { setGalleryText("Gallery is Coming Soon!"); Analytics.trackClickGallery(chartType.label) }}>
              <Typography variant="inherit">{galleryText}</Typography>
            </MenuItem>
          </MenuList>
        </StyledMenu>
      </React.Fragment>
    )}
  </PopupState>

}

const AddChartMenu = observer(() => {

  return (
    <Paper
      elevation={0}

      sx={{
        backgroundColor: 'transparent',
        display: 'flex',
        flexWrap: 'wrap',
        
      }}
    >
      <StyledToggleButtonGroup sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mx: 2 }}>

        <Tooltip title="Download screenshot" arrow>
          <ToggleButton value="italic" aria-label="italic">
            {/* <TableChartOutlinedIcon onClick={() => BoardStore.editMode ? BoardStore.addChart('datagrid', ['']) : ''} /> */}
            <DownloadOutlinedIcon sx={{transform: 'scale(1)'}} onClick={() => printBoard()}/>
          </ToggleButton>
        </Tooltip>
        <BoardZoomWidget />

      </StyledToggleButtonGroup>
      
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1, background: 'rgba(64,64,64, 1)' }} />
      <StyledToggleButtonGroup
        size="small"
        // value={alignment}
        exclusive
        // onChange={handleAlignment}
        aria-label="text alignment"
      >


        {ChartTypeMenu('bar', <BarChartIcon />, bars)}
        {ChartTypeMenu('line', <SsidChartIcon />, lines)}
        {ChartTypeMenu('pie', <PieChartIcon />, pies)}
        {/* {ChartTypeMenu('kpi', <KPIIcon  />, kpis)} */}
        {ChartTypeMenu('kpi', <SevenKOutlinedIcon />, kpis)}

        <Tooltip title="Table" arrow>
          <ToggleButton value="italic" aria-label="italic" disabled={!BoardStore.editMode}>
            <TableChartOutlinedIcon onClick={() => BoardStore.editMode ? BoardStore.addChart('datagrid', ['']) : ''} />
          </ToggleButton>
        </Tooltip>

        {/* <KPIIcon component={KPIIcon} inheritViewBox /> */}
        {/* <ToggleButton value="line" aria-label="centered" onClick={() => ChartStore.addChart('line', [])}>
            <SsidChartIcon />
            <KeyboardArrowDown />
          </ToggleButton> */}
        {/* <ToggleButton value="line" aria-label="centered" onClick={() => ChartStore.addChart('pie', [])}>
            <PieChartIcon />
            <KeyboardArrowDown />
          </ToggleButton> */}


        {/* TABLES */}
        {/* <ToggleButton value="line" aria-label="centered" onClick={() => ChartStore.addChart('pie', [])} disabled>
            <GridOnIcon disabled/>
            <KeyboardArrowDown />
          </ToggleButton> */}
        {/* <Tooltip title="Big Number" arrow>
          <ToggleButton value="italic" aria-label="italic">
            <OneKPlusOutlinedIcon sx={ {transform: 'scale(1.2)'}} onClick={() => BoardStore.editMode ? BoardStore.addChart('kpi', []) : ''}/>
          </ToggleButton>
          </Tooltip>
          <Tooltip title="Sparkline" arrow>
          <ToggleButton value="italic" aria-label="italic">
            <OneKPlusOutlinedIcon sx={ {transform: 'scale(1.2)'}} onClick={() => BoardStore.editMode ? BoardStore.addChart('spark', []) : ''}/>
          </ToggleButton>
          </Tooltip> */}

      </StyledToggleButtonGroup>
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1, background: 'rgba(64,64,64, 1)' }} />
      <StyledToggleButtonGroup
        size="small"
      >
        {/* {ChartTypeMenu('filter', <FilterAltIcon />, filters)} */}
        {ChartTypeMenu('filter', <FilterAltOutlinedIcon />, filters)}


        <Tooltip title="Text Box" arrow>
          <ToggleButton value="italic" aria-label="italic" disabled={!BoardStore.editMode}>
            <TitleIcon onClick={() => BoardStore.editMode ? BoardStore.addChart('textbox', []) : ''} />
          </ToggleButton>
        </Tooltip>

        <Tooltip title="Placeholder" arrow>
          <ToggleButton value="italic" aria-label="italic" disabled={!BoardStore.editMode}>
            <DisabledByDefaultOutlinedIcon onClick={() => BoardStore.editMode ? BoardStore.addChart('textbox', ['placeholder']) : ''} />
          </ToggleButton>
        </Tooltip>


        <Tooltip title="Card" arrow>
          <ToggleButton value="underlined" aria-label="underlined" disabled={!BoardStore.editMode}>
            <SquareOutlinedIcon onClick={() => BoardStore.editMode ? BoardStore.addChart('box', []) : ''} />
            {/* <KeyboardArrowDown /> */}
          </ToggleButton>
        </Tooltip>
      </StyledToggleButtonGroup>

      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1, background: 'rgba(64,64,64, 1)' }} />

      <StyledToggleButtonGroup size="small" >

        <Tooltip title="Move Backwards" arrow>
          <ToggleButton disabled={!BoardStore.editMode} value="toBack" aria-label="toBack" 
              onClick={() => { if (BoardStore.editMode) { BoardStore.zMinus(); BoardStore.rightDrawerOpen = true; 
              // Analytics.trackClickSendToBack() 
              } }}>
            <FlipToBackIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Move Forward" arrow>

          <ToggleButton disabled={!BoardStore.editMode} value="toFront" aria-label="toFront" onClick={() => {
            if (BoardStore.editMode) {
              BoardStore.zPlus();
              BoardStore.rightDrawerOpen = true;
              // Analytics.trackClickSendToFront()
            }
          }}>
            <FlipToFrontIcon />
          </ToggleButton>
        </Tooltip>

        <Tooltip disabled={!BoardStore.editMode} title="Undo" arrow>
          <ToggleButton value="underlined" aria-label="underlined">
            <UndoIcon onClick={() => BoardStore.loadStore()} />
          </ToggleButton>
        </Tooltip>

        <Tooltip title="Duplicate" arrow>
          <ToggleButton disabled={!BoardStore.editMode} value="underlined" aria-label="underlined" sx={{}}>
            <DifferenceOutlinedIcon sx={{ visibility: BoardStore.currentElem != null ? 'inherit' : 'hidden' }} onClick={() => { BoardStore.onCopy(); BoardStore.onPaste() }} />
          </ToggleButton>
        </Tooltip>

        {/* <ToggleButton value="underlined" aria-label="underlined">
            <RedoIcon />
          </ToggleButton> */}

      </StyledToggleButtonGroup>
    </Paper>
  );
})
export default AddChartMenu;