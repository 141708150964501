import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FormGroup, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import { alpha, styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import TextField from '@mui/material/TextField';
import {
  DataGrid, gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect } from 'react';
import { BoardStore } from '../../Stores/Stores';
import { ColumnDataTypeMenuWidget, EditColumnMenue } from '../Editors/EditorWidgets/ColumnInputWidget';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import { DASHING_DATA_TYPES, dataValueFormatter } from '../../Utils/ElementsUtils';


// { field: 'name', headerName: 'Name', width: 100, editable: true, sortable: false },
function CustomColumnMenuComponent(props) {
  const { hideMenu, currentColumn, color, chart, ...other } = props;
  let columnIdx = currentColumn.index;
  return <ClickAwayListener onClickAway={hideMenu} >
        <Box sx={{ marginTop: 1, border: '1px solid rgba(64,64,64,0.5)', borderRadius: 1, paddingX: 2, position: 'absolute', left: -20, backgroundColor: 'white'}}
          // onClick={(e) => { e.stopPropagation(); e.preventDefault() }}
          >

          <EditColumnMenue chart={chart} columnIdx={columnIdx} disableWidth={true} />

        </Box>
    </ClickAwayListener>
}

const EditableColumnHeader = ({ chart, column, onDisableDrag }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>

      <Tooltip title={column.description || "Column Description..."} arrow>
      <Box aria-describedby={id} variant="contained" onClick={handleClick} sx={{ display: 'flex', width: '100%', flexGrow: 1 }}>
        <TextField size="small"
          key={`column-header-input-${column.index}`}
          // key={`column-header-input-${column.field}`}
          sx={{ width: `${column.width - 30}`, alignSelf: 'flex-start', marginTop: '4px' }}
          variant="standard"
          margin="dense"
          value={column.headerName}
          onKeyDown={e => { e.stopPropagation(); }}
          onKeyUp={e => { e.stopPropagation(); }}
          onChange={(event) => { BoardStore.updateTableColumnHeader(column.index, event.target.value); event.stopPropagation(); }}
          InputProps={{
            style: {
              fontSize: chart.chartOptions.plugins.headers.font.size,
              fontWeight: chart.chartOptions.plugins.headers.font.weight,
              color: chart.chartOptions.plugins.headers.color,
              
            },
            disableUnderline: true,

          }}
          inputProps={{
            style: { fontSize: 12, paddingBottom: 3, textAlign: column.align },
          }}
        />
        {/* <ColumnDrag onDisableDrag={onDisableDrag} style={{alignSelf: 'flex-end'}} column={column} flexItem onHover={(e)=> console.log("hover column drag")}/> */}

      </Box>
      </Tooltip>
      {/* </Popper> */}
    </div>
  );

}


// function CustomHeader() {
//   return <Box sx={{ width: '100%', backgroundColor: 'red' }}> AAAA</Box>
// }


function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      // color="primary"
      // boundaryCount={0}
      // siblingCount={1}
      variant="outlined"
      shape="rounded"
      size='small'
      page={page + 1}
      // count={pageCount}
      count={pageCount}
      renderItem={(props2) => <PaginationItem sx={{ border: '1px solid #ccc', color: 'rgba(64,64,64,1)' }} {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}


const ColumnDrag = ({ column, onDisableDrag }) => {
  // console.log("ColumnDrag", column)
  const [isDraggingColumn, setIsDraggingColumn] = React.useState(false)
  const [mouseDownX, setMouseDownX] = React.useState(null)
  // return <HeightIcon onMouseDown={() => setDisableDragging(true)} onMouseUp={() => setDisableDragging(false)} />
  return <Box sx={{ paddingX: 1, color: 'red', cursor: 'col-resize', }} flexItem
    onMouseDown={(e) => { e.preventDefault(); setIsDraggingColumn(true); onDisableDrag(true); setMouseDownX(e.clientX); console.log("onMouseDown", e.clientX) }}
    onMouseUp={() => { setIsDraggingColumn(false); onDisableDrag(false) }}
    onMouseMove={(e) => { if (isDraggingColumn && mouseDownX != null) { e.preventDefault(); column.width += (e.clientX - mouseDownX); setMouseDownX(e.clientX) } }}
    onMouseOut={(e) => { setIsDraggingColumn(false); setMouseDownX(null) }}   >

    <Button variant='outline' sx={{ width: '15px', paddingX: '5px', minWidth: 'unset' }}>|</Button>

  </Box>
}

const GridChart = observer(({ chart, onDisableDrag }) => {

  // chart.chartSettings.isDataDraggable = false;




  const getGridColumns = () => {
    // chart.columns.forEach((column, idx) => addValueFormatter(column))
    return chart.columns.map((col, idx) => {
      // console.log("getGridColumns", col)
      return {
        ...col,
        valueFormatter: (col) => dataValueFormatter(chart.columns.find((x) => x.field === col.field)?.dataType, col.value ),
        renderHeader: () => <EditableColumnHeader column={col} chart={chart} onDisableDrag={onDisableDrag} />
      }
    })
  }

  useEffect(() => {

  }, [chart.chartSettings.renderedAt,
  chart.chartSettings.updatedAt, chart.chartSettings.rowsPerPage, chart.rows.length]);

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
      <div style={{ flexGrow: 1 }}>

        <DataGrid
          onClick={(e) => e.stopPropagation()}
          // key={`dg-${chart.chartSettings.updatedAt}`}
          components={{
            ColumnMenu: CustomColumnMenuComponent,
            Pagination: CustomPagination,
            // ColumnResizeIcon: ()=><div></div>
            // ColumnResizeIcon: ColumnDrag
            // Header: CustomHeader,
          }}

          componentsProps={{
            // ColumnResizeIcon: { foo: 2, setDisableDragging: setDisableDragging },
            columnMenu: { 'chart': chart },
          }}
          pageSize={chart.chartSettings.rowsPerPage || 10}
          // rowsPerPageOptions={[chart.chartSettings.rowsPerPage || 10]}
          rowsPerPageOptions={[5, 10, 20, 25, 30, 40, 50, 100]}
          // autoHeight
          // autoPageSize
          rows={chart.rows}
          // columns={chart.columns}
          columns={getGridColumns(chart.columns)}
          experimentalFeatures={{ newEditingApi: true }}

          // chart.chartSettings.isDataDraggable
          // onColumnHeaderClick={(params, e) => { console.log("onColumnHeaderClick", params); e.stopPropagation() }}
          // onColumnHeaderEnter={(params, e) => {console.log("onColumnHeaderEnter"); chart.chartSettings.isDataDraggable =false; e.stopPropagation()}}
          // onColumnHeaderLeave={(params, e) => {chart.chartSettings.isDataDraggable =true; e.stopPropagation()}}
          // columnHeaderDragStart={console.log("columnHeaderDragStart")}
          // columnHeaderDragStart={chart.chartSettings.isDataDraggable =false}
          // columnHeaderDragStart={() => {chart.chartSettings.isDataDraggable =false; console.log("columnHeaderDragStart")}}
          // columnHeaderDragEnd={console.log("columnHeaderDragEnd")}

          rowHeight={30}
          headerHeight={30}
          // hideFooter

          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }

          // hideFooterPagination
          sx={{
            // '& .MuiDataGrid-footerContainer' : {
            //   display: 'none'
            // },
            '& .odd': { backgroundColor: 'rgba(200,200,200,0.2)', border: 'none' },
            '& .even': { border: 'none' },
            WebkitFontSmoothing: 'auto',
            letterSpacing: 'normal',
            borderRadius: 0,
            margin: 1,
            // fontSize: 12,
            fontSize: chart.chartOptions.plugins.cells.font.size,
            fontFamily: chart.chartOptions.plugins.cells.font.family,
            color: chart.chartOptions.plugins.cells.color,
            fontWeight: chart.chartOptions.plugins.cells.font.weight,
            // borderColor: '#ccc',
            border: 'none',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
              border: 'none',
            },
            '&>.MuiDataGrid-main': {
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: chart.chartOptions.plugins.headers.font.size,
                fontWeight: chart.chartOptions.plugins.headers.font.weight,
                color: chart.chartOptions.plugins.headers.color,
              },
              '&>.MuiDataGrid-columnHeaders': {
                borderBottom: 'none',
              },

              '& div div div div >.MuiDataGrid-cell': {
                borderBottom: 'none',
              },
            },
            boxShadow: 0,
          }}
        />
      </div>
      <AddColumnDataTypeMenue chart={chart}  />
    </div>
  );
})


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 240,
    // padding: 4,
    color:
      theme.palette.mode === 'lighter' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const AddColumnDataTypeMenue = observer(({ chart, columnIdx }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [columnDataType, setColumnDataType] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, selectedColumnDataType) => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '30px' }}>
      <FormGroup sx={{ margin: 0 }}>

        <FormControlLabel
          control={<ButtonGroup disableElevation variant="contained" onClick={() => { }}>
            <IconButton aria-label="add column" disableFocusRipple disableRipple sx={{ transform: 'scale(1)', margin: 0, }}
              onClick={handleClick}> <AddCircleOutlineIcon /> </IconButton>
          </ButtonGroup>}
          sx={{ justifyContent: 'space-between', margin: 0, paddingX: 0 }}
        />
      </FormGroup>
      <StyledMenu
        id="column-data-type"
        MenuListProps={{
          'aria-labelledby': 'column-data-type',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <ColumnDataTypeMenuWidget 
            dataTypes={DASHING_DATA_TYPES}
            handleClose={handleClose} 
            onSelect={(columnIdx, dgType, dataType, valueOptions) => {BoardStore.addColumn(dgType, dataType, valueOptions)} }/>
      </StyledMenu>
    </Box>
  );
})


// function CustomToolbar() {
//   return (
//     <div>Custom actions for</div>
//   );
// }

// column type:
// 'string' (default)
// 'number'
// 'date'
// 'dateTime'
// 'boolean'
// 'singleSelect'
// 'actions'

// const currencyFormatter = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'USD',
// });

// const usdPrice = {
//   type: 'number',
//   width: 70,
//   valueFormatter: ({ value }) => currencyFormatter.format(value),
//   cellClassName: 'font-tabular-nums',
// };

// const columns = [
//   { field: 'name', headerName: 'Name', width: 100, editable: true, sortable: false },
//   // { field: 'age', headerName: 'Age', type: 'number', editable: true, sortable: false },
//   { field: 'age', headerName: 'Age', editable: true, ...usdPrice },
//   {
//     field: 'dateCreated',
//     headerName: 'Date Created',
//     type: 'date',
//     width: 180,
//     editable: true, sortable: false
//   },{
//     field: 'country',
//     type: 'singleSelect',
//     editable: true,
//     valueOptions: ['United Kingdom', 'Spain', 'Brazil']
//   },

//   {
//     field: 'lastLogin',
//     headerName: 'Last Login',
//     type: 'dateTime',
//     width: 220,
//     editable: true, sortable: false

//   },
// ];

// function randomTraderName(){
//   return "aaaa"
// }
// function randomCreatedDate(){
//   return new Date()
// }
// function randomUpdatedDate(){
//   return new Date()
// }


// function getRows(len){
//   let res = [];
//   for(let i =0; i<len; i++){
//     res.push({
//       id: i,
//       name: randomTraderName(),
//       age: 25,
//       dateCreated: randomCreatedDate(),
//       lastLogin: randomUpdatedDate(),
//     },)
//   }
//   return res
// }



// const rows = getRows(10)

export default GridChart;