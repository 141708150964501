import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { BoardStore } from '../../../Stores/Stores';

const PaddingWidget = observer(({ titleObj, titleType, allowXaxis, editMode }) => {

  const tg = (direction, rotate) => {
    // if (titleObj == undefined) {
    //   console.log("tg", titleObj)
    // }
    return <>
      <Stack direction="column" spacing={0}
        sx={{ justifyContent: 'space-between', marginRight: 0, marginY: 0, width: 40, }} >

        <TextField
          size="small"
          type="number"
          variant="standard"
          sx={{ alignItems: 'center', input: { textAlign: "center", paddingX: 1, paddingY: 1.5, }, textAlign: 'center', '& .MuiFormHelperText-root': { margin: 0 } }}
          helperText={<span style={{ fontSize: 9, textAlign: 'center', margin: 0 }}>{direction}</span>}
          value={titleObj.padding[direction]}
          onChange={(event) => BoardStore.updatePadding({titleType, direction, value: event.target.value, editMode})}
          inputProps={{ style: { fontSize: 11, textAlign: 'center', margin: 0, padding: 0, width: '2ch', maxWidth: '2ch' } }}
          InputProps={{
            // disableUnderline: true,
            inputMode: "number",
            pattern: '[0-9]*',
            sx: { textAlign: 'center', padding: '2px' },
            min: 0,
            // endAdornment: <InputAdornment position="end">
            //   <Box componant="span" sx={{ fontSize: 10, textAlign: 'left' }}> px</Box>
            // </InputAdornment>          
          }} />
        {/* <PaddingOutlinedIcon sx={{ transform: `rotate(${rotate}deg)`, fontSize: 18, strokeWidth: 1, color: 'rgba(0, 0, 0, 0.8)' }} /> */}
        {/* <Box componant="span" sx={{ fontSize: 11, textAlign: 'left' }}> px</Box> */}
      </Stack>
    </>
  }

  return <FormGroup sx={{ marginTop: 1 }}>
    <FormGroup sx={{ margin: 0 }}>
      <FormControlLabel
        control={
          <>
            {allowXaxis? tg('left', 90) : ''}
            {tg('bottom', 0)}
            {allowXaxis? tg('right', -90) : ''}
            {tg('top', -180)}
          </>
        }
        sx={{ justifyContent: 'flex-end', marginLeft: 0 }}
        labelPlacement="start"
        label={<Typography color="textSecondary" sx={{ fontSize: 12, flexGrow: 1, alignSelf: 'flex-start' }}>Padding</Typography>} />
        
    </FormGroup>
  </FormGroup>;
});

export default PaddingWidget;
