import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { BoardStore } from '../../../Stores/Stores';

const DatePickerWidget = observer(({ pluginName, dateLabel, dateObj }) => {

  const [open, setOpen] = React.useState(false);


  const handleChange = (newValue) => {
    // datePicker.date = newValue;
    BoardStore.updateDate(pluginName, newValue)
  };

  return <FormControlLabel
    control={<DesktopDatePicker
      inputFormat="DD/MM/YYYY"
      key={"datepicker-" + pluginName}
      value={dateObj}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      size="small"
      // alignSelf={datePicker.align}
      // sx={{alignSelf: datePicker.align}}
      onChange={handleChange}
      renderInput={(props) => <TextField
        key={"datepicker-" + pluginName}
        type="text"
        inputRef={props.inputRef}
        inputProps={{
          ...props.inputProps,
          sx: { padding: 0, paddingLeft: 1, margin: 0, width: '10ch', fontSize: 14 }
        }}

        // value={props.value}
        value={dateObj}
        sx={{ margin: 0, padding: 0, height: '22px' }}
        // onClick={props.onClick}
        onChange={props.onChange}
        // inputFormat="DD/mm/yyyy"
        onClick={(e) => setOpen(true)}
        InputProps={{ sx: { padding: 0, margin: 0, paddingRight: 0.1 }, endAdornment: <CalendarMonthIcon sx={{ transform: 'scale(0.8)', color: '#444' }} /> }}

      ></TextField>} />}
    labelPlacement="start"
    label={!dateLabel.display ? '' : <Box sx={{
      fontWeight: dateLabel.font.weight,
      fontSize: dateLabel.font.size,
      fontStyle: dateLabel.font.style,
      fontFamily: dateLabel.font.family,
      color: dateLabel.color, //'center', 

      // alignSelf: chart.chartOptions.plugins.title.align, //'center', 

      marginRight: 1
    }}>{dateLabel.display ? dateLabel.text : ''}</Box>} />;
})
export default DatePickerWidget;