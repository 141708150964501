import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { Divider, FormHelperText } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import { observer } from "mobx-react-lite";
import * as React from 'react';
import { useEffect } from 'react';
import { BoardStore, UserStore } from '../Stores/Stores';
// import { UserSourceForm } from '../Stores';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DoneIcon from '@mui/icons-material/Done';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { sendMagicLink, updateSignupQuestion } from '../Services/DBService';
import { Analytics } from '../Services/Analytics';
const Transition = React.forwardRef(function Transition(props, ref) {
    //   return <Slide direction="down" ref={ref} {...props} />;
    return <Collapse direction="down" ref={ref} {...props} />;
});


const AuthInputLabel = ({ label }) => <Typography sx={{ fontSize: 14, fontWeight: '700', color: 'rgba(64,64,64,1)', }}>{label}</Typography>;

const AuthInput = ({ placeholder, label, onUpdate, errorMsg }) => {
    return <FormControlLabel
        sx={{ alignItems: 'start', display: 'flex', width: '100%', marginBottom: 2, marginX: 0, }}
        labelPlacement="top"

        control={<TextField
            size='small'
            error={errorMsg}
            // placeholder={placeholder}
            placeholder={placeholder}
            helperText={errorMsg}
            // value={value}
            onChange={(e) => onUpdate(e.target.value)}
            fullWidth
            required
            inputProps={{
                sx: { '::placeholder': { fontSize: 14, } }
            }}
        />}
        label={<AuthInputLabel label={label} />}
    />
}

const ConnectTitle = () => {
    return <>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            {/* <Box sx={{ fontSize: 18, color: 'rgba(64,64,64,1)', marginX: 'auto', textAlign: 'center', fontWeight: '300', fontStyle: 'italic', marginTop: 3 }}>Customize, Save, and Collaborate your <br /> design like a data professional</Box> */}
        </Box>
    </>
}

const SuccessTitle = () => {
    return <>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            {/* <CheckCircleOutlineIcon sx={{ color: '#0ABF53', fontSize: 90, stroke: 'white', }} /> */}
            <Box sx={{ fontSize: 24, fontWeight: 500, color: 'rgba(64,64,64,1)', marginX: 'auto', }}>You are now officially Dashing 🤩</Box>
            <Box sx={{ border: '2px solid #0ABF53', borderRadius: '50%', margin: '20px', padding: '20px' }}>
                <DoneIcon sx={{ color: '#0ABF53', fontSize: 90, stroke: 'white' }} />
            </Box>
            {/* <Divider sx={{ color: 'rgba(64,64,64, 0.6)', fontSize: 12, marginY: 2 }}></Divider> */}
        </Box>
    </>
}
const EmailSentTitle = () => {
    return <>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Box sx={{ border: '2px solid #0ABF53', borderRadius: '50%', margin: '30px', padding: '30px' }}>
                <MarkEmailReadOutlinedIcon sx={{ color: '#0ABF53', fontSize: 90, stroke: 'white' }} />
            </Box>
            <Box sx={{ fontSize: 18, fontWeight: 500, color: 'rgba(64,64,64,1)', marginX: 'auto', }}>Done! Check your inbox</Box>
            {/* <Divider sx={{ color: 'rgba(64,64,64, 0.6)', fontSize: 12, marginY: 2 }}></Divider> */}
        </Box>
    </>
}

const SignUpForm = () => {
    // {/* TODO: Add notice on pricavy link and sla */}
    // const [isSignUp, setIsSignUp] = React.useState(true); // sign up or login
    // const [showPassword, setShowPassword] = React.useState(false);

    // const handleClickShowPassword = () => setShowPassword((show) => !show);

    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    // };

    const [email, setEmail] = React.useState();
    const [emailErrorMessage, setEmailErrorMessage] = React.useState();
    // const [name, setName] = React.useState();
    const [emailSent, setEmailSent] = React.useState(false);
    // const [password, setPassword] = React.useState();

    const onEmailSubmit = (e) => {
        // TODO validations....
        // "please enter a valid email address"
        e.preventDefault();
        // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        if (/^\S+@\S+$/.test(email)) {
            setEmailSent(true)
            sendMagicLink(email)
            Analytics.trackSendMagicLink({email})
        } else {
            setEmailErrorMessage("Invalid email, please try again")
        }
        // isSignUp ? emailSignUp(email, password) : emailSignIn(email, password);
        // emailSignUp(email)
        // console.log("onEmailSubmit ---- ", "isSignup", isSignUp, 'name', name, "email", email, "password", password)
    }

    // useEffect(() => setEmailSent(false), [BoardStore.showAuthDialog])

    return <>
        <Box sx={{ fontSize: 24, color: 'rgba(64,64,64,1)', marginX: 'auto', marginY: 2, textAlign: 'center' }}>
            {/* {isSignUp ? 'Create an Account' : 'Welcome Back'} */}
            Let's start Dashing
        </Box>
        <Box sx={{ marginTop: 3, marginBottom: 0, display: 'flex', justifyContent: 'center', }}>
            <GoogleAuthButton />
        </Box>

        <Divider sx={{ color: 'rgba(64,64,64, 0.6)', fontSize: 12, marginY: 4 }}>OR</Divider>

        <Box sx={{ paddingX: '10%', marginBottom: 4, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', fontSize: 14 }}>

            <Box component="form" sx={{ width: '100%', paddingX: 4 }}>
                {/* {isSignUp ? <AuthInput label={"Name"} value={name} onUpdate={setName} /> : ''} */}

                {/* <InputLabel htmlFor="component-helper">Name</InputLabel> */}



                {emailSent ?
                    <EmailSentTitle />
                    : <>
                        <AuthInput placeholder={"name@work-emal.com"} value={email} onUpdate={(email) => { setEmail(email); setEmailErrorMessage() }} errorMsg={emailErrorMessage} />
                        <Button disableRipple disableElevation
                            type="submit" variant="contained"
                            sx={{ textTransform: 'none', 
                                    border: '1px solid #0ABF53', 
                                    // backgroundColor: 'white', 
                                    // color: '#0ABF53', 
                                    backgroundColor: '#0ABF53', color: 'white',
                                    fontSize: 14, width: '100%', my: 0, 
                                    ':hover': { backgroundColor: '#0ABF53', color: 'white' } }}
                            onClick={onEmailSubmit}>

                            {/* {isSignUp ? 'SIGN UP' : 'LOG IN'} */}
                            <AutoFixHighOutlinedIcon sx={{ fontSize: 18, marginRight: 1 }} />
                            Email me a Magic Link
                        </Button>
                        <FormHelperText id="component-helper-text" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            <AutoAwesomeOutlinedIcon sx={{ fontSize: 14 }} />
                            <Box component={'span'}> We'll send you a link for a password-free sign in</Box>
                        </FormHelperText>

                    </>}

                {/*  */}
                {/* <FormControlLabel required
                    sx={{ alignItems: 'start', marginX: 0, display: 'flex', width: '100%', marginBottom: 2, '& .MuiFormHelperText-root': { margin: 0 } }}
                    labelPlacement="top"
                    control={<TextField
                        fullWidth
                        helperText={isSignUp ? '' : //todo: password verification 
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: 12, '& .MuiFormHelperText-root': { margin: 0 } }}>
                                <Typography sx={{ fontSize: 12 }} color={'textSecondary'}>{'forgot password?'}</Typography>
                                <Button disableFocusRipple disableRipple disableElevation sx={{ textTransform: 'none', fontSize: 12, padding: 0, ':hover': { backgroundColor: 'white' } }} >
                                    <u>reset</u>                                    
                                </Button>
                            </Box>}
                        // label={'Password'}
                        size={'small'}
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="standard-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        inputProps={{
                            type: "password",
                            sx: { '& .MuiFormHelperText-root': { margin: 0 } }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} >
                                    {showPassword ? <VisibilityOff sx={{ fontSize: 18 }} /> : <Visibility sx={{ fontSize: 18 }} />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />}
                    label={<AuthInputLabel label={'Password'} />}
                /> */}


            </Box>
        </Box>
        {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 14 }}>
            <Typography color={'textSecondary'} sx={{ fontSize: 14 }}>{isSignUp ? 'Allready have an accuont?' : 'No account yet?'}</Typography>
            <Button disableFocusRipple disableRipple disableElevation sx={{ fontSize: 14, textTransform: 'none', ':hover': { backgroundColor: 'white' } }} onClick={() => setIsSignUp(!isSignUp)}>
                <u>{isSignUp ? 'Log In' : 'Sign Up'}</u>
            </Button>
        </Box> */}
    </>
}

const rolesOptions = [
    { value: 'data_manager', label: 'Data Manager' },
    { value: 'data_engineer', label: 'Data Engineer' },
    { value: 'data_analyst', label: 'Data Analyst' },
    { value: 'data_scientist', label: 'Data Scientist' },
    { value: 'business_user', label: 'Business User' },
    { value: 'other', label: 'Other' },
    { value: '', label: '' },
];

const toolsOptions = [
    { value: 'tablaue', label: 'Tableau' },
    { value: 'looker', label: 'Looker' },
    { value: 'power_bi', label: 'Power BI' },
    { value: 'qliksense', label: 'QlikSense' },
    { value: 'sisense', label: 'Sisense' },
    { value: 'cognos', label: 'Cognos' },
    { value: 'alteryx', label: 'Alteryx' },
    { value: 'sas', label: 'SAS' },
    { value: 'other', label: 'Other' },
    { value: '', label: '' },
];

const sourcesOptions = [
    { value: 'google', label: 'Google Search' },
    { value: 'blog', label: 'Blog' },
    { value: 'colleagues', label: 'Colleagues' },
    { value: 'social', label: 'Social Media' },
    { value: 'ad', label: 'Online Advertisement' },
    { value: 'other', label: 'Other' },
    { value: '', label: '' },
];

const PostConnectForm = ({ handleClose }) => {

    const [role, setRole] = React.useState("");
    const [tool, setTool] = React.useState('');
    const [source, setSource] = React.useState('');
    const [userName, setUserName] = React.useState('');

    return <>

        <Box sx={{ width: '100%', marginBottom: 0, display: 'flex', fontSize: 14, flexDirection: 'column', justifyContent: 'center' }}>

            {/* <Divider sx={{ color: 'rgba(64,64,64, 0.6)', fontSize: 12, marginY: 2 }}></Divider> */}

            <Collapse in={true} sx={{}}>
                <Box sx={{ paddingX: '10%', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography sx={{ width: '100%', color: 'rgba(64,64,64,1)', marginBottom: 2, textAlign: 'center', marginX: 0, }}>Help us better fit your needs: </Typography>

                    {!UserStore.displayName && <FormControlLabel
                        sx={{ alignItems: 'start', display: 'flex', width: '100%', marginBottom: 2, marginX: 0, }}
                        labelPlacement="top"
                        control={<TextField
                            size='small'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            fullWidth
                            onBlur={() => UserStore.updateUserName(userName)}
                        />}
                        label={<AuthInputLabel label={"Full Name"} />}
                    />}

                    <AuthFormSelect label={'I am a...'}
                        // selected={UserStore.sourceForm.role}
                        // onSelect={(val) => UserStore.sourceForm.update('role', val)}
                        selected={role}
                        onSelect={(val) => { setRole(val); updateSignupQuestion('role', val) }}
                        placeHolder={'Data Manager / Data Engineer / ...'}
                        options={rolesOptions} />

                    <AuthFormSelect label={'We build Dashboards in...'}
                        placeHolder={'Tablaue / Power BI / ...'}
                        selected={tool}
                        onSelect={(val) => { setTool(val); updateSignupQuestion('tool', val) }}
                        // selected={UserStore.sourceForm.tool}
                        // onSelect={(val) => UserStore.sourceForm.update('tool', val)}
                        options={toolsOptions} />

                    <AuthFormSelect label={'I heard about Dashing through...'}
                        placeHolder={'Social Media / Colleagues / ...'}
                        // selected={UserStore.sourceForm.source}
                        // onSelect={(val) => UserStore.sourceForm.update('source', val)}
                        selected={source}
                        onSelect={(val) => { setSource(val); updateSignupQuestion('source', val) }}
                        options={sourcesOptions} />


                    {role && tool && source ?
                        <Button variant="contained" size="large" onClick={handleClose}
                            sx={{ backgroundColor: '#0ABF53', width: '100%', ':hover': { backgroundColor: '#0ABF53', }, justifyItems: 'center' }}>
                            Get Started
                        </Button>
                        :
                        <Button size="large"
                            onClick={handleClose} 
                            disableFocusRipple disableRipple
                            sx={{
                                fontSize: 14, textTransform: 'none', marginTop: 2, color: 'rgba(64,64,64,0.87)',
                                width: '16ch', height: '1em', ':hover': { color: 'rgba(64,64,64,0.87)', backgroundColor: 'white' }, justifyItems: 'center'
                            }}>
                            <u>Skip Questions</u>
                        </Button>

                    }
                </Box>
            </Collapse>
        </Box>
    </>
}


const AuthFormSelect = observer(({ label, placeHolder, options, selected, onSelect }) => {
    // const [option, setOption] = React.useState({});

    const handleChange = (event) => {
        console.log("AuthFormSelect", label)
        onSelect(event.target.value);
    };

    React.useEffect(() => { }, [selected]);

    return (
        <>
        <FormControlLabel required
            sx={{ alignItems: 'start', display: 'flex', width: '100%', marginBottom: 2, marginX: 0, }}
            labelPlacement="top"
            size='small'
            control={<Select
                id="demo-simple-select"
                size="small"
                fullWidth
                placeholder={'Social netwrok / Colleagues / ...'}
                displayEmpty
                value={selected}
                onChange={handleChange}
                renderValue={(foo) => options.find((op) => op.value === foo)?.label} //|| <Typography sx={{color: '#888', fontSize: 14, }}>{placeHolder}</Typography>}
                >
                {options.map((option) => <MenuItem key={`auth-option-${option.value}`} value={option.value}>{option.label}</MenuItem>)}
            </Select>}
            label={<Typography sx={{ fontWeight: '700', color: 'rgba(64,64,64,1)', }}>{label}</Typography>}
            />
            
            </>
    );

})

const GoogleAuthButton = () => <div id="google-auth-btn"></div>


export const AuthForm = observer(() => {
    return <Container sx={{ width: '500px', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 0, }} >
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, width: '100%', marginX: 'auto' }}>
            {UserStore.isAuthed ? <SuccessTitle /> : <ConnectTitle />}
        </Box>
        {UserStore.isAuthed ? <PostConnectForm handleClose={() => BoardStore.showAuthDialog = false} /> : <SignUpForm />}
        <IconButton aria-label="delete" size="large"
            onClick={() => BoardStore.showAuthDialog = false}
            disableFocusRipple
            disableRipple
            sx={{ position: 'absolute', zIndex: 100, right: 5, top: 5, }}>
            <ClearIcon fontSize="inherit" sx={{ color: 'rgba(100, 100, 100, 0.7)' }} /></IconButton>
            <Box sx={{textAlign: 'center', marginTop: 3}}>
                <Typography sx={{ fontWeight: '400', fontSize: 12, color: 'rgba(100,100,100,0.7)', }}>
                        By signing in you agree to our 
                        <a style={{color: 'inherit', marginLeft: 4}} href='https://www.dashing.app/terms-and-conditions' target={'_blank'} rel="noreferrer">
                            terms and conditions
                            </a>                
                ,<br/> and acknowledge that Dashing is in beta mode and should be used with discretion
                </Typography>
            </Box>
    </Container>
})


const AuthDialog = observer(() => {
    // const [isSignUp, setIsSignUp] = React.useState(true); // sign up or login
    const [renderedGoogleBtn, setRenderedGoogleBtn] = React.useState(false); // sign up or login


    const handleClose = () => {
        // setIsSignUp(false);
        BoardStore.showAuthDialog = false
    };

    useEffect(() => {
        /*global google*/
        if (BoardStore.showAuthDialog && !renderedGoogleBtn && window.google) {

            google.accounts.id.renderButton(
                // googleButton.current,
                document.getElementById("google-auth-btn"),
                { theme: 'outline', size: 'large', width: 300 }
            )
            setRenderedGoogleBtn(true)
            console.log("rendered google button")
        }
        if(BoardStore.showAuthDialog){
            Analytics.trackShowAuthDialog({});
        } 

    }, [BoardStore.showAuthDialog])
    


    return (<Dialog
        fullWidth
        // maxWidth={'lg'}
        sx={{
            '& .MuiDialogContent-root': {
                // padding: theme.spacing(2),
                // height: 600,
                // top: 200,
            },
            '& .MuiDialogActions-root': {
                // padding: theme.spacing(1),
            },
        }}
        open={BoardStore.showAuthDialog}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => BoardStore.showAuthDialog = false}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle >
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, width: '100%', marginX: 'auto' }}>
                {UserStore.isAuthed ? <SuccessTitle /> : <ConnectTitle /> }  
            </Box> */}

        </DialogTitle>

        <DialogContent sx={{ m: 0 }}>
            <AuthForm />
            {/* <Divider></Divider> */}
            {/* <Container sx={{ width: '400px', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 0 }} >
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, width: '100%', marginX: 'auto' }}>
                {UserStore.isAuthed ? <SuccessTitle /> : <ConnectTitle /> }  
            </Box>
                {UserStore.isAuthed ?  <PostConnectForm handleClose={handleClose} /> : <SignUpForm />}

                <IconButton aria-label="delete" size="large"
                    onClick={handleClose}
                    disableFocusRipple
                    disableRipple
                    sx={{ position: 'absolute', zIndex: 100, left: 5, top: 5, }}>
                    <ClearIcon fontSize="inherit" sx={{ color: 'rgba(204, 204, 204, 1)' }} /></IconButton>

            </Container> */}

            {/* <DialogContentText id="alert-dialog-slide-description"> </DialogContentText> */}
        </DialogContent>
        <DialogActions>
            {/* <Button variant='contained' onClick={handleClose}>Done!</Button> */}
        </DialogActions>
    </Dialog>
    );
})

export default AuthDialog;