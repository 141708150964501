import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import BarChartIcon from '@mui/icons-material/BarChart';
import TabIcon from '@mui/icons-material/Tab';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { Analytics } from '../../Services/Analytics';
import * as React from 'react';
import { BoardStore } from '../../Stores/Stores';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.6rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'white',
  fontSize: '14px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    
    
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    fontSize: '14px',
    marginLeft: theme.spacing(3.5),
    padding: theme.spacing(0.5),
}));


const ChartTree = observer(() =>{
  const [expanded, setExpanded] = React.useState([]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if(newExpanded){
        setExpanded([...expanded, panel]);
    }else {
        expanded.splice(expanded.indexOf(panel))
        setExpanded([...expanded]);
    }
  };

  return (
    <div id="chart-tree">

  {BoardStore.board.tabs.map((tab) => {  
    return (
        <Accordion key={`list-tab-${tab.id}`} expanded={tab.isActive} onChange={handleChange(tab.id)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
            sx={{
                color: tab.isActive ? 'black' : '#444', 
                fontSize: 12,
                fontWeight: 'bold', 
                lineHeight: 1.2,
        }}
            onClick={(e) => BoardStore.setActiveTab(tab.id)}
            >
              <TabIcon sx={{width: 14, height: 14, borderRadius: '3px', marginRight: 1}}/>{tab.tabName}
            </AccordionSummary>

            {/* {tab && tab.charts ? tab.charts.filter((chart) => chart.key != null ).sort((c1, c2) => c1.chartSettings.zIndex < c2.chartSettings.zIndex ? 1 : -1).map( (chart) => { */}
            {tab && tab.charts ? tab.charts.map( (chart) => {
              
                return <AccordionDetails key={`list-chart-${chart.key}`} onClick={(e) => {BoardStore.setActive(chart); Analytics.trackClickChartTree()}}>
                            <Box disableRipple
                                sx={{
                                    color: chart.isActive ? 'black' : '#444', 
                                    fontWeight: chart.isActive ? 'bold' : 'regular', 
                                    fontSize: 12,
                                    marginTop:0.5,
                                    lineHeight: 1.2,
                                    display: "flex",
                                    justifyContent: "start",
                                    cursor: 'pointer'}} >
                                  {/* todo: chart icon by chart type */}
                                <BarChartIcon sx={{width: 14, height: 14, marginRight: 1, borderRadius: '3px', border: '1px solid'}}/> 
                                {chart.chartOptions.plugins.title.text}
                            </Box>
                        </AccordionDetails> 
            }) : ''}
  
        </Accordion>
)})}

    </div>
  );
})
export default ChartTree;