import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Divider, Stack } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import FAQ from "../Components/Elements/FAQSection";
import { BoardStore, UserStore } from '../Stores/Stores';
import { Drawer, drawerWidth } from "./AppTopBar";

export function HomeDrawers({ children }) {
    return <div>
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth * 1.5,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
        >

            {/* <DrawerHeader /> */}
            <Toolbar sx={{ minHeight: 'unset !important', height: '48px' }} />

            {UserStore.authStatus === UserStore.NO_USER ? '' :
                <Box sx={{ marginTop: 0, marginX: 1.5, height: '63px' }}>
                    {/* <Typography sx={{ fontSize: 16, fontWeight: '500', marginX: 0, marginY: 1, textAlign: 'center' }}>
                Getting Started
            </Typography> */}

                    <Box sx={{ marginY: 1.5, marginX: 0.5 }}>

                        {UserStore.isAnonymous ?

                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 1, gap: 2, justifyContent: 'space-around' }}>
                                <Typography color="textSecondary">Welcome, <Typography component={"span"} sx={{ fontWeight: 'bold', fontStyle: 'italic', color: 'rgba(64,64,64,1)' }}>Anonymous</Typography></Typography>
                                <Button variant="outlined" size="small" sx={{ width: '80px', color: 'rgba(64,64,64,1)', borderColor: 'rgba(64,64,64,1)', fontSize: 12 }}
                                    // onClick={() => googleSignIn(() => { navigate(`/board/${BoardStore.board.id}`); console.log("after sign up success") })}
                                    onClick={() => BoardStore.showAuthDialog = true}
                                >
                                    Sign In </Button>
                            </Box>
                            :

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar alt="You" src={UserStore.photoURL} referrerPolicy="no-referrer" sx={{ width: 42, height: 42, border: '1px solid rgba(64,64,64,1)' }} />
                                <Box sx={{ marginLeft: 1, display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>

                                    <Typography sx={{ fontSize: 16, fontWeight: '500', marginX: 0, marginY: 0, justifyContent: 'center' }}>
                                        {UserStore.displayName}</Typography>
                                    <Typography color="textSecondary"
                                        sx={{ fontSize: 11, textAlign: 'center', fontWeight: '300', fontStyle: 'italic', marginX: 0, marginY: 'auto', justifyContent: 'center' }}>
                                        {UserStore.email}</Typography>
                                </Box>
                            </Box>}
                    </Box>
                </Box>}
            <Divider></Divider>

            <FAQ></FAQ>
        </Drawer>
        <Box
            component="main"
            // sx={{  }}
            sx={{ flexGrow: 1, bgcolor: 'background.default', width: `calc(100% - ${drawerWidth * 1.5}px)`, maxWidth: `${window.innerWidth - drawerWidth * 1.5 - 30}px`, ml: `${drawerWidth * 1.5}px`, }}
        >
            {children}
        </Box>
        {/* <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth * 1.5,
                    boxSizing: 'border-box',                    
                },                
            }}

            variant="permanent"
            anchor="right"
        >

            <Toolbar sx={{ minHeight: 'unset !important', height: '48px' }} />
            <Box sx={{ height: '63px', marginBottom: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 22 }}>Getting Started Guides</Box>
            <Box>

                <BlogPosts />
            </Box>

        </Drawer> */}
    </div>;
}


const BlogPosts = () => {


    const posts = [
        { title: 'How to Design a Dashboard?', subTitle: 'The 10 steps to designing a useful dashboard', actionLink: 'https://www.dashing.app/post/design-dashboards-like-a-data-pro-a-step-by-step-guide', imageLink: 'https://static.wixstatic.com/media/b05f42_665c9b3772ae43bca21192113d71d4d6~mv2.png' },
        { title: 'The 4 types of Dashboards', subTitle: 'Learn about Strategic, Tactical, Operational, and Analytical dashboards', actionLink: 'https://www.dashing.app/post/designing-effective-dashboards-with-gestalt-principles', imageLink: 'https://static.wixstatic.com/media/b05f42_1036f89095814b5fa7b53e759f74d278~mv2.png' },
        { title: 'Data and Human Perception', subTitle: 'Designing Effective Dashboards according to the Gestalt principles', actionLink: 'https://www.dashing.app/post/designing-effective-dashboards-with-gestalt-principles', imageLink: 'https://static.wixstatic.com/media/b05f42_6ecbb419e00d4cddb7abdf58dcff6d9e~mv2.png' },
    ]

    return <Stack gap={4} sx={{ marginTop: 1 }}>
        {posts.map((post) => {
            return <Box sx={{ margin: '0 auto' }} >
                {/* https://www.dashing.app/post/designing-effective-dashboards-with-gestalt-principles */}

                <CardActionArea sx={{}} href={post.actionLink} target={'_blank'}>
                    <Card sx={{ border: '1px solid #ccc', minWidth: 275 }}>

                        <CardMedia
                            sx={{ height: 140, padding: '10px', backgroundSize: 'contain', backgroundColor: '#f1f1f1' }}
                            image={post.imageLink}
                        // image="https://static.wixstatic.com/media/b05f42_6ecbb419e00d4cddb7abdf58dcff6d9e~mv2.png"
                        />
                        <CardContent sx={{ width: 275, maxWidth: 275, whiteSpace: 'break-spaces' }}>
                            <Typography gutterBottom component="div" >
                                {post.title}

                            </Typography>
                            <Typography variant="body2" component={"div"} color="text.secondary">
                                {post.subTitle}
                            </Typography>
                        </CardContent>

                    </Card>
                </CardActionArea>
            </Box>
        })
        }</Stack>
}