import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import * as React from 'react';


const StyledToggleButtonGroupContainer = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    border: 0,
    // '&.Mui-disabled': {
    //   border: 0,
    // },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  // },
  '& .MuiToggleButton-root': {
    '& .MuiSvgIcon-root': {
      fontSize: 14
    },
  },
}));

const StyledToggleButtonGroup = observer(({ children, valueObj, txt }) => {
  return <FormGroup sx={{ marginTop: 1, width: '100%' }}>
    <FormControlLabel
      control={<StyledToggleButtonGroupContainer
        size="small"
        value={valueObj}
        // aria-label="Legend Position"
        sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'end', margin: 0, width: '100%' }}>

        {children}

      </StyledToggleButtonGroupContainer>}
      sx={{ justifyContent: 'space-between', marginLeft: 0 }}
      labelPlacement="start"
      label={<Typography color="textSecondary" sx={{ fontSize: 12 }}>{txt}</Typography>} />
  </FormGroup>;
}
)

export default StyledToggleButtonGroup;


