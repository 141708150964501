import MotionPhotosAutoOutlinedIcon from '@mui/icons-material/MotionPhotosAutoOutlined';
import TextRotateUpIcon from '@mui/icons-material/TextRotateUp';
import TextRotationAngleupIcon from '@mui/icons-material/TextRotationAngleup';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import FontStyleWidget from './FontStyleWidget';
import FontSelectWidget from './FontSelectWidget';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    // margin: 5,
    padding: theme.spacing(0.5),
    // margin: 0,
    border: 0,
    // '&.Mui-disabled': {
    //   border: 0,
    // },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  '& .MuiToggleButton-root': {
    '& .MuiSvgIcon-root': {
      fontSize: 14
    },
  },
}));

// export default function TextEditMenu({titleObj, titleType}) {
const TickEditWidget = observer(({ axis, storeObj, editType, noLabel }) => {
  const [alignment, setAlignment] = React.useState('left');
  // const titleObj = ChartStore.current.chartOptions.scales.x.ticks 
  // const titleType = (editType == 'theme' ? 'theme_' : '') + `${axis}-ticks`
  const titleType =`${axis}-ticks`
  
  
  const handleAlignment = (event, newAlignment,) => {
    setAlignment(newAlignment);
  };

  const getIcon = (direction) => {
    if (direction === "0") return <TextRotationNoneIcon />
    if (direction === "45") return <TextRotationAngleupIcon />
    if (direction === "90") return <TextRotateUpIcon />
    if (direction === "auto") return <MotionPhotosAutoOutlinedIcon />
  }

  return <Paper elevation={0}
    style={{ width: '100%' }}
    sx={{
      display: 'flex', 
      // alignItems: 'center',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      //  border: (theme) => `1px solid ${theme.palette.divider}`, 
      // , my:1
      flexWrap: 'wrap'
    }} >

    <FontStyleWidget 
          titleType={titleType}
          shouldDisplay={storeObj.chartOptions.scales[axis].ticks.display}
          toggleDisplay={() => BoardStore.toggleTicksDisplay({axis, editMode: editType})}
          font={storeObj.chartOptions.scales[axis].ticks.font}
          color={storeObj.chartOptions.scales[axis].ticks.color}
          label={noLabel ? '' : "Tick Style"}
          editMode={editType}
        />

    <FormGroup sx={{ marginTop: 1, width: '100%' }}>
      <FormControlLabel
        mt={'8px'}
        control={
          <StyledToggleButtonGroup
            size="small"
            value={alignment}
            exclusive            
            onChange={handleAlignment}
            aria-label="text alignment" >

            {['auto', '0', '45', '90'].map((direction) =>
              <ToggleButton key={`tick-edit-${direction}`} value={direction} aria-label="aligned"
                selected={(storeObj.chartOptions.scales[axis].ticks.maxRotation === direction) ||
                  (direction === 'auto' && storeObj.chartOptions.scales[axis].ticks.maxRotation !== storeObj.chartOptions.scales[axis].ticks.minRotation)
                }
                onClick={(event, newAlignment) => BoardStore.setTickAlignment({axis, align:newAlignment, editMode:editType})}>
                {getIcon(direction)}
              </ToggleButton>
            )}

          </StyledToggleButtonGroup>
        }
        sx={{ justifyContent: 'space-between', 
              marginLeft: 0,               
              // alignSelf: noLabel ? 'flex-start' : 'flex-end'
             }}
        labelPlacement="start"
        label={<Typography color="textSecondary" sx={{ fontSize: 11 }}>{noLabel ? '' : 'Tick Orientation'}</Typography>} />
    </FormGroup>
    <FontSelectWidget titleType={titleType} titleObj={storeObj.chartOptions.scales[axis].ticks} noLabel={true} />
  </Paper>
})
export default TickEditWidget;