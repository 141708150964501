import {ReactComponent as SvgKPIIcon} from '../../Assets/svgs/kpi-icon.svg';
import {ReactComponent as SvgKPIArea} from '../../Assets/svgs/KPI_Area.svg';
import {ReactComponent as SvgKPIBars} from '../../Assets/svgs/KPI_Bars.svg';
import {ReactComponent as SvgKPILine} from '../../Assets/svgs/kpi-line.svg';



import {ReactComponent as SvgLineIcon} from '../../Assets/svgs/Line.svg';
import {ReactComponent as SvgAreaIcon} from '../../Assets/svgs/Area.svg';
import {ReactComponent as SvgStackedAreaIcon} from '../../Assets/svgs/Stacked_Area.svg';
import {ReactComponent as SvgPieIcon} from '../../Assets/svgs/Pie_Chart.svg';
import {ReactComponent as SvgDoughnutIcon} from '../../Assets/svgs/Doughnut_Chart.svg';

import {ReactComponent as SvgBarGroupIcon} from '../../Assets/svgs/bar_grouped.svg';
import {ReactComponent as SvgBarStackedIcon} from '../../Assets/svgs/bar_stacked.svg';
import {ReactComponent as SvgBarHorizontalStackedIcon} from '../../Assets/svgs/bar_horizontal_stacked.svg';
import {ReactComponent as SvgBarHorizontalStacked100Icon} from '../../Assets/svgs/bar_horizontal_stacked_100.svg';
import {ReactComponent as SvgBarStacked100Icon} from '../../Assets/svgs/bar_stacked_100.svg';

import SevenKOutlinedIcon from '@mui/icons-material/SevenKOutlined';

// Filters
import {ReactComponent as SvgCalendarIcon} from '../../Assets/svgs/Calendar.svg';
import {ReactComponent as SvgKRangeIcon} from '../../Assets/svgs/Range.svg';
import {ReactComponent as SvgSListIcon} from '../../Assets/svgs/List.svg';

import TitleIcon from '@mui/icons-material/Title';
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import PieChartIcon from '@mui/icons-material/PieChart';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SsidChartIcon from '@mui/icons-material/SsidChart';

import UndoIcon from '@mui/icons-material/Undo';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const IconFactory = (chartType, chartVariant) => {

    switch (chartType) {
        case 'bar':
            return <BarChartIcon></BarChartIcon>
            // if(chartVariant.includes('stacked') && 
            //    chartVariant.includes('100') && 
            //    chartVariant.includes('horizontal')) return <SvgBarStacked100Icon/>
            
            // if(chartVariant.includes('stacked') && 
            //    !chartVariant.includes('100') && 
            //    !chartVariant.includes('horizontal')) return <SvgBarStackedIcon/>
            
            // if(!chartVariant.includes('stacked') && 
            //    chartVariant.includes('100') && 
            //    !chartVariant.includes('horizontal')) return <SvgBarHorizontalStackedIcon/>
            
            // if(!chartVariant.includes('stacked') && 
            //    !chartVariant.includes('100') && 
            //    chartVariant.includes('horizontal')) return <SvgBarHorizontalStackedIcon/>                    
            
            //    return <SvgBarStackedIcon/>      
               
                
            
//         { label: 'Grouped Bar Chart', icon: IconFactory('bar', []), onClick: () => BoardStore.addChart('bar', []) },
//   { label: 'Stacked Bar Chart', icon: IconFactory('bar', ["stacked"]), onClick: () => BoardStore.addChart('bar', ["stacked"]) },
//   { label: 'Horizontal Bar Chart', icon: IconFactory('bar', ["horizontal"]), onClick: () => BoardStore.addChart('bar', ["horizontal"]) },
//   { label: 'Horizontal Stacked', icon: IconFactory('bar', ["stacked", "horizontal"]), onClick: () => BoardStore.addChart('bar', ["stacked", "horizontal"]) },
//   { label: 'Horizontal Stacked 100%', icon: IconFactory('bar', ["stacked", "horizontal", "100"]), onClick: () => BoardStore.addChart('bar', ["stacked", "horizontal", "100"]) },
//   { label: 'Stacked 100%', icon: IconFactory('bar', ["stacked", "100"]), onClick: () => BoardStore.addChart('bar', ["stacked", "100"]) },

        case 'line':
            return <SsidChartIcon/>
            // if(chartVariant.includes('stacked')){
            //     return chartVariant.includes('fill') ? <SvgStackedAreaIcon/> : <SvgAreaIcon/>
            // }
            // return <SvgLineIcon/>;
            // TODO
        case 'pie':
            return <PieChartIcon/>
            // return <SvgPieIcon/>;
        case 'doughnut':
            // return <SvgDoughnutIcon/>;
            return <PieChartIcon/>

        case 'kpi':
            // IconFactory('kpi', ["line", "area"])
            // if(chartVariant.includes('line')){
            //     return chartVariant.includes('area') ? <SvgKPIArea/> : <SvgKPILine/>;
            // }
            // if(chartVariant.includes('bar')){
            //     return <SvgKPIBars/>;
            // }
            
            // return <SvgKPIIcon/>;
            return <SevenKOutlinedIcon/>
            // TODO

        case 'textbox':
            return <TitleIcon/>;
        
        case 'filter':
            if (chartVariant.includes('date')){
                // return <SvgCalendarIcon/>; 
                return <DateRangeIcon/>
            }else if (chartVariant.includes('range')){
                // return <SvgKRangeIcon/>;
                return <LinearScaleIcon/>
            }else if (chartVariant.includes('list')){
                // return <SvgSListIcon/>;
                return <FormatListBulletedIcon/>
            }
            break;
            
        case 'box':
            return <SquareOutlinedIcon/>;
            
        default:
            break;
        //TODO 
    }

};


export const KPIIcon = SvgKPIIcon;