// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { EmailAuthProvider, getAdditionalUserInfo, getAuth, isSignInWithEmailLink, linkWithCredential, sendSignInLinkToEmail, signInAnonymously, signInWithEmailLink, signOut, updateProfile } from "firebase/auth";
import { collection, documentId, FieldPath, getDocs, getFirestore, query, where } from "firebase/firestore";
// import { ChartStore } from "../ChartStore";
import { GoogleAuthProvider, onAuthStateChanged, signInWithCredential } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadString } from "firebase/storage";
import jwt_decode from "jwt-decode";
import { BoardStore, UserStore } from "../Stores/Stores";
import { Analytics } from "./Analytics";


const firebaseConfig = {
  apiKey: "AIzaSyBBtB8nrOTQTifI-8hhMwWSqPAMnMCv2Uw",
  authDomain: "dashing-bi.firebaseapp.com",
  // authDomain: "app.dashing.app",
  projectId: "dashing-bi",
  storageBucket: "dashing-bi.appspot.com",
  messagingSenderId: "260456464637",
  appId: "1:260456464637:web:2a26f223185f4ea5293e94",
  measurementId: "G-QB98Z66LPP"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const fbAnalytics = getAnalytics(app);
export const fbAuth = getAuth();
// const googleAuthProvider = new GoogleAuthProvider();
const storage = getStorage();
const db = getFirestore(app);
const functions = getFunctions();


// eslint-disable-next-line no-restricted-globals
// if(location.hostname === 'localhost'){
//   connectFirestoreEmulator(db, 'localhost', 8080);
//   connectStorageEmulator(storage, "localhost", 9199);
//   connectAuthEmulator(fbAuth, "http://localhost:9099");
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }

const afterSignUpCF = httpsCallable(functions, 'afterSignUp');
const inviteToDashboardCF = httpsCallable(functions, 'inviteToDashboard');
// const sendInviteToDashboard = httpsCallable(functions, 'sendEmailInviteToDashboard');
// const saveProfilePicCF = httpsCallable(functions, 'saveProfilePic');


////////////   AUTH    ////////////   
export const anonSignIn = () => {
  signInAnonymously(fbAuth).then(() => {
    // TODO: analytics?
    console.log("anon signin")
  }).catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
}

const _handleAuthStateChanged = (user) => {
  if (user) {
    UserStore.signIn({ ...user });
    Analytics.signIn(user.uid); // what about logout?
    // if (UserStore.userId !== user.uid) {
    // }
    console.log("_handleAuthStateChanged user:", user)
  } else {
    console.log("no current user", user);
  }
}

export const initAuthChangeListner = () => {
  onAuthStateChanged(fbAuth, _handleAuthStateChanged)
}

export const logOut = () => {

  signOut(fbAuth).then(() => {
      UserStore.signOut();
      window.location.href = window.location.origin
      window.location.load(window.location.origin); // TODO: no need to refresh
      // TODO: analytics
      // signInAnonymously(fbAuth).then(() => onSuccess())
    }
  )
}

export const handleGoogleConnect = (response) => {
  const idToken = response.credential;
  const googleCredential = GoogleAuthProvider.credential(idToken);

  let emailFromCredential = jwt_decode(idToken).email;

  if (emailFromCredential) {
    BoardStore.updateBoardOwnerEmail(emailFromCredential)
  }

  signInWithCredential(fbAuth, googleCredential).then((result) => {
    const googleUser = result.user;
    BoardStore.updateBoardOwnerId(googleUser?.uid)
    BoardStore.updateBoardOwnerEmail(googleUser?.email)
    _handleNewUser(result)      
    saveProfilePic();
    linkWithCredential(fbAuth.currentUser, googleCredential)
      .then((usercred) => {
        const user = usercred.user;
        console.log("Anonymous account linked with google", user);
        Analytics.googleConnect()

      }).catch((error) => {
        if (error.code === 'auth/provider-already-linked') {
            // TODO....
          } else if (error.code === 'auth/credential-already-in-use') {
          // TODO....
        }
        // console.log("Error upgrading anonymous account", error);
      });

  }).catch((error) => {
    // If there are errors we want to undo the data merge/deletion
    console.log("Sign In Error", error);
    if (fbAuth.currentUser.isAnonymous) {
      BoardStore.updateBoardOwnerEmail(null)
      BoardStore.updateBoardOwnerId(fbAuth.currentUser?.uid)
    }
  });
}

////////////                   ////////////   
////////////    EMAIL AUTH     ////////////   
////////////                   ////////////   
export const sendMagicLink = (email) => {

  const actionCodeSettings = {
    url: window.location.href + '?emailMagicLink=true',
    handleCodeInApp: true, // This must be true.
  };
  sendSignInLinkToEmail(fbAuth, email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      if (UserStore.isAnonymous) {
        BoardStore.updateBoardOwnerEmail(email)
      }
    })
    .catch((error) => {
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // console.log(errorCode)
      // console.log(errorMessage)
// TODO
    });
}

const _handleNewUser = async (creds) => {
let additionalUserInfo = getAdditionalUserInfo(creds)
    if (additionalUserInfo.isNewUser) {
      BoardStore.showAuthDialog = true;
      // TODO: analytics, add email and display
      afterSignUpCF();
      createAuthedUser(creds.user.uid)
    }else{
      BoardStore.showAuthDialog = false
    }

    // TODO
    createAuthedUser(creds.user.uid)
}

if (isSignInWithEmailLink(fbAuth, window.location.href)) {
  // Additional state parameters can also be passed via URL.
  // This can be used to continue the user's intended action before triggering
  // the sign-in operation.
  // Get the email if available. This should be available if the user completes
  // the flow on the same device where they started it.
  let email = window.localStorage.getItem('emailForSignIn');
  // console.log("isSignInWithEmailLink", window.location.href)
  if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    email = window.prompt('Please provide your email for confirmation');
  }

  signInWithEmailLink(fbAuth, email, window.location.href)
    .then((result) => {
      // console.log("------ signInWithEmailLink --------")
      // console.log("Result ", result)
      // console.log("Result User", result.user)      
      window.localStorage.removeItem('emailForSignIn');
      BoardStore.updateBoardOwnerId(result.user?.uid)
      BoardStore.updateBoardOwnerEmail(result.user?.email)
      
      _handleNewUser(result)
      
      const credential = EmailAuthProvider.credentialWithLink(email, window.location.href);
      // Link the credential to the current user.
      linkWithCredential(fbAuth.currentUser, credential)
        .then((usercred) => {})
        .catch((error) => {});
    })
    .catch((error) => {
      // console.log("------ ERROR signInWithEmailLink ERROR --------")
      console.log("Sign In Error", error);
    });
}

export const updateDBUserName = async (username) => {
  return updateProfile(fbAuth.currentUser, {
    displayName: username,
  })
}

////////////           ////////////   
////////////    DB     ////////////   
////////////           ////////////   

export const saveBoard = async (board) => {
  await setDoc(doc(db, "boards", board.id), board, {merge: true})
}

const createAuthedUser = async (userId) => {
  await setDoc(doc(db, "authedUsers", userId), 
  { 
    userId: userId, 
    signupQuestions: {},
    sharedBoards: []
  })
}

export const updateSignupQuestion = (question, answer) => {
  const docRef = doc(db, "authedUsers", fbAuth.currentUser.uid)
  const qKey = `signupQuestions.${question}`
  updateDoc(docRef, {
    [qKey] : answer 
  });
  Analytics.trackSignupQuestin({question, answer})
}

export const saveUserForm = async (form) => {
  console.log("saveUserForm",)
  if (UserStore.currentUser)
    setDoc(doc(db, "authedUsers", UserStore.userId), { user_id: UserStore.userId, sourceForm: { ...form } }, {merge: true})
}

export const getAuthedUserInfo = async (userId) => {
  console.log("getting authed user", userId, fbAuth.currentUser.uid)
  const docRef = doc(db, "authedUsers", userId);
  // const docSnap = await getDoc(docRef);
  // return docSnap.data();
  return getDoc(docRef).then(docSnap => {
    return  docSnap.data()    
  }).catch(e => {
    console.log("getAuthedUserInfo ERROR", e)
  })
}

export const getBoard = async (boardId) => {
  const docRef = doc(db, "boards", boardId);
  const docSnap = await getDoc(docRef);
  return docSnap.data()
}

export const getUserBoards = async (userId) => {
  if (!UserStore.currentUser) return []
  return _getBoardList("board.ownerId", "==", UserStore.userId)
}

export const getUserSharedBoards = async () => {
  
  console.log("trying... getUserSharedBoards")
  const authedUser = await getAuthedUserInfo(UserStore.userId)
  console.log("getUserSharedBoards", authedUser)
  if(!authedUser?.sharedBoards || authedUser.sharedBoards.length === 0) return;
  // TODO: this is limited to 10 boards!!
  console.log("getting board list", authedUser.sharedBoards)
  return _getBoardList(documentId(), 'in', authedUser.sharedBoards)
  // return _getBoardList("id", 'in', authedUser.sharedBoards)
  // return [getBoard(authedUser.sharedBoards[0])]


  // const refs = authedUser.sharedBoards.map(id => doc(`boards/${id}`))
  // const refs = authedUser.sharedBoards.map(id => doc(db, "boards", id));
  // const boards = await this.firestore.getAll(...refs)
  // return boards.map((b) => _boardPreview(b.data()))
  // const querySnapshot = await getDocs(q);
  // var res = querySnapshot.docs.map((doc) => {
  //   let boardObj = doc.data();
  //   return _boardPreview(boardObj)
  // });
  // return res;
}

// check firebase field_paths
const _getBoardList = async (field, cond, value) => {
  const q = query(collection(db, "boards"), where(field, cond, value));
  console.log("getting docs...", field, cond, value)
  // const querySnapshot = await getDocs(q);
  return getDocs(q).then(querySnapshot => {
    console.log("got querySnapshot")
    var res = querySnapshot.docs.map((doc) => {
      let boardObj = doc.data();
      return _boardPreview(boardObj)
    });
    console.log("_getBoardList", res)
    return res;
  }).catch(e => {
    console.log("failed to get boards", e, field, cond, value)
  });
}

const _boardPreview = (boardObj) => {
  return {
    id: boardObj.id,
    title: boardObj.board.title.text,
    isPrivate: boardObj.board.isPrivate,
    createdAt: boardObj.board.createdAt,
    updatedAt: boardObj.board.updatedAt,
    thumbnailUrl: boardObj.thumbnailUrl,
    collaborators: boardObj.collaborators ? Object.values(boardObj.collaborators) : [],
  }

}

export const getBoardCollaborators = async (boardId) => {
  const board = await getBoard(boardId)
  return board?.collaborators ? Object.values(board.collaborators) : []
}

export const inviteToDashboard = async ({ boardName, boardId, collaborator }) => {
  console.log("calling inviteToDashboard", boardName, boardId, collaborator)
  await inviteToDashboardCF({ boardName, boardId, collaborator }).then((result) => {
    // console.log("---------- inviteToDashboard --------------")
    // console.log(result)
  }).catch(error => {
    console.log("Failed to invite", error)
  })
}

// export const saveCollaborators = async (boardId, collaborators) => {
//   const docRef = doc(db, "boards", boardId);
//   return updateDoc(docRef, {
//     'collaborators': collaborators.map((c) => JSON.parse(JSON.stringify(c)))
//   });
// }

const _updateBoardThumbnailUrl = async (boardId, thumbnailUrl) => {

  const docRef = doc(db, "boards", boardId);
  return updateDoc(docRef, {
    'thumbnailUrl': thumbnailUrl
  })
  // .then(()=> console.log("updated url in db"));
}

export const saveBoardThumbnail = async (boardId, imageBlob) => {
  // console.log("saveBoardThumbnail currentUser.uid", UserStore.currentUser)

  const fPath = `/boards/thumbnails/${BoardStore.board.id}`
  // console.log("saveBoardThumbnail", fPath)
  const thumbnailMetadata = { contentType: 'image/jpeg', };
  const storageRef = ref(storage, fPath);

  await uploadBytes(storageRef, imageBlob, thumbnailMetadata)
  await uploadString(storageRef, imageBlob, 'data_url')

  const downloadUrl = await getDownloadURL(storageRef);
  await _updateBoardThumbnailUrl(boardId, downloadUrl)
  // console.log("downloadUrl", downloadUrl)
  return downloadUrl;
}

export const saveProfilePic = async () => {
  console.log("saving profile pic....")
  console.log("fbAuth.currentUser", fbAuth.currentUser)
  let url = UserStore.currentUser?.providerData[0]?.photoURL

  fetch(url, {
    method: "GET", // POST, PUT, DELETE, etc.
    referrerPolicy: "no-referrer", // no-referrer-when-downgrade, no-referrer, origin, same-origin...
    mode: "cors", // same-origin, no-cors
    keepalive: false, // true
  })
    .then(response => response.blob())
    .then(imageBlob => {
      // console.log("UserStore.userId", UserStore.userId)
      const fPath = `/users/${UserStore.userId}/profile.jpg`
      // console.log("writeing to storage", fPath)

      const storageRef = ref(storage, fPath);
      // /users/profilepic/{userId}
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, imageBlob).then((snapshot) => {
        // console.log('Uploaded a blob or file!', snapshot);
        getDownloadURL(storageRef).then((photoURL) => {
          // console.log('photoURL', photoURL);
          updateProfile(fbAuth.currentUser, {
            photoURL: photoURL
          }).then(() => {
            // console.log("updated profile pic....", photoURL)
            UserStore.photoURL = photoURL;
          }).catch((error) => {
            // An error occurred
            // ...
          });
        })
      });
    });

}

