import AddIcon from '@mui/icons-material/Add';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LinkIcon from '@mui/icons-material/Link';
import { AvatarGroup, CardActionArea, CardHeader, Divider, Tooltip } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { avatarColors } from '../Components/Editors/EditorWidgets/DashingColors';
import { Analytics } from '../Services/Analytics';
import { getBoard } from '../Services/DBService';
import { BoardsList, BoardStore, UserStore } from '../Stores/Stores';
import { getExampleBoard } from '../Utils/BoardTemplates';
import { AuthForm } from './AuthPage';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
//   >
//     •
//   </Box>
// );

const StyledCard = styled(Card)({

    width: '250px',
    height: '200px',
    margin: 4,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    ":hover": {
        // boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'unset'


});

const ActionCard = styled(Card)({

    width: '200px',
    height: '10px',
    margin: 4,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    ":hover": {
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'unset'


});


const CardAvatar = styled(Avatar)({

    width: '20px',
    height: '20px',
    border: '1px solid rgba(64,64,64,1)'

});



function BoardCard({ titleLabel, mainIcon, bottomTitle, bottomSubTitle, cardAction, isPrivate, thumbnailUrl, collaborators }) {

    return <CardActionArea onClick={cardAction} sx={{ width: 'auto', }}>
        <StyledCard >
            {/* <Box textAlign="center">{titleLabel}</Box> */}
            {/* <CardHeader title={titleLabel} sx={{
                width: '100%', fontSize: 14, alignItems: 'center', padding: 1,
                '& .MuiCardHeader-title': { fontSize: 14, textAlign: 'center' }
            }}> </CardHeader> */}

            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
                <Box position={'absolute'} top={10} right={10}>{titleLabel}</Box>
                {thumbnailUrl ? <CardMedia
                    sx={{ objectFit: 'cover', objectPosition: 'center top' }}
                    component="img"
                    height="140"
                    image={thumbnailUrl}
                /> :
                    <Box sx={{
                        width: '100%', height: '140px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                        background: 'repeating-conic-gradient(rgba(64,64,64,0.1) 0% 25%, transparent 0% 50%)  50% / 20px 20px',
                        "& .MuiSvgIcon-root": { transform: 'scale(2)', color: 'rgba(64,64,64,1)', border: '1.5px solid rgba(64,64,64,1)', borderRadius: '4px' }
                    }}>{mainIcon}</Box>
                }
            </CardContent>

            <CardActions sx={{ margin: 0, borderTop: '1px solid rgba(200,200,200,1)', display: 'flex', flexDirection: 'column', alignItems: 'start', height: 60 }}>
                <Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis", width: '11rem', fontSize: 14, fontWeight: 500, color: 'rgba(64,64,64,1)', textAlign: 'left' }}>{bottomTitle}</Typography>

                <Box sx={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}>
                    <Typography color="textSecondary" sx={{ textAlign: 'left', fontSize: 12, marginLeft: 0 }}><i>{bottomSubTitle}</i></Typography>


                    {/* // <LockOutlinedIcon sx={{ alignSelf: 'flex-end', margin: -0.5, color: 'rgba(64,64,64,0.8)' }} /> */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                        {collaborators &&
                            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{collaborators.map(c => (c.displayName || c.email) + "\n")}</span>}>
                                <AvatarGroup max={4} total={collaborators?.length} sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: "14px", } }}>

                                    {collaborators?.map((collaborator, idx) => {
                                        // console.log("Avater for", collaborator)
                                        // return <Avatar sx={{ width: 24, height: 24,fontSize:14 }} alt={c.displayName}> { c.displayName.split(/\s/).reduce((response,word)=> response+=word.slice(0,1).toUpperCase(),'')}</Avatar>
                                        return <Avatar sx={{
                                            width: 24, height: 24, fontSize: "14px",
                                            backgroundColor: avatarColors[Math.floor(Math.random() * avatarColors.length)]
                                        }}
                                            alt={collaborator.displayName || collaborator.email}
                                            src={collaborator.photoURL}
                                        />
                                    })}
                                </AvatarGroup>
                            </Tooltip>
                        }

                        {/* {!isPrivate && <LockOpenIcon sx={{ alignSelf: 'flex-end', color: '#0ABF53' }} />} */}
                        {!isPrivate && <Tooltip title="Anyone with a link can view">
                            <Box sx={{ display: 'flex' }}><LanguageOutlinedIcon sx={{ fontSize: 24, marginRight: 0, color: 'rgba(64,64,64,0.87)' }} /> </Box>
                        </Tooltip>
                        }

                    </Box>
                </Box>

            </CardActions>
        </StyledCard>
    </CardActionArea>
}

// BoardCard.propTypes = {
//     mainIcon: PropTypes.element
//   };


const PrivateBoards = observer(({ boards, navigateToBoard }) => {
    return boards.map(board => {
        return <BoardCard key={`private-card-${board.id}`}
            titleLabel={board.id === BoardStore.board.id ? <Chip label={"Current"} sx={{ fontSize: 14, height: 20, backgroundColor: '#0ABF53', color: 'white' }} /> : ''}
            mainIcon={<BarChartOutlinedIcon />}
            bottomTitle={board.title || "Untitled Board"}
            bottomSubTitle={"Edited " + moment.unix(board.updatedAt / 1000).fromNow()}
            isPrivate={board.isPrivate}
            cardAction={() => navigateToBoard(board.id)}
            thumbnailUrl={board.thumbnailUrl}
            collaborators={board.collaborators}
        ></BoardCard>
    })
})

const NewBoardCard = ({ createNewBorad }) => {
    return <CardActionArea sx={{ width: 'auto', }} onClick={() => createNewBorad()}>
        <StyledCard >

            {/* <Box textAlign="center">{titleLabel}</Box> */}
            <CardHeader sx={{
                width: '100%', fontSize: 14, alignItems: 'center', padding: 1,
                '& .MuiCardHeader-title': { fontSize: 14, textAlign: 'center' }
            }}> </CardHeader>

            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ "& .MuiSvgIcon-root": { transform: 'scale(3)', color: 'rgba(64,64,64,1)' } }}><AddIcon /></Box>
            </CardContent>

            <CardActions sx={{ margin: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 14, fontWeight: 500, color: 'rgba(64,64,64,1)', }}>
                    New Board
                </Typography>

            </CardActions>
        </StyledCard>
    </CardActionArea>
}

const NewBoardActionCard = ({ createNewBorad }) => {
    return <CardActionArea sx={{ width: 'auto', }} onClick={() => createNewBorad()}>
        <StyledCard >

            {/* <Box textAlign="center">{titleLabel}</Box> */}
            <CardHeader sx={{
                width: '100%', fontSize: 14, alignItems: 'center', padding: 1,
                '& .MuiCardHeader-title': { fontSize: 14, textAlign: 'center' }
            }}> </CardHeader>

            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ "& .MuiSvgIcon-root": { transform: 'scale(3)', color: 'rgba(64,64,64,1)' } }}><AddIcon /></Box>
            </CardContent>

            <CardActions sx={{ margin: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 14, fontWeight: 500, color: 'rgba(64,64,64,1)', }}>
                    New Board
                </Typography>

            </CardActions>
        </StyledCard>
    </CardActionArea>
}


const HomeSection = observer(() => {

    const navigate = useNavigate();
    const [showCopyInviteMessage, setShowCopyInviteMessage] = React.useState(false);

    const navigateToBoard = (boardId) => {
        BoardStore.boardId = boardId
        getBoard(BoardStore.boardId).then(
            (boardFromDb) => {
                BoardStore.init(boardFromDb);
                navigate(`/board/${BoardStore.board.id}`)
                BoardStore.canvasMode = "design"
                Analytics.trackCanvasMode({ mode: 'design' })
            },
            (d) => console.log("Failed to load board"))
    }

    React.useEffect(() => {
        BoardsList.refresh()

        if (UserStore.NO_USER && window.google) {
            /*global google*/
            google.accounts.id.renderButton(
                // googleButton.current,
                document.getElementById("google-auth-btn"),
                { theme: 'outline', size: 'large', width: 300 }
            )
        }
    }, [])

    const createNewBorad = () => {

        BoardStore.init();
        navigate(`/board/${BoardStore.board.id}`);
        Analytics.trackClickNewBoard();
        BoardStore.canvasMode = "design"
    }

    const createExampleBoard = () => {
        BoardStore.initFromTemplate(getExampleBoard());
        navigate(`/board/${BoardStore.board.id}`);
        Analytics.trackClickNewBoard("example board");
        BoardStore.canvasMode = "design"
    }


    const getRandomQuote = () => {
        const quotes = [{ text: "Show, don't tell", by: " Edward Tufte " },
        { text: "Less is more", by: " Edward Tufte " },
        { text: "Truthful art is non-fiction", by: " Edward Tufte " },
        { text: "Good design is as little design as possible", by: " Dieter Rams " },
        { text: "A picture is worth a thousand words", by: " Napoleon Bonaparte " },
        { text: "Design is not just what it looks like and feels like. Design is how it works", by: " Steve Jobs " },
        { text: "Simplicity is the ultimate sophistication", by: " Leonardo da Vinci " },
        { text: "Clutter and confusion are not attributes of data – they are failures of design", by: " Edward Tufte " },
        { text: "The greatest value of a picture is when it forces us to notice what we never expected to see", by: " John Tukey " },
        { text: "Design is about making the complex clear", by: " Charles Eames " },
        ]


        let q = quotes[Math.floor(Math.random() * quotes.length)];
        return <>
            <FormatQuoteRoundedIcon sx={{ alignSelf: 'start', transform: 'scaleX(-1)', fontSize: 16, color: 'rgba(64,64,64,0.6)' }} />
            <Typography sx={{ marginX: 1, fontSize: 18, fontWeight: 300 }}>{q.text} </Typography>
            <FormatQuoteRoundedIcon sx={{ alignSelf: 'end', transform: 'scaleY(-1)', fontSize: 16, color: 'rgba(64,64,64,0.6)' }} />

            <Typography sx={{ marginTop: 2, marginLeft: 5, fontStyle: 'italic', fontSize: 14, fontWeight: 500 }}> ~ {q.by}</Typography>
        </>

    }

    return <>

        {UserStore.authStatus === UserStore.NO_USER ? <AuthForm /> :
            <Box sx={{}}>
                <Box sx={{ borderBottom: '1px solid #ccc', height: '64px', position: 'sticky', top: 48, zIndex: 1000, background: 'white' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', flexGrow: 1, paddingY: 1.5, color: 'rgba(64,64,64,0.87)' }}>
                        {getRandomQuote()}
                        {/* Getting Started */}
                    </Box>
                </Box>

                {/* 
            <Box sx={{ marginTop: 3 }}>

                <ClosaebleAlert onAction={() => alert("video tutorial")}
                    leftIcon={<PlayCircleFilledWhiteOutlinedIcon sx={{ fontSize: 24 }} />}>
                    <Button sx={{ color: 'white', textTransform: 'none', display: 'flex', padding: 0, fontSize: 16, width: '100%' }}>
                        Watch a short video tutorial to get started quickly (1:32)
                    </Button>
                </ClosaebleAlert>
            </Box> */}

                <Box sx={{ marginTop: 6 }}>
                    <Box sx={{ maxWidth: '1200', marginTop: 2, justifyContent: 'start', flexDirection: 'column', display: 'flex', alignItems: 'start', marginRight: '200px' }}>


                        <Box sx={{ paddingX: 3, }}>
                            <Typography sx={{ color: 'rgba(64,64,64,1)', fontWeight: '500', fontSize: 14 }}>Getting Started</Typography>
                            <Stack direction="row" spacing={5} sx={{ marginY: 1 }}>
                                <NewBoardActionCard createNewBorad={createNewBorad} />

                                {/* <Typography sx={{ fontSize: 16, paddingX: 5, color: 'rgba(100,100,100,1)', fontStyle: 'italic'}}>Or</Typography> */}
                                <CardActionArea sx={{ width: 'auto', }} onClick={createExampleBoard}>
                                    <StyledCard sx={{}}>
                                        <CardMedia
                                            sx={{ objectFit: 'cover', objectPosition: 'center top' }}
                                            component="img"
                                            height="160"
                                            image="https://firebasestorage.googleapis.com/v0/b/dashing-bi.appspot.com/o/public%2Fimages%2Fexample_board.png?alt=media&token=bc94bcfd-8483-45b0-b6c4-653d9dcb405a"
                                        // image="https://firebasestorage.googleapis.com/v0/b/dashing-bi.appspot.com/o/public%2Fimages%2Fexample_board_thumbnail.png?alt=media&token=023e9c67-4ffc-499a-ab02-150693fefff9"
                                        // image="http://127.0.0.1:9199/v0/b/dashing-bi.appspot.com/o/boards%2Fthumbnails%2FjKMxHGi__8leM6roJOGbV%2Fthumbnail.png?alt=media&token=070d2801-e905-414e-b41f-d62039e33296"
                                        />
                                        <Typography sx={{ fontSize: 14, fontWeight: 500, color: 'rgba(64,64,64,1)', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            Example Board
                                        </Typography>
                                        {/* <CardActions sx={{ margin: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        </CardActions> */}
                                    </StyledCard>
                                </CardActionArea>

                                <CardActionArea sx={{ width: 'auto', }} href="https://calendly.com/dashing-demo" target={'_blank'}>
                                    <StyledCard >

                                        {/* <Box textAlign="center">{titleLabel}</Box> */}
                                        <CardHeader sx={{
                                            width: '100%', fontSize: 14, alignItems: 'center', padding: 1,
                                            '& .MuiCardHeader-title': { fontSize: 14, textAlign: 'center' }
                                        }}> </CardHeader>

                                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {/* <Box sx={{ "& .MuiSvgIcon-root": { transform: 'scale(3)', color: 'rgba(64,64,64,1)' } }}><PlayCircleFilledWhiteOutlinedIcon /></Box> */}
                                            <Box sx={{ "& .MuiSvgIcon-root": { transform: 'scale(3)', color: 'rgba(64,64,64,1)' } }}><EventAvailableIcon /></Box>
                                        </CardContent>

                                        <CardActions sx={{ margin: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: 14, fontWeight: 500, color: 'rgba(64,64,64,1)', textAlign: 'center' }}>
                                                {/* Troubles getting started? <br/> */}
                                                Book a Demo
                                            </Typography>

                                        </CardActions>
                                    </StyledCard>
                                </CardActionArea>

                            </Stack>
                        </Box>
                    </Box>
                    <Divider sx={{ marginTop: 2, }}></Divider>
                    <Box sx={{ minHeight: 200, marginTop: 2, paddingX: 3 }}>
                        <Typography sx={{ color: 'rgba(64,64,64,1)', fontWeight: '500', fontSize: 14 }}>Your Boards</Typography>

                        {BoardsList.loading && false ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                <CircularProgress />
                            </Box>
                            :
                            BoardsList.boards?.length === 0 ?
                                <Box sx={{ height: 200, paddingY: '50px' }}>
                                    <Typography color="textSecondary"
                                        sx={{ textAlign: 'center', fontWeight: '300', fontStyle: 'italic', marginX: 0, marginY: 'auto', justifyContent: 'center' }}
                                    > Mmm... You havn't created any boards yet</Typography>
                                    <Button variant="outlined"
                                        onClick={createNewBorad}
                                        sx={{ margin: '1em auto', marginTop: 3, display: 'flex', justifyContent: "center", fontSize: 14, fontWeight: 'regular', color: '#404040', border: '1px solid #404040' }}>
                                        <AddIcon sx={{ fontSize: 18, marginRight: 1 }} />Create your first board</Button>
                                </Box>

                                :
                                <Box sx={{ overflowX: 'scroll', }}>
                                    <Stack direction="row" spacing={5} sx={{ marginY: 3 }}>
                                        <PrivateBoards boards={BoardsList.boards} navigateToBoard={navigateToBoard} />
                                        <NewBoardCard createNewBorad={createNewBorad} />
                                    </Stack>
                                </Box>
                        }
                    </Box>
                    <Divider sx={{ marginY: 2, }}></Divider>
                    <Box sx={{ paddingX: 3 }}>
                        <Typography sx={{ color: 'rgba(64,64,64,1)', fontWeight: '500', fontSize: 14 }}>Shared with you</Typography>
                        {BoardsList.sharedBoards.length > 0 ?
                            <Box sx={{ overflowX: 'scroll', }}>
                                <Stack direction="row" spacing={5} sx={{ marginY: 3 }}>
                                    <PrivateBoards boards={BoardsList.sharedBoards} navigateToBoard={navigateToBoard} />
                                </Stack>
                            </Box>
                            :

                            <Box sx={{ overflowX: 'scroll', width: '800px', margin: '0 auto', minHeight: '200px' }}>
                                <Box sx={{ height: 100, paddingY: '50px' }}>
                                    <Typography color="textSecondary" sx={{ textAlign: 'center', fontWeight: '300', fontStyle: 'italic', marginX: 0, marginY: 'auto', justifyContent: 'center' }}>No one shared a board with you yet 😢</Typography>
                                    <Button variant="outlined"
                                        onClick={(event) => {
                                            navigator.clipboard.writeText(`Hi, Let’s design together in Dashing! It’s a new dashboard design tool for our team to design, share, and brainstorm our data products. https://dashing.app`);
                                            setShowCopyInviteMessage(true); setTimeout(() => setShowCopyInviteMessage(false), 700);
                                            Analytics.trackCopyInviteLink();
                                        }}
                                        sx={{ margin: '1em auto', marginTop: 3, display: 'flex', justifyContent: "center", fontSize: 14, fontWeight: 'regular', color: '#404040', border: '1px solid #404040' }}>
                                        <LinkIcon sx={{ fontSize: 18, marginRight: 1 }} />Copy Invite Link
                                    </Button>
                                    <Fade in={showCopyInviteMessage} timeout={{ enter: 300, exit: 700 }}>
                                        <Typography color="textSecondary" sx={{ fontSize: 12, fontStyle: 'italic', textAlign: 'center' }}>Copied Link</Typography>
                                    </Fade>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
               
            </Box>
        }
    </>
})

export default HomeSection;