
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Analytics } from '../../Services/Analytics';
import { BoardStore } from '../../Stores/Stores';

const AntTabs = styled(Tabs)({
  // borderBottom: '1px solid #e8e8e8',
  border: '1px solid #ccc',
  backgroundColor: 'rgba(250,250,250,1)',
  minHeight: 0,
  height: '36px',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  maxWidth: 200,
  fontWeight: theme.typography.fontWeightRegular,
  borderRight: "1px solid #ccc",
  fontSize: 12,
  minHeight: 0,
  height: "36px",
  color: '#888',
  
  '&:hover': {
    color: '#666',
    opacity: 1,
  },
  '&.Mui-selected': {
    // color: '#1890ff',
    // fontWeight: theme.typography.fontWeightBold,
    fontWeight: theme.typography.fontWeightMedium,
    color: '#444',
  },
//   "&.Mui-disabled": {
//     color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
// },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));


const BoardTabs = observer(() => {


  const handleChange = (event, newValue) => {
  };

  return (
    <AntTabs variant="scrollable"
               scrollButtons="auto" 
               value={BoardStore.currentTabUid} 

               onChange={handleChange} 
                sx={{borderX: '1px solid #ccc',
                 width: '100%', 
                 maxWidth: 'unset', maxHeight: 'unset'}}
               aria-label="ant example" 
               TabIndicatorProps={{ style: { background: "none"} }}>
          
          { BoardStore.board.tabs.map( (tab, idx) => {

            // {console.log("tabs disabled", !BoardStore.editMode)}
            return  <AntTab key={`board-tab-${tab.id}`}
                    // wrapped
                    style={{backgroundColor: tab.isActive ? 'rgba(200, 200,200,0.1)' : 'white'}}
                    onClick={(e) => BoardStore.setActiveTab(tab.id)}
                    sx={{paddingX: 1}}
                    value={tab.id}
                    label={
                            <Input
                              id={tab.id}
                              disabled={!BoardStore.editMode}
                              inputProps={{
                                // width: 1000,
                                // sx: {"&.MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "#000", }} // (default alpha is 0.38)}
                                onBlur: () => Analytics.trackChartEdit({editType: 'update_tab_title'})
                              } }
                              disableUnderline={true}
                              sx={{ paddingX: 0, 
                                    // width: `${ChartStore.board.tabs.get(tab.id).name.length * 6}px`, 
                                    width: `${tab.tabName.length * 6}px`, 
                                    minWidth: '16ch', 
                                    fontSize: 12, 
                                    fontWeight: 
                                    tab.isActive ? 'bold' : 'regular',
                                  }}
                              // value={ChartStore.board.tabs.get(tab.id).name} 
                              value={tab.tabName}
                              onChange={(event) => {BoardStore.updateTabTitle(tab.id, event.target.value)}} 
                              // fullWidth
                              
                              endAdornment={
                                BoardStore.board.tabs.length > 1 && tab.isActive &&
                                <InputAdornment position="end" 
                                    // disableRipple
                                    // aria-label="toggle password visibility"
                                    sx={{paddingX: 0, cursor: "pointer"}}                                    
                                    onClick={(e) => {BoardStore.deleteTab(tab.id); e.stopPropagation()}}
                                  >
                                    <CloseIcon style={{fontSize: 12}}/>
                                </InputAdornment>
                              }
                            />
          } />})}
          {BoardStore.editMode ? 
          <AntTab key={`board-tab-plus`}
                    onClick={(e) => BoardStore.addTab()}
                    sx={{width: 20, minWidth: 0}}
                    label={<AddIcon style={{fontSize: 16, color: '#444'}}/>}
          /> : ''}
          
        </AntTabs>

);
})

export default BoardTabs;