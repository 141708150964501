import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { observer } from "mobx-react-lite";
import * as React from 'react';
import { useEffect } from 'react';
import AuthDialog from '../Pages/AuthPage';
import InviteDialog from '../Pages/InviteDialog';
import { BoardStore } from '../Stores/Stores';
import { AppTopBar, DrawerHeader } from './AppTopBar';

const AppContainer = observer(({ children }) => {
  
  useEffect(() => {
  }, [BoardStore.board?.updatedAt]);

  return (
    <Box component={'div'} sx={{ display: 'flex' }}>
      <CssBaseline />
        <AuthDialog />
        <InviteDialog />
      <AppTopBar board={BoardStore.board}/>
        <Box id="app-main" component="main" sx={{ flexGrow: 1, px: 0, py: 0}}>
        {/* <Box id="app-main" component="main" sx={{ flexGrow: 1, px: 0, py: 1 }}> */}
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
});

export default AppContainer;