import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { observer } from "mobx-react-lite";
import { useState } from 'react';
import { Drawer, DrawerHeader, drawerWidth } from "./AppTopBar";
import BoardsManager, { BoardSearch } from "./BoardsManager";
import ChartTree from '../Components/Elements/ChartTree';
// import { useNavigate } from 'react-router-dom';
// import Image from './dashing-banner.png'; // Import using relative path
import * as React from 'react';
// import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { BoardStore } from '../Stores/Stores';

const styles = {
  paperContainer: {
    height: '40px',
    width: '40px',
    alignSelf: 'flex-start',
    // width: '100%',,
    // backgroundImage: `url('/banner-small.png')`,
    
    // backgroundImage: `url('https://static.wixstatic.com/media/b05f42_16b938f76190459caebb218b02a02e09~mv2.png/v1/fill/w_276,h_276,al_c,lg_1,q_85,enc_auto/dashing%20pie%20logo.png')`,
    backgroundImage: `url('/images/logo-white.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    margin: '8px',
  }
};


export const RightDrawer = observer(() => {
  // const navigate = useNavigate();
  // const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    // setOpen(true);
    BoardStore.rightDrawerOpen = true
  };
  
  const handleDrawerClose = () => {
    // setOpen(false);
    BoardStore.rightDrawerOpen = false
  };
  return <Box sx={{ overflow: 'auto' }}>

    <Drawer variant="permanent"
      anchor="right"

      open={BoardStore.rightDrawerOpen}
      sx={{
        backgroundColor: 'red',
        border: '1px solid red',
        width: drawerWidth * 1.5,
        flexShrink: 0, marginBottom: 0,

        [`& .MuiDrawer-paper`]: { width: BoardStore.rightDrawerOpen ? drawerWidth : '48px', boxSizing: 'border-box' },
      }}>
      <DrawerHeader />
      {BoardStore.editMode && <>
      <Toolbar disableGutters sx={{ "&.MuiToolbar-root" : {minHeight: "0px", } }}>
        {BoardStore.rightDrawerOpen ? <IconButton onClick={handleDrawerClose} >
                  <ChevronRightIcon />
                </IconButton> 
                :  <>
                  <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen}
                              edge="start" sx={{ width: 12, margin: '0 auto', ...(BoardStore.rightDrawerOpen && { display: 'none' }) }} >
                        <MenuIcon sx={{ width: 18}}/>
                  </IconButton> </>}
      </Toolbar>
      {BoardStore.rightDrawerOpen ? <>

      </> : ''}
      <Divider sx={{ marginY: 0 }}></Divider>
      <Box sx={{ marginTop: 2 }}>
        <BoardsManager shrink={!BoardStore.rightDrawerOpen} drawerClick={(e) => handleDrawerOpen(e)} />
      </Box>

      <Divider sx={{ marginY: 2 }}></Divider>

      {BoardStore.rightDrawerOpen ? <>
        <ChartTree />
        <Divider />
      </>
        : ''}
</>}
      {/* <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'center', marginBottom: 0, marginLeft: open ? 0 : -0.5 }}>
        <Box sx={{backgroundColor: 'rgba(64,64,64,1)', width: '100%', display: 'flex',  alignItems: 'center'}}> 
        <a href='https://dashing.app' target='blank'> <div style={styles.paperContainer} > </div> </a>
        <div style={{color: 'white', fontSize: '24px', fontWeight: 'bold', marginLeft: '24px', fontFamily: 'Helvetica'}}>Dashing</div>
        </Box> */}
        {/* <Avatar alt="Dashful" size="small"
          src={DashfulLogo} display='flex' sx={{ width: 40, height: 40 }} />
        <Typography noWrap component="span" fontFamily={"Rockwell"} display='flex'  mx={2} fontSize="24px">
          Dashing.
        </Typography> */}
      {/* </Box> */}
    </Drawer>
  </Box>;
}
);
