import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';
import StyledToggleButtonGroup from './StyledToggleButtonGroup';

const LegendPositionWidget = observer(({ storeObj, editType }) => {

    const tg = (direction, rotate) => {
        return <ToggleButton key={direction} value={direction} aria-label={direction + ' aligned'}
            sx={{ border: 0, fontSize: 12 }}
            selected={storeObj.chartOptions.plugins.legend.position === direction &&
                storeObj.chartOptions.plugins.legend.display}
            onClick={(event) => { BoardStore.updateLegend({direction, editMode: editType}) }}>

            <VerticalAlignBottomOutlinedIcon sx={{ transform: `rotate(${rotate}deg)`, strokeWidth: 4 }} />

        </ToggleButton>
    }

    return <StyledToggleButtonGroup valueObj={storeObj.chartOptions.plugins.legend.position} txt={'Position'}>
        {tg('top', -180)}
        {tg('left', 90)}
        {tg('right', -90)}
        {tg('bottom', 0)}
    </StyledToggleButtonGroup>
}

)

export default LegendPositionWidget;


