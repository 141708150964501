import { computed, makeObservable, observable, toJS } from 'mobx';
// import { mergeDeep } from './Elements/ElementsUtils';
import { mergeDeep } from '../Utils/ElementsUtils';


const _defaultColors = {
    success: 'rgba(80, 227, 194, 1)',
    danger: 'rgba(247, 76, 76, 1)',
    datasets: [
        'rgba(76, 148, 247, 1)',
        'rgba(193, 159, 248, 1)',
        // 'rgba(250, 252, 165, 1)',
        'rgba(255, 222, 32, 1)',
        'rgba(165, 252, 242, 1)',
        'rgba(86, 191, 156, 1)',
        'rgba(247, 76, 76, 1)',
        'rgba(255, 138, 101, 1)',
    ],
    palette: {
        primary: {
            main: "rgba(58, 81, 102, 1)",
        },
        secondary: {
            main: "rgba(58, 81, 102, 1)",
        },
        title: {
            main: "rgba(58, 81, 102, 1)",
        }
    },
}

const _default_theme = {

    board: {
        // backgroundColor: 'rgba(100, 100,100, 0.8)',
        backgroundColor: 'white',
    },

    colors: _defaultColors,

    charts: {
        default: {
            chartSettings: {
                layout: {
                    padding: {
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 5
                    },
                },
                width: 500,
                height: 300,
                backgroundColor: "rgba(255,255,255,1)",
                borderColor: "rgba(204, 204, 204, 1)",
                borderRadius: 0,
                borderWidth: 1,
            },
            chartOptions: {
                indexAxis: "x",
                labelType: 'month',
                seriesDataType: 'department',
                valuesDataType: 'number',
                layout: {
                    padding: {
                        top: 10,
                        right: 20,
                        left: 10,
                        bottom: 10
                    },
                },
                plugins: {
                    legend: {
                        display: true,
                        position: "top",
                        align: "end",
                        title: {
                            display: false,
                            text: "Legend Title",
                            align: "right",
                            padding: {
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 5
                            },
                            color: "rgba(58, 81, 102, 1)",
                            position: "top",
                            font: {
                                weight: "lighter",
                                style: "normal",
                                size: 10,
                                family: "Helvetica"
                            }
                        },
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            textAlign: "right",
                            radius: 6,
                            boxWidth: 6,
                            boxHeight: 6,
                            color: "rgba(125, 125, 125, 1)",
                            padding: 10,
                            font: {
                                weight: "lighter",
                                style: "normal",
                                size: 14,
                                family: "Helvetica"
                            }
                        }
                    },
                    title: {
                        text: "Chart title",
                        align: "start",
                        padding: {
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0
                        },
                        display: true,
                        color: "rgba(58, 81, 102, 1)",
                        position: "top",
                        font: {
                            weight: "bold",
                            style: "normal",
                            size: 16,
                            family: "Helvetica"
                        }
                    },
                    subtitle: {
                        text: "Chart Sub title",
                        align: "start",
                        padding: {
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 10
                        },
                        display: true,
                        color: "rgba(58, 81, 102, 1)",
                        position: "top",
                        font: {
                            weight: "lighter",
                            style: "italic",
                            size: 11,
                            family: "Helvetica"
                        }
                    },
                    datalabels: {
                        display: "auto",
                        source: "data",
                        borderRadius: 3,
                        padding: {
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 5
                        },
                        align: "start",
                        anchor: "end",
                        font: {
                            weight: "lighter",
                            style: "normal",
                            size: 10,
                            family: "Helvetica"
                        },
                        color: "rgba(58, 81, 102, 1)"
                    },
                },
                scales: {
                    y: {
                        display: true,
                        stacked: false,
                        title: {
                            text: "Values",
                            align: "center",
                            padding: {
                                top: 10,
                                right: 0,
                                left: 0,
                                bottom: 5
                            },
                            display: true,
                            color: "rgba(125, 125, 125, 1)",
                            position: "bottom",
                            font: {
                                weight: "lighter",
                                style: "normal",
                                size: 12,
                                family: "Helvetica"
                            }
                        },
                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: true,
                            drawTicks: false,
                            borderWidth: 1,
                            lineWidth: 1,
                            color: "rgba(200, 200,200, 0.2)"

                        },
                        ticks: {
                            source: "labels",
                            autoSkip: true,
                            display: true,
                            color: "rgba(58, 81, 102, 1)",
                            min: 0,
                            max: 1000,
                            maxRotation: 0,
                            minRotation: 45,

                            font: {
                                weight: "lighter",
                                style: "normal",
                                size: 12,
                                family: "Helvetica"
                            }
                        },
                        type: "linear"
                    },
                    x: {
                        display: true,
                        stacked: false,
                        title: {
                            text: "Index",
                            align: "center",
                            padding: {
                                top: 10,
                                right: 0,
                                left: 0,
                                bottom: 5
                            },
                            display: true,
                            color: "rgba(125, 125, 125, 1)",
                            position: "bottom",
                            font: {
                                weight: "lighter",
                                style: "normal",
                                size: 12,
                                family: "Helvetica"
                            }
                        },
                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: true,
                            drawTicks: false,
                            borderWidth: 1,
                            lineWidth: 1,
                            color: [
                                "rgba(200, 200,200, 0.2)"
                            ]
                        },
                        ticks: {
                            source: "labels",
                            autoSkip: true,
                            display: true,
                            color: "rgba(58, 81, 102, 1)",
                            min: 0,
                            max: 1000,
                            maxRotation: 0,
                            minRotation: 45,
                            font: {
                                weight: "lighter",
                                style: "normal",
                                size: 12,
                                family: "Helvetica"
                            }
                        },
                        bounds: "data",
                        type: "time",
                    }
                }
            },
            chartData: {
                datasets: 2,
                length: 8,
            },
        },
        kpi: {
            chartOptions: {
                plugins: {
                    metric: {
                        display: true,
                        text: '42',
                        align: 'start',
                        padding: { top: 0, right: 0, left: 0, bottom: 5 }, // make primitive
                        color: "rgba(58, 81, 102, 1)",
                        position: 'top',
                        font: {
                            weight: 'bold',
                            style: 'normal',
                            size: 48,
                            family: "Helvetica",
                        },
                    },
                    startAdornment: {
                        display: true,
                        text: '$',
                        align: 'start',
                        padding: { top: 0, right: 0, left: 0, bottom: 5 }, // make primitive
                        color: "rgba(58, 81, 102, 1)",
                        position: 'top',
                        font: {
                            weight: 'bold',
                            style: 'normal',
                            size: 36,
                            family: "Helvetica",
                        },
                    },
                    endAdornment: {
                        display: true,
                        text: 'M',
                        align: 'start',
                        padding: { top: 0, right: 0, left: 0, bottom: 5 }, // make primitive
                        color: "rgba(58, 81, 102, 1)",
                        position: 'top',
                        font: {
                            weight: 'bold',
                            style: 'normal',
                            size: 36,
                            family: "Helvetica",
                        },
                    },
                    submetric: {
                        display: true,
                        text: '⬆ 7% from last week',
                        align: 'start',
                        padding: { top: 0, right: 0, left: 0, bottom: 5 }, // make primitive
                        color: _defaultColors.success,
                        position: 'top',
                        font: {
                            weight: 'normal',
                            style: 'normal',
                            size: 14,
                            family: "Helvetica",
                        },
                    },
                }
            }
        },
        spark: {
            chartOptions: {
                plugins: {
                    metric: {
                        display: true,
                        text: '42',
                        align: 'start',
                        padding: { top: 0, right: 0, left: 0, bottom: 5 }, // make primitive
                        color: "rgba(58, 81, 102, 1)",
                        position: 'top',
                        font: {
                            weight: 'bold',
                            style: 'normal',
                            size: 48,
                            family: "Helvetica",
                        },
                    },
                    startAdornment: {
                        display: true,
                        text: '$',
                        align: 'start',
                        padding: { top: 0, right: 0, left: 0, bottom: 5 }, // make primitive
                        color: "rgba(58, 81, 102, 1)",
                        position: 'top',
                        font: {
                            weight: 'bold',
                            style: 'normal',
                            size: 36,
                            family: "Helvetica",
                        },
                    },
                    endAdornment: {
                        display: true,
                        text: 'M',
                        align: 'start',
                        padding: { top: 0, right: 0, left: 0, bottom: 5 }, // make primitive
                        color: "rgba(58, 81, 102, 1)",
                        position: 'top',
                        font: {
                            weight: 'bold',
                            style: 'normal',
                            size: 36,
                            family: "Helvetica",
                        },
                    },
                    submetric: {
                        display: true,
                        text: 'SALES',
                        align: 'start',
                        padding: { top: 0, right: 0, left: 0, bottom: 5 }, // make primitive
                        color: 'rgba(58, 81, 102, 1)',
                        position: 'top',
                        font: {
                            weight: 'normal',
                            style: 'normal',
                            size: 14,
                            family: "Helvetica",
                        },
                    },
                }
            },
        },
        pie: {
            chartOptions: {
                labelType: 'department',
                seriesDataType: 'department',
                valuesDataType: 'number',
                plugins: {
                    datalabels: {
                        display: 'auto',
                        anchor: 'center',
                        align: 'center',
                        padding: { top: 0, right: 0, left: 0, bottom: 5 }, // make primitive
                        color: 'rgba(255,255,255,0)',
                        font: {
                            weight: 'lighter',
                            style: 'normal',
                            size: 14,
                            family: "Helvetica",
                        },
                    },
                }
            },

            chartData: {
                datasets: 1,
                length: 2,
                labelType: 'category',
            }
        },
        datagrid: {
            chartOptions: {
                plugins: {
                    title: {
                        text: "Table title",
                        align: "start",
                        padding: {
                            top: 10,
                            right: 0,
                            left: 0,
                            bottom: 0
                        },
                        display: true,
                        color: "rgba(58, 81, 102, 1)",
                        position: "top",
                        font: {
                            weight: "bold",
                            style: "normal",
                            size: 16,
                            family: "Helvetica"
                        }
                    },
                    subtitle: {
                        text: "Table Subtitle...",
                        align: "start",
                        padding: {
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 5
                        },
                        display: true,
                        color: "rgba(58, 81, 102, 1)",
                        position: "top",
                        font: {
                            weight: "lighter",
                            style: "italic",
                            size: 11,
                            family: "Helvetica"
                        }
                    },
                    cell: {
                        color: "rgba(64, 64, 64, 1)",
                        font: {
                            weight: "lighter",
                            style: "normal",
                            size: 11,
                            family: "Helvetica"
                        }
                    },
                    header: {
                        color: "rgba(64, 64, 64, 1)",
                        font: {
                            weight: "bold",
                            style: "normal",
                            size: 14,
                            family: "Helvetica"
                        }
                    },
                },
            },
        },
        bar: {
            data: {
                datasets: 2,
                length: 5,
            }
        }
    }
}

class ThemeStoreImple {

    constructor() {
        // this.defaults = Object.assign({}, _default_theme.charts)
        this.theme = Object.assign({}, _default_theme)
        this.updatedAt = new Date().getTime()
        makeObservable(this, {
            theme: observable,
            updatedAt: observable,
            default: computed,
        })
    }

    get default() {
        return Object.assign({}, toJS(this.theme.charts.default))
    }

    get colors() {
        return Object.assign({}, toJS(this.theme.colors))
    }

    chartTheme(chartType){
        return mergeDeep(this.default, this.theme.charts[chartType] || {})
    }

    loadFromDB(themeFromDB) {
        this.theme = { ...themeFromDB }
    }

    resetTheme() {
        this.theme = Object.assign({}, _default_theme)
    }

    updateColor(idx, color) {
        // this.defaults.colors.datasets[idx] = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
        this.theme.colors.datasets[idx] = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    }

    updateSuccessColor(color) {
        // this.defaults.colors.danger = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
        this.theme.colors.danger = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    }

    updateDangerColor(color) {
        // this.defaults.colors.success = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
        this.theme.colors.success = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    }

    nextColor = (idx) => {
        return this.theme.colors.datasets[idx]
    }

}
export const ThemeStore = new ThemeStoreImple();


