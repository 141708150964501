import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { BoardStore } from '../../../Stores/Stores';

const NumberInputWidget = observer(({numericValue, onValueChange, label, inputAdornment, allowText, showUnderline, charLength, helperText, fontSize=12}) => {
  return <FormGroup sx={{ marginTop: 1, marginRight: 1.5 }}>
    <FormControlLabel
      mt={'8px'}
      control={<TextField
        id="outlined-number"
        size="small"
        type={allowText ? "text" : "number"}
        variant="standard"
        helperText={helperText && <span style={{ fontSize: 9, textAlign: 'center', margin: 0 }}>{helperText}</span>}
        disabled={!BoardStore.editMode}
        sx={{ input: { textAlign: "center", paddingX: 1, paddingY: 0 }}}
        // style={{ padding: 0, marginRight: '1ch' }}
        style={{ padding: 0}}

        value={numericValue}
        onChange={(e) => {onValueChange(e)}}
        onKeyDown={e=>{ e.stopPropagation()}}
        inputProps={{ style: { fontSize: fontSize, textAlign: 'right', margin: 0, padding: 0, width: `${charLength || 3}ch` } }}
        InputProps={{
          style: { fontSize: fontSize, marginBottom: 0}, 
          disableUnderline: !showUnderline, 
          inputMode: allowText ? "text" : "decimal", 
          
          // pattern: '[0-9]*', 
          // min: 0, max: 100,
          endAdornment: <InputAdornment position="end" sx={{marginLeft: '2px'}}>
            <Box componant="span" sx={{ fontSize: 11, marginLeft: '2px' }}> {inputAdornment}</Box>
          </InputAdornment>
        }} />}
      // sx={{ justifyContent: 'space-between', marginLeft: 0 }}
      sx={{ justifyContent: 'space-between', marginLeft: 0, alignSelf: label === '' ? 'flex-start' : ''  }}
      labelPlacement="start"
      label={label &&<Typography color="textSecondary" sx={{ fontSize: fontSize }}>{label}</Typography>} />
  </FormGroup>;
});

export default NumberInputWidget;
