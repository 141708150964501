import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BoardStore } from '../../../Stores/Stores';


const EditorSection = observer(({cardtitle, cardid, children, accordionDetailsProps}) => {
  
  const handleCardChange = (cardid, event) => {
      BoardStore.editorSection =  BoardStore.editorSection === cardid ? '' : cardid;
    };

    const AccordionSummary = styled((props) => (
      <MuiAccordionSummary
        {...props}
      />
    ))(({ theme }) => ({
        minHeight:0,         
      '&.Mui-expanded': {
        minHeight:0,
        height:20,
        marginTop:'10px',
        marginBottom:'10px',
      },
      '& .MuiAccordionSummary-content': {
        minHeight:0,      
      },
      // '&. Mui-disabled' : {backgroundColor: 'red', opacity: 1} 
    }));

  return <Box sx={{ }}>
          <Accordion key={`acc-card-${cardid}`} expanded={BoardStore.editMode && BoardStore.editorSection === cardid} 
                                  onChange={(event) => handleCardChange(cardid, event)}
                                  disableGutters
                                  disabled={!BoardStore.editMode}
                                  sx={{marginY: '0px', boxShadow: '0', borderTop: '1px solid #cfcfcf', borderRadius: '0 !important',
                                  // '& .Mui-disabled' : {backgroundColor: 'white !important',} 
                                  }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} 
                              aria-controls="panel1bh-content" id="panel1bh-header" 
                              // sx={{'& .Mui-disabled' : {backgroundColor: 'rgba(200,200,200,0.12)'} }}
                              // disableGutters
                              >
              <Typography sx={{ width: '100%', flexShrink: 0, fontSize: 12, fontWeight: 'bold', color: '#444',  }}> {cardtitle} </Typography>
            </AccordionSummary>
            <AccordionDetails key={`acc-details-${cardid}`} {...accordionDetailsProps} >
                {children}
            </AccordionDetails>
          </Accordion>      
        </Box>

  
})

export default EditorSection;

